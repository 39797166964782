import { captureException } from '@sentry/react'
import { useEffect } from 'react'
import { useLocation, useParams, useRouteError } from 'react-router-dom'
import { ZodError } from 'zod'
import PageErrorBoundary from '#src/components/error'
import { AUTH_REDIRECT_KEY, isAuth0Error, logout } from '#src/routes/init/auth'
import { isRouteErrorResponse } from '#src/utils/misc'

function useRouteCaptureException() {
	const params = useParams()
	const error = useRouteError()

	useEffect(() => {
		if (error) {
			if (isRouteErrorResponse(error)) {
				captureException(
					new Error(error.data ? JSON.stringify(error.data) : error.statusText),
					scope => {
						if ([401, 403, 404, 429].includes(error.status)) {
							scope.setLevel('warning')
							scope.setFingerprint([
								error.status.toString(),
								error.data ? JSON.stringify(error.data) : error.statusText,
							])
						} else if (error.status >= 400 && error.status < 500) {
							scope.setLevel('error')
						} else {
							scope.setLevel('fatal')
						}

						scope.setContext('Message', {
							messages: error.data
								? JSON.stringify(error.data)
								: error.statusText
									? error.statusText
									: 'No message',
						})

						return scope
					},
				)
			} else if (error instanceof ZodError) {
				captureException(error, scope => {
					scope.setLevel('fatal')
					scope.setFingerprint([
						'zod',
						...error.issues.map(issue => issue.path.join('.')),
					])
					scope.setContext('Zod', {
						codes: error.issues.map(issue => issue.code).join('|'),
						issues: error.issues.map(issue => issue.path.join('.')).join('|'),
						messages: error.issues.map(issue => issue.message).join('|'),
					})

					return scope
				})
			} else if (isAuth0Error(error)) {
				captureException(error, scope => {
					scope.setLevel('fatal')

					return scope
				})
			} else {
				captureException(error)
			}
		}
	}, [error, params])

	return null
}

export default function RootBoundary() {
	const error = useRouteError()

	if (!import.meta.env.PROD) {
		console.error(error)
	}

	useRouteCaptureException()

	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			return (
				<PageErrorBoundary
					title="Page Not Found"
					message="This page doesn't exist!"
					description="There's only one way back, and it's home."
				/>
			)
		}

		if (error.status === 401) {
			return <UnauthorizedBoundary />
		}

		if (error.status >= 400 && error.status < 500) {
			return (
				<PageErrorBoundary
					title="Ouch!"
					message="Bad request!"
					description="We tried our best, but something went wrong. Try something else."
				/>
			)
		}

		if (error.status >= 500) {
			return (
				<PageErrorBoundary
					title="Ouch!"
					message="Looks like a problem on our end!"
					description="Our team is aware and working to fix the issue — apologies for any inconvenience."
				/>
			)
		}
	} else if (error instanceof ZodError) {
		return (
			<PageErrorBoundary
				title="Ouch!"
				message="Looks like a problem on our end!"
				description="Our team is aware and working to fix the issue — apologies for any inconvenience."
			/>
		)
	} else if (isAuth0Error(error)) {
		return (
			<PageErrorBoundary
				title="Ouch!"
				message="Authentication service is down!"
				description={
					error.error_description ??
					error.errorDescription ??
					error.description ??
					error?.name ??
					'Try again later.'
				}
			/>
		)
	}

	return (
		<PageErrorBoundary
			title="Ouch!"
			message="Looks like a problem on our end!"
			description="Our team is aware and working to fix the issue — apologies for any inconvenience."
		/>
	)
}

function UnauthorizedBoundary() {
	const location = useLocation()

	useEffect(() => {
		localStorage.setItem(AUTH_REDIRECT_KEY, location.pathname)
		logout()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}
