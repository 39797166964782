import * as PopoverPrimitive from '@radix-ui/react-popover'
import { type QueryClient } from '@tanstack/react-query'
import { useState, type ReactNode } from 'react'
import {
	useLoaderData,
	type LoaderFunctionArgs,
	Link,
	Outlet,
	useRouteLoaderData,
} from 'react-router-dom'
import { type z } from 'zod'
import { transformEcosystemsToAccordionData } from '#src/components/ecosystems-accordion'
import EcosystemsSort from '#src/components/ecosystems-sort'
import Markdown from '#src/components/markdown'
import NoVerticals from '#src/components/no-verticals'
import { PersonaData } from '#src/components/persona'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { Dialog, DialogTrigger } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { DeleteEcosystemAsyncForm } from '#src/routes/calibrate/ecosystem/delete'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { SortEcosystemsAsyncForm } from '#src/routes/calibrate/ecosystem/sort'
import { cn, getWordCount } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import {
	type EcosystemAPISchema,
	type EcosystemAPIVerticalSchema,
} from '../ecosystem/schema'
import { autofocusVerticalMutation } from '../verticals/autofocus'
import { companyQuery } from './queries'

type CompanyDetailsResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))

		if (!company)
			throw new Response('Company Not Found', {
				status: 404,
				statusText: 'Not Found',
			})

		const ecosystems = await queryClient.fetchQuery(
			ecosystemsQuery(params.companyId),
		)

		return { companyId: params.companyId, company, ecosystems }
	}

function Section({
	title,
	ctaOrSubtitle,
	children,
}: {
	title: ReactNode
	ctaOrSubtitle: ReactNode | null
	children: ReactNode
}) {
	return (
		<section className="flex w-full flex-col gap-4">
			<div className="grid grid-cols-2 grid-rows-1 items-center">
				<h2 className="text-label-lg text-neutral-3-fg">{title}</h2>
				{ctaOrSubtitle ? (
					<div className="justify-self-end">{ctaOrSubtitle}</div>
				) : null}
			</div>
			<div>{children}</div>
		</section>
	)
}

function ValueProposition() {
	const { company } = useLoaderData() as CompanyDetailsResponse
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse
	const wordCount = getWordCount(company.valueProposition)
	const wordCountLabel =
		new Intl.PluralRules('en').select(wordCount) === 'one' ? 'word' : 'words'

	return (
		<>
			<Section
				title={
					<div className="flex items-center gap-1">
						Value Proposition
						<ProductTip
							className="text-body-sm font-normal text-neutral-3-fg"
							content="Explains how your product or service solves a problem, delivers benefits, and why it is better than the alternatives. It includes the most important information about your company, tailored to refine your verticals and personas. Built with a customer-centric approach, including your products’ offerings, features, benefits, verticals, personas, insights from customer stories, USPs, other relevant insights, and pricing (Min. 3500 words)."
						/>
						<span className="text-body-sm font-normal text-neutral-3-fg">
							({wordCount} {wordCountLabel})
						</span>
					</div>
				}
				ctaOrSubtitle={
					readOnlySession ? null : (
						<Link
							to={routes.calibrate.edit({
								companyId: company.id,
							})}
							className="flex flex-nowrap items-center gap-1 bg-transparent py-2 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
						>
							<Icon name="edit" size="sm" /> Edit
						</Link>
					)
				}
			>
				<Surface className="relative overflow-hidden">
					<Markdown className={cn(wordCount > 100 ? 'max-h-[200px]' : '')}>
						{company.valueProposition}
					</Markdown>
					{wordCount > 100 ? (
						<Dialog
							trigger={
								<DialogTrigger asChild>
									<div className="absolute bottom-0 left-0 right-0 flex h-24 items-center justify-center bg-gradient-to-b from-transparent from-10% via-20% to-[rgba(255,255,255,1)] to-60%">
										<Button size="sm">Read more</Button>
									</div>
								</DialogTrigger>
							}
							dialogTitle="Value Proposition"
							dialogDescription={`${wordCount} ${wordCountLabel}`}
							content={<Markdown>{company.valueProposition}</Markdown>}
						/>
					) : null}
				</Surface>
			</Section>
		</>
	)
}

function NoEcosystems() {
	const { company } = useLoaderData() as CompanyDetailsResponse

	return (
		<Surface className="flex min-h-[260px] flex-col items-center justify-center gap-4">
			<Icon name="ibm-cloud-pak" size="xl" className="text-neutral-3-fg" />
			<h1 className="text-body-lg text-neutral-2-fg">
				You don&apos;t have any ecosystems yet
			</h1>
			<div>
				<Button
					asChild
					variant="outline"
					className="flex flex-nowrap items-center gap-1"
				>
					<Link
						to={routes.calibrate.ecosystem.create({
							companyId: company.id,
						})}
					>
						<Icon name="add" /> Add Ecosystem
					</Link>
				</Button>
			</div>
		</Surface>
	)
}

function EcosystemData({
	ecosystem,
}: {
	ecosystem: z.infer<typeof EcosystemAPISchema>
}) {
	const { company } = useLoaderData() as CompanyDetailsResponse

	return ecosystem.verticals?.length ? (
		<section className="grid auto-rows-max grid-cols-3 gap-x-5 gap-y-8">
			{ecosystem.verticals.map(vertical => (
				<VerticalData
					key={vertical.id}
					vertical={vertical}
					ecosystemId={ecosystem.id!}
				/>
			))}
		</section>
	) : (
		<NoVerticals companyId={company.id} ecosystemId={ecosystem.id!} />
	)
}

function VerticalData({
	ecosystemId,
	vertical,
}: {
	ecosystemId: number
	vertical: z.infer<typeof EcosystemAPIVerticalSchema>
}) {
	const { company } = useLoaderData() as CompanyDetailsResponse

	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse

	return (
		<div className="space-y-3">
			<div className="grid grid-cols-[1fr,max-content] grid-rows-1 overflow-hidden border-b border-b-neutral-1-bd pb-0.5 pl-2">
				<Link
					to={routes.calibrate.verticals.index({
						companyId: company.id,
						ecosystemId: ecosystemId.toString(),
					})}
					className="truncate text-title-sm"
					onClick={() => autofocusVerticalMutation(vertical.id.toString())}
				>
					{vertical.name}
				</Link>

				{readOnlySession ? null : (
					<Link
						to={routes.calibrate.persona.edit({
							companyId: company.id,
							ecosystemId: ecosystemId.toString(),
							verticalId: vertical.id.toString(),
							personaId: vertical.personas?.[0]?.id.toString() ?? null,
						})}
						className="flex h-5 w-5 items-center justify-center rounded-sm text-label-sm font-normal text-neutral-3-fg outline-none transition-colors hover:bg-neutral-1-bg-hover"
					>
						<Icon name="add" size="sm" />
					</Link>
				)}
			</div>
			<div className="space-y-4">
				{vertical.personas?.length ? (
					vertical.personas.map(persona => (
						<PersonaData
							key={persona.id}
							id={persona.id}
							type={persona.type}
							status={persona.status}
							priority={persona.priority}
							expertise={persona.expertise}
							companyId={company.id}
							ecosystemId={ecosystemId}
							verticalId={vertical.id.toString()}
						/>
					))
				) : readOnlySession ? null : (
					<Link
						to={routes.calibrate.persona.edit({
							companyId: company.id,
							ecosystemId: ecosystemId.toString(),
							verticalId: vertical.id.toString(),
							personaId: vertical.personas?.[0]?.id.toString() ?? null,
						})}
						className="flex flex-nowrap items-center gap-1 bg-transparent py-2 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
					>
						<Icon name="add" /> Add Persona
					</Link>
				)}
			</div>
		</div>
	)
}

function EcosystemContextMenu({
	companyId,
	ecosystemId,
}: {
	companyId: string
	ecosystemId: number
}) {
	const [open, setOpen] = useState(false)
	return (
		<PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
			<PopoverPrimitive.Trigger asChild>
				<button
					type="button"
					className="flex h-5 w-5 items-center justify-center rounded-sm text-label-sm font-normal text-neutral-3-fg outline-none transition-colors hover:bg-neutral-2-bg-hover"
				>
					<Icon name="overflow-menu-horizontal" size="sm" />
				</button>
			</PopoverPrimitive.Trigger>

			<PopoverPrimitive.Portal>
				<PopoverPrimitive.Content
					updatePositionStrategy={undefined}
					hideWhenDetached={false}
					className="z-50 flex max-h-[20rem] max-w-[20rem] flex-col overflow-hidden overflow-y-auto rounded border border-neutral-1-bd bg-neutral-1-bg p-2 text-body-md text-neutral-2-fg shadow"
					side="bottom"
					sideOffset={5}
					align="start"
					collisionPadding={20}
				>
					<Link
						to={routes.calibrate.ecosystem.edit({
							companyId: companyId,
							ecosystemId: ecosystemId.toString(),
						})}
						className="flex select-none items-center gap-2 rounded-sm px-2 py-1.5 hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover"
						onClick={() => setOpen(false)}
					>
						<Icon name="pencil-1" size="sm" />
						Edit
					</Link>

					<Link
						to={routes.calibrate.verticals.create({
							companyId: companyId,
							ecosystemId: ecosystemId.toString(),
						})}
						className="flex select-none items-center gap-2 rounded-sm px-2 py-1.5 hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover"
						onClick={() => setOpen(false)}
					>
						<Icon name="category-new" size="sm" />
						Add vertical
					</Link>

					<DeleteEcosystemAsyncForm ecosystemId={Number(ecosystemId)}>
						<div className="flex select-none items-center gap-2 rounded-sm px-2 py-1.5 hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover">
							<Icon name="trash" size="sm" />
							Delete Ecosystem
						</div>
					</DeleteEcosystemAsyncForm>
				</PopoverPrimitive.Content>
			</PopoverPrimitive.Portal>
		</PopoverPrimitive.Root>
	)
}

export default function CompanyDetails() {
	const { company, ecosystems } = useLoaderData() as CompanyDetailsResponse
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse
	const [reorder, setReorder] = useState(false)
	const [order, setOrder] = useState(ecosystems.map(({ id }) => id!))

	return (
		<main className="flex h-full w-full flex-col items-center">
			<section className="flex w-full max-w-screen-2xl flex-col items-center gap-10 px-20 pb-20 pt-10">
				<h1 className="text-center text-heading-md">{company.name}</h1>

				<ValueProposition />

				<Section
					title={
						<div className="flex items-center gap-1">
							Ecosystems
							<ProductTip
								className="text-content-neutral-tertiary text-body-sm font-normal"
								content="Ecosystems: are networks of verticals with common goals and aligned customer targets, where ICPs  shape interactions and shared expertise, customer bases, and strategies."
							/>
						</div>
					}
					ctaOrSubtitle={
						readOnlySession ? null : (
							<div className="flex items-center gap-2">
								<SortEcosystemsAsyncForm
									enabled={reorder}
									toggleEnabled={() => setReorder(!reorder)}
									order={order}
									onError={() => setOrder(ecosystems.map(({ id }) => id!))}
									onCancel={() => setOrder(ecosystems.map(({ id }) => id!))}
								/>
								<Button
									asChild
									size="sm"
									className="flex flex-nowrap items-center gap-1"
								>
									<Link
										to={routes.calibrate.ecosystem.create({
											companyId: company.id,
										})}
									>
										<Icon name="add" size="sm" /> Add Ecosystem
									</Link>
								</Button>
							</div>
						)
					}
				>
					{ecosystems?.length ? (
						<EcosystemsSort
							ecosystems={transformEcosystemsToAccordionData(
								ecosystems.map(es => ({
									...es,
									content: <EcosystemData ecosystem={es} />,
									contextMenu: readOnlySession ? null : (
										<EcosystemContextMenu
											companyId={company.id}
											ecosystemId={es.id!}
										/>
									),
								})),
							)}
							order={order}
							onReorder={setOrder}
							enabled={reorder}
						/>
					) : (
						<NoEcosystems />
					)}
				</Section>
			</section>

			<Outlet />
		</main>
	)
}
