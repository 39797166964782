import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { forwardRef, type HTMLAttributes } from 'react'
import { cn } from '#src/utils/misc'

export const chipVariants = cva(
	'inline-flex flex-nowrap items-center gap-0.5 rounded-full py-0.5 px-2 text-label-sm',
	{
		variants: {
			variant: {
				green: 'bg-green-10 text-green-70',
				blue: 'bg-blue-10 text-blue-60',
				orange: 'bg-orange-10 text-orange-70',
				red: 'bg-red-10 text-red-60',
				gray: 'bg-neutral-3-bg text-neutral-2-fg',
			},
		},
		defaultVariants: {
			variant: 'green',
		},
	},
)

export interface ChipProps
	extends HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof chipVariants> {
	asChild?: boolean
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
	({ className, variant, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'div'
		return (
			<Comp
				className={cn(chipVariants({ variant, className }))}
				ref={ref}
				{...props}
			/>
		)
	},
)
Chip.displayName = 'Chip'
