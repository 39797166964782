import { useEffect, useRef } from 'react'
import { cn } from '#src/utils/misc'
import AudioVisualizer from './AudioVisualizer'

export default function CameraStream({
	className,
	analyzer,
}: {
	className?: string
	analyzer: AnalyserNode | null
}) {
	const streamRef = useRef<MediaStream | null>(null)
	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		const stream = streamRef.current
		const videoElement = videoRef.current

		const startStream = async () => {
			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				try {
					const stream = await navigator.mediaDevices.getUserMedia({
						video: true,
						audio: false,
					})

					if (videoElement) {
						streamRef.current = stream
						videoElement.srcObject = streamRef.current

						await videoElement.play()
					}
				} catch (error) {
					return
				}
			}
		}

		void startStream()

		return () => {
			if (videoElement) {
				videoElement.pause()
				videoElement.srcObject = null
			}

			if (stream) {
				stream.getTracks().forEach(t => t.stop())
				streamRef.current = null
			}
		}
	}, [])

	return (
		<div
			className={cn(
				'pointer-events-none relative z-0 flex items-center justify-center',
				className,
			)}
		>
			<div className="pointer-events-none h-40 w-40 overflow-hidden rounded-full border border-neutral-1-bd">
				<video
					ref={videoRef}
					className={cn(
						'pointer-events-none h-full w-full shrink-0 scale-x-[-1] object-cover',
					)}
				/>
			</div>
			{analyzer ? (
				<div className="absolute bottom-0 z-10 flex h-8 w-14 translate-y-1/2 items-center justify-center rounded-full border border-neutral-1-bd bg-neutral-1-bg">
					<AudioVisualizer analyzer={analyzer} />
				</div>
			) : null}
		</div>
	)
}
