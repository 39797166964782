import { queryOptions } from '@tanstack/react-query'
import {
	ChatSchema,
	ChatsSchema,
	ConversationDataSchema,
	ScenariosSchema,
	StatusSchema,
	UsersSchema,
	VoicesSchema,
} from '#src/routes/enable/roleplay/schema'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'

export const roleplayKeys = {
	all: ['enable', 'roleplay'] as const,
	status: (companyId: string, contactId: string) =>
		[...roleplayKeys.all, 'status', companyId, contactId] as const,
	conversation: (companyId: string, conversationId: string) =>
		[...roleplayKeys.all, 'chat', companyId, conversationId] as const,
	chats: (companyId: string) =>
		[...roleplayKeys.all, 'chats', companyId] as const,
	chat: (companyId: string, conversationId: string) =>
		[...roleplayKeys.all, 'chats', companyId, conversationId] as const,
	scenarios: (companyId: string) =>
		[...roleplayKeys.all, 'scenarios', companyId] as const,
	voices: (companyId: string) =>
		[...roleplayKeys.all, 'voices', companyId] as const,
	users: (companyId: string) =>
		[...roleplayKeys.all, 'users', companyId] as const,
}

export const conversationQuery = (
	companyId: string,
	conversationId: string,
) => ({
	queryKey: roleplayKeys.conversation(companyId, conversationId),
	queryFn: async () =>
		await safeFetch(
			ConversationDataSchema,
			api.roleplay.index(companyId, conversationId),
			{
				method: 'GET',
			},
		),
})

export const chatsQuery = (companyId: string) =>
	queryOptions({
		queryKey: roleplayKeys.chats(companyId),
		queryFn: async () =>
			(
				await safeFetch(ChatsSchema, api.roleplay.list(companyId), {
					method: 'GET',
				})
			).sort((a, b) =>
				new Date(a.conversation.createdAt) < new Date(b.conversation.createdAt)
					? 1
					: -1,
			),
	})

export const chatQuery = (companyId: string, conversationId: string) =>
	queryOptions({
		queryKey: roleplayKeys.chat(companyId, conversationId),
		queryFn: async () => {
			return await safeFetch(
				ChatSchema,
				api.chat.messages(companyId, conversationId),
				{
					method: 'GET',
				},
			)
		},
	})

export const statusQuery = (companyId: string, conversationId: string) =>
	queryOptions({
		queryKey: roleplayKeys.status(companyId, conversationId),
		queryFn: async () =>
			await safeFetch(
				StatusSchema,
				api.roleplay.status(companyId, conversationId),
				{
					method: 'GET',
				},
			),
	})

export const scenariosQuery = (companyId: string) =>
	queryOptions({
		queryKey: roleplayKeys.scenarios(companyId),
		queryFn: async () =>
			await safeFetch(ScenariosSchema, api.roleplay.scenarios(companyId), {
				method: 'GET',
			}),
	})

export const voicesQuery = (companyId: string) =>
	queryOptions({
		queryKey: roleplayKeys.voices(companyId),
		queryFn: async () =>
			await safeFetch(VoicesSchema, api.roleplay.voices(companyId), {
				method: 'GET',
			}),
	})

export const usersQuery = (companyId: string) =>
	queryOptions({
		queryKey: roleplayKeys.users(companyId),
		queryFn: async () =>
			await safeFetch(UsersSchema, api.roleplay.users(companyId), {
				method: 'GET',
			}),
	})
