import {
	useForm,
	getFormProps,
	getInputProps,
	getSelectProps,
	getTextareaProps,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useQuery, type QueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import {
	type LoaderFunctionArgs,
	useLoaderData,
	Link,
	Form,
} from 'react-router-dom'
import {
	Field,
	TextareaField,
	SelectTextInput,
	ErrorList,
	Select,
} from '#src/components/forms'
import RegionsInput from '#src/components/forms/regions'
import Priority from '#src/components/priority'
import Status from '#src/components/status'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import {
	Sidebar,
	SidebarFooter,
	SidebarHeader,
} from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#src/components/ui/tooltip'
import {
	criteriaQuery,
	dataPointsQuery,
} from '#src/routes/calibrate/ecosystem/queries'
import {
	STATUS_OPTIONS,
	PRIORITY_OPTIONS,
	EMPLOYEE_RANGE_OPTIONS,
	EMPLOYEE_RANGE_UPPER_LIMIT,
} from '#src/utils/enumerations'
import { useIsPending } from '#src/utils/misc'
import { COUNTRIES, REGION_CLUSTERS } from '#src/utils/regions'
import { routes } from '#src/utils/routes'
import { autofocusFieldMutation, autofocusFieldQuery } from './autofocus'
import { verticalQuery } from './queries'
import { VerticalFormSchema } from './schema'

export type VerticalEditLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const criteria = await queryClient.fetchQuery(
			criteriaQuery(params.companyId, params.ecosystemId),
		)

		const datapoints = await queryClient.fetchQuery(
			dataPointsQuery(params.companyId, params.ecosystemId),
		)

		const url = new URL(request.url)
		const duplicate = url.searchParams.get('duplicate')

		const verticalId = params.verticalId || duplicate

		if (verticalId) {
			const { id, ...vertical } = await queryClient.fetchQuery(
				verticalQuery(params.companyId, params.ecosystemId, verticalId),
			)

			return {
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				vertical: duplicate
					? { ...vertical, id: undefined, name: vertical.name + ' (Copy)' }
					: { ...vertical, id },
				criteria,
				datapoints,
			}
		}

		return {
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
			vertical: null,
			criteria,
			datapoints,
		}
	}

export default function VerticalEdit() {
	const { companyId, ecosystemId, vertical, criteria, datapoints } =
		useLoaderData() as VerticalEditLoaderResponse

	const action = vertical?.id
		? routes.calibrate.verticals.update({
				companyId: companyId,
				ecosystemId: ecosystemId,
				verticalId: vertical.id.toString(),
			})
		: routes.calibrate.verticals.save({
				companyId: companyId,
				ecosystemId: ecosystemId,
			})
	const method = vertical?.id ? 'PATCH' : 'POST'
	// NOTE: criteria management happens now without taking user out of main flow
	const formId = vertical?.id
		? 'vertical-form-' + vertical.id + '-' + criteria.length
		: 'vertical-form'

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<div className="sticky right-0 w-[26rem] shrink-0">
			<Sidebar
				header={
					<SidebarHeader>
						<h1>{vertical?.id ? 'Edit Vertical' : 'New Vertical'}</h1>
						<Link
							className="inline-flex"
							to={routes.calibrate.verticals.index({
								companyId: companyId,
								ecosystemId: ecosystemId,
							})}
						>
							<Icon name="cross-1" />
						</Link>
					</SidebarHeader>
				}
				main={
					<VerticalForm
						key={formId}
						formId={formId}
						action={action}
						method={method}
						criteria={criteria}
						vertical={vertical}
						datapoints={datapoints}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link
								to={routes.calibrate.verticals.index({
									companyId: companyId,
									ecosystemId: ecosystemId,
								})}
							>
								Cancel
							</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							type="submit"
							size="sm"
							name="intent"
							value="vertical"
							form={formId}
							disabled={isPending}
						>
							{vertical?.id ? 'Update' : 'Create'}
						</StatusButton>
					</SidebarFooter>
				}
			/>
		</div>
	)
}

function VerticalForm({
	formId,
	action,
	method,
	vertical,
	criteria,
	datapoints,
}: {
	formId: string
	action: string
	method: 'PATCH' | 'POST'
	vertical: VerticalEditLoaderResponse['vertical']
	criteria: VerticalEditLoaderResponse['criteria']
	datapoints: VerticalEditLoaderResponse['datapoints']
}) {
	const { data: autofocus } = useQuery(autofocusFieldQuery())

	const defaultCriteriaValues =
		criteria?.map(criterion => ({
			criterionId: criterion.id!,
			value:
				vertical?.answers?.find(answer => answer.criterionId === criterion.id)
					?.value ?? '',
			question: criterion.question,
		})) || []

	const defaultDataPointsValues =
		datapoints?.map(item => ({
			id: item.id!,
			value: vertical?.dataPoints?.find(i => i.id === item.id)?.value ?? '',
			title: item.title,
			bizModel: Boolean(item.inBizmodel),
		})) || []

	const defaultValue = vertical
		? {
				...vertical,
				answers: defaultCriteriaValues,
				dataPoints: defaultDataPointsValues,
			}
		: {
				name: '',
				status: 'Ongoing',
				priority: 'High',
				answers: defaultCriteriaValues,
				dataPoints: defaultDataPointsValues,
				bizDescriptionOverride: '',
				bizModelLen: 400,
				exampleCompanies: '',
				subVerticals: '',
				regions: null,
				employeesMin: '1',
				employeesMax: '10000',
				listBuildingNotes: '',
				otherNotes: null,
			}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(VerticalFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, {
				schema: VerticalFormSchema,
			})
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})
	const verticalAnswers = fields.answers.getFieldList()
	const verticalDataPoints = fields.dataPoints.getFieldList()
	const otherNotes = fields.otherNotes.getFieldList()

	useEffect(() => {
		if (autofocus && fields[autofocus as keyof typeof fields]) {
			document
				.getElementById(fields[autofocus as keyof typeof fields].id)
				?.focus()
		}
	}, [autofocus, fields])

	function onFocus(name: string) {
		return function innerOnFocus() {
			if (autofocus !== name) {
				autofocusFieldMutation(name)
			}
		}
	}

	return (
		<Form
			method={method}
			{...getFormProps(form)}
			action={action}
			className="-mx-4"
		>
			<input
				{...getInputProps(fields.id, { type: 'hidden' })}
				defaultValue={fields.id.value}
			/>

			<section className="flex flex-col gap-4 px-4">
				<Field
					labelProps={{ children: 'Vertical name' }}
					inputProps={{
						...getInputProps(fields.name, { type: 'text' }),
						placeholder: 'Enter here',
						autoFocus: autofocus === 'name' || (!autofocus && !vertical),
						onFocus: onFocus('name'),
					}}
					errors={fields.name.errors}
				/>

				<Select
					labelProps={{ children: 'Status' }}
					inputProps={{
						...getSelectProps(fields.status),
						defaultValue: fields.status.initialValue,
						value: fields.status.value,
						placeholder: '-',
						triggerProps: {
							autoFocus: autofocus === 'status',
							onFocus: onFocus('status'),
						},
					}}
					options={STATUS_OPTIONS.map(({ value, label }) => ({
						value,
						label: <Status status={label} />,
					}))}
					errors={fields.status.errors}
				/>
				<Select
					labelProps={{ children: 'Priority' }}
					inputProps={{
						...getSelectProps(fields.priority),
						defaultValue: fields.priority.initialValue,
						value: fields.priority.value,
						placeholder: '-',
						triggerProps: {
							autoFocus: autofocus === 'priority',
							onFocus: onFocus('priority'),
						},
					}}
					options={PRIORITY_OPTIONS.map(({ value, label }) => ({
						value,
						label: <Priority priority={label} />,
					}))}
					errors={fields.priority.errors}
				/>
			</section>

			{verticalAnswers.length ? (
				<>
					<section className="mb-2 mt-6 bg-neutral-2-bg">
						<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
							Qualification Criteria{' '}
							<span className="text-body-sm font-medium text-neutral-3-fg">
								{verticalAnswers.length ?? 0}
							</span>
						</h3>
					</section>

					<section className="flex flex-col gap-4 px-4">
						{verticalAnswers.map((answer, index) => {
							const input = answer.getFieldset()

							return (
								<div key={index + '-criterion'}>
									<input
										{...getInputProps(input.criterionId, { type: 'hidden' })}
										defaultValue={input.criterionId.value}
									/>
									<input
										{...getInputProps(input.question, { type: 'hidden' })}
										defaultValue={input.question.value}
									/>
									<Field
										className="flex-grow"
										labelProps={{
											children: (
												<span className="whitespace-pre-line">{`${input.question.value} (optional)`}</span>
											),
										}}
										inputProps={{
											...getInputProps(input.value, { type: 'text' }),
											placeholder: 'Enter here',
											autoFocus: autofocus === `answers[${index}].value`,
											onFocus: onFocus(`answers[${index}].value`),
										}}
										errors={input.value.errors}
									/>
								</div>
							)
						})}
					</section>
				</>
			) : null}

			{verticalDataPoints.length ? (
				<>
					<section className="mb-2 mt-6 bg-neutral-2-bg">
						<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
							Enrichment Criteria{' '}
							<span className="text-body-sm font-medium text-neutral-3-fg">
								{verticalDataPoints.length ?? 0}
							</span>
						</h3>
					</section>

					<section className="flex flex-col gap-4 px-4">
						{verticalDataPoints.map((datapoint, index) => {
							const input = datapoint.getFieldset()

							return (
								<div key={index + '-dataPoint'}>
									<input
										{...getInputProps(input.id, { type: 'hidden' })}
										value={input.id.value}
									/>
									<input
										{...getInputProps(input.title, {
											type: 'hidden',
										})}
										value={input.title.value}
									/>
									<input
										{...getInputProps(input.bizModel, {
											type: 'hidden',
										})}
										value={input.bizModel.value}
									/>
									<Field
										className="flex-grow"
										labelProps={{
											children: (
												<span className="whitespace-pre-line">{`${input.title.value} (optional)`}</span>
											),
										}}
										inputProps={{
											...getInputProps(input.value, { type: 'text' }),
											placeholder: 'Enter here',
											autoFocus: autofocus === `dataPoints[${index}].value`,
											onFocus: onFocus(`dataPoints[${index}].value`),
										}}
										errors={input.value.errors}
									/>
								</div>
							)
						})}
					</section>
				</>
			) : null}

			<section className="mb-2 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Business description
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<TextareaField
					labelProps={{ children: 'Manual description (optional)' }}
					textareaProps={{
						...getTextareaProps(fields.bizDescriptionOverride),
						placeholder: 'Enter here',
						autoFocus: autofocus === 'bizDescriptionOverride',
						onFocus: onFocus('bizDescriptionOverride'),
					}}
					errors={fields.bizDescriptionOverride.errors}
				/>

				<Field
					labelProps={{ children: 'Generated description length (words)' }}
					inputProps={{
						...getInputProps(fields.bizModelLen, { type: 'number' }),
						placeholder: 'Enter here',
						autoFocus: autofocus === 'bizModelLen',
						onFocus: onFocus('bizModelLen'),
					}}
					errors={fields.bizModelLen.errors}
				/>

				<Field
					labelProps={{ children: 'Example companies' }}
					inputProps={{
						...getInputProps(fields.exampleCompanies, { type: 'text' }),
						placeholder: 'Enter here',
						autoFocus: autofocus === 'exampleCompanies',
						onFocus: onFocus('exampleCompanies'),
					}}
					errors={fields.exampleCompanies.errors}
				/>
				<Field
					labelProps={{ children: 'Sub-Verticals' }}
					inputProps={{
						...getInputProps(fields.subVerticals, { type: 'text' }),
						placeholder: 'Enter here',
						autoFocus: autofocus === 'subVerticals',
						onFocus: onFocus('subVerticals'),
					}}
					errors={fields.subVerticals.errors}
				/>
				<RegionsInput
					labelProps={{ children: 'Locations' }}
					includeInputProps={{
						...getInputProps(fields.regions, { type: 'text' }),
						value: fields.regions.value,
					}}
					excludeInputProps={{
						...getInputProps(fields.regionsExcluded, { type: 'text' }),
						value: fields.regionsExcluded.value,
					}}
					autoFocus={autofocus === 'regions'}
					onFocus={onFocus('regions')}
					options={REGION_CLUSTERS.map(({ name, regions }) => ({
						value: name,
						label: regions?.length ? (
							<TooltipProvider delayDuration={100}>
								<Tooltip>
									<TooltipTrigger asChild>
										<span>{name}</span>
									</TooltipTrigger>
									<TooltipContent
										side="left"
										align="center"
										className="max-w-[220px]"
										avoidCollisions
										collisionPadding={20}
									>
										{regions?.join(', ')}
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						) : (
							<span>{name}</span>
						),
						keywords: regions,
					})).concat(
						...COUNTRIES.map(c => ({
							value: c.name,
							label: <span>{c.name}</span>,
							keywords: [c.name, c.code],
						})),
					)}
					errors={fields.regions.errors}
				/>

				<div className="grid grid-cols-2 grid-rows-1 gap-4">
					<SelectTextInput
						labelProps={{ children: 'Min Employees' }}
						inputProps={{
							...getInputProps(fields.employeesMin, { type: 'text' }),
							placeholder: '-',
							autoFocus: autofocus === 'employeesMin',
							onFocus: onFocus('employeesMin'),
							onChange: e => {
								const value = e.currentTarget.value
								if (
									Number(value) >= Number(fields.employeesMax.value) ||
									!fields.employeesMax.value
								) {
									form.update({
										name: fields.employeesMax.name,
										value,
									})
								}
							},
						}}
						options={EMPLOYEE_RANGE_OPTIONS}
						errors={fields.employeesMin.errors}
					/>
					<SelectTextInput
						labelProps={{ children: 'Max Employees' }}
						inputProps={{
							...getInputProps(fields.employeesMax, { type: 'text' }),
							placeholder: '-',
							autoFocus: autofocus === 'employeesMax',
							onFocus: onFocus('employeesMax'),
						}}
						options={EMPLOYEE_RANGE_OPTIONS.concat(EMPLOYEE_RANGE_UPPER_LIMIT)}
						errors={fields.employeesMax.errors}
					/>
				</div>
			</section>

			<section className="mb-2 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Notes
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<TextareaField
					labelProps={{
						children: 'List building notes',
					}}
					textareaProps={{
						...getTextareaProps(fields.listBuildingNotes),
						placeholder: 'Enter here',
					}}
					errors={fields.listBuildingNotes.errors}
				/>

				{otherNotes.map((note, index) => {
					return (
						<TextareaField
							key={index + '-note'}
							labelProps={{
								children: (
									<span className="flex items-center justify-between pb-1">
										Note #{index + 1}
										<button
											type="button"
											onClick={() =>
												form.remove({ name: fields.otherNotes.name, index })
											}
										>
											<Icon name="trash" />
										</button>
									</span>
								),
							}}
							textareaProps={{
								...getTextareaProps(note),
								placeholder: 'Enter here',
								autoFocus: autofocus === 'otherNotes' && index === 0,
								onFocus: onFocus('otherNotes'),
							}}
							errors={note.errors}
						/>
					)
				})}
				<button
					className="flex items-center gap-2 text-body-md text-link outline-none hover:text-link-hover focus:underline active:text-link-pressed"
					{...form.insert.getButtonProps({
						name: fields.otherNotes.name,
					})}
					autoFocus={autofocus === 'otherNotes' && !otherNotes.length}
				>
					<Icon name="add" size="sm" />
					Add note
				</button>
			</section>

			<ErrorList errors={form.errors} id={form.errorId} />
		</Form>
	)
}
