import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { GlossaryTermListAPISchema } from './schema'

export const glossaryKeys = {
	all: ['glossary'] as const,
	list: (companyId: string) =>
		[...glossaryKeys.all, 'list', companyId] as const,
	term: (companyId: string, termId: string) =>
		[...glossaryKeys.all, 'term', companyId, termId] as const,
}

export const glossaryQuery = (companyId: string) => ({
	queryKey: glossaryKeys.list(companyId),
	queryFn: async () => {
		return await safeFetch(
			GlossaryTermListAPISchema,
			api.glossary.list(companyId),
			{
				method: 'GET',
			},
		)
	},
})
