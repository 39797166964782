import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import { updateAssignedSignals } from './mutations'
import { assignedSignalsQuery, signalsKeys } from './queries'
import { AssignedSignalsFormSchema } from './schema'

export type PersonaAssignActionResponse = Awaited<
	ReturnType<ReturnType<typeof action>>
>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.signalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()
		const intent = formData.get('intent') as string | null | undefined

		const submission = parseWithZod(formData, {
			schema: AssignedSignalsFormSchema,
		})

		const assignedSignals = (
			await queryClient.fetchQuery(assignedSignalsQuery(params.companyId))
		).filter(s => s.signalId === Number(params.signalId))

		if (submission.status !== 'success') {
			throw Error(
				`Failed to parse form data: ${JSON.stringify(submission.error)}`,
			)
		} else if (!intent) {
			throw Error('Form is missing intent')
		}

		const { personas } = submission.value

		const data = personas
			.filter(p => p.selected === 'on')
			.map(p => ({
				id: p.id,
				manualInput: p.manualInput ?? null,
			}))

		let payload:
			| Array<{ id: number; manualInput: string | null | undefined }>
			| undefined

		if (intent === 'map') {
			const newPersonas = data.filter(
				p => !assignedSignals.some(a => a.personaId === p.id),
			)
			const oldPersonas = assignedSignals.map(signal => {
				const persona = data.find(p => p.id === signal.personaId)

				if (!persona) {
					return {
						id: signal.personaId,
						manualInput: signal.manualInput,
					}
				}

				return {
					id: persona.id,
					manualInput: persona.manualInput ?? null,
				}
			})

			payload = oldPersonas.concat(newPersonas)
		} else if (intent === 'unmap') {
			payload = assignedSignals
				.filter(p => !data.some(a => a.id === p.personaId))
				.map(p => ({
					id: p.personaId,
					manualInput: p.manualInput,
				}))
		} else if (intent === 'unmap-all') {
			payload = []
		}

		if (!payload) {
			throw Error('Failed to parse form data')
		}

		try {
			await updateAssignedSignals(params.companyId, params.signalId, {
				personas: payload,
			})
		} catch (error) {
			return {
				ok: false,
				action: intent,
			}
		}

		void queryClient.invalidateQueries({
			queryKey: signalsKeys.all,
		})

		return {
			ok: true,
			action: intent,
		}
	}
