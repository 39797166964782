import { client as queryClient } from '#src/main'
import { api } from '#src/utils/endpoints'
import { PRIORITY_RANK, STATUS_RANK } from '#src/utils/enumerations'
import { safeFetch } from '#src/utils/safeFetch'
import { VerticalAPISchema, VerticalListAPISchema } from './schema'

export const verticalKeys = {
	all: ['vertical'] as const,
	list: (companyId: string, ecosystemId: number) =>
		[...verticalKeys.all, 'list', companyId, ecosystemId] as const,
	details: () => [...verticalKeys.all, 'details'] as const,
	detail: (companyId: string, verticalId: string) =>
		[...verticalKeys.details(), companyId, verticalId] as const,
}

export const verticalsQuery = (companyId: string, ecosystemId: number) => ({
	queryKey: verticalKeys.list(companyId, ecosystemId),
	queryFn: async () => {
		const verticals = await safeFetch(
			VerticalListAPISchema,
			api.ecosystem.verticals(companyId, ecosystemId.toString()),
			{
				method: 'GET',
			},
		)

		verticals.sort((a, b) => {
			const statusA = STATUS_RANK[a.status]
			const statusB = STATUS_RANK[b.status]
			const statusComparison = statusA - statusB
			if (statusComparison !== 0) {
				return statusComparison
			}

			const priorityComparison =
				PRIORITY_RANK[a.priority] - PRIORITY_RANK[b.priority]
			return priorityComparison
		})

		return verticals
	},
})

export const verticalQuery = (
	companyId: string,
	ecosystemId: string,
	verticalId: string,
) => ({
	queryKey: verticalKeys.detail(companyId, verticalId),
	queryFn: async () => {
		return await safeFetch(
			VerticalAPISchema,
			api.vertical.details(companyId, Number(verticalId)),
			{
				method: 'GET',
			},
		)
	},
	initialData: () => {
		const verticals = queryClient.getQueryData(
			verticalKeys.list(companyId, Number(ecosystemId)),
		) as Awaited<
			ReturnType<ReturnType<typeof verticalsQuery>['queryFn']>
		> | null

		if (verticals?.length) {
			const vertical = verticals.find(v => v.id === Number(verticalId))

			if (vertical && VerticalAPISchema.safeParse(vertical).success) {
				return vertical
			}

			return undefined
		}

		return undefined
	},
})
