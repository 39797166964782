import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs } from 'react-router-dom'
import { updateTAM } from '#src/routes/admin/tam/mutations'
import { TAMFormSchema } from '#src/routes/admin/tam/schema'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'

export type EditActionResponse = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ request, params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, { schema: TAMFormSchema })

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		try {
			await updateTAM(params.companyId, params.verticalId, submission.value)

			void queryClient.invalidateQueries({
				queryKey: verticalKeys.list(
					params.companyId,
					Number(params.ecosystemId),
				),
			})

			return {
				ok: true,
			}
		} catch (error) {
			let errorMessage =
				'Something went wrong with updating TAM details. Try again later.'

			if (error instanceof Response) {
				const errorBody = (await error.json()) as { message?: string }

				if (errorBody?.message) {
					errorMessage = errorBody.message
				}
			}

			return {
				ok: false,
				result: submission.reply({
					formErrors: [errorMessage],
				}),
			}
		}
	}
