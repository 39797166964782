import {
	getFormProps,
	getInputProps,
	getSelectProps,
	getTextareaProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import {
	redirect,
	type LoaderFunctionArgs,
	useLoaderData,
	Link,
	Form,
	type ActionFunctionArgs,
	useActionData,
} from 'react-router-dom'
import { type z } from 'zod'
import { ErrorList, Field, Select, TextareaField } from '#src/components/forms'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import {
	Sidebar,
	SidebarFooter,
	SidebarHeader,
} from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import { personaQuery } from '#src/routes/calibrate/personas/queries'
import { MONTH_SELECT_OPTIONS } from '#src/utils/enumerations'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { createSalesCoach, updateSalesCoach } from './mutations'
import { salesCoachQuery, salesCoachKeys } from './queries'
import {
	SalesCoachAdjustmentsFormSchema,
	type SalesCoachListSchema,
} from './schema'

export type SalesCoachLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (
			!params.companyId ||
			!params.ecosystemId ||
			!params.verticalId ||
			!params.personaId
		)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const persona = await queryClient.fetchQuery(
			personaQuery(params.companyId, params.personaId),
		)

		if (!persona)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const salesCoach = await queryClient.fetchQuery(
			salesCoachQuery(params.companyId, params.personaId),
		)

		return {
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
			verticalId: params.verticalId,
			personaId: params.personaId,
			salesCoach,
		}
	}

export type SalesCoachActionResponse = Awaited<
	ReturnType<ReturnType<typeof action>>
>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (
			!params.companyId ||
			!params.verticalId ||
			!params.ecosystemId ||
			!params.personaId
		)
			throw redirect('/')

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: SalesCoachAdjustmentsFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		try {
			const salesCoach = await queryClient.ensureQueryData(
				salesCoachQuery(params.companyId, params.personaId),
			)

			const { month, ...adjustments } = submission.value

			if (salesCoach?.calibrated) {
				await updateSalesCoach(
					params.companyId,
					params.personaId,
					salesCoach.calibrated.id,
					adjustments,
				)
			}

			if (salesCoach?.timing && salesCoach?.timing?.month === month) {
				await updateSalesCoach(
					params.companyId,
					params.personaId,
					salesCoach.timing.id,
					adjustments,
				)
			} else if (month) {
				const sc = salesCoach?.rest.find(sc => sc.month == month)

				if (sc) {
					await updateSalesCoach(
						params.companyId,
						params.personaId,
						sc.id,
						adjustments,
					)
				} else {
					await createSalesCoach(
						params.companyId,
						params.personaId,
						submission.value,
					)
				}
			}

			void queryClient.invalidateQueries({
				queryKey: salesCoachKeys.list(params.companyId, params.personaId),
			})

			return redirect(
				routes.calibrate.persona.index({
					companyId: params.companyId,
					ecosystemId: params.ecosystemId,
					verticalId: params.verticalId,
					personaId: params.personaId,
				}),
			)
		} catch (error) {
			if (error instanceof Response && error.status === 422) {
				captureException(error)

				return {
					ok: false,
					result: submission.reply({
						formErrors: ['Failed to submit form. Try again later.'],
					}),
				}
			}

			throw error
		}
	}

export default function SalesCoach() {
	const { companyId, ecosystemId, verticalId, personaId, salesCoach } =
		useLoaderData() as SalesCoachLoaderResponse

	const action = routes.calibrate.persona.coach.index({
		companyId: companyId,
		ecosystemId: ecosystemId,
		verticalId: verticalId,
		personaId: personaId,
	})
	const method = 'PATCH'
	const formId = 'salescoach-form-' + personaId

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	const cancelTo = routes.calibrate.persona.index({
		companyId: companyId,
		ecosystemId: ecosystemId,
		verticalId: verticalId,
		personaId: personaId,
	})

	return (
		<div className="w-[26rem]">
			<Sidebar
				header={
					<SidebarHeader>
						<h1 className="text-title-sm">Sales Coach Adjustments</h1>
						<Link className="inline-flex" to={cancelTo}>
							<Icon name="cross-1" />
						</Link>
					</SidebarHeader>
				}
				main={
					<SalesCoachForm
						formId={formId}
						method={method}
						action={action}
						month={salesCoach?.timing?.month}
						adjustments={salesCoach?.adjustments}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link to={cancelTo}>Cancel</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							size="sm"
							type="submit"
							form={formId}
							disabled={isPending}
						>
							Save
						</StatusButton>
					</SidebarFooter>
				}
			/>
		</div>
	)
}

function SalesCoachForm({
	formId,
	action,
	method,
	month,
	adjustments,
}: {
	formId: string
	action: string
	method: 'PATCH'
	month: number | null | undefined
	adjustments: z.infer<typeof SalesCoachListSchema>['adjustments'] | null
}) {
	const actionData = useActionData() as SalesCoachActionResponse

	const defaultValue = adjustments
		? {
				...adjustments,
				month: month ? String(month) : '',
			}
		: {
				questionCount: 5,
				defuse: '',
				wordsMin: 30,
				wordsMax: 50,
				criteria: '',
				valueProp: '',
				month: '',
			}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(SalesCoachAdjustmentsFormSchema),
		defaultValue,
		lastResult: actionData && 'result' in actionData ? actionData.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, {
				schema: SalesCoachAdjustmentsFormSchema,
			})
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<Form
			method={method}
			{...getFormProps(form)}
			action={action}
			className="-mx-4"
		>
			<section className="mb-2 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Question calibration
					<ProductTip content="Calibrated Questions: Tailored questions designed to engage prospects meaningfully by acknowledging their current efforts and introducing strategic areas for improvement." />
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Number of Questions
								<ProductTip content="Number of Questions: Set the number of calibrated questions for sales interactions to control the depth and focus of prospect engagement." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.questionCount, { type: 'number' }),
					}}
					errors={fields.questionCount.errors}
				/>

				<div className="flex items-center gap-4">
					<Field
						className="flex-grow"
						labelProps={{
							children: (
								<div className="flex items-center gap-1">
									Min # of words for Qs
									<ProductTip content="Min/Max # of Words for Questions: Define the word count range for each question to ensure they are concise yet comprehensive enough for meaningful engagements." />
								</div>
							),
						}}
						inputProps={{
							...getInputProps(fields.wordsMin, { type: 'number' }),
						}}
						errors={fields.wordsMin.errors}
					/>
					<Field
						className="flex-grow"
						labelProps={{
							children: (
								<div className="flex items-center gap-1">
									Max # of words for Qs
									<ProductTip content="Min/Max # of Words for Questions: Define the word count range for each question to ensure they are concise yet comprehensive enough for meaningful engagements." />
								</div>
							),
						}}
						inputProps={{
							...getInputProps(fields.wordsMax, { type: 'number' }),
						}}
						errors={fields.wordsMax.errors}
					/>
				</div>

				<TextareaField
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Manual input for calibrated questions
								<ProductTip content="Manual Input for Calibrated Questions: Enter specific questions to address the unique challenges and needs of your prospects." />
							</div>
						),
					}}
					textareaProps={{
						...getTextareaProps(fields.criteria),
					}}
					errors={fields.criteria.errors}
				/>
			</section>

			<section className="mb-2 mt-6 flex items-center justify-between bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Timing + Context
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Choose month
								<ProductTip content="Choose Month: Select the month for setting the context and timing of your sales strategy to ensure it is timely and relevant to market conditions." />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.month),
						defaultValue:
							fields.month.initialValue ??
							(new Date().getMonth() + 2).toString(),
						value: fields.month.value ?? (new Date().getMonth() + 2).toString(),
						placeholder: '-',
					}}
					options={MONTH_SELECT_OPTIONS}
					errors={fields.month.errors}
				/>

				<TextareaField
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Manual input for timing context
								<ProductTip content="Manual Input for Timing Context: Provide additional context for the selected month, such as seasonal trends or industry events, to tailor your sales efforts to current market happenings." />
							</div>
						),
					}}
					textareaProps={{
						...getTextareaProps(fields.valueProp),
					}}
					errors={fields.valueProp.errors}
				/>
			</section>

			<section className="mb-2 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Objection calibration
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<TextareaField
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Objections to defuse
								<ProductTip content="Objection Calibration - Objections to Defuse: Common objections from prospects to prepare effective responses for overcoming them during sales conversations." />
							</div>
						),
					}}
					textareaProps={{
						...getTextareaProps(fields.defuse),
					}}
					errors={fields.defuse.errors}
				/>

				<ErrorList errors={form.errors} id={form.errorId} />
			</section>
		</Form>
	)
}
