import { type QueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import {
	type LoaderFunctionArgs,
	Outlet,
	Link,
	useNavigate,
	useLoaderData,
} from 'react-router-dom'
import { z } from 'zod'
import ContactAvatar, {
	avatarVariantByPersonaType,
} from '#src/components/chat/avatar'
import NoRoleplay from '#src/components/no-roleplay'
import { PersonaAvatar } from '#src/components/persona'
import { TrainingScenario } from '#src/components/training-scenario'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import {
	chatsQuery,
	conversationQuery,
} from '#src/routes/enable/roleplay/queries'
import { userQuery } from '#src/routes/init/user/me'
import { UserSchema } from '#src/routes/init/user/schema'
import {
	checkIsAdminSession,
	checkIsReadOnlySession,
	cn,
	formatPersonaTypeAbbr,
} from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { ChatsSchema, ConversationDataSchema } from './schema'

export type RoleplayLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const RoleplayLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
	}),
	conversationId: z.string().nullable().optional(),
	canRestartChat: z.boolean(),
	canDeleteChat: z.boolean().optional(),
	conversationData: ConversationDataSchema.nullable(),
	conversationStatus: z
		.enum(['waiting_for_contact', 'closed', 'ready', 'initializing', 'crashed'])
		.nullable(),
	chats: ChatsSchema,
	user: UserSchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const chats = await queryClient.fetchQuery(chatsQuery(params.companyId))
		const user = await queryClient.fetchQuery(userQuery())
		const adminSession = checkIsAdminSession(user.roles)

		if (!params.conversationId)
			return {
				handle: {
					companyId: params.companyId,
				},
				conversationId: undefined,
				canRestartChat: false,
				conversationData: null,
				conversationStatus: null,
				chats,
				user,
			}

		const conversationData = await queryClient.fetchQuery(
			conversationQuery(params.companyId, params.conversationId),
		)

		return {
			handle: {
				companyId: params.companyId,
			},
			conversationId: params.conversationId,
			conversationData,
			conversationStatus: conversationData?.conversation.status,
			chats,
			user,
			canRestartChat:
				['ready', 'crashed', 'closed'].includes(
					conversationData?.conversation.status ?? '',
				) && adminSession,
			canDeleteChat: conversationData?.conversation.canDelete,
		}
	}

export default function Roleplay() {
	const { chats } = useLoaderData() as RoleplayLoaderResponse

	if (!chats?.length) {
		return (
			<main className="flex h-full w-full flex-col items-center justify-center px-8">
				<NoRoleplay />

				<Outlet />
			</main>
		)
	}

	return (
		<>
			<Dashboard />

			<Outlet />
		</>
	)
}

function Dashboard() {
	const params = useParsedRouteParams(['companyId'])
	const navigate = useNavigate()
	const { chats, user } = useLoaderData() as RoleplayLoaderResponse

	const readOnlySession = checkIsReadOnlySession(user.roles)
	const adminSession = checkIsAdminSession(user.roles)

	return (
		<section className="flex h-full w-full flex-col items-center">
			<section className="flex w-full max-w-screen-2xl items-center justify-between px-20 pb-4 pt-10">
				<h1 className="pb-2 text-heading-sm text-neutral-1-fg">
					Roleplay training
				</h1>
				{!readOnlySession ? (
					<div className="flex items-center gap-2">
						<Button size="sm" asChild className="flex items-center gap-1">
							<Link
								to={routes.enable.roleplay.new({
									companyId: params.companyId,
									newRoleplaySessionType: 'group',
								})}
							>
								<Icon name="user-multiple" size="sm" />
								New Group Session
							</Link>
						</Button>
						<Button size="sm" asChild className="flex items-center gap-1">
							<Link
								to={routes.enable.roleplay.new({
									companyId: params.companyId,
									newRoleplaySessionType: 'solo',
								})}
							>
								<Icon name="user" size="sm" />
								New Solo Session
							</Link>
						</Button>
					</div>
				) : null}
			</section>

			<section className="w-full max-w-screen-2xl px-20">
				<table className="w-full">
					<thead className="text-left">
						<tr>
							<th className="py-3 pl-3 text-label-md text-neutral-2-fg">
								Session Name
							</th>
							<th className="py-3 text-label-md text-neutral-2-fg">
								Training scenario
							</th>
							<th className="py-3 text-label-md text-neutral-2-fg">
								Persona used
							</th>
							<th className="py-3 text-label-md text-neutral-2-fg">Status</th>
							{adminSession ? (
								<th className="py-3 text-label-md text-neutral-2-fg">
									Created by
								</th>
							) : null}
							<th className="py-3 pr-3 text-right text-label-md text-neutral-2-fg">
								Date created
							</th>
						</tr>
					</thead>

					<tbody className="text-left">
						{chats.map(chat => (
							<tr
								key={chat.conversation.id}
								className="cursor-pointer border-none bg-transparent transition-colors hover:bg-neutral-1-bg-hover"
								onClick={() =>
									navigate(
										chat.conversation.status === 'closed'
											? routes.enable.roleplay.review({
													companyId: params.companyId,
													conversationId: chat.conversation.id,
												})
											: routes.enable.roleplay.session({
													companyId: params.companyId,
													conversationId: chat.conversation.id,
												}),
									)
								}
							>
								<td className="py-4 pl-3">
									<p className="text-body-md text-neutral-1-fg">
										{chat.conversation.title ?? 'Untitled'}
									</p>
								</td>
								<td className="py-4">
									<TrainingScenario
										title={chat.conversation.scenario ?? ''}
										icon="user-service-desk"
									/>
								</td>
								<td className="py-4">
									<div className="grid grid-cols-[max-content,1fr] items-center gap-x-2">
										<PersonaAvatar
											type={chat.participant.persona.type ?? ''}
											size="sm"
											className="row-span-2"
										/>
										<div>
											<p className="w-full truncate text-label-sm text-neutral-3-fg">
												{chat.conversation.name}
											</p>
											<p className="w-full truncate text-body-sm font-semibold text-neutral-1-fg">
												{chat.participant.persona.type}
											</p>
										</div>
									</div>
								</td>
								<td className="py-4">
									<p
										className={cn(
											'flex flex-nowrap items-center gap-1 py-0.5 pl-0.5 pr-2 text-label-md transition-colors',
											['Pending', 'Evaluating'].includes(
												chat.conversation.evaluation?.status ?? '',
											)
												? 'text-yellow-70'
												: chat.conversation.status === 'closed'
													? 'text-green-70'
													: chat.conversation.status === 'ready'
														? 'text-orange-70'
														: 'text-blue-60',
										)}
									>
										<Icon name="circle-fill" size="sm" />
										{['Pending', 'Evaluating'].includes(
											chat.conversation.evaluation?.status ?? '',
										)
											? 'Evaluating'
											: chat.conversation.status === 'closed'
												? 'Completed'
												: chat.conversation.status === 'ready'
													? 'Ready'
													: 'Initializing'}
									</p>
								</td>
								{adminSession ? (
									<td className="py-4">
										<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
											<ContactAvatar
												className="inline-flex shrink-0 items-center justify-center rounded-full"
												variant={avatarVariantByPersonaType(
													chat.participant.persona.type?.toLowerCase(),
												)}
												size="sm"
												initial={formatPersonaTypeAbbr(
													chat.conversation.user ?? '',
												)}
											/>
											{chat.conversation.user}
										</div>
									</td>
								) : null}
								<td className="py-4 pr-3 text-right">
									<p className="text-body-md text-neutral-1-fg">
										{format(new Date(chat.conversation.createdAt), 'PP')}
									</p>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</section>
		</section>
	)
}
