import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { type QueryClient } from '@tanstack/react-query'
import { useState, type ComponentPropsWithoutRef, type ReactNode } from 'react'
import {
	useFetcher,
	useLoaderData,
	useNavigate,
	useRouteLoaderData,
	type LoaderFunctionArgs,
} from 'react-router-dom'
import { Chip } from '#src/components/chip'
import ProductTip from '#src/components/product-tip'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import {
	Toast,
	ToastDescription,
	ToastViewport,
} from '#src/components/ui/toast'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#src/components/ui/tooltip'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { type PersonaAssignActionResponse } from '../assign'
import { Filters, NoResults, Sort } from '../filters'
import { filterQuery, sortQuery } from '../filters/queries'
import {
	FILTERS,
	Hack,
	IntentSource,
	IntentType,
	MappedPersonas,
	SORT,
} from '../filters/schema'
import { assignedSignalsQuery, signalsTemplatesQuery } from '../queries'
import { SignalWeightAsyncForm, WeightValue } from '../save'

export type EnrichLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const filters = {
			[FILTERS.TYPE]: await queryClient.fetchQuery(filterQuery(FILTERS.TYPE)),
			[FILTERS.SOURCE]: await queryClient.fetchQuery(
				filterQuery(FILTERS.SOURCE),
			),
			[FILTERS.HACK]: await queryClient.fetchQuery(filterQuery(FILTERS.HACK)),
			[FILTERS.WEIGHT]: await queryClient.fetchQuery(
				filterQuery(FILTERS.WEIGHT),
			),
			[FILTERS.MAPPED_PERSONAS]: await queryClient.fetchQuery(
				filterQuery(FILTERS.MAPPED_PERSONAS),
			),
		}
		const sortBy = await queryClient.fetchQuery(sortQuery())

		const assignedSignals = await queryClient.fetchQuery(
			assignedSignalsQuery(params.companyId),
		)

		const signalsTemplates = (
			await queryClient.fetchQuery(signalsTemplatesQuery(params.companyId))
		).map(st => ({
			...st,
			mappedPersonaCount:
				assignedSignals?.filter(as => as.signalId === st.id)?.length ?? 0,
		}))

		if (!signalsTemplates?.length)
			throw new Response('Signals Not Found', {
				status: 404,
				statusText: 'Not Found',
			})

		return {
			signalsTemplates: signalsTemplates
				?.filter(st =>
					filters[FILTERS.TYPE]?.length && st.type
						? filters[FILTERS.TYPE]?.includes(st.type)
						: true,
				)
				?.filter(st =>
					filters[FILTERS.SOURCE]?.length && st.source
						? filters[FILTERS.SOURCE]?.includes(st.source)
						: true,
				)
				?.filter(st => {
					if (typeof st.hack === 'undefined') return true

					const isHack = st.hack ? Hack.HACK : Hack.NO_HACK

					return filters[FILTERS.HACK]?.length
						? filters[FILTERS.HACK]?.includes(isHack)
						: true
				})
				?.filter(st =>
					filters[FILTERS.WEIGHT]?.length && st.config?.weight?.toString()
						? filters[FILTERS.WEIGHT]?.includes(
								st.config?.weight?.toString() ?? '',
							)
						: true,
				)
				?.filter(st => {
					if (
						filters[FILTERS.MAPPED_PERSONAS]?.includes(MappedPersonas.MAPPED) &&
						!filters[FILTERS.MAPPED_PERSONAS]?.includes(
							MappedPersonas.NOT_MAPPED,
						)
					) {
						return assignedSignals?.some(s => s.signalId === st.id)
					}

					if (
						filters[FILTERS.MAPPED_PERSONAS]?.includes(
							MappedPersonas.NOT_MAPPED,
						) &&
						!filters[FILTERS.MAPPED_PERSONAS]?.includes(MappedPersonas.MAPPED)
					) {
						return assignedSignals?.every(s => s.signalId !== st.id)
					}

					return true
				})
				.sort((a, b) => {
					if (
						[SORT.NUMBER, SORT.WEIGHT, SORT.MAPPED_PERSONAS].includes(
							sortBy?.key ?? '',
						)
					) {
						let numberA: number = 0
						let numberB: number = 0

						if (sortBy?.key === SORT.NUMBER) {
							numberA = a.id
							numberB = b.id
						} else if (sortBy?.key === SORT.WEIGHT) {
							numberA = Number(a.config?.weight ?? 1)
							numberB = Number(b.config?.weight ?? 1)
						} else if (sortBy?.key === SORT.MAPPED_PERSONAS) {
							numberA = a.mappedPersonaCount
							numberB = b.mappedPersonaCount
						}

						return sortBy?.direction === 'asc'
							? numberA - numberB
							: numberB - numberA
					} else if (sortBy?.key === SORT.STATUS)
						return sortBy?.direction === 'asc'
							? a.available
								? 1
								: -1
							: a.available
								? -1
								: 1
					else if (
						[
							SORT.INTENT,
							SORT.INTENT_TYPE,
							SORT.INTENT_SOURCE,
							SORT.HACK,
						].includes(sortBy?.key ?? '')
					) {
						let keyA: string = ''
						let keyB: string = ''

						if (sortBy?.key === SORT.INTENT) {
							keyA = a.name
							keyB = b.name
						} else if (sortBy?.key === SORT.INTENT_TYPE) {
							keyA = a.type
							keyB = b.type
						} else if (sortBy?.key === SORT.INTENT_SOURCE) {
							keyA = a.source
							keyB = b.source
						} else if (sortBy?.key === SORT.HACK) {
							// NOTE: because N is before Y
							keyA = b.hack ? 'Yes' : 'No'
							keyB = a.hack ? 'Yes' : 'No'
						}

						return sortBy?.direction === 'asc'
							? keyB.localeCompare(keyA)
							: keyA.localeCompare(keyB)
					} else {
						return 0
					}
				}),
			filters,
			sortBy,
		}
	}

export default function Signals() {
	const { signalsTemplates } = useLoaderData() as EnrichLoaderResponse
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse
	const navigate = useNavigate()
	const params = useParsedRouteParams(['companyId'])

	const theadCells: TableCellsProps[] = [
		{ children: '', className: 'w-9 text-center' },
		{
			children: <Sort sortKey={SORT.NUMBER}>Number</Sort>,
			className: 'w-20',
		},
		{
			children: (
				<div className="flex items-center gap-1">
					<Sort sortKey={SORT.INTENT}>Intent</Sort>
					<ProductTip content="Intent Signals: Indicators suggesting a potential customer’s readiness or interest in your product or service, helping prioritize accounts and contacts." />
				</div>
			),
		},
		{ children: <Sort sortKey={SORT.STATUS}>Status</Sort> },
		{
			children: (
				<div className="flex items-center gap-1">
					<Sort sortKey={SORT.INTENT_TYPE}>Intent Type</Sort>
					<ProductTip content="Intent Type: Categorizes the nature of the intent signal, such as “Cold Outbound Signal,” “Static Hook,” “Warm Outbound Signal,” or “Inbound Signal.”" />
				</div>
			),
		},
		{
			children: (
				<div className="flex items-center gap-1">
					<Sort sortKey={SORT.INTENT_SOURCE}>Intent Source</Sort>
					<ProductTip content="Intent Source: Identifies whether the intent signal comes from an account (company-level) or a contact (individual-level)." />
				</div>
			),
		},
		{
			children: (
				<div className="flex items-center gap-1">
					<Sort sortKey={SORT.HACK}>Hack compatible</Sort>
					<ProductTip content="Hack Compatible: Suitable for use in the intent-based hack workflow within the TAM organizer." />
				</div>
			),
			className: 'w-40',
		},
		{
			children: (
				<div className="flex items-center gap-1">
					<Sort sortKey={SORT.WEIGHT}>Weight</Sort>
					<ProductTip content="Weight: The importance or relevance of the intent signal, which is converted later into a score and integrated into the CRM." />
				</div>
			),
			className: 'w-32',
		},
		{
			children: (
				<div className="flex items-center gap-1">
					<Sort sortKey={SORT.MAPPED_PERSONAS}>Mapped Personas</Sort>
					<ProductTip content="Mapped Personas: The number of personas linked to the intent signal." />
				</div>
			),
			className: 'w-40',
		},
		{ children: '', className: 'w-10 text-center' },
	]
	const bodyRows: TableRowsProps = signalsTemplates
		?.map(signalTemplate => [
			{
				['data-parent-row']: true,
				className: 'cursor-pointer hover:bg-neutral-1-bg-hover',
				onClick: () =>
					navigate(
						routes.prioritize.signal.index({
							companyId: params.companyId,
							signalId: signalTemplate.id.toString(),
						}),
					),
				cells: [
					{
						children: (
							<Icon
								name="chevron-down"
								size="sm"
								className="group-data-[state='open']:rotate-180"
							/>
						),
						['data-state']: 'closed',
						className: 'group w-9 text-center',
						onClick: (
							e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
						) => {
							e.stopPropagation()

							const collapsibleRow =
								e.currentTarget.parentElement?.nextElementSibling
							if (!collapsibleRow) throw Error('Collapsible row not found')

							e.currentTarget.setAttribute(
								'data-state',
								e.currentTarget.getAttribute('data-state') === 'open'
									? 'closed'
									: 'open',
							)
							collapsibleRow.setAttribute(
								'data-state',
								collapsibleRow.getAttribute('data-state') === 'closed'
									? 'open'
									: 'closed',
							)
						},
					},
					{ children: signalTemplate.id, className: 'w-20' },
					{ children: signalTemplate.name },
					{
						children: <SignalStatus enabled={signalTemplate.available} />,
					},
					{ children: <SignalType type={signalTemplate.type} /> },
					{
						children:
							signalTemplate.source === IntentSource.ACCOUNT_PUBLIC_COMPANY ? (
								<div>
									Account
									<br />
									(publicly listed)
								</div>
							) : (
								signalTemplate.source
							),
					},
					{ children: signalTemplate.hack ? 'Yes' : 'No', className: 'w-40' },
					{
						children: readOnlySession ? (
							signalTemplate.config?.weight ? (
								<WeightValue value={signalTemplate.config.weight.toString()} />
							) : null
						) : (
							<SignalWeightAsyncForm
								signalId={signalTemplate.id}
								weight={signalTemplate.config?.weight}
							/>
						),
						className: 'w-32',
					},
					{
						children: signalTemplate.mappedPersonaCount,
						className: 'w-40',
					},
					{
						onClick: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
							e.stopPropagation(),
						children: readOnlySession ? (
							<Icon name="chevron-right" size="sm" />
						) : (
							<SignalTemplateContextMenu signalId={signalTemplate.id} />
						),
						className: 'w-14 last:text-left',
					},
				],
			},
			{
				['data-child-row']: true,
				['data-state']: 'closed',
				className: 'group',
				cells: [
					{
						children: '',
						colSpan: 1,
						className:
							'border-transparent p-0 group-data-[state="closed"]:border-t-0',
					},
					{
						children: '',
						colSpan: 1,
						className: 'p-0 group-data-[state="closed"]:border-t-0',
					},
					{
						children: (
							<div className="h-0 overflow-hidden group-data-[state='open']:h-full">
								<div className="max-w-2xl px-2 py-4">
									<section className="mb-4">
										<h2 className="text-label-sm text-neutral-3-fg">
											Example of manual steps
										</h2>
										<p className="text-body-md font-normal text-neutral-1-fg">
											{signalTemplate.manualSteps}
										</p>
									</section>
									<section>
										<h2 className="text-label-sm text-neutral-3-fg">
											Context & Example
										</h2>
										<p className="text-body-md font-normal text-neutral-1-fg">
											{signalTemplate.context}
										</p>
									</section>
								</div>
							</div>
						),
						colSpan: theadCells?.length - 3,
						className:
							'p-0 group-data-[state="closed"]:border-t-0 group-data-[state="closed"]:border-transparent',
					},
					{
						children: '',
						colSpan: 1,
						className:
							'p-0 group-data-[state="closed"]:border-t-0 group-data-[state="closed"]:border-transparent',
					},
				],
			},
		])
		.flat()

	return (
		<main className="w-full flex-grow">
			<section className="px-20 pb-4 pt-10">
				<h1 className="pb-2 text-heading-sm text-neutral-1-fg">Prioritize</h1>
				<p className="pb-4 text-body-md text-neutral-2-fg">
					Enrich and prioritize accounts & prospects with intent signals.
				</p>
				<Filters />
			</section>
			<section className="flex gap-8">
				{bodyRows?.length ? (
					<Table theadCells={theadCells} bodyRows={bodyRows} />
				) : (
					<NoResults />
				)}
			</section>
		</main>
	)
}

function SignalTemplateContextMenu({ signalId }: { signalId: number }) {
	const [open, setOpen] = useState(false)
	const params = useParsedRouteParams(['companyId'])
	const fetcher = useFetcher<PersonaAssignActionResponse>()
	const action = routes.prioritize.signal.assign({
		companyId: params.companyId,
		signalId: signalId.toString(),
	})

	return (
		<>
			<Dropdown
				open={open}
				onOpenChange={setOpen}
				trigger={
					<button
						type="button"
						data-type="contextmenu"
						className="flex h-5 w-5 items-center justify-center rounded-[2px] outline-none transition-colors hover:bg-neutral-2-bg-hover radix-state-open:bg-neutral-2-bg"
					>
						<Icon name="overflow-menu-horizontal" size="sm" />
					</button>
				}
				contentProps={{
					side: 'bottom',
					align: 'end',
				}}
			>
				<DropdownItem asChild className="justify-start">
					<button
						onClick={() => {
							fetcher.submit(
								{ intent: 'unmap-all' },
								{ action, method: 'PATCH' },
							)
							setOpen(false)
						}}
						disabled={
							fetcher.state !== 'idle' &&
							fetcher.formData?.get('intent') === 'unmap-all'
						}
					>
						<Icon name="subtract-alt" size="sm" />
						{fetcher.state === 'idle' ||
						fetcher.formData?.get('intent') !== 'unmap-all'
							? 'Unmap all'
							: 'Unmapping...'}
					</button>
				</DropdownItem>
			</Dropdown>
			<Toast
				shouldOpen={fetcher.state === 'loading' && !fetcher.data?.ok}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="error-filled" size="md" className="text-red-50" />
					{fetcher.data?.action === 'unmap-all'
						? 'Error while unmapping all personas. Try again.'
						: fetcher.data?.action === 'map'
							? 'Error while mapping all personas. Try again.'
							: 'Error while unmapping personas. Try again.'}
				</ToastDescription>
			</Toast>
			<Toast
				shouldOpen={
					fetcher.state === 'loading' &&
					!!fetcher.data?.ok &&
					['unmap-all'].includes(fetcher.data?.action)
				}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3">
					<Icon name="checkmark-filled" size="md" className="text-green-50" />
					All personas successfully unmapped
				</ToastDescription>
			</Toast>
			<ToastViewport />
		</>
	)
}

const Cell = ({
	as = 'td',
	colSpan,
	className,
	children,
	onClick,
}: {
	as?: 'td' | 'th'
	colSpan?: number
	className?: string
	children?: ReactNode
	onClick?: React.TdHTMLAttributes<HTMLTableCellElement>['onClick']
}) => {
	const Slot = as ? as : 'td'
	return (
		<Slot
			className={cn(
				'h-full w-full p-2',
				'border-t border-neutral-1-bd last:text-right group-[.table-header]:border-t-0',
				className,
				onClick ? 'cursor-pointer' : '',
			)}
			onClick={onClick}
			colSpan={colSpan}
		>
			{children}
		</Slot>
	)
}

export type TableCellsProps = {
	children: ReactNode
	className?: string
	onClick?: React.TdHTMLAttributes<HTMLTableCellElement>['onClick']
}

export type TableRowsProps = {
	['data-parent-row']?: boolean
	['data-child-row']?: boolean
	['data-state']?: string
	active?: boolean
	className?: string
	onClick?: React.TdHTMLAttributes<HTMLTableRowElement>['onClick']
	cells: TableCellsProps[]
}[]

export function Table({
	theadCells,
	bodyRows,
}: {
	theadCells?: TableCellsProps[]
	bodyRows?: TableRowsProps
}) {
	return (
		<table className="w-full table-fixed border-separate border-spacing-0 border-b border-neutral-1-bd text-left">
			<thead>
				<tr className="table-header group bg-neutral-2-bg">
					{theadCells?.map((cell, i) => (
						<Cell
							key={i}
							as="th"
							className={cn('text-label-sm text-neutral-3-fg', cell.className)}
							onClick={cell.onClick}
						>
							{cell.children}
						</Cell>
					))}
				</tr>
			</thead>
			<tbody>
				{bodyRows?.map(({ cells, active, className, ...row }, i) => (
					<tr
						key={i}
						{...row}
						className={cn(
							'transition-colors',
							active ? 'bg-neutral-2-bg' : 'bg-neutral-1-bg',
							className,
						)}
					>
						{cells.map(({ children, className, ...cell }, j) => (
							<Cell
								key={j}
								className={cn(
									'text-body-md font-medium text-neutral-1-fg',
									className,
								)}
								{...cell}
							>
								{children}
							</Cell>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export function TableAction({
	className,
	...props
}: ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>) {
	return (
		<CheckboxPrimitive.Root
			{...props}
			className={cn(
				'group flex h-4 w-4 items-center justify-center text-neutral-3-fg',
				className,
			)}
		>
			<CheckboxPrimitive.Indicator
				className={cn('flex items-center justify-center text-brand-1-fg')}
			>
				<Icon
					name="checkbox-indeterminate-filled"
					size="sm"
					className="hidden group-data-[state=indeterminate]:inline-flex"
				/>
				<Icon
					name="checkbox-checked-filled"
					size="sm"
					className="hidden group-data-[state=checked]:inline-flex"
				/>
			</CheckboxPrimitive.Indicator>

			<span
				className={cn(
					'hidden items-center justify-center text-current group-data-[state=unchecked]:flex',
				)}
			>
				<Icon
					name="checkbox-unchecked"
					size="sm"
					className="hidden group-data-[state=unchecked]:inline-flex"
				/>
			</span>
		</CheckboxPrimitive.Root>
	)
}

export function SignalStatus({ enabled }: { enabled: boolean }) {
	if (enabled)
		return (
			<div className="flex items-center text-label-md text-green-70">
				<Icon
					name="circle-fill"
					size="sm"
					className="flex flex-nowrap items-center text-green-70 transition-colors"
				/>
				Available
			</div>
		)

	return (
		<div className="flex flex-nowrap items-center text-label-md text-neutral-3-fg">
			<Icon
				name="circle-fill"
				size="sm"
				className="flex flex-nowrap items-center text-orange-70 transition-colors"
			/>
			Coming soon&nbsp;
			<TooltipProvider>
				<Tooltip delayDuration={0}>
					<TooltipTrigger>
						<Icon
							name="information"
							size="sm"
							className="flex flex-nowrap items-center transition-colors"
						/>
					</TooltipTrigger>
					<TooltipContent side="bottom" align="center" className="max-w-60">
						Currently, these signals are still under development, but you can
						map personas so we can prioritize them as soon as they become
						available
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</div>
	)
}

export function SignalType({ type }: { type: IntentType }) {
	if (type === IntentType.COLD_OUTBOUND_SIGNAL)
		return (
			<div className="flex items-center gap-1">
				<Chip className="w-max" variant="blue">
					{type}
				</Chip>
				<ProductTip
					className="text-neutral-3-fg"
					content="Cold Outbound Signal: Indicator suggesting potential customer
								interest, used to guide outreach efforts for engagement."
				/>
			</div>
		)
	if (type === IntentType.STATIC_HOOK)
		return (
			<div className="flex items-center gap-1">
				<Chip className="w-max" variant="green">
					{type}
				</Chip>
				<ProductTip
					className="text-neutral-3-fg"
					content="Static hook: Persistent data within a target company’s website,
								linkedIn profile etc..."
				/>
			</div>
		)
	if (type === IntentType.INBOUND_SIGNAL)
		return (
			<div className="flex items-center gap-1">
				<Chip className="w-max" variant="red">
					{type}
				</Chip>
				<ProductTip
					className="text-neutral-3-fg"
					content="Inbound Signal: Indicator of potential customer interest based
								on actions taken towards your company, such as website visits or
								content downloads."
				/>
			</div>
		)
	if (type === IntentType.WARM_OUTBOUND_SIGNAL)
		return (
			<div className="flex items-center gap-1">
				<Chip className="w-max" variant="orange">
					{type}
				</Chip>
				<ProductTip
					className="text-neutral-3-fg"
					content="Warm Outbound Signal: Indicator suggesting a higher likelihood
								of interest, used to guide targeted outreach efforts to engage
								with familiar prospects."
				/>
			</div>
		)
}
