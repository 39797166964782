import { type QueryClient } from '@tanstack/react-query'
import {
	type LoaderFunctionArgs,
	useLoaderData,
	Link,
	redirect,
} from 'react-router-dom'
import { Icon } from '#src/components/ui/icon'
import { companyQuery } from '#src/routes/calibrate/company/queries'
import {
	ecosystemQuery,
	ecosystemsQuery,
} from '#src/routes/calibrate/ecosystem/queries'
import { verticalsQuery } from '#src/routes/calibrate/verticals/queries'
import { routes } from '#src/utils/routes'
import EditTAM from './edit'

export type EditLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))

		if (!params.ecosystemId) {
			const ecosystems = await queryClient.fetchQuery(
				ecosystemsQuery(params.companyId),
			)

			if (!ecosystems.length)
				throw new Response('Ecosystems not found', {
					status: 404,
					statusText: 'Not Found',
				})

			throw redirect(`/admin/tam/${params.companyId}/${ecosystems[0].id}`)
		}

		const ecosystem = await queryClient.fetchQuery(
			ecosystemQuery(params.companyId, params.ecosystemId),
		)

		const verticals = await queryClient.fetchQuery(
			verticalsQuery(params.companyId, Number(params.ecosystemId)),
		)
		const vertical = params.verticalId
			? verticals.find(v => v.id === Number(params.verticalId))
			: null

		return {
			company,
			ecosystem,
			verticals,
			vertical,
		}
	}

export default function TAMEstimates() {
	const { company, ecosystem } = useLoaderData() as EditLoaderResponse

	return (
		<main className="flex h-full w-full max-w-screen-md flex-col gap-8 justify-self-center">
			<Link
				to={`/admin`}
				className="flex flex-nowrap items-center justify-center gap-1 bg-transparent text-label-lg font-normal text-link hover:text-link-hover active:text-link-pressed"
			>
				<Icon name="arrow-left" /> Back
			</Link>
			<Link
				to={`/company/${company.id}`}
				className="flex flex-nowrap items-center justify-center gap-1 bg-transparent text-title-lg text-link hover:text-link-hover active:text-link-pressed"
			>
				{company.name}
			</Link>

			<p className="text-center text-body-lg">
				TAM details for
				<br />
				<Link
					to={routes.calibrate.verticals.index({
						companyId: company.id,
						ecosystemId: ecosystem.id!.toString(),
					})}
					className="flex flex-nowrap items-center justify-center gap-1 bg-transparent text-link hover:text-link-hover active:text-link-pressed"
				>
					{ecosystem.name}
				</Link>
			</p>

			<EditTAM />
		</main>
	)
}
