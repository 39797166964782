import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { signalUsageExampleMutation } from './mutations'

export type SignalUsageExampleActionResponse = Awaited<
	ReturnType<ReturnType<typeof action>>
>

export const action =
	// prettier-ignore
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	(queryClient: QueryClient) => 
		async ({ params }: LoaderFunctionArgs) => {
			if (!params.companyId || !params.signalId || !params.personaId)
				throw new Response('Missing parameters', {
					status: 400,
					statusText: 'Bad Request',
				})

			return await signalUsageExampleMutation(
				params.companyId,
				params.personaId,
				params.signalId,
			)
		}
