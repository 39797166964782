import { type z } from 'zod'
import { VerticalAPISchema } from '#src/routes/calibrate/verticals/schema'
import { admin } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { type TAMFormSchema } from './schema'

export const updateTAM = async (
	companyId: string,
	verticalId: string,
	data: z.infer<typeof TAMFormSchema>,
) => {
	return await safeFetch(VerticalAPISchema, admin.tam(companyId, verticalId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})
}
