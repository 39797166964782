import * as LabelPrimitive from '@radix-ui/react-label'

import {
	forwardRef,
	type ComponentPropsWithoutRef,
	type ElementRef,
} from 'react'

import { cn } from '#src/utils/misc'

const Label = forwardRef<
	ElementRef<typeof LabelPrimitive.Root>,
	ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => (
	<LabelPrimitive.Root
		ref={ref}
		className={cn('text-body-sm font-normal text-neutral-2-fg', className)}
		{...props}
	/>
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
