import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import {
	forwardRef,
	type ComponentPropsWithoutRef,
	type ElementRef,
} from 'react'

import { cn } from '#src/utils/misc'
import { Icon } from './icon'

export type CheckboxProps = Omit<
	ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
	'type'
> & {
	type?: string
}

const Checkbox = forwardRef<
	ElementRef<typeof CheckboxPrimitive.Root>,
	ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
	<CheckboxPrimitive.Root
		ref={ref}
		className={cn(
			'radix-state-checked:text-brand-1-fg border-neutral-2-bd disabled:border-neutral-2-bd-disabled ring-offset-neutral-2-bg peer h-6 w-6 shrink-0 rounded border disabled:cursor-not-allowed radix-state-checked:disabled:text-neutral-1-fg-disabled',
			className,
		)}
		{...props}
	>
		<CheckboxPrimitive.Indicator
			className={cn('flex items-center justify-center text-current')}
		>
			<Icon name="check" size="md" />
		</CheckboxPrimitive.Indicator>
	</CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
