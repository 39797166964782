import * as SwitchPrimitive from '@radix-ui/react-switch'
import {
	forwardRef,
	type ComponentPropsWithoutRef,
	type ElementRef,
} from 'react'
import { cn } from '#src/utils/misc'

export const SwitchRoot = SwitchPrimitive.Root

export const SwitchThumb = forwardRef<
	ElementRef<typeof SwitchPrimitive.Thumb>,
	ComponentPropsWithoutRef<typeof SwitchPrimitive.Thumb>
>(({ className, ...props }, forwardedRef) => (
	<SwitchPrimitive.Thumb
		className={cn(
			'block h-[18px] w-[18px] transform rounded-full bg-white transition-transform',
			'translate-x-[3px] will-change-transform radix-state-checked:translate-x-[27px]',
			className,
		)}
		{...props}
		ref={forwardedRef}
	/>
))
SwitchThumb.displayName = 'SwitchThumb'

export const Switch = forwardRef<
	ElementRef<typeof SwitchPrimitive.Root>,
	ComponentPropsWithoutRef<typeof SwitchPrimitive.Root>
>(({ className, ...props }, forwardedRef) => (
	<SwitchPrimitive.Root
		className={cn(
			'relative inline-flex h-6 w-12 items-center rounded-full',
			'bg-neutral-1-bg-disabled radix-state-checked:bg-status-success-fg',
			'transition-colors',
			className,
		)}
		{...props}
		ref={forwardedRef}
	>
		<SwitchThumb />
	</SwitchPrimitive.Root>
))
Switch.displayName = 'Switch'
