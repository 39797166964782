import {
	getFormProps,
	getInputProps,
	getTextareaProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { type QueryClient } from '@tanstack/react-query'
import { type ReactNode } from 'react'
import {
	redirect,
	type LoaderFunctionArgs,
	useLoaderData,
	Link,
	type ActionFunctionArgs,
	Form,
} from 'react-router-dom'
import {
	ErrorList,
	Field,
	SwitchField,
	TextareaField,
} from '#src/components/forms'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import {
	Sidebar,
	SidebarFooter,
	SidebarHeader,
} from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import {
	DeleteDataPointFormSchema,
	VerticalsDataPointsFormSchema,
	deleteDataPoint,
	saveDataPoint,
} from '#src/routes/calibrate/ecosystem/mutations'
import {
	dataPointsQuery,
	ecosystemKeys,
} from '#src/routes/calibrate/ecosystem/queries'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { updateVertical } from './mutations'
import { verticalKeys, verticalsQuery } from './queries'

export type VerticalsDataPointsLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const datapoint = await queryClient.fetchQuery(
			dataPointsQuery(params.companyId, params.ecosystemId),
		)
		const verticals = await queryClient.fetchQuery(
			verticalsQuery(params.companyId, Number(params.ecosystemId)),
		)

		return {
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
			datapoints: datapoint?.map(c => ({
				id: c.id!,
				title: c.title,
				inBizmodel: c.inBizmodel,
				dataSources: c.dataSources,
			})),
			verticals: verticals?.map(v => ({
				id: v.id!,
				name: v.name,
				dataPoints: v.dataPoints,
			})),
		}
	}

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()
		const deleteDataPointId = formData.get('deleteDataPoint')

		if (deleteDataPointId) {
			const submission = parseWithZod(formData, {
				schema: DeleteDataPointFormSchema,
			})

			if (submission.status !== 'success') {
				throw Error('Failed to parse form data')
			}

			await deleteDataPoint(params.companyId, submission.value.deleteDataPoint)

			await queryClient.invalidateQueries({
				queryKey: ecosystemKeys.datapoint(params.companyId, params.ecosystemId),
			})
			await queryClient.invalidateQueries({
				queryKey: verticalKeys.list(
					params.companyId,
					Number(params.ecosystemId),
				),
			})

			return redirect(
				routes.calibrate.datapoints.create({
					companyId: params.companyId,
					ecosystemId: params.ecosystemId,
				}),
			)
		}

		const submission = parseWithZod(formData, {
			schema: VerticalsDataPointsFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		// NOTE: update/create data point first
		// since some of them may be new data point
		// thus we can not update verticals without datapoint id
		const dataPoints = submission.value.dataPoints.map(c => ({
			ecosystemId: Number(params.ecosystemId),
			id: c.id,
			title: c.title,
			inBizmodel: Boolean(c.inBizmodel),
			dataSources: c.dataSources,
		}))

		const data = await saveDataPoint(params.companyId, {
			dataPoints,
		})

		const verticals = await queryClient.fetchQuery(
			verticalsQuery(params.companyId, Number(params.ecosystemId)),
		)

		const verticalsDataPoints = verticals?.map(v => ({
			...v,
			dataPoints: data
				.map(item => ({
					id: item.id!,
					value: submission.value.dataPoints
						.find(c => c.title === item.title)
						?.answers.find(a => a.verticalId === v.id)?.value,
				}))
				.filter(a => !!a.value),
		}))

		await Promise.all(
			verticalsDataPoints?.map(v =>
				updateVertical(params.companyId!, v.id!, v),
			) ?? [],
		)

		void queryClient.invalidateQueries({
			queryKey: dataPointsQuery(params.companyId, params.ecosystemId).queryKey,
		})

		void queryClient.invalidateQueries({
			queryKey: verticalsQuery(params.companyId, Number(params.ecosystemId))
				.queryKey,
		})

		if (verticals?.length)
			return redirect(
				routes.calibrate.verticals.index({
					companyId: params.companyId,
					ecosystemId: params.ecosystemId,
				}),
			)

		return redirect(
			routes.calibrate.verticals.create({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
			}),
		)
	}

export default function VerticalsDataPointsEdit() {
	const { companyId, ecosystemId, verticals, datapoints } =
		useLoaderData() as VerticalsDataPointsLoaderResponse

	const action = routes.calibrate.datapoints.edit({
		companyId: companyId,
		ecosystemId: ecosystemId,
	})
	const method = 'POST'
	const formId = 'datapoint-form-' + datapoints.map(c => c.id).join('')

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<div className="sticky right-0 w-[26rem] shrink-0">
			<Sidebar
				header={
					<SidebarHeader>
						<h1 className="text-body-md font-semibold">Enrichment criteria</h1>
						<Link
							className="inline-flex"
							to={routes.calibrate.verticals.index({
								companyId: companyId,
								ecosystemId: ecosystemId,
							})}
						>
							<Icon name="cross-1" size="sm" />
						</Link>
					</SidebarHeader>
				}
				main={
					<DataPointsForm
						action={action}
						method={method}
						formId={formId}
						datapoints={datapoints}
						verticals={verticals}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link
								to={routes.calibrate.verticals.index({
									companyId: companyId,
									ecosystemId: ecosystemId,
								})}
							>
								Cancel
							</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							size="sm"
							type="submit"
							form={formId}
							disabled={isPending}
						>
							Save
						</StatusButton>
					</SidebarFooter>
				}
			/>
		</div>
	)
}

function DataPointsForm({
	action,
	method,
	formId,
	datapoints,
	verticals,
}: {
	action: string
	method: 'POST'
	formId: string
	datapoints: VerticalsDataPointsLoaderResponse['datapoints']
	verticals: VerticalsDataPointsLoaderResponse['verticals']
}) {
	const isPending = useIsPending({ formAction: action, formMethod: method })

	const defaultValue = datapoints.length
		? {
				dataPoints: datapoints.map(c => ({
					id: c.id,
					title: c.title,
					inBizmodel: c.inBizmodel,
					dataSources: c.dataSources,
					answers: verticals?.length
						? verticals.map(v => ({
								verticalId: v.id,
								title: v.name,
								value: v.dataPoints?.find(a => a.id === c.id)?.value,
							}))
						: null,
				})),
			}
		: {
				dataPoints: null,
			}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(VerticalsDataPointsFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: VerticalsDataPointsFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	const dataPointsFields = fields.dataPoints.getFieldList()

	return (
		<Form
			method={method}
			action={action}
			{...getFormProps(form)}
			className="flex flex-col gap-4"
		>
			{dataPointsFields.map((datapoint, index) => {
				const input = datapoint.getFieldset()
				const answers = input.answers.getFieldList()
				const textareaDataSourcesProps = getTextareaProps(input.dataSources)

				return (
					<section
						key={index}
						className="flex flex-col gap-6 overflow-hidden rounded border border-neutral-1-bd pb-6"
					>
						<input
							{...getInputProps(input.id, { type: 'hidden' })}
							defaultValue={input.id.value}
						/>

						<h2 className="flex w-full items-center justify-between bg-neutral-2-bg px-3 py-2 text-label-sm text-neutral-1-fg">
							Enrichment criterion #{index + 1}{' '}
							{input.id?.value ? (
								<button
									type="submit"
									name="deleteDataPoint"
									value={input.id.value}
									disabled={isPending}
									className="flex items-center justify-center outline-none transition-all"
								>
									<Icon name="trash" />
								</button>
							) : (
								<button
									type="button"
									onClick={() =>
										form.remove({ name: fields.dataPoints.name, index })
									}
									className="flex items-center justify-center outline-none transition-all"
								>
									<Icon name="trash" />
								</button>
							)}
						</h2>

						<Field
							className="flex-grow px-3"
							labelProps={{
								children: 'Title',
							}}
							inputProps={{
								...getInputProps(input.title, {
									type: 'text',
								}),
								placeholder: 'Enter here',
							}}
							errors={input.title.errors}
						/>

						<TextareaField
							className="flex-grow px-3"
							labelProps={{
								children:
									'AI Agent instructions for finding and interpreting data',
							}}
							textareaProps={textareaDataSourcesProps}
							errors={input.dataSources.errors}
						/>

						<div className="flex flex-grow items-center gap-2 px-3">
							<SwitchField
								labelProps={{
									children: 'Include in business model description',
								}}
								switchProps={{
									...getInputProps(input.inBizmodel, {
										type: 'checkbox',
									}),
								}}
							/>
						</div>

						{answers.length ? (
							<VerticalsDataPointsAnswers
								defaultOpen={!input.id.value}
								label={
									<>
										Criteria answers for verticals{' '}
										<span className="font-medium text-brand-1-fg">
											{datapoints.length ?? 0}
										</span>
									</>
								}
							>
								{answers.map((item, i) => {
									const vertical = item.getFieldset()

									return (
										<section key={i} className="relative space-y-4">
											<input
												{...getInputProps(vertical.title, { type: 'hidden' })}
												defaultValue={vertical.title.value}
											/>
											<input
												{...getInputProps(vertical.verticalId, {
													type: 'hidden',
												})}
												defaultValue={vertical.verticalId.value}
											/>

											<Field
												className="flex-grow"
												labelProps={{
													children: (
														<span className="flex items-center justify-between pb-1">
															{vertical.title.value}
														</span>
													),
												}}
												inputProps={{
													...getInputProps(vertical.value, { type: 'text' }),
													placeholder: 'Enter here',
												}}
												errors={vertical.value.errors}
											/>
										</section>
									)
								})}
							</VerticalsDataPointsAnswers>
						) : null}
					</section>
				)
			})}

			{dataPointsFields?.length ? (
				<button
					className="flex w-full items-center gap-2 text-body-md text-link outline-none transition-all hover:text-link-hover focus-visible:text-link-pressed active:text-link-pressed"
					{...form.insert.getButtonProps({
						name: fields.dataPoints.name,
						defaultValue: {
							title: '',
							inBizmodel: true,
							answers: verticals.map(v => ({
								verticalId: v.id,
								title: v.name,
								value: '',
							})),
						},
					})}
				>
					<Icon name="add" size="sm" aria-hidden />
					Add
				</button>
			) : (
				<section className="flex flex-col items-center justify-center gap-4 rounded bg-neutral-2-bg p-8">
					<Icon name="data-collection" className="h-8 w-8 text-neutral-3-fg" />
					<p className="text-body-md text-neutral-2-fg">
						You have not added any enrichment criteria yet.
					</p>
					<Button
						size="sm"
						{...form.insert.getButtonProps({
							name: fields.dataPoints.name,
							defaultValue: {
								title: '',
								inBizmodel: true,
								answers: verticals.map(v => ({
									verticalId: v.id,
									title: v.name,
									value: '',
								})),
							},
						})}
					>
						+ Add new enrichment criteria
					</Button>
				</section>
			)}

			<ErrorList errors={form.errors} id={form.errorId} />
		</Form>
	)
}

function VerticalsDataPointsAnswers({
	defaultOpen,
	label,
	children,
}: {
	defaultOpen?: boolean
	label: ReactNode
	children: ReactNode
}) {
	return (
		<AccordionPrimitive.Root
			type="multiple"
			defaultValue={defaultOpen ? ['item-1'] : []}
			className="px-3"
		>
			<AccordionPrimitive.Item
				value="item-1"
				className="group focus-within:relative focus-within:z-0"
			>
				<AccordionPrimitive.Trigger className="flex flex-1 items-center gap-1 text-body-md font-semibold outline-none transition-all focus-visible:text-brand-1-fg">
					<Icon
						name="chevron-down"
						className="transform group-radix-state-open:rotate-180"
						aria-hidden
						size="sm"
					/>
					{label}
				</AccordionPrimitive.Trigger>
				<AccordionPrimitive.Content
					// WARNING: Must keep this as the children are form elements, w/o them formData will be sparse
					forceMount
					// WARNING: invisible is necessary as the elements are still mounted, this is to prevent content being focused
					className="group -mx-4 overflow-hidden px-4 radix-state-closed:invisible radix-state-closed:h-0 radix-state-open:h-auto"
					// className="group -mx-4 overflow-hidden px-4 radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]"
				>
					<div className="space-y-2 py-3">{children}</div>
				</AccordionPrimitive.Content>
			</AccordionPrimitive.Item>
		</AccordionPrimitive.Root>
	)
}
