export const PRIORITY = {
	high: 'High',
	medium: 'Medium',
	low: 'Low',
}

export const PRIORITY_OPTIONS = Object.values(PRIORITY).map(status => ({
	value: status,
	label: status,
}))

export const PRIORITY_RANK = {
	[PRIORITY.high]: 1,
	[PRIORITY.medium]: 2,
	[PRIORITY.low]: 3,
}

export const STATUS = {
	ongoing: 'Ongoing',
	paused: 'Paused',
	crashed: 'Crashed',
}

export const TAM_STATUS_ENUM = {
	REQUESTED: 'Requested',
	IN_PROGRESS: 'In Progress',
	COMPLETED: 'Completed',
}

export const STATUS_OPTIONS = Object.values(STATUS).map(status => ({
	value: status,
	label: status,
}))

export const STATUS_RANK = {
	[STATUS.ongoing]: 1,
	[STATUS.paused]: 2,
	[STATUS.crashed]: 3,
}

export const PERSONA_TYPES = {
	decisionmaker: 'Decision Maker',
	influencer: 'Influencer',
	champion: 'Champion',
}

export const PERSONA_TYPE_RANK = {
	[PERSONA_TYPES.decisionmaker]: 1,
	[PERSONA_TYPES.influencer]: 2,
	[PERSONA_TYPES.champion]: 3,
}

export const PERSONA_TYPE_OPTIONS = Object.values(PERSONA_TYPES).map(
	personaType => ({
		value: personaType,
		label: personaType,
	}),
)

export const EMPLOYEE_RANGE_OPTIONS = [
	{ value: '1', label: '1' },
	{ value: '5', label: '5' },
	{ value: '10', label: '10' },
	{ value: '50', label: '50' },
	{ value: '100', label: '100' },
	{ value: '200', label: '200' },
	{ value: '500', label: '500' },
	{ value: '1000', label: '1 000' },
	{ value: '5000', label: '5 000' },
	{ value: '10000', label: '10 000' },
]

export const EMPLOYEE_MAX_RANGE_UPPER_LIMIT = '10001+'
export const EMPLOYEE_RANGE_UPPER_LIMIT = {
	value: EMPLOYEE_MAX_RANGE_UPPER_LIMIT,
	label: '10 001+',
}

export const MONTH_SELECT_OPTIONS = [
	{
		value: '1',
		label: 'January',
	},
	{
		value: '2',
		label: 'February',
	},
	{
		value: '3',
		label: 'March',
	},
	{
		value: '4',
		label: 'April',
	},
	{
		value: '5',
		label: 'May',
	},
	{
		value: '6',
		label: 'June',
	},
	{
		value: '7',
		label: 'July',
	},
	{
		value: '8',
		label: 'August',
	},
	{
		value: '9',
		label: 'September',
	},
	{
		value: '10',
		label: 'October',
	},
	{
		value: '11',
		label: 'November',
	},
	{
		value: '12',
		label: 'December',
	},
]
