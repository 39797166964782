import { Outlet } from 'react-router-dom'
import Header from '#src/components/header'
import Actions from '#src/components/header/actions'
import Crumbs from '#src/components/header/crumbs'
import PageLoader from '#src/components/loader'
import { PanelProvider, PanelToggle } from '#src/components/panel'
import Sidebar from '#src/routes/enable/_layout/sidebar'

function Main() {
	return (
		<>
			<Sidebar />

			<div
				id="scrollable"
				className="relative flex w-full flex-col overflow-auto"
			>
				<Header
					panelToggle={<PanelToggle />}
					crumbs={<Crumbs />}
					actions={<Actions />}
					className="border-b border-neutral-1-bd"
				/>
				<PageLoader status={status === 'pending' ? 'submitting' : undefined} />
				<Outlet />
			</div>
		</>
	)
}

export default function Layout() {
	return (
		<PanelProvider>
			<Main />
		</PanelProvider>
	)
}
