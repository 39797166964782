import { type ReactNode } from 'react'
import { Link, useRouteLoaderData } from 'react-router-dom'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { cn } from '#src/utils/misc'
import { Button } from './ui/button'
import { Icon } from './ui/icon'
import { Surface } from './ui/surface'

export default function NoPersonas({
	companyId,
	ecosystemId,
	verticalId,
	children,
	className,
}: {
	companyId: string
	ecosystemId: string | number
	verticalId: string
	children?: ReactNode
	className?: string
}) {
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse

	return (
		<Surface
			className={cn(
				'flex min-h-[260px] w-full flex-col items-center justify-center gap-4 border-none bg-neutral-2-bg',
				className,
			)}
		>
			<Icon name="user-avatar" size="xl" className="text-neutral-3-fg" />
			{children}
			{readOnlySession ? null : (
				<div>
					<Button
						asChild
						variant="outline"
						className="flex flex-nowrap items-center gap-1"
					>
						<Link
							to={`/personas/${companyId}/${ecosystemId}/${verticalId}/edit`}
						>
							<Icon name="add" /> Add Persona
						</Link>
					</Button>
				</div>
			)}
		</Surface>
	)
}
