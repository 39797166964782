import { type getInputProps, useInputControl } from '@conform-to/react'
import { CommandItem as CommandPrimitiveItem } from 'cmdk'
import {
	type LabelHTMLAttributes,
	type ReactNode,
	useRef,
	useState,
	useId,
	useEffect,
} from 'react'
import { cn } from '#src/utils/misc'
import { Chip } from '../chip.tsx'
import * as Command from '../ui/command.tsx'
import { Icon, type IconName } from '../ui/icon.tsx'
import { Label } from '../ui/label.tsx'
import { PopoverContent, PopoverRoot, PopoverTrigger } from '../ui/popover.tsx'
import { type ListOfErrors, ErrorList } from '.'

export default function RegionsInput({
	labelProps,
	includeInputProps,
	excludeInputProps,
	options,
	autoFocus,
	onFocus,
	errors,
	className,
}: {
	labelProps: LabelHTMLAttributes<HTMLLabelElement>
	includeInputProps: ReturnType<typeof getInputProps> & {
		value: string | undefined | null
		name: string
		form: string
	}
	excludeInputProps: ReturnType<typeof getInputProps> & {
		value: string | undefined | null
		name: string
		form: string
	}
	options: { value: string; label: ReactNode; keywords?: string[] }[]
	autoFocus?: boolean
	onFocus?: () => void
	errors?: ListOfErrors
	className?: string
}) {
	// https://github.com/pacocoursey/cmdk/issues/233
	const scrollId = useRef<ReturnType<typeof setTimeout>>()
	const listRef = useRef<HTMLDivElement>(null)
	const searchRef = useRef<HTMLInputElement>(null)
	const [open, setOpen] = useState(false)
	const [search, setSearch] = useState('')
	const { defaultValue } = includeInputProps

	const includeInputFallbackId = useId()
	const includeSelectedValue = includeInputProps.value ?? undefined
	const includeInput = useInputControl({
		key: includeInputProps.name,
		name: includeInputProps.name,
		formId: includeInputProps.form,
		initialValue: defaultValue ? includeSelectedValue : undefined,
	})
	const includeInputId = includeInputProps.id ?? includeInputFallbackId

	const excludeInputFallbackId = useId()
	const excludeSelectedValue = excludeInputProps.value ?? undefined
	const excludeInput = useInputControl({
		key: excludeInputProps.name,
		name: excludeInputProps.name,
		formId: excludeInputProps.form,
		initialValue: defaultValue ? excludeSelectedValue : undefined,
	})
	const excludeInputId = excludeInputProps.id ?? excludeInputFallbackId

	const errorId = errors?.length
		? `${includeInputId}-${excludeInputId}-error`
		: undefined

	useEffect(() => {
		if (searchRef.current && autoFocus) {
			searchRef.current.focus()
		}
	}, [autoFocus])

	function handleItemSelect(value: string) {
		const isIncluded = Boolean(
			includeSelectedValue && includeSelectedValue?.includes(value),
		)
		const isExcluded = Boolean(
			excludeSelectedValue && excludeSelectedValue?.includes(value),
		)

		if (!isIncluded && !isExcluded) {
			includeInput.change(
				includeSelectedValue ? `${includeSelectedValue}, ${value}` : value,
			)
		} else if (includeSelectedValue && isIncluded) {
			includeInput.change(
				includeSelectedValue
					.split(', ')
					.filter(v => v !== value)
					.join(', '),
			)
			excludeInput.change(
				excludeSelectedValue ? `${excludeSelectedValue}, ${value}` : value,
			)
		} else if (excludeSelectedValue && isExcluded) {
			excludeInput.change(
				excludeSelectedValue
					.split(', ')
					.filter(v => v !== value)
					.join(', '),
			)
		}
	}

	function handleIncludeChange(value: string) {
		const isIncluded = Boolean(
			includeSelectedValue && includeSelectedValue?.includes(value),
		)
		const isExcluded = Boolean(
			excludeSelectedValue && excludeSelectedValue?.includes(value),
		)

		if (isIncluded) return

		includeInput.change(
			includeSelectedValue ? `${includeSelectedValue}, ${value}` : value,
		)
		if (excludeSelectedValue && isExcluded) {
			excludeInput.change(
				excludeSelectedValue
					.split(', ')
					.filter(v => v !== value)
					.join(', '),
			)
		}
	}

	function handleExcludeChange(value: string) {
		const isIncluded = Boolean(
			includeSelectedValue && includeSelectedValue?.includes(value),
		)
		const isExcluded = Boolean(
			excludeSelectedValue && excludeSelectedValue?.includes(value),
		)

		if (isExcluded) return

		excludeInput.change(
			excludeSelectedValue ? `${excludeSelectedValue}, ${value}` : value,
		)
		if (includeSelectedValue && isIncluded) {
			includeInput.change(
				includeSelectedValue
					.split(', ')
					.filter(v => v !== value)
					.join(', '),
			)
		}
	}

	return (
		<div className={cn('flex flex-col gap-1', className)}>
			<Label {...labelProps} />

			<Selection
				value={includeSelectedValue}
				variant="green"
				onRemove={selection =>
					includeInput.change(
						includeSelectedValue
							? includeSelectedValue
									.split(', ')
									.filter(v => v !== selection)
									.join(', ')
							: '',
					)
				}
			/>
			<Selection
				value={excludeSelectedValue}
				variant="red"
				onRemove={selection =>
					excludeInput.change(
						excludeSelectedValue
							? excludeSelectedValue
									.split(', ')
									.filter(v => v !== selection)
									.join(', ')
							: '',
					)
				}
			/>

			<PopoverRoot
				onOpenChange={o => {
					if (!o) setSearch('')

					setOpen(o)
				}}
				open={open}
			>
				<Command.Root
					filter={(value: string, search: string, keywords?: string[]) => {
						if (value.toLocaleLowerCase() === search.toLocaleLowerCase())
							return 1
						else if (
							value &&
							value.toLocaleLowerCase().includes(search.toLocaleLowerCase())
						)
							return 0.9
						else if (
							keywords &&
							keywords.some(k =>
								k.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
							)
						)
							return 0.8
						else return 0
					}}
				>
					<div className="relative text-body-md">
						<PopoverTrigger asChild>
							<Command.Input
								ref={searchRef}
								placeholder="Search locations"
								autoFocus={autoFocus ? true : undefined}
								value={search}
								onFocus={() => {
									onFocus?.()
								}}
								onValueChange={v => {
									if (!open) setOpen(true)

									setSearch(v)

									clearTimeout(scrollId.current)
									scrollId.current = setTimeout(() => {
										listRef.current?.scrollTo({ top: 0 })
									}, 0)
								}}
								className={cn(
									'transition-all',
									'flex h-10 w-full items-center justify-between rounded px-3 py-2.5',
									'bg-transparent disabled:bg-neutral-2-bg',
									'border border-neutral-2-bd outline-none hover:border-neutral-2-bd-selected focus-visible:border-brand-2-bd aria-[invalid]:border-status-danger-bd',
									'text-body-md text-neutral-1-fg placeholder:text-neutral-inverse-fg disabled:text-neutral-inverse-fg-disabled',
									'disabled:cursor-not-allowed disabled:opacity-50',
									className,
								)}
							/>
						</PopoverTrigger>

						{search ? (
							<button
								type="button"
								onClick={e => {
									e.stopPropagation()
									setSearch('')
								}}
								className="absolute right-0 top-0 h-10 rounded p-3 text-label-sm text-neutral-2-fg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-2-bd-selected focus-visible:ring-offset-2"
							>
								<Icon name="cross-1" />
							</button>
						) : (
							<div className="pointer-events-none absolute right-0 top-0 h-10 p-3 text-label-sm text-neutral-2-fg">
								<Icon
									name="chevron-down"
									className={cn(
										'pointer-events-none',
										open ? 'rotate-180' : '',
									)}
								/>
							</div>
						)}
					</div>

					<Command.List ref={listRef}>
						<PopoverContent
							className="max-h-[12rem] overflow-y-auto px-0 py-2"
							sideOffset={0}
							side="bottom"
							onOpenAutoFocus={e => e.preventDefault()}
						>
							<Command.Empty className="pb-3 pt-3 text-center">
								{`No results found for '${search}'.`}
							</Command.Empty>

							{options.map(option => {
								const isIncluded = Boolean(
									includeSelectedValue &&
										includeSelectedValue?.includes(option.value),
								)
								const isExcluded = Boolean(
									excludeSelectedValue &&
										excludeSelectedValue?.includes(option.value),
								)
								return (
									<CommandPrimitiveItem
										key={option.value}
										value={option.value}
										keywords={option.keywords}
										onSelect={() => handleItemSelect(option.value)}
										className={cn(
											'relative flex items-center justify-between gap-2 px-3 py-1.5 text-body-md outline-none',
											'hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
										)}
									>
										<div
											className={cn(
												'transition-colors',
												isIncluded ? 'text-green-70' : '',
												isExcluded ? 'text-red-60' : '',
											)}
										>
											{isExcluded ? <s>{option.label}</s> : option.label}
										</div>

										<div className="flex items-center gap-1">
											<SelectionAction
												variant="green"
												disabled={isIncluded}
												onClick={() => handleIncludeChange(option.value)}
												icon="add-alt"
												hoverIcon="add-filled"
											/>
											<SelectionAction
												variant="red"
												disabled={isExcluded}
												onClick={() => handleExcludeChange(option.value)}
												icon="subtract-alt"
												hoverIcon="subtract-filled"
											/>
										</div>
									</CommandPrimitiveItem>
								)
							})}
						</PopoverContent>
					</Command.List>
				</Command.Root>
			</PopoverRoot>

			{errorId ? (
				<div className="min-h-[32px] pb-3">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

function SelectionAction({
	icon,
	hoverIcon,
	variant,
	onClick,
	disabled,
}: {
	icon: IconName
	hoverIcon: IconName
	variant: 'green' | 'red'
	onClick: () => void
	disabled: boolean
}) {
	return (
		<button
			type="button"
			disabled={disabled}
			className="group disabled:pointer-events-none disabled:opacity-50"
			onClick={e => {
				e.stopPropagation()
				onClick()
			}}
		>
			<Icon
				name={icon}
				size="sm"
				className="block animate-in fade-in group-hover:hidden"
			/>
			<Icon
				name={hoverIcon}
				size="sm"
				className={cn(
					'hidden group-hover:block group-hover:animate-in group-hover:fade-in',
					variant === 'green' ? 'text-green-70' : '',
					variant === 'red' ? 'text-red-60' : '',
				)}
			/>
		</button>
	)
}

function Selection({
	value,
	variant,
	onRemove,
}: {
	value: string | undefined
	variant: 'green' | 'red'
	onRemove: (value: string) => void
}) {
	if (!value) return null

	return (
		<div className="flex flex-wrap items-center gap-1 py-1">
			{value.split(', ')?.map(selection => (
				<Chip
					key={selection}
					variant={variant}
					className="grid w-max max-w-max grid-cols-[1fr,max-content,max-content] items-center gap-1 pl-2 pr-0.5"
				>
					<div className="truncate whitespace-nowrap">{selection}</div>
					<button
						type="button"
						className="flex items-center justify-center"
						onClick={e => {
							e.stopPropagation()
							onRemove(selection)
						}}
					>
						<Icon name="cross-1" size="xs" />
					</button>
				</Chip>
			))}
		</div>
	)
}
