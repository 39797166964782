import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import { loader as enableLoader } from '#src/routes/enable'
import EnableLayout from '#src/routes/enable/_layout'
import Chat, {
	type ChatLoaderResponse,
	ChatLoaderResponseSchema,
	loader as chatLoader,
} from '#src/routes/enable/chat'
import ChatLayout from '#src/routes/enable/chat/_layout'
import DeleteChat, {
	action as deleteChatAction,
} from '#src/routes/enable/chat/delete'
import ChatContact, {
	loader as chatContactLoader,
} from '#src/routes/enable/chat/info/contact'
import ChatPersona, {
	loader as chatPersonaLoader,
} from '#src/routes/enable/chat/info/persona'
import NewChat, {
	loader as chatNewLoader,
	action as chatNewAction,
} from '#src/routes/enable/chat/new'
import { action as restartChatAction } from '#src/routes/enable/chat/restart'
import EnableDashboard, {
	loader as enableDashboardLoader,
} from '#src/routes/enable/dashboard'
import EnableDashboardLayout from '#src/routes/enable/dashboard/_layout'
import Glossary, {
	loader as glossaryLoader,
	GlossaryLoaderResponseSchema,
	type GlossaryLoaderResponse,
} from '#src/routes/enable/glossary'
import GlossaryLayout from '#src/routes/enable/glossary/_layout'
import { action as deleteTermAction } from '#src/routes/enable/glossary/delete'
import TermEdit, {
	loader as editTermLoader,
} from '#src/routes/enable/glossary/edit'
import { action as saveTermAction } from '#src/routes/enable/glossary/save'
import Roleplay, {
	loader as roleplayLoader,
	type RoleplayLoaderResponse,
	RoleplayLoaderResponseSchema,
} from '#src/routes/enable/roleplay'
import RoleplayLayout from '#src/routes/enable/roleplay/_layout'
import DeleteRoleplayChat, {
	action as deleteRoleplayAction,
} from '#src/routes/enable/roleplay/delete'
import EndRoleplayChat, {
	action as chatEndRoleplayAction,
} from '#src/routes/enable/roleplay/end'
import NewRoleplaySession, {
	loader as chatNewRoleplayLoader,
	action as chatNewRoleplayAction,
} from '#src/routes/enable/roleplay/new'
import { action as restartRoleplayAction } from '#src/routes/enable/roleplay/restart'
import RoleplayReview, {
	loader as roleplayReviewLoader,
	RoleplayReviewLoaderResponseSchema,
	type RoleplayReviewLoaderResponse,
} from '#src/routes/enable/roleplay/review'
import RoleplaySession, {
	loader as roleplaySessionLoader,
	type RoleplaySessionLoaderResponse,
	RoleplaySessionLoaderResponseSchema,
} from '#src/routes/enable/roleplay/session'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export default (client: QueryClient) =>
	({
		id: 'enable-loader',
		path: '/enable/:companyId',
		element: <EnableLayout />,
		loader: enableLoader(client),
		children: [
			{
				element: <EnableDashboardLayout />,
				children: [
					{
						index: true,
						element: <EnableDashboard />,
						errorElement: <RouteBoundary />,
						loader: enableDashboardLoader(client),
					},
				],
			},
			{
				element: <GlossaryLayout />,
				children: [
					{
						path: 'glossary',
						element: <Glossary />,
						errorElement: <RouteBoundary />,
						loader: glossaryLoader(client),
						handle: {
							crumb: (data: GlossaryLoaderResponse) => {
								const parsedRouteHandleData =
									GlossaryLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								return [
									{
										to: routes.enable.index({
											companyId: handle.companyId,
										}),
										label: 'Enable',
										icon: 'data-enrichment',
									},
									{
										to: routes.enable.glossary.index({
											companyId: handle.companyId,
										}),
										label: 'Industry-jargons',
										icon: 'notebook-reference',
									},
								]
							},
							action: (data: GlossaryLoaderResponse) => {
								const parsedRouteHandleData =
									GlossaryLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								if (handle.readOnlySession || handle.enableOnlySession)
									return []
								return [
									{
										to: routes.enable.glossary.edit({
											companyId: handle.companyId,
											termId: null,
										}),
										label: 'Add new term',
										icon: 'add',
									},
								]
							},
						},
						children: [
							{
								path: 'save/:termId?',
								action: saveTermAction(client),
							},
							{
								path: 'delete/:termId',
								action: deleteTermAction(client),
							},
							{
								path: 'edit/:termId?',
								element: <TermEdit />,
								loader: editTermLoader(client),
							},
						],
					},
				],
			},
			{
				element: <ChatLayout />,
				children: [
					{
						path: 'copilot/:conversationId?',
						element: <Chat />,
						errorElement: <RouteBoundary />,
						loader: chatLoader(client),
						handle: {
							crumb: (data: ChatLoaderResponse) => {
								const parsedRouteHandleData =
									ChatLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								return [
									{
										to: routes.enable.index({ companyId: handle.companyId }),
										label: 'Enable',
										icon: 'data-enrichment',
									},
									{
										to: routes.enable.copilot.index({
											companyId: handle.companyId,
											conversationId: handle.conversationId!,
										}),
										label: data.conversationData?.conversation.name,
										icon: 'user',
									},
								]
							},
							action: (data: ChatLoaderResponse) => {
								const parsedRouteHandleData =
									ChatLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								if (!handle.conversationId) return []
								return [
									...(handle.canRestartChat
										? [
												{
													id: 'chat-restart',
													action: routes.enable.copilot.restart({
														companyId: handle.companyId,
														conversationId: handle.conversationId,
													}),
													method: 'PUT',
													label: 'Restart Chat',
													icon: 'reset',
												},
											]
										: []),
									{
										to: routes.enable.copilot.persona({
											companyId: handle.companyId,
											conversationId: handle.conversationId,
											properties: 'pain-points',
										}),
										label: 'Persona Info',
									},
									{
										to: routes.enable.copilot.contact({
											companyId: handle.companyId,
											conversationId: handle.conversationId,
										}),
										label: 'Contact Info',
									},
									...(handle.canDeleteChat
										? [
												{
													to: routes.enable.copilot.delete({
														companyId: handle.companyId,
														conversationId: handle.conversationId,
													}),
													icon: 'trash',
													className: 'text-status-danger-fg',
												},
											]
										: []),
								]
							},
						},
						children: [
							{
								path: 'new',
								element: <NewChat />,
								loader: chatNewLoader(client),
								action: chatNewAction(client),
							},
							{
								path: 'restart',
								action: restartChatAction(client),
							},
							{
								path: 'delete',
								element: <DeleteChat />,
								action: deleteChatAction(client),
							},
							{
								path: 'persona/:properties',
								element: <ChatPersona />,
								loader: chatPersonaLoader(client),
							},
							{
								path: 'contact',
								element: <ChatContact />,
								loader: chatContactLoader(client),
							},
						],
					},
				],
			},
			{
				element: <RoleplayLayout />,
				children: [
					{
						path: 'roleplay',
						element: <Roleplay />,
						errorElement: <RouteBoundary />,
						loader: roleplayLoader(client),
						handle: {
							crumb: (data: RoleplayLoaderResponse) => {
								const parsedRouteHandleData =
									RoleplayLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								return [
									{
										to: routes.enable.index({ companyId: handle.companyId }),
										label: 'Enable',
										icon: 'data-enrichment',
									},
									{
										to: routes.enable.roleplay.index({
											companyId: handle.companyId,
										}),
										label: 'Roleplay training',
										icon: 'voice-activate',
									},
								]
							},
						},
						children: [
							{
								path: 'new/:newRoleplaySessionType',
								element: <NewRoleplaySession />,
								loader: chatNewRoleplayLoader(client),
								action: chatNewRoleplayAction(client),
							},
						],
					},
					{
						path: 'roleplay',
						errorElement: <RouteBoundary />,
						children: [
							{
								path: 'session/:conversationId',
								element: <RoleplaySession />,
								loader: roleplaySessionLoader(client),
								handle: {
									crumb: (data: RoleplaySessionLoaderResponse) => {
										const parsedRouteHandleData =
											RoleplaySessionLoaderResponseSchema.safeParse(data)
										if (!parsedRouteHandleData.success) return
										const {
											data: { handle },
										} = parsedRouteHandleData
										return [
											{
												to: routes.enable.index({
													companyId: handle.companyId,
												}),
												label: 'Enable',
												icon: 'data-enrichment',
											},
											{
												to: routes.enable.roleplay.index({
													companyId: handle.companyId,
												}),
												label: 'Roleplay training',
												icon: 'voice-activate',
											},
											{
												to: routes.enable.roleplay.index({
													companyId: handle.companyId,
												}),
												label: 'Voice chat',
												icon: 'user-service-desk',
											},
										]
									},
									action: (data: RoleplaySessionLoaderResponse) => {
										const parsedRouteHandleData =
											RoleplaySessionLoaderResponseSchema.safeParse(data)
										if (!parsedRouteHandleData.success) return
										const {
											data: { handle },
										} = parsedRouteHandleData
										if (!handle.conversationId) return []
										return [
											...(handle.canRestartChat
												? [
														{
															id: 'copilot-restart',
															action: routes.enable.roleplay.restart({
																companyId: handle.companyId,
																conversationId: handle.conversationId,
															}),
															method: 'PUT',
															label: 'Restart Roleplay',
															icon: 'reset',
														},
													]
												: []),
											...(handle.canDeleteChat
												? [
														{
															to: routes.enable.roleplay.delete({
																companyId: handle.companyId,
																conversationId: handle.conversationId,
															}),
															icon: 'trash',
															className: 'text-status-danger-fg',
														},
													]
												: []),
										]
									},
								},
								children: [
									{
										path: 'restart',
										action: restartRoleplayAction(client),
									},
									{
										path: 'end-session',
										element: <EndRoleplayChat />,
										action: chatEndRoleplayAction(client),
									},
									{
										path: 'delete',
										element: <DeleteRoleplayChat />,
										action: deleteRoleplayAction(client),
									},
								],
							},
							{
								id: 'roleplay-review',
								path: 'review/:conversationId',
								element: <RoleplayReview />,
								loader: roleplayReviewLoader(client),
								handle: {
									crumb: (data: RoleplayReviewLoaderResponse) => {
										const parsedRouteHandleData =
											RoleplayReviewLoaderResponseSchema.safeParse(data)
										if (!parsedRouteHandleData.success) return
										const {
											data: { handle },
										} = parsedRouteHandleData
										return [
											{
												to: routes.enable.index({
													companyId: handle.companyId,
												}),
												label: 'Enable',
												icon: 'data-enrichment',
											},
											{
												to: routes.enable.roleplay.index({
													companyId: handle.companyId,
												}),
												label: 'Roleplay training',
												icon: 'voice-activate',
											},
											{
												to: routes.enable.roleplay.review({
													companyId: handle.companyId,
													conversationId: handle.conversationId,
												}),
												label: 'Review',
												icon: 'user-service-desk',
											},
										]
									},
									action: (data: RoleplayReviewLoaderResponse) => {
										const parsedRouteHandleData =
											RoleplayReviewLoaderResponseSchema.safeParse(data)
										if (!parsedRouteHandleData.success) return

										const {
											data: { handle },
										} = parsedRouteHandleData

										if (!handle.canDeleteChat) return []

										return [
											{
												to: routes.enable.roleplay.deleteReviewed({
													companyId: handle.companyId,
													conversationId: handle.conversationId,
												}),
												icon: 'trash',
												className: 'text-status-danger-fg',
											},
										]
									},
								},
								children: [
									{
										path: 'delete',
										element: <DeleteRoleplayChat />,
										action: deleteRoleplayAction(client),
									},
								],
							},
						],
					},
				],
			},
		],
	}) satisfies RouteObject
