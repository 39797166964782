import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import CopilotLoader, {
	loader as copilotLoader,
} from '#src/routes/enable/copilot'
import InitLayout from '#src/routes/init/_layout'
import Login, {
	loader as loginLoader,
	action as loginAction,
} from '#src/routes/init/login'
import { loader as logoutLoader } from '#src/routes/init/logout'

export const loginRouter = () =>
	({
		element: <InitLayout />,
		children: [
			{
				path: '/login',
				element: <Login />,
				loader: loginLoader,
				action: loginAction,
			},
			{
				path: '/logout',
				loader: logoutLoader,
			},
		],
	}) satisfies RouteObject

/**
 * WARNING: This route is used by the Copilot feature.
 * It is referenced in client's CRM's and should not be removed.
 * If need be, please implement a redirect.
 */
export const copilotRouter = (client: QueryClient) =>
	({
		path: '/copilot/:contactId',
		element: <CopilotLoader />,
		loader: copilotLoader(client),
	}) satisfies RouteObject
