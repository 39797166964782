import {
	getFormProps,
	getInputProps,
	getSelectProps,
	getTextareaProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import {
	type ActionFunctionArgs,
	useLoaderData,
	type LoaderFunctionArgs,
	redirect,
	Link,
	useNavigate,
	Form,
	useActionData,
} from 'react-router-dom'
import { ErrorList, Field, Select, TextareaField } from '#src/components/forms'
import { PersonaAvatar } from '#src/components/persona'
import { TrainingScenario } from '#src/components/training-scenario'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Label } from '#src/components/ui/label'
import {
	Drawer,
	SidebarFooter,
	SidebarHeader,
} from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { userQuery } from '#src/routes/init/user/me'
import { checkIsReadOnlySession, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { apiErrorSchema } from '#src/utils/safeFetch.js'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params.js'
import { createChatForSolo, createChatForGroup } from './mutations'
import {
	roleplayKeys,
	scenariosQuery,
	usersQuery,
	voicesQuery,
} from './queries'
import {
	CommunicationStyle,
	DecisionMakingProcess,
	EmotionalState,
	NewConversationFormSchema,
	PersonalityTrait,
} from './schema'

export type NewChatLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const readOnlySession = checkIsReadOnlySession(user.roles)

		if (readOnlySession) {
			throw new Response('Page Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		const ecosystems = await queryClient.fetchQuery(
			ecosystemsQuery(params.companyId),
		)
		const scenarios = await queryClient.fetchQuery(
			scenariosQuery(params.companyId),
		)
		const voices = await queryClient.fetchQuery(voicesQuery(params.companyId))
		const users = await queryClient.fetchQuery(usersQuery(params.companyId))

		if (!scenarios.length)
			throw new Response('No scenarios provided', {
				status: 400,
				statusText: 'Bad Request',
			})

		return {
			companyId: params.companyId,
			conversationId: params.conversationId,
			ecosystems,
			scenarios,
			voices,
			users,
		}
	}

export type NewChatActionResponse = Awaited<
	ReturnType<ReturnType<typeof action>>
>

export const action =
	(queryClient: QueryClient) =>
	async ({ request, params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.newRoleplaySessionType)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: NewConversationFormSchema,
		})

		if (submission.status !== 'success') {
			return {
				ok: false,
				result: submission.reply({
					formErrors: [
						'Failed to submit form. Make sure all fields are filled out correctly.',
					],
				}),
			}
		}

		const payload = {
			title: submission.value.title,
			personaId: submission.value.personaId,
			linkedinUrl: submission.value.linkedinUrl,
			config: submission.value.config,
			scenarioTitle: submission.value.scenario.name,
			scenario: submission.value.scenario.scenario,
			evaluation: submission.value.scenario.evaluation,
		}

		try {
			if (params.newRoleplaySessionType === 'group') {
				await createChatForGroup(params.companyId, {
					...payload,
					count: Number(submission.value.count),
				})

				await queryClient.invalidateQueries({
					queryKey: roleplayKeys.all,
				})

				return redirect(
					routes.enable.roleplay.index({
						companyId: params.companyId,
					}),
				)
			} else {
				const response = await createChatForSolo(params.companyId, {
					...payload,
				})

				await queryClient.invalidateQueries({
					queryKey: roleplayKeys.all,
				})

				return redirect(
					routes.enable.roleplay.session({
						companyId: params.companyId,
						conversationId: response.conversationId,
					}),
				)
			}
		} catch (error) {
			if (error instanceof Response) {
				const payload = apiErrorSchema.parse(await error.json())

				if (error.status === 422 && payload.message.includes('linkedin')) {
					return {
						ok: false,
						result: submission.reply({
							fieldErrors: {
								linkedinUrl: [
									'Unable to create new roleplay chat due to invalid or unparseable URL.',
								],
							},
						}),
					}
				}
			}

			captureException(new Error('Unable to create roleplay session'))

			return {
				ok: false,
				result: submission.reply({
					formErrors: ['Ooops! Something went wrong. Please try again later.'],
				}),
			}
		}
	}

export default function NewRoleplaySession() {
	const params = useParsedRouteParams(['companyId', 'newRoleplaySessionType'])
	const navigate = useNavigate()

	const formId = 'new-roleplay-form-' + params.newRoleplaySessionType
	const action = routes.enable.roleplay.new({
		companyId: params.companyId,
		newRoleplaySessionType: params.newRoleplaySessionType,
	})
	const method = 'POST'

	const cancelTo = routes.enable.roleplay.index({
		companyId: params.companyId,
	})

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<Drawer
			dialogProps={{
				defaultOpen: true,
				onOpenChange(open) {
					if (!open)
						navigate(
							routes.enable.roleplay.index({ companyId: params.companyId }),
						)
				},
			}}
			header={
				<SidebarHeader>
					<h1 className="text-title-sm">Roleplay setup</h1>
					<Link className="inline-flex" to={cancelTo}>
						<Icon name="cross-1" />
					</Link>
				</SidebarHeader>
			}
			main={<NewRoleplayForm formId={formId} action={action} method={method} />}
			footer={
				<SidebarFooter>
					<Button asChild variant="outline" size="sm">
						<Link to={cancelTo}>Cancel</Link>
					</Button>
					<StatusButton
						status={isPending ? 'pending' : 'idle'}
						size="sm"
						type="submit"
						form={formId}
						disabled={isPending}
					>
						{params.newRoleplaySessionType === 'group'
							? 'Create & Share scenarios'
							: 'Start session'}
					</StatusButton>
				</SidebarFooter>
			}
		/>
	)
}

function NewRoleplayForm({
	formId,
	action,
	method = 'POST',
}: {
	formId: string
	action: string
	method: 'POST'
}) {
	const params = useParsedRouteParams(['newRoleplaySessionType'])
	const actionData = useActionData() as NewChatActionResponse
	const { ecosystems, scenarios, voices, users } =
		useLoaderData() as NewChatLoaderResponse

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(NewConversationFormSchema),
		defaultValue: {
			title: null,
			ecosystemId: null,
			verticalId: null,
			personaId: null,
			linkedinUrl: null,
			config: {
				personalityTrait: PersonalityTrait.Analytical,
				emotionalState: EmotionalState.Busy,
				communicationStyle: CommunicationStyle.Formal,
				decisionMakingProcess: DecisionMakingProcess.Collaborative,
				voice: voices[0].voiceId,
			},
			scenario: {
				name: scenarios[0].name,
				scenario: scenarios[0].scenario ?? '',
				evaluation: scenarios[0].evaluation ?? '',
			},
			...(params.newRoleplaySessionType === 'group' ? { count: '1' } : {}),
		},
		lastResult: !(actionData instanceof Response) ? actionData?.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: NewConversationFormSchema })
		},
		shouldValidate: 'onSubmit',
		shouldRevalidate: 'onInput',
	})

	const scenariosOptions = scenarios.map(({ name }) => ({
		value: name,
		label: <TrainingScenario title={name} icon="user-service-desk" />,
	}))
	const ecosystemsOptions = ecosystems.map(({ id, name, verticals }) => ({
		value: id!.toString(),
		label: `${name} (${verticals.length} verticals)`,
	}))
	const verticalsOptions =
		ecosystems
			.find(({ id }) => String(id) === fields.ecosystemId.value)
			?.verticals.map(({ id, name, personas }) => ({
				value: id.toString(),
				label: `${name} (${personas.length} personas)`,
			})) ?? []
	const personasOptions =
		ecosystems
			.find(({ id }) => String(id) === fields.ecosystemId.value)
			?.verticals.find(({ id }) => String(id) === fields.verticalId.value)
			?.personas.map(({ id, expertise, type, jobTitles }) => ({
				value: id.toString(),
				label: (
					<div className="grid grid-cols-[max-content,1fr] grid-rows-2 items-center gap-x-2">
						<PersonaAvatar type={type} size="sm" className="row-span-2" />
						<h3 className="text-label-sm text-neutral-3-fg">{expertise}</h3>
						<p className="w-full truncate text-label-sm text-neutral-2-fg">
							{jobTitles}
						</p>
					</div>
				),
			})) ?? []
	const personalityTraitOptions = [
		{ value: PersonalityTrait.Analytical, label: 'Analytical' },
		{ value: PersonalityTrait.Amiable, label: 'Amiable' },
		{ value: PersonalityTrait.Expressive, label: 'Expressive' },
		{ value: PersonalityTrait.Driver, label: 'Driver' },
	]
	const emotionalStateOptions = [
		{ value: EmotionalState.Skeptical, label: 'Skeptical' },
		{ value: EmotionalState.Supportive, label: 'Supportive' },
		{ value: EmotionalState.Busy, label: 'Busy' },
		{ value: EmotionalState.Indecisive, label: 'Indecisive' },
	]
	const communicationStyleOptions = [
		{ value: CommunicationStyle.Direct, label: 'Direct' },
		{ value: CommunicationStyle.Indirect, label: 'Indirect' },
		{ value: CommunicationStyle.Formal, label: 'Formal' },
		{ value: CommunicationStyle.Informal, label: 'Informal' },
	]
	const decisionMakingProcessOptions = [
		{ value: DecisionMakingProcess.Collaborative, label: 'Collaborative' },
		{ value: DecisionMakingProcess.Autonomous, label: 'Autonomous' },
		{ value: DecisionMakingProcess.Hierarchical, label: 'Hierarchical' },
	]
	const voiceOptions = voices.map(v => ({
		value: v.voiceId,
		label: v.name,
	}))
	const countOptions = ['1', '2', '3', '4', '5'].map(v => ({
		value: v,
		label: v,
	}))
	const scenarioFields = fields.scenario.getFieldset()
	const configFields = fields.config.getFieldset()

	return (
		<Form
			{...getFormProps(form)}
			action={action}
			method={method}
			className="-mx-4"
		>
			<section className="flex flex-col gap-4 px-4">
				<Field
					labelProps={{ children: 'Session name' }}
					inputProps={{
						...getInputProps(fields.title, { type: 'text' }),
						placeholder: 'Enter session name',
					}}
					errors={fields.title.errors}
				/>

				<Select
					labelProps={{
						children: 'Scenario',
					}}
					inputProps={{
						contentProps: {
							className: 'overflow-y-auto max-h-[20rem]',
						},
						className: 'h-12',
						...getSelectProps(scenarioFields.name),
						defaultValue: scenarioFields.name.initialValue,
						value: scenarioFields.name.value,
						placeholder: '-',
					}}
					options={scenariosOptions}
					errors={scenarioFields.name.errors}
				/>

				<TextareaField
					labelProps={{ children: 'Scenario Tuning' }}
					textareaProps={{
						...getTextareaProps(scenarioFields.scenario),
						placeholder: 'Enter details',
						rows: 9,
					}}
					errors={scenarioFields.scenario.errors}
				/>
			</section>

			<section className="mb-4 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Persona mapping
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<Select
					labelProps={{
						children: 'Select Ecosystem',
					}}
					inputProps={{
						...getSelectProps(fields.ecosystemId),
						defaultValue: fields.ecosystemId.initialValue,
						value: fields.ecosystemId.value,
						placeholder: '-',
					}}
					options={ecosystemsOptions}
					errors={fields.ecosystemId.errors}
				/>
				<Select
					labelProps={{
						children: `Select Vertical`,
					}}
					inputProps={{
						...getSelectProps(fields.verticalId),
						defaultValue: fields.verticalId.initialValue,
						value: fields.verticalId.value,
						placeholder: '-',
						disabled: !verticalsOptions.length,
					}}
					options={verticalsOptions}
					errors={fields.verticalId.errors}
				/>
				<Select
					labelProps={{
						children: 'Assign Persona',
					}}
					inputProps={{
						contentProps: {
							position: 'popper',
							className: 'overflow-y-auto max-h-[20rem]',
						},
						className: 'h-12',
						...getSelectProps(fields.personaId),
						defaultValue: fields.personaId.initialValue,
						value: fields.personaId.value,
						placeholder: '-',
						disabled: !fields.ecosystemId.value || !fields.verticalId.value,
					}}
					options={personasOptions}
					errors={fields.personaId.errors}
				/>

				<Field
					labelProps={{ children: 'LinkedIn URL (optional)' }}
					inputProps={{
						...getInputProps(fields.linkedinUrl, { type: 'text' }),
						placeholder: 'Enter LinkedIn URL',
					}}
					errors={fields.linkedinUrl.errors}
				/>
			</section>

			<section className="mb-4 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Personality
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<Select
					labelProps={{
						children: `Personality Trait`,
					}}
					inputProps={{
						...getSelectProps(configFields.personalityTrait),
						defaultValue: configFields.personalityTrait.initialValue,
						value: configFields.personalityTrait.value,
						placeholder: '-',
					}}
					options={personalityTraitOptions}
					errors={configFields.personalityTrait.errors}
				/>
				<Select
					labelProps={{
						children: `Emotional state`,
					}}
					inputProps={{
						...getSelectProps(configFields.emotionalState),
						defaultValue: configFields.emotionalState.initialValue,
						value: configFields.emotionalState.value,
						placeholder: '-',
					}}
					options={emotionalStateOptions}
					errors={configFields.emotionalState.errors}
				/>
				<Select
					labelProps={{
						children: `Communication style`,
					}}
					inputProps={{
						...getSelectProps(configFields.communicationStyle),
						defaultValue: configFields.communicationStyle.initialValue,
						value: configFields.communicationStyle.value,
						placeholder: '-',
					}}
					options={communicationStyleOptions}
					errors={configFields.communicationStyle.errors}
				/>
				<Select
					labelProps={{
						children: `Decision making process`,
					}}
					inputProps={{
						...getSelectProps(configFields.decisionMakingProcess),
						defaultValue: configFields.decisionMakingProcess.initialValue,
						value: configFields.decisionMakingProcess.value,
						placeholder: '-',
					}}
					options={decisionMakingProcessOptions}
					errors={configFields.decisionMakingProcess.errors}
				/>
			</section>

			<section className="mb-4 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Voice
				</h3>
			</section>

			<section className="flex flex-col gap-2 px-4">
				<Select
					labelProps={{
						children: `Voice character`,
					}}
					inputProps={{
						...getSelectProps(configFields.voice),
						defaultValue: configFields.voice.initialValue,
						value: configFields.voice.value,
						placeholder: '-',
					}}
					options={voiceOptions}
					errors={configFields.voice.errors}
				/>
			</section>

			<section className="mb-4 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Scenario Evaluation
				</h3>
			</section>

			<section className="flex flex-col gap-2 px-4">
				<TextareaField
					labelProps={{ children: 'Evaluation criteria' }}
					textareaProps={{
						...getTextareaProps(scenarioFields.evaluation),
						placeholder: 'Enter details',
						rows: 9,
					}}
					errors={scenarioFields.evaluation.errors}
				/>
			</section>

			{params.newRoleplaySessionType === 'group' ? (
				<>
					<section className="mb-4 mt-6 bg-neutral-2-bg">
						<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
							Session sharing
						</h3>
					</section>

					<section className="flex flex-col gap-4 px-4">
						<Select
							labelProps={{
								children: 'Select number of duplicates',
							}}
							inputProps={{
								...getSelectProps(fields.count),
								defaultValue: fields.count.initialValue,
								value: fields.count.value,
								placeholder: '-',
							}}
							options={countOptions}
							errors={fields.count.errors}
						/>
						<div className="flex flex-col gap-1">
							<Label>Participants</Label>
							<ul className="flex flex-col gap-2">
								{users?.length ? (
									users.map(({ id, username }) => (
										<li key={id}>
											<p className="text-body-sm text-neutral-2-fg">
												{username}
											</p>
										</li>
									))
								) : (
									<li>
										<p className="text-body-sm text-neutral-2-fg">
											No User List Specified
										</p>
									</li>
								)}
							</ul>
						</div>
					</section>
				</>
			) : null}

			<section className="mb-4 mt-6 flex flex-col gap-2 px-4">
				<ErrorList errors={form.errors} id={form.errorId} />
			</section>
		</Form>
	)
}
