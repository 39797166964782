import * as Sentry from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import { createBrowserRouter } from 'react-router-dom'
import Root, { loader as rootLoader } from '#src/routes/_layout/root'
import adminRouter from '#src/routes/_router/admin'
import { copilotRouter, loginRouter } from '#src/routes/_router/init'
import mainRouter from '#src/routes/_router/main'
import RootBoundary from '#src/routes/root-boundary'

const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const createAppRouter = (client: QueryClient) =>
	sentryCreateBrowserRouter([
		{
			element: <Root />,
			errorElement: <RootBoundary />,
			loader: rootLoader(client),
			children: [
				loginRouter(),
				copilotRouter(client),
				adminRouter(client),
				mainRouter(client),
			],
		},
	])
