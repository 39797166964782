import { type Config } from 'tailwindcss'
import defaultTheme from 'tailwindcss/defaultTheme'

export const theme = {
	container: {
		center: true,
		padding: '2rem',
		screens: {
			'2xl': '1400px',
		},
	},
	spacing: {
		// NOTE: leave commented stuff as we may need to add more spacing variables as we go
		// px: '1px',
		0: '0',
		0.5: '0.125rem',
		1: '0.25rem',
		1.5: '0.375rem',
		2: '0.5rem',
		2.5: '0.625rem',
		3: '0.75rem',
		3.5: '0.875rem',
		4: '1rem',
		4.5: '1.125rem',
		5: '1.25rem',
		6: '1.5rem',
		7: '1.75rem',
		8: '2rem',
		9: '2.25rem',
		10: '2.5rem',
		11: '2.75rem',
		12: '3rem',
		14: '3.5rem',
		16: '4rem',
		20: '5rem',
		24: '6rem',
		// 28: '7rem',
		32: '8rem',
		// 36: '9rem',
		40: '10rem',
		// 44: '11rem',
		// 48: '12rem',
		// 52: '13rem',
		56: '14rem',
		60: '15rem',
		64: '16rem',
		// 72: '18rem',
		// 80: '20rem',
		96: '24rem',
	},
	colors: {
		current: 'currentColor',
		transparent: 'transparent',
		white: '#fff',
		black: '#000',
	},
	fontFamily: {
		sans: ['"Inter"', ...defaultTheme.fontFamily.sans],
	},
	fontSize: {
		// 1rem = 16px
		'heading-lg': [
			'3rem',
			{ letterSpacing: 'normal', lineHeight: '4rem', fontWeight: '600' },
		],
		'heading-md': [
			'2.5rem',
			{ letterSpacing: 'normal', lineHeight: '3.5rem', fontWeight: '600' },
		],
		'heading-sm': [
			'2rem',
			{ letterSpacing: 'normal', lineHeight: '2.5rem', fontWeight: '600' },
		],

		'title-lg': [
			'1.5rem',
			{ letterSpacing: 'normal', lineHeight: '2rem', fontWeight: '600' },
		],
		'title-md': [
			'1.125rem',
			{ letterSpacing: 'normal', lineHeight: '1.5rem', fontWeight: '600' },
		],
		'title-sm': [
			'1rem',
			{ letterSpacing: 'normal', lineHeight: '1.5rem', fontWeight: '600' },
		],

		'body-lg': ['1rem', { letterSpacing: 'normal', lineHeight: '1.5rem' }],
		'body-md': ['0.875rem', { letterSpacing: 'normal', lineHeight: '1.25rem' }],
		'body-sm': ['0.75rem', { letterSpacing: 'normal', lineHeight: '1rem' }],

		'label-lg': [
			'0.875rem',
			{ letterSpacing: '0.0175rem', lineHeight: '1.25rem', fontWeight: '600' },
		],
		'label-md': [
			'0.75rem',
			{ letterSpacing: '0.015rem', lineHeight: '1rem', fontWeight: '600' },
		],
		'label-sm': [
			'0.6875rem',
			{ letterSpacing: '0.03rem', lineHeight: '1rem', fontWeight: '600' },
		],

		'button-lg': [
			'1rem',
			{ letterSpacing: 'normal', lineHeight: '1rem', fontWeight: '500' },
		],
		'button-sm': [
			'0.875rem',
			{ letterSpacing: 'normal', lineHeight: '1rem', fontWeight: '500' },
		],
	},
	borderRadius: {
		none: '0px',
		sm: '0.25rem',
		DEFAULT: '0.5rem',
		lg: '1rem',
		xl: '1.5rem',
		full: '9999px',
	},
	boxShadow: {
		// X Y Blur Spread Color
		sm: '0 1px 4px 0 rgba(40, 49, 88, 0.15)',
		DEFAULT: '0 3px 8px 0 rgba(40, 49, 88, 0.20)',
		md: '0 8px 16px 0 rgba(40, 49, 88, 0.20)',
		lg: '0 18px 40px 0 rgba(40, 49, 88, 0.15)',
		xl: '0 25px 50px 0 rgba(40, 49, 88, 0.25)',
		none: 'none',
	},
	dropShadow: {
		// X Y Blur Color
		sm: '0 1px 4px rgba(40, 49, 88, 0.15)',
		DEFAULT: '0 3px 8px rgba(40, 49, 88, 0.20)',
		md: '0 8px 16px rgba(40, 49, 88, 0.20)',
		lg: '0 18px 40px rgba(40, 49, 88, 0.04)',
		xl: '0 25px 50px 0 rgba(40, 49, 88, 0.25)',
		none: '0 0 #0000',
	},
} satisfies Config['theme']
