import { Link } from 'react-router-dom'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { Button } from './ui/button'
import { Icon } from './ui/icon'
import { Surface } from './ui/surface'

export default function NoTranscript() {
	const params = useParsedRouteParams(['companyId'])

	return (
		<Surface className="flex min-h-[360px] w-full max-w-[920px] flex-col items-center justify-center gap-4 border-none bg-neutral-2-bg">
			<Icon name="user-service-desk" size="xl" className="text-neutral-3-fg" />

			<h1 className="text-center text-body-lg text-neutral-2-fg">
				This roleplay session does not have any messages
			</h1>

			<div>
				<Button asChild className="flex flex-nowrap items-center gap-2">
					<Link
						to={routes.enable.roleplay.index({
							companyId: params.companyId,
						})}
					>
						Back to Home
					</Link>
				</Button>
			</div>
		</Surface>
	)
}
