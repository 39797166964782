import * as React from 'react'

import { cn } from '#src/utils/misc'

export interface TextareaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ className, ...props }, ref) => {
		return (
			<textarea
				className={cn(
					'transition-all',
					'flex w-full rounded px-3 py-2.5',
					'bg-transparent disabled:bg-neutral-2-bg',
					'border border-neutral-2-bd outline-none hover:border-neutral-2-bd-selected focus-visible:border-brand-2-bd aria-[invalid]:border-status-danger-bd',
					'text-body-md text-neutral-1-fg placeholder:text-neutral-inverse-fg disabled:text-neutral-inverse-fg-disabled',
					'read-only:cursor-not-allowed disabled:cursor-not-allowed disabled:opacity-50',
					'file:border-0 file:bg-transparent file:text-button-sm',
					className,
				)}
				rows={props.rows ?? 3}
				ref={ref}
				{...props}
			/>
		)
	},
)
Textarea.displayName = 'Textarea'

export { Textarea }
