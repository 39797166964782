import { type QueryClient } from '@tanstack/react-query'
import { type ButtonHTMLAttributes, type ReactNode, forwardRef } from 'react'
import {
	redirect,
	useNavigate,
	type LoaderFunctionArgs,
} from 'react-router-dom'
import DeleteConfirm, {
	DeleteConfirmContent,
	DeleteConfirmFooter,
} from '#src/components/delete-confirm'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import { userQuery } from '#src/routes/init/user/me'
import { checkIsReadOnlySession, cn, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { deleteConversation } from './mutations'
import { roleplayKeys } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const readOnly = checkIsReadOnlySession(user.roles)

		if (readOnly)
			throw new Response('Access Denied', {
				status: 403,
				statusText: 'Forbidden',
			})

		await deleteConversation(params.companyId, params.conversationId)

		void queryClient.invalidateQueries({
			queryKey: roleplayKeys.chats(params.companyId),
		})
		return redirect(
			routes.enable.roleplay.index({
				companyId: params.companyId,
			}),
		)
	}

export default function DeleteChatForm() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const navigate = useNavigate()
	const action = routes.enable.roleplay.delete({
		companyId: params.companyId,
		conversationId: params.conversationId,
	})
	const isPending = useIsPending({
		formAction: action,
		formMethod: 'DELETE',
	})

	return (
		<DialogRoot
			defaultOpen={true}
			onOpenChange={isOpen => {
				if (!isOpen && !isPending) {
					navigate(-1)
				}
			}}
		>
			<DialogContent
				className="w-full max-w-lg"
				closeClassName={isPending ? 'opacity-50 cursor-default' : ''}
			>
				<DeleteConfirmContent
					title="Remove chat?"
					description="This action cannot be undone. This will permanently delete the chat and remove any associated data such as messages."
				>
					<DeleteConfirmFooter action={action} />
				</DeleteConfirmContent>
			</DialogContent>
		</DialogRoot>
	)
}

interface DeleteChatAsyncFormProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	conversationId: string
	children: ReactNode
}

export const DeleteChatAsyncForm = forwardRef<
	HTMLButtonElement,
	DeleteChatAsyncFormProps
>(({ conversationId, children, className }, ref) => {
	const params = useParsedRouteParams(['companyId'])
	const action = routes.enable.roleplay.delete({
		companyId: params.companyId,
		conversationId,
	})

	return (
		<DeleteConfirm
			trigger={
				<button type="button" className={cn('w-full', className)} ref={ref}>
					{children}
				</button>
			}
			action={action}
			title="Remove chat?"
			description="This action cannot be undone. This will permanently delete the chat and remove any associated data such as messages."
		/>
	)
})
DeleteChatAsyncForm.displayName = 'DeleteChatAsyncForm'
