import { Outlet, useRouteLoaderData } from 'react-router-dom'
import Header from '#src/components/header'
import Actions from '#src/components/header/actions'
import Crumbs from '#src/components/header/crumbs'
import PageLoader from '#src/components/loader'
import Panel, { PanelProvider, PanelToggle } from '#src/components/panel'
import Sidebar from '#src/routes/_layout/sidebar'
import { type AidtLoaderResponse } from '#src/routes/organize/aidt'
import ChatsPanel from './panel'

function Main() {
	const {
		user,
		handle: { conversationId },
	} = useRouteLoaderData('aidt-loader') as AidtLoaderResponse

	return (
		<>
			<Sidebar />

			{conversationId && (
				<Panel
					header={
						<div className="flex w-full items-center justify-between gap-4">
							{user.company && user.roles.includes('enable-only') ? (
								<h1>{user.company}</h1>
							) : (
								<span className="w-full text-center text-body-sm font-semibold tracking-[.02em] text-neutral-3-fg">
									Lead research agent Q&A
								</span>
							)}
						</div>
					}
				>
					<ChatsPanel />
				</Panel>
			)}

			<div
				id="scrollable"
				className="relative flex w-full flex-col overflow-auto"
			>
				<Header
					panelToggle={<PanelToggle />}
					crumbs={<Crumbs />}
					actions={<Actions />}
					className="border-b border-neutral-1-bd"
				/>
				<PageLoader />
				<Outlet />
			</div>
		</>
	)
}

export default function Layout() {
	return (
		<PanelProvider>
			<Main />
		</PanelProvider>
	)
}
