import { type ReactNode } from 'react'
import { cn } from '#src/utils/misc'
import { Button } from './ui/button'
import { Icon } from './ui/icon'
import { Logo } from './ui/logo'

export default function PageErrorBoundary({
	title,
	message,
	description,
	className,
	children,
}: {
	title: string
	message: string
	description?: string
	className?: string
	children?: ReactNode
}) {
	return (
		<div
			className={cn(
				'relative flex h-screen w-screen flex-col items-center justify-center gap-4 overflow-hidden',
				className,
			)}
		>
			<div className="pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center">
				<div className="min-h-[670px] w-[42.5%] min-w-[670px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.04]" />
			</div>
			<div className="pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center">
				<div className="min-h-[500px] w-[32.5%] min-w-[500px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.05]" />
			</div>
			<div className="pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center">
				<div className="min-h-[330px] w-[22.5%] min-w-[330px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-10" />
			</div>
			<Logo size="xl" type="symbol" />
			<h1 className="text-heading-sm text-neutral-1-fg">{title}</h1>
			<section className="flex flex-col items-center justify-center gap-1 text-center">
				{message ? (
					<p className="text-title-sm text-neutral-1-fg">{message}</p>
				) : null}
				{description ? (
					<p className="text-body-sm font-normal text-neutral-1-fg">
						{description}
					</p>
				) : null}
			</section>
			<div>
				{children ? (
					children
				) : (
					<Button asChild className="px-8">
						<a href="/">Back to Home</a>
					</Button>
				)}
			</div>
		</div>
	)
}

export function ComponentErrorBoundary({
	title,
	message,
	description,
	className,
	children,
}: {
	title: string
	message: string
	description?: string
	className?: string
	children?: ReactNode
}) {
	return (
		<div
			className={cn(
				'relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden',
				className,
			)}
		>
			<Icon name="warning-alt" size="xl" />
			<h1 className="text-heading-sm text-neutral-1-fg">{title}</h1>
			<section className="flex flex-col items-center justify-center gap-1 text-center">
				{message ? (
					<p className="text-title-sm text-neutral-1-fg">{message}</p>
				) : null}
				{description ? (
					<p className="whitespace-pre-line text-body-sm font-normal text-neutral-1-fg">
						{description}
					</p>
				) : null}
			</section>
			{children ? <div>{children}</div> : null}
		</div>
	)
}
