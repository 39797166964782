import { client as queryClient } from '#src/main'
import {
	FILTERS,
	IntentSourceSchema,
	IntentTypeSchema,
	HackSchema,
	WeightSchema,
	MappedPersonasSchema,
	SortSchema,
} from './schema'

export const signalFilterKeys = {
	all: ['filters'] as const,
	detail: (key: FILTERS) => ['filters', key] as const,
}

export const sortKey = {
	all: ['sort'] as const,
}

export const filterQuery = (key: FILTERS) => ({
	queryKey: signalFilterKeys.detail(key),
	queryFn: () => {
		const result = queryClient.getQueryData(signalFilterKeys.detail(key))

		switch (key) {
			case FILTERS.TYPE:
				return IntentTypeSchema.parse(result) ?? null
			case FILTERS.SOURCE:
				return IntentSourceSchema.parse(result) ?? null
			case FILTERS.HACK:
				return HackSchema.parse(result) ?? null
			case FILTERS.WEIGHT:
				return WeightSchema.parse(result) ?? null
			case FILTERS.MAPPED_PERSONAS:
				return MappedPersonasSchema.parse(result) ?? null
			default:
				throw new Error('Invalid filter key')
		}
	},
})

export const sortQuery = () => ({
	queryKey: sortKey.all,
	queryFn: () => {
		const result = queryClient.getQueryData(sortKey.all)

		return SortSchema.parse(result) ?? null
	},
})
