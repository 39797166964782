import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { PersonaAPISchema } from './schema'

export const personaKeys = {
	all: ['persona'] as const,
	details: () => [...personaKeys.all, 'details'] as const,
	detail: (companyId: string, personaId: string) =>
		[...personaKeys.details(), companyId, personaId] as const,
}

export const personaQuery = (companyId: string, personaId: string) => ({
	queryKey: personaKeys.detail(companyId, personaId),
	queryFn: async () => {
		return await safeFetch(
			PersonaAPISchema,
			api.persona.details(companyId, personaId),
			{
				method: 'GET',
			},
		)
	},
})
