import {
	getFormProps,
	getInputProps,
	getSelectProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect, Form, Link } from 'react-router-dom'
import { type z } from 'zod'
import { ErrorList, Field, Select } from '#src/components/forms'
import { Button } from '#src/components/ui/button'
import { StatusButton } from '#src/components/ui/status-button'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import {
	createEcosystem,
	createEcosystemFromTemplate,
	updateEcosystem,
} from './mutations'
import { ecosystemKeys } from './queries'
import {
	type EcosystemAPISchema,
	EcosystemFormSchema,
	type EcosystemTemplateListAPISchema,
} from './schema'

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()
		const intent = formData.get('intent')

		if (intent === 'ecosystem') {
			const submission = parseWithZod(formData, { schema: EcosystemFormSchema })

			if (submission.status !== 'success') {
				throw Error('Failed to parse form data')
			}

			const { id, template, ...data } = submission.value

			if (template !== 'new' && template) {
				const response = await createEcosystemFromTemplate(params.companyId, {
					ecosystemId: template,
				})

				await queryClient.invalidateQueries({
					queryKey: ecosystemKeys.all,
				})

				return redirect(
					routes.calibrate.verticals.index({
						companyId: params.companyId,
						ecosystemId: response.id!.toString(),
					}),
				)
			} else if (id) {
				await updateEcosystem(params.companyId, Number(id), data)

				await queryClient.invalidateQueries({
					queryKey: ecosystemKeys.all,
				})

				return redirect(
					routes.calibrate.verticals.index({
						companyId: params.companyId,
						ecosystemId: id,
					}),
				)
			} else {
				const response = await createEcosystem(params.companyId, data)

				await queryClient.invalidateQueries({
					queryKey: ecosystemKeys.all,
				})

				return redirect(
					routes.calibrate.verticals.create({
						companyId: params.companyId,
						ecosystemId: response.id!.toString(),
					}),
				)
			}
		}

		return redirect(
			routes.calibrate.index({
				companyId: params.companyId,
			}),
		)
	}

export function EcosystemForm({
	ecosystem,
	ecosystemTemplates,
	companyId,
}: {
	ecosystem: z.infer<typeof EcosystemAPISchema> | null
	ecosystemTemplates: z.infer<typeof EcosystemTemplateListAPISchema>
	companyId?: string
}) {
	const action = ecosystem?.id
		? routes.calibrate.ecosystem.save({
				companyId: companyId!,
				ecosystemId: ecosystem.id.toString(),
			})
		: routes.calibrate.ecosystem.save({
				companyId: companyId!,
				ecosystemId: null,
			})
	const method = ecosystem?.id ? 'PATCH' : 'POST'

	const isPending = useIsPending({ formAction: action, formMethod: method })

	const defaultValue = {
		id: ecosystem?.id,
		name: ecosystem?.name ?? '',
		template: 'new',
	}

	const [form, fields] = useForm({
		id: ecosystem?.id ? 'ecosystem-form-' + ecosystem.id : 'ecosystem-form',
		constraint: getZodConstraint(EcosystemFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: EcosystemFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<Form
			method={method}
			{...getFormProps(form)}
			className="flex flex-col gap-4"
		>
			<input {...getInputProps(fields.id, { type: 'hidden' })} />

			{!ecosystem ? (
				<Select
					labelProps={{ children: 'Select a template or start from scratch' }}
					inputProps={{
						...getSelectProps(fields.template),
						defaultValue: fields.template.initialValue,
						value: fields.template.value,
						placeholder: 'Select template',
					}}
					options={[{ value: 'new', label: 'New Ecosystem' }].concat(
						ecosystemTemplates.map(template => ({
							value: template.id.toString(),
							label: template.name,
						})),
					)}
				/>
			) : null}

			{fields.template.value === 'new' || Boolean(ecosystem) ? (
				<Field
					labelProps={{ children: 'Ecosystem name' }}
					inputProps={{
						...getInputProps(fields.name, { type: 'text' }),
						placeholder: 'Enter here',
						autoFocus: true,
					}}
					errors={fields.name.errors}
				/>
			) : null}

			<ErrorList errors={form.errors} id={form.errorId} />

			<div className="flex items-center justify-end gap-2 pt-4">
				<Button
					className="min-w-24"
					size="sm"
					variant="outline"
					disabled={isPending}
					asChild
				>
					<Link
						to={routes.calibrate.index({
							companyId: companyId!,
						})}
					>
						Cancel
					</Link>
				</Button>
				<StatusButton
					className="min-w-24"
					size="sm"
					status={isPending ? 'pending' : 'idle'}
					type="submit"
					name="intent"
					value="ecosystem"
					disabled={isPending}
					formAction={action}
				>
					{ecosystem ? 'Update' : 'Create'}
				</StatusButton>
			</div>
		</Form>
	)
}
