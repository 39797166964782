import {
	Link,
	useMatch,
	useMatches,
	useRouteLoaderData,
} from 'react-router-dom'
import Sidebar from '#src/components/sidebar'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#src/components/ui/tooltip'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { logout } from '#src/routes/init/auth'
import { useUnreadChats } from '#src/routes/organize/aidt'
import { checkIsAdminSession, cn } from '#src/utils/misc'

enum DashboardItemName {
	Calibrate = 'Calibrate',
	Organize = 'Organize',
	Prioritize = 'Prioritize',
	Enable = 'Enable',
}

export const navLinkClasses = (
	currentPath: boolean | number,
	isDisabled: boolean,
) =>
	cn(
		'flex shrink-0 items-center justify-center rounded-sm p-1.5 transition-colors relative',
		currentPath && !isDisabled
			? 'bg-neutral-3-bg-selected text-brand-1-fg hover:bg-neutral-3-bg-selected-hover'
			: 'bg-neutral-3-bg text-neutral-3-fg hover:bg-neutral-3-bg-hover',
		isDisabled ? 'cursor-not-allowed hover:bg-neutral-3-bg' : 'cursor-pointer',
	)

export const useRouteMatches = () => {
	const matches = useMatches()

	const dashboardMatch = Boolean(useMatch('/company/:companyId'))
	const companyMatch = Boolean(useMatch('/company/:companyId/details'))
	const calibrateMatch = Boolean(
		matches.filter(
			match =>
				match.pathname.includes('/verticals/') ||
				match.pathname.includes('/personas/'),
		)?.length,
	)
	const enrichMatch = Boolean(useMatch('/prioritize/:companyId'))
	const enrichDetailMatch = Boolean(
		useMatch('/prioritize/:companyId/details/:signalId'),
	)
	const organizeMatch = Boolean(useMatch('/organize/:companyId'))
	const oraganizeDetailMatch = matches.filter(match =>
		match.pathname.includes('/organize/'),
	)?.length
	const enableMatch = Boolean(useMatch('/enable/:companyId'))
	const enableDetailMatch = matches.filter(match =>
		match.pathname.includes('/enable/'),
	)?.length

	return {
		dashboardMatch,
		companyMatch,
		calibrateMatch,
		enrichMatch,
		enrichDetailMatch,
		organizeMatch,
		oraganizeDetailMatch,
		enableMatch,
		enableDetailMatch,
	}
}

export default function SidebarMain() {
	const { company, dataTrainerSession, user } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse
	const { unreadChats } = useUnreadChats(
		dataTrainerSession || checkIsAdminSession(user.roles),
	)

	const {
		dashboardMatch,
		companyMatch,
		calibrateMatch,
		enrichMatch,
		enrichDetailMatch,
		organizeMatch,
		oraganizeDetailMatch,
		enableMatch,
		enableDetailMatch,
	} = useRouteMatches()

	const dashboardItem = (itemName: DashboardItemName) =>
		company?.dashboard?.find(
			item => (item.name as DashboardItemName) == itemName,
		)

	return (
		<Sidebar>
			<TooltipProvider>
				<Tooltip delayDuration={100}>
					<TooltipTrigger asChild>
						<Link
							to={company?.id ? `/company/${company.id}` : '/'}
							className={navLinkClasses(dashboardMatch, false)}
						>
							<Icon name="home" size="font" className="text-[1.25rem]" />
						</Link>
					</TooltipTrigger>
					<TooltipContent side="right" align="center">
						Dashboard
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>

			<TooltipProvider>
				<Tooltip delayDuration={100}>
					<TooltipTrigger asChild>
						<Link
							to={company?.id ? `/company/${company.id}/details` : '/'}
							className={navLinkClasses(companyMatch || calibrateMatch, false)}
						>
							<Icon
								name="data-unstructured"
								size="font"
								className="text-[1.25rem]"
							/>
						</Link>
					</TooltipTrigger>
					<TooltipContent side="right" align="center">
						Calibrate
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>

			<TooltipProvider key={dashboardItem(DashboardItemName.Organize)?.id}>
				<Tooltip delayDuration={100}>
					<TooltipTrigger asChild>
						<Link
							to={company?.id ? `/organize/${company.id}` : '/'}
							className={navLinkClasses(
								organizeMatch || oraganizeDetailMatch,
								false,
							)}
						>
							<Icon name="data-unreal" size="font" className="text-[1.25rem]" />
							{(dataTrainerSession || checkIsAdminSession(user.roles)) &&
								unreadChats &&
								unreadChats.length > 0 && (
									<div className="absolute -right-2 -top-2 flex h-4 w-4 items-center justify-center rounded-full bg-brand-3-bg text-label-sm text-brand-3-fg">
										{unreadChats.length}
									</div>
								)}
						</Link>
					</TooltipTrigger>
					<TooltipContent
						side="right"
						align="center"
						className="flex items-center gap-1"
					>
						Organize
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>

			<TooltipProvider>
				<Tooltip delayDuration={100}>
					<TooltipTrigger asChild>
						<Link
							to={company?.id ? `/prioritize/${company.id}` : '/'}
							className={navLinkClasses(
								enrichMatch || enrichDetailMatch,
								false,
							)}
						>
							<Icon name="upstream" size="font" className="text-[1.25rem]" />
						</Link>
					</TooltipTrigger>
					<TooltipContent side="right" align="center">
						Prioritize
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>

			<TooltipProvider key={dashboardItem(DashboardItemName.Enable)?.id}>
				<Tooltip delayDuration={100}>
					<TooltipTrigger asChild>
						<Link
							to={company?.id ? `/enable/${company.id}` : '/'}
							className={navLinkClasses(
								enableMatch || enableDetailMatch,
								false,
							)}
						>
							<Icon
								name="data-enrichment"
								size="font"
								className="text-[1.25rem]"
							/>
						</Link>
					</TooltipTrigger>
					<TooltipContent
						side="right"
						align="center"
						className="flex items-center gap-1"
					>
						Enable
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
			<div className="my-3 mt-auto flex flex-col items-center gap-3">
				<a
					href="https://docs.evergrowth.com"
					target="__blank"
					aria-description="Evergrowth Docs"
					title="Evergrowth Docs"
					className="flex shrink-0 items-center justify-center rounded-sm bg-neutral-3-bg p-1.5 text-neutral-3-fg transition-colors hover:bg-neutral-3-bg-hover"
				>
					<Icon name="help-filled" size="font" className="text-[1.25rem]" />
				</a>

				<Account />
			</div>
		</Sidebar>
	)
}

export function Account() {
	const { user } = useRouteLoaderData('main-loader') as MainLoaderResponse

	return (
		<Dropdown
			trigger={
				<button
					type="button"
					className="flex h-8 w-8 items-center justify-center rounded-full bg-brand-1-bg text-body-sm font-medium text-brand-1-fg"
				>
					{user.email.slice(0, 1).toUpperCase()}
				</button>
			}
			contentProps={{
				side: 'right',
				align: 'end',
			}}
		>
			{user?.roles?.includes('admin') ? (
				<DropdownItem asChild className="justify-start">
					<Link to="/admin">
						<Icon name="settings-services" size="sm" />
						Admin
					</Link>
				</DropdownItem>
			) : null}
			<DropdownItem asChild className="min-w-32 justify-start">
				<button
					type="button"
					onClick={() => {
						logout()
					}}
				>
					<Icon name="logout" size="sm" />
					Log out
				</button>
			</DropdownItem>
		</Dropdown>
	)
}
