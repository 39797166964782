import * as DialogPrimitive from '@radix-ui/react-dialog'
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
	type ReactNode,
} from 'react'
import { cn } from '#src/utils/misc'
import { Icon } from './icon'

type DialogProps = {
	trigger: ReactNode
	content: ReactNode | null
	dialogTitle?: ReactNode
	dialogDescription?: ReactNode
	contentProps?: ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
}

export const Dialog = forwardRef<
	ElementRef<typeof DialogPrimitive.Content>,
	ComponentPropsWithoutRef<typeof DialogPrimitive.Root> & DialogProps
>(
	(
		{
			trigger,
			dialogTitle,
			dialogDescription,
			content,
			contentProps,
			...props
		},
		ref,
	) => (
		<DialogPrimitive.Root {...props}>
			{trigger}

			<DialogContent
				ref={ref}
				dialogTitle={dialogTitle}
				dialogDescription={dialogDescription}
				{...contentProps}
			>
				{content}
			</DialogContent>
		</DialogPrimitive.Root>
	),
)
Dialog.displayName = 'Dialog'

export const DialogRoot = DialogPrimitive.Root

export const DialogTrigger = forwardRef<
	ElementRef<typeof DialogPrimitive.Trigger>,
	ComponentPropsWithoutRef<typeof DialogPrimitive.Trigger>
>(({ children, ...props }, forwardedRef) => (
	<DialogPrimitive.Trigger {...props} ref={forwardedRef}>
		{children}
	</DialogPrimitive.Trigger>
))
DialogTrigger.displayName = 'DialogTrigger'

export const DialogContent = forwardRef<
	ElementRef<typeof DialogPrimitive.Content>,
	ComponentPropsWithoutRef<typeof DialogPrimitive.Content> &
		Pick<DialogProps, 'dialogTitle' | 'dialogDescription'> & {
			closeClassName?: string
		}
>(
	(
		{
			dialogTitle,
			dialogDescription,
			children,
			className,
			closeClassName,
			...props
		},
		ref,
	) => (
		<DialogPrimitive.Portal>
			<DialogPrimitive.Overlay className="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-overlay p-4 animate-in fade-in">
				<DialogPrimitive.Content
					className={cn(
						'm-6 flex max-w-screen-lg flex-col rounded-xl bg-neutral-1-bg shadow-lg animate-in fade-in zoom-in-90',
						className,
					)}
					{...props}
					ref={ref}
				>
					<section
						className={cn(
							'grid grid-cols-[1rem,1fr,1rem] grid-rows-1 px-6',
							dialogDescription ? 'border-b border-neutral-1-bd py-2' : 'py-4',
						)}
					>
						<div />
						<div className="text-center">
							{dialogTitle ? (
								<DialogPrimitive.Title className="text-title-sm text-neutral-1-fg">
									{dialogTitle}
								</DialogPrimitive.Title>
							) : null}
							{dialogDescription ? (
								<DialogPrimitive.Description className="text-body-sm text-neutral-3-fg">
									{dialogDescription}
								</DialogPrimitive.Description>
							) : null}
						</div>
						<DialogPrimitive.Close asChild className={closeClassName}>
							<button className="outline-none">
								<Icon name="cross-1" size="sm" />
							</button>
						</DialogPrimitive.Close>
					</section>

					<section
						className={cn(dialogDescription ? 'p-10' : 'px-10 pb-6 pt-2')}
					>
						{children}
					</section>
				</DialogPrimitive.Content>
			</DialogPrimitive.Overlay>
		</DialogPrimitive.Portal>
	),
)
DialogContent.displayName = 'DialogContent'
