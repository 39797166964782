import { type z } from 'zod'
import { client as queryClient } from '#src/main'
import { signalFilterKeys, sortKey } from './queries'
import {
	type IntentTypeSchema,
	type IntentSourceSchema,
	type HackSchema,
	type FILTERS,
	type WeightSchema,
	type MappedPersonasSchema,
	type SortSchema,
} from './schema'

export function filterMutation(
	key: FILTERS,
	data:
		| z.infer<typeof IntentTypeSchema>
		| z.infer<typeof IntentSourceSchema>
		| z.infer<typeof HackSchema>
		| z.infer<typeof WeightSchema>
		| z.infer<typeof MappedPersonasSchema>
		| null,
): unknown {
	return queryClient.setQueryData(signalFilterKeys.detail(key), data)
}

export function filterMutationIntentType(
	filter: FILTERS.TYPE,
	value: z.infer<typeof IntentTypeSchema> | null,
) {
	return filterMutation(filter, value)
}
export function filterMutationIntentSource(
	filter: FILTERS.SOURCE,
	value: z.infer<typeof IntentSourceSchema> | null,
) {
	return filterMutation(filter, value)
}
export function filterMutationHack(
	filter: FILTERS.HACK,
	value: z.infer<typeof HackSchema> | null,
) {
	return filterMutation(filter, value)
}
export function filterMutationWeight(
	filter: FILTERS.WEIGHT,
	value: z.infer<typeof WeightSchema> | null,
) {
	return filterMutation(filter, value)
}
export function filterMutationMappedPersonas(
	filter: FILTERS.MAPPED_PERSONAS,
	value: z.infer<typeof MappedPersonasSchema> | null,
) {
	return filterMutation(filter, value)
}

export function sortMutation(data: z.infer<typeof SortSchema> | null): unknown {
	return queryClient.setQueryData(sortKey.all, data)
}
