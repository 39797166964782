import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { UserSchema } from './schema'

export const userKeys = {
	all: ['user'] as const,
}

export const userQuery = () => ({
	queryKey: userKeys.all,
	queryFn: async () =>
		await safeFetch(UserSchema, api.user.me, {
			method: 'GET',
		}),
})
