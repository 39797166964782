import { STATUS, TAM_STATUS_ENUM } from '#src/utils/enumerations'
import { cn } from '#src/utils/misc'
import { Chip } from './chip'
import { Icon } from './ui/icon'

export default function Status({ status }: { status: string }) {
	return (
		<Chip
			className="py-0.5 pl-0.5 pr-2"
			variant={
				status === STATUS.ongoing
					? 'green'
					: status === STATUS.paused
						? 'orange'
						: status === STATUS.crashed
							? 'red'
							: 'green'
			}
		>
			<StatusDot status={status} />
			<p>{status}</p>
		</Chip>
	)
}

export function StatusDot({ status }: { status: string }) {
	return (
		<Icon
			name="circle-fill"
			size="sm"
			className={cn(
				'flex flex-nowrap items-center transition-colors',
				status === STATUS.ongoing ? 'text-green-70' : '',
				status === STATUS.paused ? 'text-orange-70' : '',
				status === STATUS.crashed ? 'text-red-60' : '',
			)}
		/>
	)
}

export function TAMStatus({ status }: { status: string }) {
	return (
		<Chip
			className="py-0.5 pl-0.5 pr-2"
			variant={
				status === TAM_STATUS_ENUM.COMPLETED
					? 'green'
					: status === TAM_STATUS_ENUM.IN_PROGRESS
						? 'orange'
						: status === TAM_STATUS_ENUM.REQUESTED
							? 'blue'
							: 'green'
			}
		>
			<Icon
				name="circle-fill"
				size="sm"
				className="flex flex-nowrap items-center text-current transition-colors"
			/>
			<p className="text-label-sm">{status}</p>
		</Chip>
	)
}
