import { z } from 'zod'

export enum FILTERS {
	TYPE = 'Intent Type',
	SOURCE = 'Intent Source',
	HACK = 'Hack Compatible',
	WEIGHT = 'Weight',
	MAPPED_PERSONAS = 'Mapped Personas',
}

export enum IntentType {
	COLD_OUTBOUND_SIGNAL = 'Cold Outbound signal',
	STATIC_HOOK = 'Static hook',
	INBOUND_SIGNAL = 'Inbound signal',
	WARM_OUTBOUND_SIGNAL = 'Warm outbound signal',
}

export enum IntentSource {
	ACCOUNT = 'Account',
	CONTACT = 'Contact',
	EXTERNAL = 'External',
	ACCOUNT_PUBLIC_COMPANY = 'Account (publicly listed)',
}

export enum Hack {
	HACK = 'Yes',
	NO_HACK = 'No',
}

export enum Weight {
	ONE = '1',
	TWO = '2',
	THREE = '3',
	FOUR = '4',
	FIVE = '5',
}

export enum MappedPersonas {
	MAPPED = 'Yes',
	NOT_MAPPED = 'No',
}

const IntentTypeValues = [
	IntentType.COLD_OUTBOUND_SIGNAL,
	IntentType.STATIC_HOOK,
	IntentType.INBOUND_SIGNAL,
	IntentType.WARM_OUTBOUND_SIGNAL,
] as const

const IntentSourceValues = [
	IntentSource.ACCOUNT,
	IntentSource.ACCOUNT_PUBLIC_COMPANY,
	IntentSource.CONTACT,
	IntentSource.EXTERNAL,
] as const

const HackValues = [Hack.HACK, Hack.NO_HACK] as const

const WeightValues = [
	Weight.ONE,
	Weight.TWO,
	Weight.THREE,
	Weight.FOUR,
	Weight.FIVE,
] as const

const MappedPersonasValues = [
	MappedPersonas.MAPPED,
	MappedPersonas.NOT_MAPPED,
] as const

export const IntentTypeEnumSchema = z.enum(IntentTypeValues)
export const IntentTypeSchema = IntentTypeEnumSchema.array()
	.nullable()
	.optional()

export const IntentSourceEnumSchema = z.enum(IntentSourceValues)
export const IntentSourceSchema = IntentSourceEnumSchema.array()
	.nullable()
	.optional()

export const HackEnumSchema = z.enum(HackValues)
export const HackSchema = HackEnumSchema.array().nullable().optional()

export const WeightEnumSchema = z.enum(WeightValues)
export const WeightSchema = WeightEnumSchema.array().nullable().optional()

export const MappedPersonasEnumSchema = z.enum(MappedPersonasValues)
export const MappedPersonasSchema = MappedPersonasEnumSchema.array()
	.nullable()
	.optional()

export enum SORT {
	NUMBER = 'Number',
	INTENT = 'Intent',
	STATUS = 'Status',
	INTENT_TYPE = 'Intent Type',
	INTENT_SOURCE = 'Intent Source',
	HACK = 'Hack',
	WEIGHT = 'Weight',
	MAPPED_PERSONAS = 'Mapped Personas',
}
const SortValues = [
	SORT.NUMBER,
	SORT.INTENT,
	SORT.STATUS,
	SORT.INTENT_TYPE,
	SORT.INTENT_SOURCE,
	SORT.HACK,
	SORT.WEIGHT,
	SORT.MAPPED_PERSONAS,
] as const
export const SortEnumSchema = z.enum(SortValues)
export const SortSchema = z
	.object({
		key: SortEnumSchema,
		direction: z.enum(['asc', 'desc']),
	})
	.nullable()
	.optional()

export const FilterFormSchema = z.object({
	intent: z.enum(['clear-all', 'clear', 'toggle', 'sort']),
	filter: z
		.enum([
			FILTERS.TYPE,
			FILTERS.SOURCE,
			FILTERS.HACK,
			FILTERS.WEIGHT,
			FILTERS.MAPPED_PERSONAS,
		])
		.optional(),
	value: z
		.enum([
			...IntentTypeValues,
			...IntentSourceValues,
			...HackValues,
			...WeightValues,
			...MappedPersonasValues,
		])
		.nullable()
		.optional(),
	sort: SortEnumSchema.optional(),
})

export const FILTER_VALUE_MAP = {
	[FILTERS.TYPE]: IntentTypeValues,
	[FILTERS.SOURCE]: IntentSourceValues,
	[FILTERS.HACK]: HackValues,
	[FILTERS.WEIGHT]: WeightValues,
	[FILTERS.MAPPED_PERSONAS]: MappedPersonasValues,
}

export function isIntentType(value: unknown): value is IntentType {
	return IntentTypeValues.includes(value as IntentType)
}
