import {
	getFormProps,
	getInputProps,
	getSelectProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useQuery } from '@tanstack/react-query'
import { useRouteLoaderData, useFetcher, Link } from 'react-router-dom'
import { type z } from 'zod'
import { ErrorList, Field, Select } from '#src/components/forms'
import { TAMStatus } from '#src/components/status'
import {
	AccordionItem,
	AccordionRoot,
	AccordionTrigger,
} from '#src/components/ui/accordion'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { autofocusVerticalQuery } from '#src/routes/calibrate/verticals/autofocus'
import { type VerticalAPISchema } from '#src/routes/calibrate/verticals/schema'
import { TAM_STATUS_ENUM } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { type EditActionResponse } from './save'
import { TAMFormSchema } from './schema'
import { type EditLoaderResponse } from '.'

export default function EditTAM() {
	const { company, ecosystem, verticals } = useRouteLoaderData(
		'editTAM',
	) as EditLoaderResponse
	const { data: verticalId } = useQuery(autofocusVerticalQuery())

	if (!verticals?.length) return <p>No verticals for this ecosystem</p>

	return (
		<AccordionRoot
			type="multiple"
			className="flex w-full flex-col gap-4"
			defaultValue={verticalId ? [verticalId] : []}
		>
			{verticals.map(v => (
				<AccordionItem
					key={v.id}
					value={v.id!.toString()}
					trigger={
						<div className="grid grid-cols-[1fr,max-content] grid-rows-1">
							<div className="flex items-center gap-2">
								<AccordionTrigger>{v.name}</AccordionTrigger>
								<Link
									to={routes.calibrate.verticals.edit({
										companyId: company.id,
										ecosystemId: ecosystem.id!.toString(),
										verticalId: v.id!.toString(),
									})}
									className="flex flex-nowrap items-center gap-1 bg-transparent text-button-sm text-link hover:text-link-hover active:text-link-pressed"
								>
									<Icon name="edit" size="sm" />
								</Link>
							</div>
							{v.tams?.status ? (
								<TAMStatus status={v.tams?.status} />
							) : (
								<p className="italic text-neutral-3-fg">Not Requested</p>
							)}
						</div>
					}
				>
					{v.bizDescription ? (
						<p className="mb-4 w-full rounded-sm bg-brand-1-bg p-2 text-body-sm">
							{v.bizDescription}
						</p>
					) : null}
					<EditTAMForm verticalId={v.id!.toString()} tam={v.tams} />
				</AccordionItem>
			))}
		</AccordionRoot>
	)
}

function EditTAMForm({
	verticalId,
	tam,
}: {
	verticalId: string
	tam: z.infer<typeof VerticalAPISchema>['tams']
}) {
	const params = useParsedRouteParams(['companyId', 'ecosystemId'])
	const fetcher = useFetcher()
	const fetcherData = fetcher.data as EditActionResponse
	const action = `/admin/tam/${params.companyId}/${params.ecosystemId}/save/${verticalId}`

	const [form, fields] = useForm({
		id: 'tam-edit-form-' + verticalId,
		constraint: getZodConstraint(TAMFormSchema),
		defaultValue: tam,
		lastResult: fetcherData?.result ?? null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: TAMFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})
	const dormantFields = fields.dormantEstimate.getFieldset()

	return (
		<fetcher.Form
			method="PATCH"
			{...getFormProps(form)}
			action={action}
			className="flex w-full flex-col gap-6"
		>
			<Select
				labelProps={{ children: 'Status' }}
				inputProps={{
					...getSelectProps(fields.status),
					defaultValue: fields.status.initialValue,
					value: fields.status.value,
					placeholder: 'Select TAM Status',
				}}
				options={[
					{
						value: TAM_STATUS_ENUM.IN_PROGRESS,
						label: <TAMStatus status={TAM_STATUS_ENUM.IN_PROGRESS} />,
					},
					{
						value: TAM_STATUS_ENUM.COMPLETED,
						label: <TAMStatus status={TAM_STATUS_ENUM.COMPLETED} />,
					},
				]}
				errors={fields.status.errors}
			/>

			<div className="grid grid-cols-2 grid-rows-1 gap-4">
				<Field
					labelProps={{ children: 'Min. TAM Estimate' }}
					inputProps={{
						...getInputProps(fields.estimateMin, { type: 'number' }),
						placeholder: 'Enter minimum TAM Estimate',
					}}
					className="w-full"
					errors={fields.estimateMin.errors}
				/>
				<Field
					labelProps={{ children: 'Max. TAM Estimate' }}
					inputProps={{
						...getInputProps(fields.estimateMax, { type: 'number' }),
						placeholder: 'Enter maximum TAM Estimate',
					}}
					className="w-full"
					errors={fields.estimateMax.errors}
				/>
			</div>

			<Field
				labelProps={{ children: 'In CRM' }}
				inputProps={{
					...getInputProps(fields.inCrm, { type: 'number' }),
					placeholder: 'Enter CRM TAM',
				}}
				errors={fields.inCrm.errors}
			/>
			<Field
				labelProps={{ children: 'Dormant - to qualify' }}
				inputProps={{
					...getInputProps(dormantFields.toQualify, { type: 'number' }),
					placeholder: "Enter 'to qualify' TAM",
				}}
				errors={dormantFields.toQualify.errors}
			/>
			<Field
				labelProps={{ children: 'Dormant - no activities' }}
				inputProps={{
					...getInputProps(dormantFields.noActivities, { type: 'number' }),
					placeholder: "Enter 'no activities' TAM",
				}}
				errors={dormantFields.noActivities.errors}
			/>
			<Field
				labelProps={{ children: 'Dormant - lost opportunity' }}
				inputProps={{
					...getInputProps(dormantFields.lostOpportunity, { type: 'number' }),
					placeholder: "Enter 'lost opportunity' TAM",
				}}
				errors={dormantFields.lostOpportunity.errors}
			/>
			<Field
				labelProps={{ children: 'Dormant - churned' }}
				inputProps={{
					...getInputProps(dormantFields.churned, { type: 'number' }),
					placeholder: "Enter 'churned' TAM",
				}}
				errors={dormantFields.churned.errors}
			/>
			<Field
				labelProps={{ children: 'Working' }}
				inputProps={{
					...getInputProps(fields.working, { type: 'number' }),
					placeholder: 'Enter Working TAM',
				}}
				errors={fields.working.errors}
			/>
			<Field
				labelProps={{ children: 'Opportunity' }}
				inputProps={{
					...getInputProps(fields.opportunity, { type: 'number' }),
					placeholder: 'Enter Opportunity TAM',
				}}
				errors={fields.opportunity.errors}
			/>
			<Field
				labelProps={{ children: 'Customer' }}
				inputProps={{
					...getInputProps(fields.customer, { type: 'number' }),
					placeholder: 'Enter Customer TAM',
				}}
				errors={fields.customer.errors}
			/>

			<ErrorList errors={form.errors} id={form.errorId} />

			<div className="flex w-full justify-end">
				<StatusButton
					status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
					type="submit"
					disabled={fetcher.state !== 'idle'}
					size="md"
					className="min-w-40"
				>
					Save
				</StatusButton>
			</div>
		</fetcher.Form>
	)
}
