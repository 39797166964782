import {
	useQuery,
	useQueryClient,
	type QueryClient,
} from '@tanstack/react-query'
import { useEffect } from 'react'
import {
	type LoaderFunctionArgs,
	redirect,
	useLoaderData,
	useNavigate,
	Outlet,
	useRevalidator,
	useFetcher,
} from 'react-router-dom'
import { z } from 'zod'
import ContactAvatar, {
	avatarVariantByPersonaType,
} from '#src/components/chat/avatar'
import Closed from '#src/components/chat/closed'
import ChatInput from '#src/components/chat/input'
import ChatLayout from '#src/components/chat/layout'
import Message from '#src/components/chat/message'
import ReadOnly from '#src/components/chat/read-only'
import ResendMessages from '#src/components/chat/resend-messages'
import { Chip } from '#src/components/chip.js'
import NoChats from '#src/components/no-chats'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Logo } from '#src/components/ui/logo'
import { userQuery } from '#src/routes/init/user/me'
import { UserSchema } from '#src/routes/init/user/schema'
import { checkIsAdminSession, checkIsReadOnlySession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { useSendMessageMutation } from './mutations'
import {
	chatQuery,
	chatsQuery,
	conversationQuery,
	enableKeys,
	statusQuery,
} from './queries'
import {
	ChatSchema,
	ChatsSchema,
	ConversationDataSchema,
	MessageStatus,
} from './schema'
import { streamMessageQuery } from './stream'

export type ChatLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const ChatLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		conversationId: z.string().nullable().optional(),
		canRestartChat: z.boolean(),
		canDeleteChat: z.boolean().optional(),
	}),
	conversationData: ConversationDataSchema.nullable(),
	chat: ChatSchema.nullable(),
	chats: ChatsSchema,
	user: UserSchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const chats = await queryClient.fetchQuery(chatsQuery(params.companyId))
		const user = await queryClient.fetchQuery(userQuery())
		const adminSession = checkIsAdminSession(user.roles)
		const readOnly = checkIsReadOnlySession(user.roles)

		if (!params.conversationId && chats?.length)
			throw redirect(
				routes.enable.copilot.index({
					companyId: params.companyId,
					conversationId: chats[0].conversation.id,
				}),
			)
		else if (!params.conversationId)
			return {
				handle: {
					companyId: params.companyId,
					conversationId: undefined,
					canRestartChat: false,
					canDeleteChat: false,
				},
				conversationData: null,
				chats,
				chat: null,
				user,
			}

		const chat = await queryClient.fetchQuery(
			chatQuery(params.companyId, params.conversationId),
		)

		const conversationData = await queryClient.fetchQuery(
			conversationQuery(params.companyId, params.conversationId),
		)

		return {
			handle: {
				companyId: params.companyId,
				conversationId: params.conversationId,
				canRestartChat: conversationData.writable && adminSession,
				canDeleteChat:
					conversationData?.conversation.canDelete &&
					conversationData?.conversation.status === 'ready' &&
					conversationData.writable &&
					!readOnly,
			},
			conversationData,
			chats,
			chat,
			user,
		}
	}

export default function Chat() {
	const { chats, user } = useLoaderData() as ChatLoaderResponse
	const readOnlySession = checkIsReadOnlySession(user.roles)

	if (!chats?.length) {
		return (
			<main className="flex w-full flex-grow flex-col items-center justify-center px-20">
				<NoChats canCreateChat={!readOnlySession} />

				<Outlet />
			</main>
		)
	}

	return (
		<main className="relative grid h-[calc(100%-var(--builder-header-height))] w-full grid-cols-[1fr,max-content]">
			<Conversation />

			<Outlet />
		</main>
	)
}

function StreamedMessage() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const { conversationData } = useLoaderData() as ChatLoaderResponse

	if (!conversationData) {
		throw new Error('Missing conversationData')
	}

	const { data: streamedMessage } = useQuery(
		streamMessageQuery({
			companyId: params.companyId,
			conversationId: params.conversationId,
		}),
	)

	if (!streamedMessage) return null

	return (
		<Message
			message={{
				id: streamedMessage.id,
				message: streamedMessage.message,
				author: {
					name: conversationData.conversation.name,
					avatarVariant: avatarVariantByPersonaType(
						conversationData.participant.persona.type ?? '',
					),
					showAuthorName: true,
				},
				status: streamedMessage.status,
				isReply: streamedMessage.isAi,
			}}
		/>
	)
}

function Conversation() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const { conversationData, user } = useLoaderData() as ChatLoaderResponse

	if (!conversationData) {
		throw new Error('Missing conversationData')
	}

	const { send, status } = useSendMessageMutation({
		companyId: params.companyId,
		conversationId: params.conversationId,
	})

	const { data: chat } = useQuery(
		chatQuery(params.companyId, params.conversationId),
	)

	if (['crashed'].includes(conversationData?.conversation.status)) {
		return <ChatCrashed />
	}

	if (!['ready', 'closed'].includes(conversationData?.conversation.status)) {
		return <ChatPending />
	}

	return (
		<ChatLayout
			// NOTE: reset scroll to bottom
			key={`${params.conversationId}-${status === 'pending' ? 'pending' : 'default'}`}
			containerClassName={
				chat?.length ? 'flex flex-col gap-6' : 'h-full flex flex-col gap-8'
			}
			status={
				<>
					{!conversationData.writable ? (
						<ReadOnly />
					) : conversationData?.conversation?.status === 'closed' ? (
						<Closed />
					) : chat?.some(chat => chat.status === MessageStatus.Error) ? (
						<ResendMessages />
					) : null}
				</>
			}
			content={
				<>
					{!chat?.length ? (
						<ChatInit send={send} disabled={status === 'pending'} />
					) : (
						<>
							{chat.map(message => (
								<Message
									key={message.id}
									message={{
										id: message.id,
										message: message.message,
										author: {
											name: message.isAi
												? conversationData.conversation.name
												: message.author,
											avatarVariant: avatarVariantByPersonaType(
												conversationData.participant.persona.type ?? '',
											),
											showAuthorName:
												message.isAi ||
												(checkIsAdminSession(user.roles) &&
													!conversationData.writable),
										},
										status: message.status,
										isReply: message.isAi,
									}}
								/>
							))}

							<StreamedMessage />
						</>
					)}
				</>
			}
			footer={
				<>
					{conversationData.writable &&
						conversationData?.conversation?.status !== 'closed' && (
							<ChatInput send={send} disabled={status === 'pending'} />
						)}
				</>
			}
		/>
	)
}

function ChatInit({
	send,
	disabled,
}: {
	send: (message: string, intent: 'create' | 'resend') => void
	disabled: boolean
}) {
	const navigate = useNavigate()
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const { conversationData } = useLoaderData() as ChatLoaderResponse

	if (!conversationData) {
		throw new Error('Missing conversationData')
	}

	const { companyId, conversationId } = params

	return (
		<div className="grid h-full w-full grid-cols-1 grid-rows-[1fr,max-content]">
			<div className="flex flex-col items-center gap-4 self-center justify-self-center">
				<ContactAvatar
					className="text-heading-sm"
					variant={avatarVariantByPersonaType(
						conversationData.participant.persona.type?.toLowerCase(),
					)}
					size="xl"
					initial={conversationData.conversation.name.slice(0, 1)}
				/>
				<h1 className="text-center text-title-lg text-neutral-2-fg">{`Hi, I'm co-pilot for ${conversationData.conversation.name}, how can I help you?`}</h1>
			</div>
			{conversationData?.writable ? (
				<div className="flex flex-wrap justify-center gap-2">
					<Chip
						variant="gray"
						className="max-w h-auto py-1 hover:bg-neutral-3-bg-hover"
						asChild
					>
						<Button
							type="button"
							variant="none"
							disabled={disabled}
							onClick={() => {
								void send('Help me write a cold email to you.', 'create')
								navigate(
									routes.enable.copilot.persona({
										companyId,
										conversationId,
										properties: 'pain-points',
									}),
								)
							}}
						>
							Help me write a cold email to you.
						</Button>
					</Chip>
					<Chip
						variant="gray"
						className="max-w h-auto py-1 hover:bg-neutral-3-bg-hover"
						asChild
					>
						<Button
							type="button"
							variant="none"
							disabled={disabled}
							onClick={() => {
								void send(
									'Help me prepare for a discovery demo call with you. ',
									'create',
								)
								navigate(
									routes.enable.copilot.persona({
										companyId,
										conversationId,
										properties: 'pain-points',
									}),
								)
							}}
						>
							Help me prepare for a discovery demo call with you.
						</Button>
					</Chip>
					<Chip
						variant="gray"
						className="max-w h-auto py-1 hover:bg-neutral-3-bg-hover"
						asChild
					>
						<Button
							type="button"
							variant="none"
							disabled={disabled}
							onClick={() => {
								void send('Show me your DISC profile', 'create')
								navigate(
									routes.enable.copilot.persona({
										companyId,
										conversationId,
										properties: 'pain-points',
									}),
								)
							}}
						>
							Show me your DISC profile
						</Button>
					</Chip>
					<Chip
						variant="gray"
						className="max-w h-auto py-1 hover:bg-neutral-3-bg-hover"
						asChild
					>
						<Button
							type="button"
							variant="none"
							disabled={disabled}
							onClick={() => {
								void send(
									'Help me prepare for your objections and how to address them. Answer with the objections and the addressing only.',
									'create',
								)
								navigate(
									routes.enable.copilot.persona({
										companyId,
										conversationId,
										properties: 'pain-points',
									}),
								)
							}}
						>
							Help me prepare for your objections and how to address them.
							Answer with the objections and the addressing only.
						</Button>
					</Chip>
					<Chip
						variant="gray"
						className="max-w h-auto py-1 hover:bg-neutral-3-bg-hover"
						asChild
					>
						<Button
							type="button"
							variant="none"
							disabled={disabled}
							onClick={() => {
								void send(
									'I have a meeting with you. Give me a list of 3 icebreakers I could use. Answer with the icebreakers only.',
									'create',
								)
								navigate(
									routes.enable.copilot.persona({
										companyId,
										conversationId,
										properties: 'pain-points',
									}),
								)
							}}
						>
							I have a meeting with you. Give me a list of 3 icebreakers I could
							use. Answer with the icebreakers only.
						</Button>
					</Chip>
					<Chip
						variant="gray"
						className="max-w h-auto py-1 hover:bg-neutral-3-bg-hover"
						asChild
					>
						<Button
							type="button"
							variant="none"
							disabled={disabled}
							onClick={() => {
								void send('Show me your homework', 'create')
								navigate(
									routes.enable.copilot.persona({
										companyId,
										conversationId,
										properties: 'pain-points',
									}),
								)
							}}
						>
							Show me your homework
						</Button>
					</Chip>
				</div>
			) : null}
		</div>
	)
}

function usePendingConversationRevalidator() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const revalidator = useRevalidator()
	const queryClient = useQueryClient()

	const { data: conversationStatus } = useQuery({
		...statusQuery(params.companyId, params.conversationId),
		refetchInterval: ({ state: { data } }) => {
			if (!data || data.status !== 'ready') {
				return 30 * 1000 // 0.5min
			} else return false
		},
		refetchIntervalInBackground: true,
	})

	useEffect(() => {
		const revalidate = async () => {
			await queryClient.invalidateQueries({
				queryKey: enableKeys.all,
			})

			revalidator.revalidate()
		}
		if (['ready', 'crashed'].includes(conversationStatus?.status ?? '')) {
			void revalidate()
		}
	}, [conversationStatus?.status, revalidator, queryClient])

	return conversationStatus?.status
}

function ChatPending() {
	const status = usePendingConversationRevalidator()

	return (
		<div className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden">
			<div className="relative">
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-1 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[670px] w-[42.5%] min-w-[670px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.06]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-2 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[500px] w-[32.5%] min-w-[500px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.09]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-3 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[330px] w-[22.5%] min-w-[330px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-[0.14]" />
				</div>
				<Logo size="xl" type="symbol" />
			</div>
			<section className="flex flex-col gap-3">
				<p className="flex items-center gap-2 text-body-sm text-neutral-2-fg">
					{status === 'waiting_for_contact' || !status ? (
						<Icon name="loading-sm" className="animate-spin" size="sm" />
					) : (
						<Icon
							name="checkmark-filled"
							className="text-status-success-fg"
							size="sm"
						/>
					)}
					Initializing contact
				</p>
				<p className="flex items-center gap-2 text-body-sm text-neutral-2-fg">
					{status === 'ready' ? (
						<Icon
							name="checkmark-filled"
							className="text-status-success-fg"
							size="sm"
						/>
					) : (
						<Icon name="loading-sm" className="animate-spin" size="sm" />
					)}
					Enriching contact
				</p>
			</section>
		</div>
	)
}

function ChatCrashed() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const {
		handle: { canRestartChat },
	} = useLoaderData() as ChatLoaderResponse
	const fetcher = useFetcher({ key: 'chat-restart' })
	const action = routes.enable.copilot.restart({
		companyId: params.companyId,
		conversationId: params.conversationId,
	})

	return (
		<div className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden">
			<Logo size="xl" type="symbol" />
			<h1 className="text-heading-sm text-neutral-1-fg">Ouch!</h1>
			<section className="flex flex-col items-center justify-center gap-1 text-center">
				<p className="text-title-sm text-neutral-1-fg">
					There was an issue with the chat.
				</p>
				<p className="text-body-sm font-normal text-neutral-1-fg">
					Our team is aware and working to fix the issue — apologies for any
					inconvenience.
				</p>
				{canRestartChat && (
					<fetcher.Form action={action} method="PUT">
						<Button
							className="mt-4 flex gap-1"
							type="submit"
							disabled={fetcher.state !== 'idle'}
						>
							<Icon name="reset" />
							Restart chat
						</Button>
					</fetcher.Form>
				)}
			</section>
		</div>
	)
}
