import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { useQuery, type QueryClient } from '@tanstack/react-query'
import { useRef, useEffect, useState, type ReactNode } from 'react'
import {
	useLoaderData,
	type LoaderFunctionArgs,
	Outlet,
	Link,
	NavLink,
	useLocation,
} from 'react-router-dom'
import { z } from 'zod'
import AiGenerated from '#src/components/ai-generated'
import { ComponentErrorBoundary } from '#src/components/error'
import Markdown from '#src/components/markdown'
import NoPersonas from '#src/components/no-personas'
import { PersonaAvatar, PersonaData } from '#src/components/persona'
import Priority from '#src/components/priority'
import ProductTip from '#src/components/product-tip'
import Status from '#src/components/status'
import { Button } from '#src/components/ui/button'
import {
	Dropdown,
	DropdownItem,
	DropdownLabel,
	DropdownSub,
	DropdownSubContent,
	DropdownSubTrigger,
} from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { RecalculatePersonasAsyncForm } from '#src/routes/calibrate/personas/recalculate'
import { userQuery } from '#src/routes/init/user/me'
import { MONTH_SELECT_OPTIONS } from '#src/utils/enumerations'
import { checkIsReadOnlySession, cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params.js'
import { ecosystemsQuery } from '../ecosystem/queries'
import {
	EcosystemAPISchema,
	EcosystemAPIVerticalSchema,
	EcosystemListAPISchema,
} from '../ecosystem/schema'
import { salesCoachQuery } from './coach/queries'
import { RecalculateSalesCoachAsyncForm } from './coach/recalculate'
import { DeletePersonaAsyncForm } from './delete'
import { personaQuery } from './queries'
import { PersonaAPISchema } from './schema'

export type PersonaLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const PersonaLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		readOnlySession: z.boolean(),
		ecosystemId: z.string(),
		ecosystemName: z.string(),
		verticalId: z.string(),
		verticalName: z.string(),
		personaId: z.string().optional(),
	}),
	ecosystem: EcosystemAPISchema,
	vertical: EcosystemAPIVerticalSchema,
	verticals: EcosystemAPIVerticalSchema.array(),
	ecosystems: EcosystemListAPISchema,
	persona: PersonaAPISchema.nullable(),
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())

		const persona = params.personaId
			? await queryClient.fetchQuery(
					personaQuery(params.companyId, params.personaId),
				)
			: null

		const ecosystems = await queryClient.fetchQuery(
			ecosystemsQuery(params.companyId),
		)
		const ecosystem = ecosystems?.find(e => e.id === Number(params.ecosystemId))
		const verticals = ecosystem?.verticals
		if (!verticals?.length)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const vertical = verticals.find(v => v.id.toString() === params.verticalId)
		const personas = vertical?.personas

		if (persona)
			void queryClient.fetchQuery(
				salesCoachQuery(params.companyId, persona.id!.toString()),
			)

		return {
			handle: {
				companyId: params.companyId,
				readOnlySession: checkIsReadOnlySession(user.roles),
				ecosystemId: params.ecosystemId,
				ecosystemName: ecosystem?.name,
				verticalId: params.verticalId,
				verticalName: vertical?.name,
				personaId: params.personaId,
			},
			ecosystem,
			vertical,
			verticals,
			ecosystems,
			persona,
			personas,
		}
	}

const usePersonaQuery = () => {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { readOnlySession, personaId },
		personas,
	} = useLoaderData() as PersonaLoaderResponse

	const { data: persona, status } = useQuery({
		...personaQuery(params.companyId, personaId!),
		refetchInterval: ({ state: { data: persona } }) => {
			if (['Pending', 'In Progress'].includes(persona?.prompt.status ?? ''))
				return 1000
			else return false
		},
		refetchIntervalInBackground: true,
		enabled: !!personaId,
	})

	return {
		persona,
		reportsToPersona: personas?.find(p => p.id === persona?.reportsTo?.id),
		personas,
		isPersonaGenerating: ['Pending', 'In Progress'].includes(
			persona?.prompt.status ?? '',
		),
		isPersonaLoading: !!personaId && status === 'pending',
		personaStatus: persona?.prompt.status ?? '',
		readOnlySession,
	}
}

const useSalesCoachQuery = () => {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { personaId },
	} = useLoaderData() as PersonaLoaderResponse

	const { isPersonaGenerating, isPersonaLoading, personaStatus, persona } =
		usePersonaQuery()

	const { data: salesCoachData, status: salesCoachStatus } = useQuery({
		...salesCoachQuery(params.companyId, personaId ?? ''),
		enabled: !!personaId,
		refetchInterval: ({ state: { data } }) => {
			if (
				['Pending', 'In Progress'].includes(
					data?.calibrated?.prompt.status ?? '',
				) ||
				['Pending', 'In Progress'].includes(
					data?.timing?.prompt.status ?? '',
				) ||
				isPersonaGenerating
			)
				return 1000
			else return false
		},
		refetchIntervalInBackground: true,
	})

	const isSalesCoachEmpty =
		!isPersonaGenerating &&
		salesCoachStatus !== 'pending' &&
		!salesCoachData?.calibrated?.questions &&
		(!salesCoachData?.calibrated?.prompt.status ||
			salesCoachData?.calibrated?.prompt.status === 'Completed') &&
		!salesCoachData?.timing?.timingContext &&
		!salesCoachData?.timing?.questions &&
		(!salesCoachData?.timing?.prompt.status ||
			salesCoachData?.timing?.prompt.status === 'Completed')

	const generalStatus =
		isPersonaGenerating ||
		salesCoachStatus === 'pending' ||
		['Pending', 'In Progress'].includes(
			salesCoachData?.calibrated?.prompt.status ?? '',
		)
			? 'In Progress'
			: salesCoachData?.calibrated?.prompt.status

	const timingStatus = ['Pending', 'In Progress'].includes(generalStatus ?? '')
		? 'In Progress'
		: ['Crashed'].includes(generalStatus ?? '')
			? 'Crashed'
			: salesCoachData?.timing?.prompt.status

	const coldEmailStatus =
		isPersonaLoading || ['Pending', 'In Progress'].includes(personaStatus ?? '')
			? 'In Progress'
			: personaStatus

	const hasSalesCoachPromptCrashed =
		generalStatus === 'Crashed' || timingStatus === 'Crashed'

	return {
		salesCoachData: {
			...salesCoachData,
			coldEmail: persona?.coldEmail,
		},
		isSalesCoachEmpty,
		generalStatus,
		timingStatus,
		coldEmailStatus,
		hasSalesCoachPromptCrashed,
	}
}

export default function Personas() {
	const { persona, personas, isPersonaLoading } = usePersonaQuery()

	if (!isPersonaLoading && (!personas?.length || !persona))
		return <PersonaEmpty />

	return (
		<>
			<main className="grid h-full w-full grid-cols-[1fr,max-content] gap-4 px-20">
				<div className="mb-8 h-full">
					<PersonaNav />

					<Surface className="w-full overflow-clip p-0">
						<PersonaHeader />

						{persona?.prompt.status === 'Crashed' ? (
							<PersonaError />
						) : (
							<Accordion
								defaulValue={['item-1']}
								items={[
									{
										value: 'item-1',
										trigger: (
											<div className="flex items-center gap-1">
												Pain points
												<AiGenerated />
												<ProductTip
													content={
														<>
															Pain points: The AI generates pain points using
															the information fed into the model, such as the
															vertical business model, persona details and type
															of task carried out by the persona. This allows
															the AI to identify pain points related to
															responsibilities, KPIs, frustrations, value
															propositions, concerns, and the buyer’s journey.
															To fine-tune these pain points click the{' '}
															<b>wand</b> icon. Edit the AI fine-tuning section
															to add specific data points about a persona (e.g.,
															focuses solely on ROI and quotas). This make the
															AI’s output more accurate and relevant to each
															persona.
														</>
													}
												/>
											</div>
										),
										content: <PainPointsContent />,
										aside: <SetupAside />,
									},
									{
										value: 'item-2',
										trigger: (
											<div className="flex items-center gap-1">
												Persona mapping
												<AiGenerated />
											</div>
										),
										content: <MappingContent />,
										aside: <MappingNavAside />,
									},
									{
										value: 'item-3',
										trigger: (
											<div className="flex items-center gap-1">
												Sales Coach
												<AiGenerated />
												<ProductTip content="Sales Coach provides tailored, customer-centric questions and context to enhance prospect engagement and relevance, aligned with seasonal trends and market conditions. It includes customizable settings for timely, strategic conversations and offers a sample email template incorporating best practices. Adjustments can be made via the “Edit” tab for continuous optimization." />
											</div>
										),
										content: <SalesCoachContent />,
										aside: <SalesCoachAside />,
									},
								]}
							/>
						)}
					</Surface>
				</div>

				<Outlet />
			</main>
		</>
	)
}

function PersonaNav() {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { ecosystemId, verticalId, personaId },
	} = useLoaderData() as PersonaLoaderResponse
	const { personas } = usePersonaQuery()
	const totalLength = personas?.length ?? 0
	const ref = useRef<HTMLDivElement>(null)
	const [visibleNav, setNav] = useState(totalLength)

	useEffect(() => {
		const node = ref.current
		const resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				const gapWidth = (totalLength - 1) * 16
				const buttonWidth = 128
				const navWidth = 256
				const containerWidth = entry.contentBoxSize[0].inlineSize

				const fitLength = Math.floor(
					(containerWidth - gapWidth - buttonWidth) / navWidth,
				)

				if (fitLength >= totalLength) {
					setNav(totalLength)
				} else {
					setNav(fitLength)
				}
			}
		})

		if (ref) {
			resizeObserver.observe(node!)
		}

		return () => {
			if (node) {
				resizeObserver.unobserve(node)
			}
		}
	}, [totalLength, setNav])

	if (!personas?.length) return null

	return (
		<nav className="my-8 w-full" ref={ref}>
			{/* NOTE: the gap and fit adapter in the side-effect */}
			<div className="grid w-full grid-cols-1 grid-rows-1 items-center justify-between gap-4 overflow-hidden">
				<ul className="flex w-full flex-nowrap items-center border-b-2 border-neutral-1-bd">
					{personas.slice(0, visibleNav).map(persona => (
						// NOTE: the width and fit adapter in the side-effect
						<li key={persona.id} className="w-64">
							<NavLink
								className={({ isActive }) =>
									cn(
										'group relative grid w-full grid-cols-[2.5rem,1fr] gap-1.5 bg-transparent px-2 py-3',
										isActive ? 'active' : '',
									)
								}
								to={routes.calibrate.persona.index({
									companyId: params.companyId,
									ecosystemId: ecosystemId,
									verticalId: verticalId,
									personaId: persona.id.toString(),
								})}
							>
								<PersonaAvatar type={persona.type} />

								<div className="flex flex-col gap-0.5 overflow-hidden">
									<p className="truncate text-label-sm text-neutral-2-fg">
										{persona.expertise ?? ''}
									</p>
									<div className="flex items-center gap-2">
										<Status status={persona.status} />
										<Priority priority={persona.priority} />
									</div>
								</div>

								<div className="absolute bottom-[-2px] h-2 w-full border-b-2 border-transparent transition-colors hover:border-brand-1-bd-hover group-hover:border-brand-1-bd-hover group-[.active]:border-brand-1-bd-selected" />
							</NavLink>
						</li>
					))}
					{visibleNav < personas.length ? (
						<li className="ml-auto w-32">
							<Dropdown
								trigger={
									<button
										type="button"
										// NOTE: the width and fit adapter in the side-effect
										className={cn(
											'group relative flex w-full flex-nowrap items-center justify-start gap-2.5 rounded bg-neutral-1-bg px-4 py-2 text-body-md font-normal text-neutral-2-fg outline-none transition-colors hover:bg-neutral-2-bg-hover radix-state-open:bg-neutral-2-bg-selected',
											personas
												.slice(visibleNav)
												.find(p => p.id.toString() === personaId)
												? 'bg-neutral-2-bg'
												: 'group-hover:bg-neutral-2-bg-hover',
										)}
									>
										<span
											className={cn(
												'flex h-10 w-10 items-center justify-center rounded-full bg-neutral-2-bg text-body-sm font-medium text-neutral-1-fg transition-colors group-radix-state-open:bg-neutral-1-bg',
												personas
													.slice(visibleNav)
													.find(p => p.id.toString() === personaId)
													? 'bg-neutral-1-bg'
													: 'group-hover:bg-neutral-1-bg-hover',
											)}
										>
											{personas.length - visibleNav}
										</span>
										more...
										<span
											className={cn(
												'absolute bottom-[-12px] left-0 h-2 w-full border-b-2 border-transparent transition-colors group-radix-state-open:border-neutral-2-bg',
												personas
													.slice(visibleNav)
													.find(p => p.id.toString() === personaId)
													? 'border-neutral-2-bg'
													: 'group-hover:border-neutral-2-bg-hover',
											)}
										/>
									</button>
								}
								contentProps={{
									className: 'max-w-96 px-0 gap-0',
									align: 'end',
									collisionPadding: 20,
								}}
							>
								{personas.slice(visibleNav).map(persona => (
									<DropdownItem key={persona.id} asChild>
										<PersonaData
											id={persona.id}
											type={persona.type}
											status={persona.status}
											priority={persona.priority}
											expertise={persona.expertise ?? ''}
											companyId={params.companyId}
											ecosystemId={Number(ecosystemId)}
											verticalId={verticalId}
											className="justify-start rounded-none"
										/>
									</DropdownItem>
								))}
							</Dropdown>
						</li>
					) : null}
				</ul>
			</div>
		</nav>
	)
}

function PersonaMappingItem({
	id,
	status,
	title,
	value,
	override,
	overrideLabel = 'Manual override',
	editLink,
	showValueWhilePending = true,
}: {
	id: string
	status: string | null | undefined
	title: ReactNode
	value?: string | null
	override?: string | null
	overrideLabel?: string | null
	editLink?: string
	showValueWhilePending?: boolean
}) {
	return (
		<div className="space-y-6">
			<h4
				className="flex scroll-mt-[calc(var(--builder-header-height))] items-center gap-2 text-title-md"
				id={id}
			>
				{title}
				{editLink ? (
					<Link preventScrollReset to={editLink}>
						<Icon name="settings-adjust" aria-hidden size="sm" />
					</Link>
				) : null}
			</h4>
			{override ? (
				<article className="grid w-full grid-cols-[1fr,max-content] grid-rows-1 rounded-lg bg-neutral-2-bg px-6 py-4">
					<div className="flex flex-col gap-1">
						<h5 className="text-label-sm font-bold text-neutral-3-fg">
							{overrideLabel}
						</h5>
						<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
							{override}
						</p>
					</div>
				</article>
			) : null}
			{['Pending', 'In Progress'].includes(status ?? '') &&
			((!showValueWhilePending && value) || !value) ? (
				<div className="flex items-center gap-2">
					<Icon name="update" className="animate-spin" size="sm" />
					Generating output...
				</div>
			) : 'Crashed' === status ? (
				<div className="flex items-center gap-2 text-status-danger-fg">
					<Icon name="error" size="sm" />
					Failed to generate output
				</div>
			) : typeof value === 'string' ? (
				<Markdown>{value}</Markdown>
			) : (
				<div className="italic">No result. Try adjusting the settings.</div>
			)}
		</div>
	)
}

function Aside({
	title,
	list,
}: {
	title: ReactNode
	list: { label: ReactNode; value: ReactNode }[]
}) {
	return (
		<section className="flex flex-col gap-6 rounded-lg bg-neutral-2-bg p-6">
			<h3 className="w-full text-title-sm text-neutral-1-fg">{title}</h3>
			{list.map(({ label, value }, index) => (
				<section key={index} className="flex flex-col gap-[1px]">
					<h4 className="text-label-sm text-neutral-3-fg">{label}</h4>
					<p className="break-words text-body-md text-neutral-1-fg">{value}</p>
				</section>
			))}
		</section>
	)
}

function Accordion({
	items,
	defaulValue,
}: {
	items: {
		value: string
		trigger: ReactNode
		content: ReactNode
		aside?: ReactNode
	}[]
	defaulValue?: string[]
}) {
	return (
		<AccordionPrimitive.Root
			type="multiple"
			defaultValue={defaulValue}
			className="w-full"
		>
			{items.map(item => (
				<AccordionPrimitive.Item
					value={item.value}
					className="group"
					key={item.value}
				>
					<AccordionPrimitive.Trigger className="flex w-full items-center gap-2 border-t border-neutral-1-bd bg-neutral-1-bg px-2 py-2.5 text-body-md font-semibold outline-none transition-all group-radix-state-open:border-transparent group-radix-state-open:bg-neutral-2-bg">
						<Icon
							name="chevron-down"
							className="transform group-radix-state-open:rotate-180"
							aria-hidden
							size="sm"
						/>
						{item.trigger}
					</AccordionPrimitive.Trigger>

					<AccordionPrimitive.Content className="overflow-clip radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]">
						<section className="bg-neutral-1-bg p-10">
							<section
								className={cn(
									'grid items-start justify-start gap-8',
									item.aside ? 'grid-cols-[1fr,33%]' : 'grid-cols-1',
								)}
							>
								<article className="space-y-6">{item.content}</article>
								{item.aside ? (
									<aside className="sticky top-[calc(var(--builder-header-height)+1rem)] flex flex-col">
										{item.aside}
									</aside>
								) : null}
							</section>
						</section>
					</AccordionPrimitive.Content>
				</AccordionPrimitive.Item>
			))}
		</AccordionPrimitive.Root>
	)
}

function PersonaEmpty() {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { ecosystemId, verticalId },
		vertical,
	} = useLoaderData() as PersonaLoaderResponse

	const { persona, personas, isPersonaLoading } = usePersonaQuery()

	if (!personas?.length && !isPersonaLoading) {
		return (
			<main className="flex w-full flex-grow flex-col items-center justify-center px-20">
				<NoPersonas
					companyId={params.companyId}
					ecosystemId={ecosystemId}
					verticalId={verticalId}
					className="min-h-[80vh]"
				>
					<h1 className="text-center text-body-lg text-neutral-2-fg">
						You don&apos;t have any personas in <br />
						<span className="font-semibold">
							{vertical?.name ?? 'this vertical'}
						</span>
					</h1>
				</NoPersonas>
				<Outlet />
			</main>
		)
	} else if (!persona && !isPersonaLoading) {
		return (
			<main className="flex w-full flex-grow flex-col items-center justify-center px-20">
				<NoPersonas
					companyId={params.companyId}
					ecosystemId={ecosystemId}
					verticalId={verticalId}
					className="min-h-[80vh] bg-neutral-1-bg"
				>
					<h1 className="text-center text-body-lg text-neutral-2-fg">
						Select persona to get started
					</h1>
					<section className="flex w-[20rem] flex-col items-center gap-4">
						{personas?.map(persona => (
							<PersonaData
								key={persona.id}
								id={persona.id}
								type={persona.type}
								status={persona.status}
								priority={persona.priority}
								expertise={persona.expertise ?? ''}
								companyId={params.companyId}
								ecosystemId={Number(ecosystemId)}
								verticalId={verticalId}
							/>
						))}
					</section>
				</NoPersonas>
				<Outlet />
			</main>
		)
	}
}

function PersonaError() {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { ecosystemId, verticalId, personaId },
	} = useLoaderData() as PersonaLoaderResponse
	const { persona, readOnlySession } = usePersonaQuery()

	if (!persona || readOnlySession) return null

	return (
		<ComponentErrorBoundary
			title="Ouch!"
			message="Failed to generate output!"
			description="It seems like there was an error when generating the output. Retry or try again later."
			className="px-10 py-32"
		>
			<div className="flex items-center gap-4">
				<RecalculatePersonasAsyncForm
					ecosystemId={Number(ecosystemId)}
					verticalId={persona.vertical.id.toString()}
					variant="default"
					className="flex w-40 grow items-center gap-2"
				>
					<Icon name="rotate" size="sm" />
					Recalculate
				</RecalculatePersonasAsyncForm>
				<Button
					asChild
					className="flex w-40 grow items-center justify-center gap-2 px-4"
					variant="outline"
				>
					<Link
						to={routes.calibrate.persona.setup({
							companyId: params.companyId,
							ecosystemId: ecosystemId,
							verticalId: verticalId,
							personaId: personaId!,
						})}
					>
						<Icon name="edit" size="sm" />
						Edit
					</Link>
				</Button>
			</div>
		</ComponentErrorBoundary>
	)
}

function PersonaHeader() {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { ecosystemId, verticalId, personaId },
		ecosystems,
	} = useLoaderData() as PersonaLoaderResponse

	const { persona, readOnlySession } = usePersonaQuery()

	if (!persona) return null

	return (
		<section className="flex items-center justify-between px-10 pb-8 pt-10">
			<section className="grid w-full grid-cols-[5rem,1fr] gap-6">
				<PersonaAvatar size="lg" type={persona.type} />
				<div className="flex h-full flex-col items-start justify-between">
					<p className="text-label-sm text-neutral-3-fg">{persona.type}</p>
					<h1 className="pr-4 text-title-md text-neutral-2-fg">
						{persona.expertise}{' '}
						{readOnlySession ? null : (
							<Link
								to={routes.calibrate.persona.setup({
									companyId: params.companyId,
									ecosystemId: ecosystemId,
									verticalId: verticalId,
									personaId: personaId!,
								})}
								className="inline-flex items-center justify-center align-middle text-neutral-3-fg outline-none"
							>
								<Icon name="edit" size="sm" />
							</Link>
						)}
					</h1>
					<div className="flex items-center gap-2">
						<Status status={persona.status} />
						<Priority priority={persona.priority} />
					</div>
				</div>
			</section>
			{readOnlySession ? null : (
				<section className="flex flex-nowrap items-center gap-3">
					<Dropdown
						trigger={
							<button
								type="button"
								className="flex items-center justify-center text-neutral-3-fg outline-none"
							>
								<Icon name="copy-file" size="sm" />
							</button>
						}
					>
						{ecosystems.map(ecosystem => (
							<DropdownSub key={ecosystem.id}>
								<DropdownSubTrigger>{ecosystem.name}</DropdownSubTrigger>
								<DropdownSubContent>
									{ecosystem.verticals.length ? (
										ecosystem.verticals.map(vertical => (
											<DropdownItem key={vertical.id} asChild>
												<Link
													replace
													preventScrollReset
													to={routes.calibrate.persona.duplicate({
														companyId: params.companyId,
														ecosystemId: ecosystem.id!.toString(),
														verticalId: vertical.id.toString(),
														personaId:
															vertical.personas?.[0]?.id?.toString() ?? null,
														duplicate: btoa(
															JSON.stringify({
																ecosystemId,
																personaId: personaId,
															}),
														),
													})}
													title={vertical.name}
												>
													{vertical.name}
												</Link>
											</DropdownItem>
										))
									) : (
										<DropdownLabel>No verticals</DropdownLabel>
									)}
								</DropdownSubContent>
							</DropdownSub>
						))}
					</Dropdown>

					<DeletePersonaAsyncForm
						ecosystemId={ecosystemId}
						verticalId={persona.vertical.id.toString()}
						personaId={persona.id!.toString()}
						className="flex items-center justify-center"
					>
						<Icon name="trash" size="sm" />
					</DeletePersonaAsyncForm>

					<Link
						to={routes.calibrate.persona.tune({
							companyId: params.companyId,
							ecosystemId: ecosystemId,
							verticalId: verticalId,
							personaId: personaId!,
						})}
						className="flex items-center justify-center text-neutral-3-fg outline-none"
					>
						<Icon name="magic-wand" size="sm" />
					</Link>
				</section>
			)}
		</section>
	)
}

function PainPointsContent() {
	const { persona, isPersonaGenerating } = usePersonaQuery()

	return isPersonaGenerating && !persona?.pain ? (
		<article className="whitespace-pre-line text-body-md font-normal text-neutral-2-fg">
			<div className="flex items-center gap-2">
				<Icon name="update" className="animate-spin" size="sm" />
				Generating output...
			</div>
		</article>
	) : (
		<Markdown>{persona?.pain ?? 'N/A'}</Markdown>
	)
}

function SetupAside() {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { ecosystemId, verticalId, personaId },
	} = useLoaderData() as PersonaLoaderResponse
	const { persona, readOnlySession, reportsToPersona } = usePersonaQuery()

	if (!persona) return null

	return (
		<Aside
			title={
				<div className="flex items-center justify-between">
					Setup info
					{readOnlySession ? null : (
						<Link
							to={routes.calibrate.persona.setup({
								companyId: params.companyId,
								ecosystemId: ecosystemId,
								verticalId: verticalId,
								personaId: personaId!,
							})}
							className="text-button-sm text-link hover:text-link-hover active:text-link-pressed"
						>
							Edit
						</Link>
					)}
				</div>
			}
			list={[
				{
					label: (
						<div className="flex items-center gap-1">
							Expertise
							<ProductTip content="Expertise: Define the primary area of expertise relevant to the persona (e.g., Revenue)." />
						</div>
					),
					value: persona.expertise,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Experience
							<ProductTip content="Job Experience: Indicate the required years of experience (e.g., +7 years)." />
						</div>
					),
					value: persona.jobExperience,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Titles
							<ProductTip content="Job Title: Provide examples of relevant job titles (e.g., CRO, Chief, President, SVP, VP, Director of Revenue or Sales)." />
						</div>
					),
					value: persona.jobTitles,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Seniority
							<ProductTip content="Job Seniority: Specify the seniority level (e.g., Chief, President, SVP, VP, Director)." />
						</div>
					),
					value: persona.jobSeniority,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Reports To
							<ProductTip content="Reports To: Specify the reporting structure (e.g., CEO)." />
						</div>
					),
					value: reportsToPersona
						? String(
								reportsToPersona.jobTitles ?? persona.reportsTo?.name ?? '-',
							)
						: '-',
				},
				{
					label: 'Reports To (manual)',
					value: persona.reportsOverride ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Types of Tasks
							<ProductTip content="Types of Tasks: Describe key responsibilities and tasks undertaken by the persona (e.g., Implementing account-based methodologies)." />
						</div>
					),
					value: persona.typeOfTask,
				},
				{
					label: 'Pain (manual)',
					value: persona.painOverride ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Pain Length (# of words)
							<ProductTip content="Pain Length (# of words): Set the word count for describing pain points." />
						</div>
					),
					value: persona.painLength,
				},
			]}
		/>
	)
}

function MappingContent() {
	const { persona } = usePersonaQuery()

	if (!persona) return null

	return (
		<>
			<PersonaMappingItem
				id="responsibilities"
				title="Responsibilities"
				status={persona.prompt.status}
				value={persona.personaMapping?.responsibilities}
			/>
			<PersonaMappingItem
				id="kpis"
				title="KPIs"
				status={persona.prompt.status}
				value={persona.personaMapping?.kpis}
			/>
			<PersonaMappingItem
				id="frustrations"
				title="Frustrations"
				status={persona.prompt.status}
				value={persona.personaMapping?.frustrations}
			/>
			<PersonaMappingItem
				id="value-props"
				title="Value Props"
				status={persona.prompt.status}
				value={persona.personaMapping?.valuePropositions}
			/>
			<PersonaMappingItem
				id="objections-concerns"
				title="Objections/Concerns"
				status={persona.prompt.status}
				value={persona.personaMapping?.objectionsConcerns}
			/>
			<PersonaMappingItem
				id="objection-addressing"
				title="Objection Addressing"
				status={persona.prompt.status}
				value={persona.personaMapping?.objectionAddressing}
			/>
			<PersonaMappingItem
				id="buyer-journey-info"
				title="Buyer Journey Info"
				status={persona.prompt.status}
				value={persona.personaMapping?.buyerJourneyInfo}
			/>
			<PersonaMappingItem
				id="buyer-journey-use-cases"
				title="Buyer Journey Use Cases"
				status={persona.prompt.status}
				value={persona.personaMapping?.buyerJourneyUseCases}
			/>
			<PersonaMappingItem
				id="gain-information-from"
				title="Gain Information From"
				status={persona.prompt.status}
				value={persona.personaMapping?.gainInformationFrom}
			/>
			<PersonaMappingItem
				id="boolean-linkedin"
				title="Boolean eg. for LinkedIn Sales Nav."
				status={persona.prompt.status}
				value={persona.personaMapping?.boolLinkedin}
			/>
		</>
	)
}

function MappingNavAside() {
	const location = useLocation()
	const lastHash = useRef('')

	// listen to location change using useEffect with location as dependency
	// https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
	useEffect(() => {
		if (location.hash) {
			lastHash.current = location.hash.slice(1) // safe hash for further use after navigation
		}

		if (lastHash.current && document.getElementById(lastHash.current)) {
			setTimeout(() => {
				document
					.getElementById(lastHash.current)
					?.scrollIntoView({ behavior: 'smooth', block: 'start' })
				lastHash.current = ''
				history.replaceState(
					null,
					document.title,
					location.pathname + window.location.search,
				)
			}, 100)
		}
	}, [location])

	return (
		<ul>
			{[
				{
					id: 'responsibilities',
					name: 'Responsibilities',
				},
				{ id: 'kpis', name: 'KPIs' },
				{ id: 'frustrations', name: 'Frustrations' },
				{ id: 'value-props', name: 'Value Props' },
				{
					id: 'objections-concerns',
					name: 'Objections/Concerns',
				},
				{
					id: 'objection-addressing',
					name: 'Objection Addressing',
				},
				{
					id: 'buyer-journey-info',
					name: 'Buyer Journey Info',
				},
				{
					id: 'buyer-journey-use-cases',
					name: 'Buyer Journey Use Cases',
				},
				{
					id: 'boolean-linkedin',
					name: 'Boolean eg. for LinkedIn Sales Nav.',
				},
			].map(({ id, name }) => (
				<li
					key={id}
					className={cn(
						'border-l-2 border-neutral-1-bd px-4 py-1.5 transition-all hover:border-brand-1-bd-hover',
						location.hash === `#${id}` ? 'border-bd-brand-1 text-link' : '',
					)}
				>
					<Link
						to={`#${id}`}
						className={cn('text-body-md font-medium text-neutral-2-fg')}
					>
						{name}
					</Link>
				</li>
			))}
		</ul>
	)
}

function SalesCoachContent() {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { readOnlySession, ecosystemId, verticalId, personaId },
	} = useLoaderData() as PersonaLoaderResponse
	const {
		salesCoachData,
		isSalesCoachEmpty,
		hasSalesCoachPromptCrashed,
		generalStatus,
		timingStatus,
		coldEmailStatus,
	} = useSalesCoachQuery()

	if (isSalesCoachEmpty)
		return (
			<section className="flex min-h-[420px] w-full flex-col items-center justify-center gap-4">
				<Icon name="education" size="xl" className="text-neutral-3-fg" />
				<h1 className="text-body-lg text-neutral-2-fg">
					For best results, you need to calibrate the Sales Coach first
				</h1>
				{readOnlySession ? null : (
					<div>
						<Button asChild className="flex flex-nowrap items-center gap-1">
							<Link
								to={routes.calibrate.persona.coach.index({
									companyId: params.companyId,
									ecosystemId: ecosystemId,
									verticalId: verticalId,
									personaId: personaId!,
								})}
							>
								Let&apos;s Begin
							</Link>
						</Button>
					</div>
				)}
			</section>
		)

	if (hasSalesCoachPromptCrashed)
		return (
			<ComponentErrorBoundary
				title="Ouch!"
				message="Failed to generate output!"
				description="It seems like there was an error when generating the output. Retry or try again later."
				className="px-10 py-32"
			>
				<div className="flex items-center gap-4">
					{!readOnlySession &&
					(salesCoachData?.calibrated?.id || salesCoachData?.timing?.id) ? (
						<RecalculateSalesCoachAsyncForm
							ecosystemId={ecosystemId}
							verticalId={verticalId}
							personaId={personaId!}
							generalSalescoachId={salesCoachData?.calibrated?.id.toString()}
							timingSalescoachId={salesCoachData?.timing?.id.toString()}
							className="w-40"
						>
							Recalculate
						</RecalculateSalesCoachAsyncForm>
					) : null}
				</div>
			</ComponentErrorBoundary>
		)

	return (
		<>
			<PersonaMappingItem
				id="calibrated-questions"
				title={
					<div className="flex items-center gap-1">
						Calibrated Questions
						<ProductTip content="Calibrated questions Our model creates calibrated questions by processing your inputs for the ecosystem, vertical criteria, and persona mapping, combined with its understanding of the LLM.  The model creates templates to engage prospects with tailored, meaningful questions that acknowledge their current efforts and suggest improvements. Users can adjust the number, word count, and specifics of questions via the “Edit” tab." />
					</div>
				}
				status={generalStatus}
				value={
					salesCoachData?.calibrated?.questions
						? (salesCoachData?.calibrated?.questions?.length ?? 0) > 1
							? salesCoachData?.calibrated.questions.join('\n\n')
							: salesCoachData?.calibrated.questions.join('\n')
						: ''
				}
				editLink={
					!readOnlySession &&
					!salesCoachData?.calibrated?.questions &&
					generalStatus !== 'In Progress'
						? routes.calibrate.persona.coach.index({
								companyId: params.companyId,
								ecosystemId: ecosystemId,
								verticalId: verticalId,
								personaId: personaId!,
							})
						: ''
				}
				showValueWhilePending={false}
			/>
			{salesCoachData?.timing ? (
				<>
					<PersonaMappingItem
						id="questions-timing"
						title={
							<div className="flex items-center gap-1">
								{`Context for ${
									MONTH_SELECT_OPTIONS.find(
										m => Number(m.value) === salesCoachData?.timing?.month,
									)?.label
								}`}
								<ProductTip content="Timing + Context: Uses persona mapping details, relevant background information and seasonal trends.The AI frames conversations effectively by understanding the persona’s situation depending on the time of the year. Users can modify the timing context manually to reflect current conditions via the “Edit” tab." />
							</div>
						}
						status={timingStatus}
						value={salesCoachData?.timing?.timingContext}
						override={salesCoachData?.adjustments?.valueProp}
						overrideLabel="Manual input for timing context"
						editLink={
							!readOnlySession &&
							!salesCoachData?.timing?.timingContext &&
							timingStatus !== 'In Progress'
								? routes.calibrate.persona.coach.index({
										companyId: params.companyId,
										ecosystemId: ecosystemId,
										verticalId: verticalId,
										personaId: personaId!,
									})
								: ''
						}
						showValueWhilePending={false}
					/>
					<PersonaMappingItem
						id="questions-timing"
						title={
							<div className="flex items-center gap-1">
								{`Questions for ${
									MONTH_SELECT_OPTIONS.find(
										m => Number(m.value) === salesCoachData?.timing?.month,
									)?.label
								}`}
								<ProductTip content="Questions for [Month]: Aligned with our model’s understanding of seasonal trends, market conditions, business cycles and job responsibilities throughout the year. The AI ensures relevance and strategic alignment of questions. Update monthly to stay current with trends via the “Edit” tab." />
							</div>
						}
						status={timingStatus}
						value={
							salesCoachData?.timing?.questions
								? (salesCoachData?.timing?.questions?.length ?? 0) > 1
									? salesCoachData?.timing.questions.join('\n\n')
									: salesCoachData?.timing.questions.join('\n')
								: ''
						}
						editLink={
							!readOnlySession &&
							!salesCoachData?.timing?.questions &&
							timingStatus !== 'In Progress'
								? routes.calibrate.persona.coach.index({
										companyId: params.companyId,
										ecosystemId: ecosystemId,
										verticalId: verticalId,
										personaId: personaId!,
									})
								: ''
						}
						showValueWhilePending={false}
					/>
				</>
			) : null}

			<PersonaMappingItem
				id="questions-timing"
				title={
					<div className="flex items-center gap-1">
						Sales co-pilot email
						<ProductTip content="Sales Co-Pilot Email: A customer-centric email template incorporating best practices for engaging prospects with personalized observations, questions, social proof, and a call to action. The AI provides a template for personalized communication. Users can customize or update email templates via the “Edit” tab." />
					</div>
				}
				status={coldEmailStatus}
				value={salesCoachData?.coldEmail}
				showValueWhilePending={false}
			/>
		</>
	)
}

function SalesCoachAside() {
	const params = useParsedRouteParams(['companyId'])
	const {
		handle: { readOnlySession, ecosystemId, verticalId, personaId },
	} = useLoaderData() as PersonaLoaderResponse
	const { salesCoachData } = useSalesCoachQuery()

	return (
		<Aside
			title={
				<div className="flex items-center justify-between">
					Settings
					{readOnlySession ? null : (
						<Link
							to={routes.calibrate.persona.coach.index({
								companyId: params.companyId,
								ecosystemId: ecosystemId,
								verticalId: verticalId,
								personaId: personaId!,
							})}
							className="text-button-sm text-link hover:text-link-hover active:text-link-pressed"
						>
							Edit
						</Link>
					)}
				</div>
			}
			list={[
				{
					label: (
						<div className="flex items-center gap-1">
							Number of Questions
							<ProductTip content="Number of Questions: Set the number of calibrated questions for sales interactions to control the depth and focus of prospect engagement." />
						</div>
					),
					value: salesCoachData?.adjustments?.questionCount ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Question length in words
							<ProductTip content="Min/Max # of Words for Questions: Define the word count range for each question to ensure they are concise yet comprehensive enough for meaningful engagements." />
						</div>
					),
					value:
						salesCoachData?.adjustments?.wordsMin &&
						salesCoachData?.adjustments?.wordsMin
							? `${salesCoachData?.adjustments?.wordsMin} - ${salesCoachData?.adjustments?.wordsMax}`
							: '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Manual input for calibrated questions
							<ProductTip content="Manual Input for Calibrated Questions: Enter specific questions to address the unique challenges and needs of your prospects." />
						</div>
					),
					value: salesCoachData?.adjustments?.criteria ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Timing
							<ProductTip content="Choose Month: Select the month for setting the context and timing of your sales strategy to ensure it is timely and relevant to market conditions." />
						</div>
					),
					value:
						MONTH_SELECT_OPTIONS.find(
							m => m.value === String(salesCoachData?.timing?.month),
						)?.label ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Manual input for timing context
							<ProductTip content="Manual Input for Timing Context: Provide additional context for the selected month, such as seasonal trends or industry events, to tailor your sales efforts to current market happenings." />
						</div>
					),
					value: salesCoachData?.adjustments?.valueProp ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Objections to defuse
							<ProductTip content="Objection Calibration - Objections to Defuse: Common objections from prospects to prepare effective responses for overcoming them during sales conversations." />
						</div>
					),
					value: salesCoachData?.adjustments?.defuse ?? '-',
				},
			]}
		/>
	)
}
