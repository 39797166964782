import { parseWithZod } from '@conform-to/zod'
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import { type QueryClient } from '@tanstack/react-query'
import { type ReactNode } from 'react'
import {
	type LoaderFunctionArgs,
	useFetcher,
	useLoaderData,
} from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { checkType, cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { type EnrichLoaderResponse } from '../signals'
import {
	filterMutation,
	filterMutationIntentSource,
	filterMutationIntentType,
	filterMutationHack,
	filterMutationWeight,
	filterMutationMappedPersonas,
	sortMutation,
} from './mutations'
import { filterQuery, sortQuery } from './queries'
import {
	type SORT,
	FILTERS,
	FILTER_VALUE_MAP,
	FilterFormSchema,
	IntentSourceEnumSchema,
	IntentSourceSchema,
	IntentTypeEnumSchema,
	IntentTypeSchema,
	HackEnumSchema,
	HackSchema,
	WeightEnumSchema,
	WeightSchema,
	MappedPersonasSchema,
	MappedPersonasEnumSchema,
	SortEnumSchema,
} from './schema'

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: FilterFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		const { intent, filter, value, sort } = submission.value

		if (intent === 'clear-all') {
			Object.values(FILTERS).forEach(filter => {
				filterMutation(filter, null)
			})

			return null
		} else if (intent === 'clear' && filter) {
			filterMutation(filter, null)

			return null
		} else if (intent === 'toggle' && filter && value) {
			const filters = await queryClient.fetchQuery(filterQuery(filter))

			switch (filter) {
				case FILTERS.TYPE:
					if (
						checkType(IntentTypeSchema, filters) &&
						checkType(IntentTypeEnumSchema, value)
					)
						if (!filters?.includes(value)) {
							filterMutationIntentType(filter, [...(filters ?? []), value])
						} else {
							filterMutationIntentType(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				case FILTERS.SOURCE:
					if (
						checkType(IntentSourceSchema, filters) &&
						checkType(IntentSourceEnumSchema, value)
					)
						if (!filters?.includes(value)) {
							filterMutationIntentSource(filter, [...(filters ?? []), value])
						} else {
							filterMutationIntentSource(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				case FILTERS.HACK:
					if (
						checkType(HackSchema, filters) &&
						checkType(HackEnumSchema, value)
					)
						if (!filters?.includes(value)) {
							filterMutationHack(filter, [...(filters ?? []), value])
						} else {
							filterMutationHack(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				case FILTERS.WEIGHT:
					if (
						checkType(WeightSchema, filters) &&
						checkType(WeightEnumSchema, value)
					)
						if (!filters?.includes(value)) {
							filterMutationWeight(filter, [...(filters ?? []), value])
						} else {
							filterMutationWeight(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				case FILTERS.MAPPED_PERSONAS:
					if (
						checkType(MappedPersonasSchema, filters) &&
						checkType(MappedPersonasEnumSchema, value)
					)
						if (!filters?.includes(value)) {
							filterMutationMappedPersonas(filter, [...(filters ?? []), value])
						} else {
							filterMutationMappedPersonas(
								filter,
								filters.filter(l => l !== value),
							)
						}
					break
				default:
					throw new Error('Invalid filter key')
			}

			return null
		} else if (intent === 'sort') {
			const sortBy = await queryClient.fetchQuery(sortQuery())

			if (checkType(SortEnumSchema, sort)) {
				if (!sortBy || sortBy.key !== sort) {
					sortMutation({ key: sort, direction: 'desc' })
				} else if (sortBy.key === sort && sortBy.direction === 'desc') {
					sortMutation({ key: sort, direction: 'asc' })
				} else if (sortBy?.key === sort && sortBy?.direction === 'asc') {
					sortMutation(null)
				}
			}

			return null
		}

		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})
	}

export function Filters() {
	const { filters } = useLoaderData() as EnrichLoaderResponse

	return (
		<section className="flex items-center gap-4">
			<p className="flex items-center gap-1 text-label-lg text-neutral-2-fg">
				<Icon name="filter" size="sm" /> Filter by:
			</p>
			<div className="flex items-center gap-2">
				<Filter filter={FILTERS.TYPE} values={filters?.[FILTERS.TYPE]} />
				<Filter filter={FILTERS.SOURCE} values={filters?.[FILTERS.SOURCE]} />
				<Filter filter={FILTERS.HACK} values={filters?.[FILTERS.HACK]} />
				<Filter filter={FILTERS.WEIGHT} values={filters?.[FILTERS.WEIGHT]} />
				<Filter
					filter={FILTERS.MAPPED_PERSONAS}
					values={filters?.[FILTERS.MAPPED_PERSONAS]}
				/>
				<ClearAllFilters />
			</div>
		</section>
	)
}

function ClearAllFilters({ children }: { children?: ReactNode }) {
	const { filters } = useLoaderData() as EnrichLoaderResponse
	const hasFilters =
		Object.values(filters).flat().filter(Boolean)?.length > 0 ?? false

	const params = useParsedRouteParams(['companyId'])
	const fetcher = useFetcher()
	const action = routes.prioritize.filters({
		companyId: params.companyId,
	})

	return (
		<fetcher.Form action={action} method="POST">
			{children ? (
				children
			) : (
				<button
					type="submit"
					name="intent"
					value="clear-all"
					disabled={!hasFilters}
					className={cn(
						'text-button-sm',
						hasFilters
							? 'text-link hover:text-link-hover active:text-link-pressed'
							: 'text-neutral-1-fg-disabled',
					)}
				>
					Clear all
				</button>
			)}
		</fetcher.Form>
	)
}

function Filter({
	filter,
	values,
}: {
	filter: FILTERS
	values: string[] | null
}) {
	const params = useParsedRouteParams(['companyId'])
	const fetcher = useFetcher()
	const action = routes.prioritize.filters({
		companyId: params.companyId,
	})

	return (
		<DropdownPrimitive.Root>
			<DropdownPrimitive.Trigger
				className={cn(
					'group flex items-center gap-1 rounded border bg-transparent py-2 pl-3 pr-2 text-body-md text-button-sm outline-none transition-colors radix-state-open:border-brand-1-bd',
					values?.length ? 'text-brand-1-fg' : 'text-neutral-1-fg',
					values?.length ? 'border-brand-1-bd' : 'border-neutral-1-bd',
				)}
			>
				{filter}
				{values?.length ? (
					<span className="inline-flex h-4 w-4 items-center justify-center rounded-full bg-brand-3-bg text-label-sm text-white animate-in fade-in">
						{values.length}
					</span>
				) : null}
				<Icon
					name="carret-down"
					size="sm"
					className="group-radix-state-open:rotate-180"
				/>
			</DropdownPrimitive.Trigger>

			<DropdownPrimitive.Content
				side="bottom"
				align="start"
				sideOffset={4}
				className={cn(
					'z-50 flex max-h-[20rem] min-w-[12rem] flex-col overflow-hidden overflow-y-auto rounded border border-neutral-1-bd bg-neutral-1-bg p-2 shadow',
				)}
			>
				<DropdownPrimitive.Item
					disabled={!values?.length}
					className={cn(
						'group flex items-center gap-2 rounded-sm px-2 py-1.5 text-button-sm font-normal outline-none transition-colors',
						'cursor-pointer text-link hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover radix-disabled:cursor-default radix-disabled:text-neutral-1-fg-disabled radix-disabled:hover:bg-transparent',
					)}
					onSelect={e => {
						e.preventDefault()

						fetcher.submit(
							{ intent: 'clear', filter },
							{ action, method: 'POST' },
						)
					}}
				>
					Clear all
				</DropdownPrimitive.Item>
				{FILTER_VALUE_MAP[filter].map((label, i) => (
					<DropdownPrimitive.CheckboxItem
						key={i}
						className={cn(
							'group flex cursor-pointer items-center gap-2 rounded-sm px-2 py-1.5 text-body-md font-normal text-neutral-2-fg outline-none transition-colors hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
						)}
						onSelect={e => e.preventDefault()}
						checked={values?.includes(label) ?? false}
						onCheckedChange={() => {
							fetcher.submit(
								{ intent: 'toggle', filter, value: label },
								{ action, method: 'POST' },
							)
						}}
					>
						<Icon
							name="checkbox-unchecked"
							size="sm"
							className="inline-flex animate-in fade-in group-radix-state-checked:hidden group-radix-state-checked:animate-out group-radix-state-checked:fade-out"
						/>
						<Icon
							name="checkbox-checked-filled"
							size="sm"
							className="hidden text-brand-1-fg animate-out fade-out group-radix-state-checked:inline-flex group-radix-state-checked:animate-in group-radix-state-checked:fade-in"
						/>
						{label}
					</DropdownPrimitive.CheckboxItem>
				))}
			</DropdownPrimitive.Content>
		</DropdownPrimitive.Root>
	)
}

export function Sort({
	sortKey,
	children,
}: {
	sortKey: SORT
	children: ReactNode
}) {
	const { sortBy } = useLoaderData() as EnrichLoaderResponse

	const params = useParsedRouteParams(['companyId'])
	const fetcher = useFetcher()
	const action = routes.prioritize.filters({
		companyId: params.companyId,
	})

	return (
		<fetcher.Form action={action} method="POST">
			<input type="hidden" name="sort" value={sortKey} />
			<button
				type="submit"
				name="intent"
				value="sort"
				className={cn(
					'flex w-max items-center justify-center gap-0.5',
					sortKey === sortBy?.key ? 'font-semibold' : '',
				)}
			>
				{children}

				<div className="relative inline-flex h-4 w-4 items-center justify-center">
					{!sortBy || sortBy.key !== sortKey ? (
						<Icon name="chevron-sort" size="sm" />
					) : (
						<>
							<Icon
								name="chevron-sort-asc"
								size="sm"
								className={cn(
									'absolute left-0 top-0',
									sortBy.direction === 'asc' ? 'opacity-100' : 'opacity-50',
								)}
							/>
							<Icon
								name="chevron-sort-desc"
								size="sm"
								className={cn(
									'absolute left-0 top-0',
									sortBy.direction === 'desc' ? 'opacity-100' : 'opacity-50',
								)}
							/>
						</>
					)}
				</div>
			</button>
		</fetcher.Form>
	)
}

export function NoResults() {
	return (
		<Surface className="mx-20 flex min-h-[260px] w-full flex-col items-center justify-center gap-4 border-none bg-neutral-2-bg">
			<Icon name="search" size="xl" className="text-neutral-3-fg" />
			<h1 className="text-title-md text-neutral-2-fg">No results match</h1>
			<div>
				<ClearAllFilters>
					<Button
						type="submit"
						name="intent"
						value="clear-all"
						variant="outline"
						className="flex flex-nowrap items-center gap-1"
					>
						Clear filters
					</Button>
				</ClearAllFilters>
			</div>
		</Surface>
	)
}
