import { type QueryClient } from '@tanstack/react-query'
import { Link, useLoaderData, type LoaderFunctionArgs } from 'react-router-dom'
import {
	DashboardCard,
	DashboardCardCount,
} from '#src/components/dashboard-card'
import ProductTip from '#src/components/product-tip'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { chatsQuery } from '../chat/queries'
import { glossaryQuery } from '../glossary/queries'
import { chatsQuery as roleplaysQuery } from '../roleplay/queries'

type EnableDashboardLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const chats = await queryClient.fetchQuery(chatsQuery(params.companyId))
		const roleplays = await queryClient.fetchQuery(
			roleplaysQuery(params.companyId),
		)
		const glossary = await queryClient.fetchQuery(
			glossaryQuery(params.companyId),
		)

		return { chats, roleplays, glossary }
	}

export default function EnableDashboard() {
	const params = useParsedRouteParams(['companyId'])
	const { chats, roleplays, glossary } =
		useLoaderData() as EnableDashboardLoaderResponse

	return (
		<main className="flex h-full w-full flex-col items-center">
			<section className="flex w-full max-w-screen-2xl flex-col items-start px-20 pb-4 pt-10">
				<h1 className="pb-2 text-heading-sm text-neutral-1-fg">Enable</h1>
				<p className="pb-4 text-body-md text-neutral-2-fg">
					Equip your team with AI-powered insights to personalize interactions
					and enhance sales engagement, delivering a customer-centric approach
					that prioritizes the unique needs and preferences of each prospect.
				</p>
			</section>

			<section className="grid w-full max-w-screen-2xl grid-cols-3 grid-rows-1 gap-6 px-20">
				<Link to={`/enable/${params.companyId}/copilot`} className="block">
					<DashboardCard
						icon="chat"
						title={
							<div className="flex items-center gap-1">
								Co-Pilot chats
								<ProductTip
									className="text-neutral-3-fg"
									content="Co-pilot Chat: Engage in conversations with simulated versions of your prospects, generated from real data, and receive live coaching on best outreach practices."
								/>
							</div>
						}
						isHoverable
					>
						<DashboardCardCount
							count={chats.length ?? 0}
							countSuffix={chats.length !== 1 ? 'Chats' : 'Chat'}
						/>
					</DashboardCard>
				</Link>
				<Link to={`/enable/${params.companyId}/roleplay`} className="block">
					<DashboardCard
						icon="voice-activate"
						title={
							<div className="flex items-center gap-1">
								Roleplay training
								<ProductTip
									className="text-neutral-3-fg"
									content="Roleplay training: Practice sales conversations with AI-generated responses, and receive feedback on your performance to improve your sales skills."
								/>
							</div>
						}
						isHoverable
					>
						<DashboardCardCount
							count={roleplays.length ?? 0}
							countSuffix={roleplays.length !== 1 ? 'Roleplays' : 'Roleplay'}
						/>
					</DashboardCard>
				</Link>
				<Link to={`/enable/${params.companyId}/glossary`} className="block">
					<DashboardCard
						icon="notebook-reference"
						title={
							<div className="flex items-center gap-1">
								Industry jargons
								<ProductTip
									className="text-neutral-3-fg"
									content="Industry Jargon: Specialized terms and phrases commonly used within a specific industry or field, enriched with AI. Synchronized with an ecosystem of your choice to enhance relevance and contextual accuracy."
								/>
							</div>
						}
						isHoverable
					>
						<DashboardCardCount
							count={glossary.length ?? 0}
							countSuffix={glossary.length !== 1 ? 'Terms' : 'Term'}
						/>
					</DashboardCard>
				</Link>
			</section>
		</main>
	)
}
