import { Link, useRouteLoaderData } from 'react-router-dom'
import Sidebar from '#src/components/sidebar'
import { Icon } from '#src/components/ui/icon'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#src/components/ui/tooltip'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import SidebarMain, {
	Account,
	navLinkClasses,
	useRouteMatches,
} from '#src/routes/_layout/sidebar'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export default function SidebarEnable() {
	const params = useParsedRouteParams(['companyId'])

	const { enableOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse

	const { enableMatch, enableDetailMatch } = useRouteMatches()

	if (!enableOnlySession) {
		return <SidebarMain />
	}

	return (
		<Sidebar>
			<TooltipProvider>
				<Tooltip delayDuration={100}>
					<TooltipTrigger asChild>
						<Link
							to={`/enable/${params.companyId}`}
							className={navLinkClasses(
								enableMatch || enableDetailMatch,
								false,
							)}
						>
							<Icon
								name="data-enrichment"
								size="font"
								className="text-[1.25rem]"
							/>
						</Link>
					</TooltipTrigger>
					<TooltipContent
						side="right"
						align="center"
						className="flex items-center gap-1"
					>
						Enable
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
			<div className="my-3 mt-auto flex flex-col items-center gap-3">
				<a
					href="https://docs.evergrowth.com"
					target="__blank"
					aria-description="Evergrowth Docs"
					title="Evergrowth Docs"
					className="flex shrink-0 items-center justify-center rounded-sm bg-neutral-3-bg p-1.5 text-neutral-3-fg transition-colors hover:bg-neutral-3-bg-hover"
				>
					<Icon name="help-filled" size="font" className="text-[1.25rem]" />
				</a>

				<Account />
			</div>
		</Sidebar>
	)
}
