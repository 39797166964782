import { Icon } from '../ui/icon'

export default function ResendMessages() {
	return (
		<section className="w-full bg-red-10 p-2">
			<p className="item-center flex justify-center gap-0.5 text-center text-body-sm font-semibold text-status-danger-fg">
				<Icon name="information-filled" size="sm" />
			</p>
		</section>
	)
}
