import { z } from 'zod'
import { EMPLOYEE_MAX_RANGE_UPPER_LIMIT } from '#src/utils/enumerations'
import { EcosystemAPISchema } from '../ecosystem/schema'
import { TAMSchema } from './tam/schema'

export const VerticalCriterionAPISchema = z.object({
	criterionId: z.number(),
	question: z.string().optional(),
	value: z.string().optional(),
})

export const VerticalDataPointAPISchema = z.object({
	id: z.number(),
	value: z.string().optional(),
	title: z.string().optional(),
	bizModel: z.boolean(),
})

export const VerticalCriteriaAPISchema = z.array(VerticalCriterionAPISchema)

export const VerticalDataPointsAPISchema = z.array(VerticalDataPointAPISchema)

export const VerticalAPISchema = z.object({
	id: z.number().optional(),
	name: z.string(),
	ecosystem: EcosystemAPISchema.pick({ id: true, name: true }).required(),
	answers: VerticalCriteriaAPISchema,
	dataPoints: VerticalDataPointsAPISchema.nullable(),
	status: z.string(),
	priority: z.string(),
	tams: TAMSchema.nullable().optional(),
	bizDescription: z.string().nullable().optional(),
	bizDescriptionOverride: z.string().nullable().optional(),
	bizModelLen: z.number(),
	exampleCompanies: z.string().nullable().optional(),
	subVerticals: z.string().nullable().optional(),
	prompt: z.object({
		status: z.string().nullable(),
	}),
	regions: z.string().nullable().optional(),
	regionsExcluded: z.string().nullable().optional(),
	employeesMin: z.string().nullable(),
	employeesMax: z
		.string()
		.nullable()
		.transform(v =>
			Number.isNaN(Number(v)) || !v ? EMPLOYEE_MAX_RANGE_UPPER_LIMIT : v,
		),
	listBuildingNotes: z.string().nullable().optional(),
	otherNotes: z.array(z.string()).nullable().optional(),
})

export const VerticalListAPISchema = z.array(VerticalAPISchema)

export const VerticalFormSchema = VerticalAPISchema.omit({
	ecosystem: true,
	answers: true,
	dataPoints: true,
	bizDescription: true,
	prompt: true,
	employeesMax: true,
	tams: true,
})
	.merge(
		z.object({
			employeesMax: z
				.string()
				.nullable()
				.optional()
				.transform(v => (Number.isNaN(Number(v)) || !v ? null : v)),
			answers: z
				.array(
					z.object({
						criterionId: z.number(),
						question: z.string().optional(),
						value: z.string().optional(),
					}),
				)
				.optional()
				.nullable(),
			dataPoints: z
				.object({
					id: z.number(),
					value: z.string().optional(),
					title: z.string().optional(),
					bizModel: z.boolean().optional(),
				})
				.array()
				.optional()
				.nullable(),
		}),
	)
	.superRefine((data, ctx) => {
		const { employeesMin, employeesMax } = data
		const min = Number(employeesMin)
		const max = Number(employeesMax)

		if (Number.isNaN(min)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Must be a number',
				path: ['employeesMin'],
			})
		} else if (min < 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Must be a positive number',
				path: ['employeesMin'],
			})
		}

		if (Number.isNaN(max) && employeesMax !== null) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Must be a number or "Infinity"',
				path: ['employeesMax'],
			})
		} else if (max < 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Must be a positive number',
				path: ['employeesMax'],
			})
		}

		if (employeesMax !== null && min > max) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message:
					'Minimum employees must be less or equal than maximum employees',
				path: ['employeesMin'],
			})
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message:
					'Maximum employees must be greater or equal than minimum employees',
				path: ['employeesMax'],
			})
		}
	})
