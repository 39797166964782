import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import Dashboard, {
	loader as dashboardLoader,
} from '#src/routes/calibrate/dashboard'
import DashboardLayout from '#src/routes/calibrate/dashboard/_layout'
import RouteBoundary from '#src/routes/route-boundary'

export default (client: QueryClient) =>
	({
		element: <DashboardLayout />,
		children: [
			{
				path: '/company/:companyId',
				element: <Dashboard />,
				errorElement: <RouteBoundary />,
				loader: dashboardLoader(client),
			},
		],
	}) satisfies RouteObject
