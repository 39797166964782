import MarkdownPrimitive from 'react-markdown'
import RemarkBreaks from 'remark-breaks'
import { cn } from '#src/utils/misc'

/**
 * Supported specification: https://commonmark.org/help/
 */
export default function Markdown({
	children,
	className,
}: {
	children: string | null | undefined
	className?: string
}) {
	return (
		<MarkdownPrimitive
			className={cn('markdown', className)}
			remarkPlugins={[RemarkBreaks]}
		>
			{children}
		</MarkdownPrimitive>
	)
}
