import { Outlet } from 'react-router-dom'
import Header from '#src/components/header'
import Actions from '#src/components/header/actions'
import Crumbs from '#src/components/header/crumbs'
import PageLoader from '#src/components/loader'
import Panel, { PanelProvider, PanelToggle } from '#src/components/panel'
import Sidebar from '#src/routes/_layout/sidebar'
import CalibratePanel from '#src/routes/calibrate/_layout/panel'
import { useElementScrollRestoration } from '#src/utils/use-element-scroll-restoration'

function Main() {
	useElementScrollRestoration('scrollable', location =>
		location.pathname.includes('/verticals/')
			? '/verticals/'
			: location.pathname.includes('/personas/')
				? '/personas/'
				: location.pathname,
	)

	return (
		<div className="relative flex h-screen w-screen overflow-hidden bg-neutral-1-bg text-neutral-1-fg">
			<Sidebar />

			<Panel>
				<CalibratePanel />
			</Panel>

			<div
				id="scrollable"
				className="relative flex w-full flex-col overflow-auto"
			>
				<Header
					panelToggle={<PanelToggle />}
					crumbs={<Crumbs />}
					actions={<Actions />}
				/>
				<PageLoader />
				<Outlet />
			</div>
		</div>
	)
}

export default function Layout() {
	return (
		<PanelProvider>
			<Main />
		</PanelProvider>
	)
}
