import { captureMessage } from '@sentry/react'

const STORAGE_KEY = 'FEATURE'

export const AVAILABLE_FEATURES: Record<string, string> = {
	ROLEPLAY: 'ROLEPLAY',
	AIDT: 'AIDT',
	VOICE_CHAT: 'VOICE_CHAT',
}

export const isFeatureEnabled = (feature: string) => {
	try {
		return localStorage.getItem(`${STORAGE_KEY}:${feature}`) === 'true'
	} catch (e) {
		captureMessage('Failed to check feature flag', scope => {
			scope.setContext('Feature', {
				name: feature,
			})
			scope.setLevel('warning')
			return scope
		})
		return false
	}
}

export const toggleFeature = (feature: string, status: string) => {
	try {
		if (status === 'false') {
			localStorage.removeItem(`${STORAGE_KEY}:${feature}`)
		} else {
			localStorage.setItem(`${STORAGE_KEY}:${feature}`, status)
		}
	} catch (e) {
		captureMessage('Failed to update feature flag', scope => {
			scope.setContext('Feature', {
				name: feature,
			})
			scope.setLevel('warning')
			return scope
		})
		return false
	}
}
