export const baseApi = import.meta.env.VITE_API_URL
export const adminApi = import.meta.env.VITE_ADMIN_API_URL

export const admin = {
	create: `${adminApi}/company`,
	details: (companyId: string) => `${adminApi}/company/${companyId}`,
	dashboard: (companyId: string) =>
		`${adminApi}/company/${companyId}/dashboard`,
	conversation: (companyId: string, conversationId: string) =>
		`${adminApi}/company/${companyId}/copilot/conversation/${conversationId}/restart`,
	roleplay: (companyId: string, conversationId: string) =>
		`${adminApi}/company/${companyId}/chat/roleplay/${conversationId}/restart`,
	tam: (companyId: string, verticalId: string) =>
		`${adminApi}/company/${companyId}/vertical/${verticalId}/tam`,
}

export const api = {
	user: {
		me: `${baseApi}/user/me`,
	},
	company: {
		list: `${baseApi}/company/list`,
		details: (companyId: string) => `${baseApi}/company/${companyId}`,
		stats: (companyId: string) => `${baseApi}/company/${companyId}/stats`,
		sort: (companyId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/sort`,
	},
	ecosystem: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/ecosystem`,
		list: (companyId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/list`,
		details: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}`,
		criteria: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/criteria`,
		datapoints: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/datapoints`,
		verticals: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/verticals`,
		templates: (companyId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/templates`,
	},
	criterion: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/criterion`,
		details: (companyId: string, criterionId: string) =>
			`${baseApi}/company/${companyId}/criterion/${criterionId}`,
	},
	datapoint: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/datapoint`,
		details: (companyId: string, dataPointId: string) =>
			`${baseApi}/company/${companyId}/datapoint/${dataPointId}`,
	},
	vertical: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/vertical`,
		details: (companyId: string, verticalId: number) =>
			`${baseApi}/company/${companyId}/vertical/${verticalId}`,
		personas: (companyId: string, verticalId: string) =>
			`${baseApi}/company/${companyId}/vertical/${verticalId}/personas`,
		recalculate: (companyId: string, verticalId: string) =>
			`${baseApi}/company/${companyId}/vertical/${verticalId}/recalculate`,
		tam: {
			request: (companyId: string, verticalId: string) =>
				`${baseApi}/company/${companyId}/vertical/${verticalId}/tam/request`,
		},
	},
	persona: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/persona`,
		details: (companyId: string, personaId: string) =>
			`${baseApi}/company/${companyId}/persona/${personaId}`,
		recalculate: (companyId: string, personaId: string) =>
			`${baseApi}/company/${companyId}/persona/${personaId}/recalculate`,
	},
	salescoach: {
		create: (companyId: string, personaId: string) =>
			`${baseApi}/company/${companyId}/salescoach/${personaId}`,
		list: (companyId: string, personaId: string) =>
			`${baseApi}/company/${companyId}/salescoach/${personaId}`,
		details: (companyId: string, personaId: string, salescoachId: number) =>
			`${baseApi}/company/${companyId}/salescoach/${personaId}/${salescoachId}`,
		recalculate: (companyId: string, personaId: string, salescoachId: string) =>
			`${baseApi}/company/${companyId}/salescoach/${personaId}/recalculate/${salescoachId}`,
	},
	signal: {
		list: (companyId: string) => `${baseApi}/company/${companyId}/signal/all`,
		assigned: (companyId: string) => `${baseApi}/company/${companyId}/signal`,
		assign: (companyId: string, signalId: string) =>
			`${baseApi}/company/${companyId}/signal/${signalId}/assign`,
		config: (companyId: string, signalId: string) =>
			`${baseApi}/company/${companyId}/signal/${signalId}/config`,
		example: (companyId: string, signalId: string, personaId: string) =>
			`${baseApi}/company/${companyId}/signal/${signalId}/example/${personaId}`,
	},
	chat: {
		contact: (contactId: string) =>
			`${baseApi}/company/copilot/contact/${contactId}`,
		status: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}/status`,
		messages: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}/messages`,
		new: (companyId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/linkedin`,
		context: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}`,
		delete: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}`,
		list: (companyId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/list`,
	},
	roleplay: {
		end: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/${conversationId}/end`,
		index: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/${conversationId}`,
		new: (companyId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/new`,
		mass: (companyId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/mass`,
		status: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/${conversationId}/status`,
		messages: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/${conversationId}/messages`,
		restart: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/${conversationId}/restart`,
		list: (companyId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/list`,
		delete: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/${conversationId}`,
		scenarios: (companyId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/scenarios`,
		voices: (companyId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/voices`,
		users: (companyId: string) =>
			`${baseApi}/company/${companyId}/chat/roleplay/users`,
	},
	glossary: {
		list: (companyId: string) => `${baseApi}/company/${companyId}/glossary`,
		create: (companyId: string) => `${baseApi}/company/${companyId}/glossary`,
		details: (companyId: string, termId: string) =>
			`${baseApi}/company/${companyId}/glossary/${termId}`,
	},
	aidt: {
		context: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/data-trainer/${conversationId}`,
		list: (companyId: string) =>
			`${baseApi}/company/${companyId}/chat/data-trainer/list`,
		messages: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/chat/data-trainer/${conversationId}/messages`,
		unread: (companyId: string) =>
			`${baseApi}/company/${companyId}/chat/data-trainer/list/unread`,
	},
}
