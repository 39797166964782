import { type z } from 'zod'
import { client as queryClient } from '#src/main'
import { type ChatMessageSchema } from './schema'

export const streamKeys = {
	all: ['stream'] as const,
	message: (companyId: string, conversationId: string) =>
		[...streamKeys.all, 'message', companyId, conversationId] as const,
}

export const streamMessageQuery = ({
	companyId,
	conversationId,
}: {
	companyId: string
	conversationId: string
}) => ({
	queryKey: streamKeys.message(companyId, conversationId),
	queryFn: (): z.infer<typeof ChatMessageSchema> | null | undefined => {
		return queryClient.getQueryData(
			streamKeys.message(companyId, conversationId),
		)
	},
	initialData: () => {
		return null
	},
})

export const streamMessageMutation = ({
	companyId,
	conversationId,
	message,
}: {
	companyId: string
	conversationId: string
	message: z.infer<typeof ChatMessageSchema> | null
}) => {
	return queryClient.setQueryData(
		streamKeys.message(companyId, conversationId),
		message,
	)
}
