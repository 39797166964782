import { z } from 'zod'
import { TAMSchema } from '#src/routes/calibrate/verticals/tam/schema'
import { TAM_STATUS_ENUM } from '#src/utils/enumerations'

export const TAMFormSchema = TAMSchema.omit({
	status: true,
	gap: true,
})
	.merge(
		z.object({
			status: z
				.enum([TAM_STATUS_ENUM.IN_PROGRESS, TAM_STATUS_ENUM.COMPLETED])
				.default(TAM_STATUS_ENUM.IN_PROGRESS),
		}),
	)
	.required()
