import { z } from 'zod'

export enum MessageStatus {
	Sending = 'sending',
	Error = 'error',
	Generating = 'generating',
}

export const ConversationSchema = z.object({
	id: z.string(),
	name: z.string(),
	user: z.string(),
	createdAt: z.string(),
	canDelete: z.boolean().optional(),
	status: z.enum([
		'waiting_for_contact',
		'closed',
		'ready',
		'initializing',
		'crashed',
	]),
	lastMessage: z.string().nullable(),
})

type ContactInfoKeyMap = {
	[key: string]: string
}

export const CONTACT_INFO_KEY_MAP: ContactInfoKeyMap = {
	company: 'Company',
	title: 'Title',
	firstName: 'First Name',
	lastName: 'Last Name',
	email: 'Email',
	linkedin: 'LinkedIn',
	phone: 'Phone',
	website: 'Website',
}

export const ContactInfoSchema = z.object({
	company: z.string().nullable().optional(),
	title: z.string().nullable().optional(),
	firstName: z.string().nullable().optional(),
	lastName: z.string().nullable().optional(),
	email: z.string().nullable().nullable().optional(),
	linkedin: z.string().nullable().nullable().optional(),
	phone: z.string().nullable().nullable().optional(),
	website: z.string().nullable().nullable().optional(),
})

export const ContactPersonaSchema = z.object({
	ecosystem: z.string(),
	vertical: z.string(),
	type: z.string().nullable().optional(),
	expertise: z.string(),
})

export const ContactPersonaMappingSchema = z.object({
	pain: z.string().nullable(),
	buyerJourneyInfo: z.string().nullable(),
	buyerJourneyUseCases: z.string().nullable(),
	frustrations: z.string().nullable(),
	gainInformationFrom: z.string().nullable(),
	kpis: z.string().nullable(),
	objectionAddressing: z.string().nullable(),
	objectionsConcerns: z.string().nullable(),
	responsibilities: z.string().nullable(),
	valuePropositions: z.string().nullable(),
	calibratedQuestions: z.string().nullable(),
})

export const ContactDataSchema = z.object({
	info: ContactInfoSchema,
	persona: ContactPersonaSchema,
	mapping: ContactPersonaMappingSchema,
})

export const ConversationDataSchema = z.object({
	conversation: ConversationSchema,
	participant: ContactDataSchema,
	writable: z.boolean(),
})

export const MessageSchema = z.object({
	message: z.string(),
})

export const ChatMessageSchema = z
	.object({
		id: z.number().or(z.string()),
		author: z.string().optional(),
		isAi: z.boolean(),
		createdAt: z.string().optional(),
		status: z
			.enum([
				MessageStatus.Sending,
				MessageStatus.Error,
				MessageStatus.Generating,
			])
			.optional(),
	})
	.merge(MessageSchema)

export const ChatSchema = ChatMessageSchema.array()

export const ChatsSchema = z
	.object({
		conversation: ConversationSchema,
		participant: z.object({
			info: z.object({ title: z.string().optional().nullable() }),
			persona: z.object({ type: z.string().optional().nullable() }),
		}),
	})
	.array()

export const ContactSchema = z.object({
	conversationId: z.string(),
	company: z.string(),
})

export const StatusSchema = z.object({
	status: z.enum([
		'waiting_for_contact',
		'closed',
		'ready',
		'initializing',
		'crashed',
	]),
})

export const NewConversationAPISchema = z.object({
	conversationId: z.string(),
	company: z.string(),
})

export const NewConversationFormSchema = z.object({
	ecosystemId: z.number(),
	verticalId: z.number(),
	personaId: z.number(),
	linkedinUrl: z.string(),
})
