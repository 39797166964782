import { type ReactNode } from 'react'
import { cn } from '#src/utils/misc'
import { Icon } from './ui/icon'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from './ui/tooltip'

export default function ProductTip({
	className,
	content,
}: {
	className?: string
	content: ReactNode
}) {
	return (
		<TooltipProvider>
			<Tooltip delayDuration={0}>
				<TooltipTrigger
					className={cn('inline-flex items-center justify-center', className)}
					tabIndex={-1}
					asChild
				>
					<span>
						<Icon name="information" size="sm" />
					</span>
				</TooltipTrigger>
				<TooltipContent side="top" align="center" className="max-w-[280px]">
					{content}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
