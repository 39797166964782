import { AgentState } from '@millisai/web-sdk'
import {
	type QueryClient,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { useState, useEffect, type ReactNode, useRef } from 'react'
import {
	Link,
	type LoaderFunctionArgs,
	Outlet,
	useFetcher,
	useFetchers,
	useLoaderData,
	useRevalidator,
} from 'react-router-dom'
import { z } from 'zod'
import CameraStream from '#src/components/camera-stream.js'
import { Chip } from '#src/components/chip.js'
import Markdown from '#src/components/markdown'
import { PersonaAvatar } from '#src/components/persona'
import { Button } from '#src/components/ui/button.js'
import { Icon } from '#src/components/ui/icon'
import { Logo } from '#src/components/ui/logo'
import {
	Toast,
	ToastDescription,
	ToastViewport,
} from '#src/components/ui/toast'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#src/components/ui/tooltip'
import {
	statusQuery,
	roleplayKeys,
	conversationQuery,
} from '#src/routes/enable/roleplay/queries'
import { userQuery } from '#src/routes/init/user/me'
import { checkIsReadOnlySession, cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import useVoiceChat, { CallState } from '#src/utils/use-voice-chat'
import { ConversationDataSchema } from './schema'

export type RoleplaySessionLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const RoleplaySessionLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		conversationId: z.string(),
		canRestartChat: z.boolean(),
		canDeleteChat: z.boolean().optional(),
	}),
	conversationData: ConversationDataSchema,
	conversationStatus: z.enum([
		'waiting_for_contact',
		'closed',
		'ready',
		'initializing',
		'crashed',
	]),
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId) {
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		}

		const user = await queryClient.fetchQuery(userQuery())
		const readOnlySession = checkIsReadOnlySession(user.roles)

		const conversationData = await queryClient.fetchQuery(
			conversationQuery(params.companyId, params.conversationId),
		)

		return {
			handle: {
				companyId: params.companyId,
				conversationId: params.conversationId,
				canRestartChat:
					['ready', 'crashed', 'closed'].includes(
						conversationData?.conversation.status ?? '',
					) && !readOnlySession,
				canDeleteChat: conversationData?.conversation.canDelete,
			},
			conversationData,
			conversationStatus: conversationData?.conversation.status,
			userEmail: user.email,
		}
	}

export default function RoleplaySession() {
	return (
		<main className="relative grid h-[calc(100%-var(--builder-header-height))] w-full grid-cols-[1fr,max-content]">
			<Conversation />

			<Outlet />
		</main>
	)
}

function Conversation() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])

	const { conversationData } = useLoaderData() as RoleplaySessionLoaderResponse

	if (!conversationData) {
		throw new Error('Missing conversationData')
	}

	const fetchers = useFetchers()
	const restartFetcher = fetchers.find(fetcher =>
		fetcher.formAction?.startsWith(
			routes.enable.roleplay.restart({
				companyId: params.companyId,
				conversationId: params.conversationId,
			}),
		),
	)
	const isRestarting = ['loading', 'submitting'].includes(
		restartFetcher?.state ?? '',
	)

	if (isRestarting) {
		return (
			<ChatLoader
				animate={true}
				message="Restarting..."
				className="h-full w-full gap-4 overflow-hidden"
			/>
		)
	}

	if (['crashed'].includes(conversationData?.conversation.status)) {
		return <ChatCrashed />
	}

	if (!['ready', 'closed'].includes(conversationData.conversation.status)) {
		return <ChatPending />
	}

	return <VoiceChat />
}

function VoiceChat() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const { conversationData, userEmail } =
		useLoaderData() as RoleplaySessionLoaderResponse

	const {
		transcript,
		toggleConversation,
		callState,
		agentState,
		error,
		analyzer,
	} = useVoiceChat(
		{
			company: params.companyId,
			chat: params.conversationId,
			user: userEmail,
		},
		conversationData.conversation.config?.voice,
	)

	const canRecord = conversationData?.writable

	return (
		<div className="grid h-full w-full grid-cols-1 grid-rows-[90px,1fr,90px] items-center justify-items-center overflow-hidden py-10">
			<section className="flex items-center justify-center self-start">
				<VoiceChatStatus callState={callState} />
			</section>
			<ChatResponse
				animate={
					callState === CallState.READY && agentState === AgentState.ANSWER
				}
				loading={agentState === AgentState.PREPARE_ANSWER}
				message={agentState === AgentState.PREPARE_ANSWER ? '' : transcript}
			/>
			<section className="z-0 flex flex-col items-center gap-2">
				{canRecord ? (
					<VoiceChatButton
						toggleConversation={() => {
							void toggleConversation()
						}}
						callState={callState}
					/>
				) : null}
				{!canRecord ? (
					<p className="text-center text-status-danger-fg">
						This chat is read-only
					</p>
				) : null}
				<Toast shouldOpen={Boolean(error)} duration={3000}>
					<ToastDescription className="flex items-center gap-3">
						<Icon
							name="error-filled"
							size="md"
							className="text-status-danger-fg"
						/>
						{error}
					</ToastDescription>
				</Toast>
				<div className="absolute">
					<ToastViewport />
				</div>
			</section>

			{callState === CallState.READY ? (
				<CameraStream
					className="absolute bottom-0 right-0 m-8"
					analyzer={analyzer}
				/>
			) : null}
		</div>
	)
}

function ChatResponse({
	message,
	animate,
	loading,
}: {
	message: string
	animate?: boolean
	loading?: boolean
}) {
	const { conversationData } = useLoaderData() as RoleplaySessionLoaderResponse

	return (
		<section
			className={cn(
				'grid h-full grid-cols-1 grid-rows-2 items-end justify-items-center gap-8',
			)}
		>
			<ChatAvatar
				animate={Boolean(animate)}
				icon={
					conversationData?.participant.persona.type ? (
						<div className="relative row-span-2">
							<PersonaAvatar
								type={conversationData.participant.persona.type}
								size="lg"
							/>
							<Icon
								name="spinner-lg"
								className={cn(
									'absolute left-0 top-0 h-full w-full animate-spin transition-opacity',
									loading ? 'opacity-1' : 'opacity-0',
								)}
							/>
						</div>
					) : null
				}
			/>
			<Markdown
				className={cn(
					'relative z-0 flex max-h-32 max-w-screen-lg flex-col items-center justify-end gap-2 self-start overflow-hidden px-6',
					'text-center text-title-lg text-neutral-1-fg',
				)}
			>
				{message}
			</Markdown>
		</section>
	)
}

function useElapsedTime({ start }: { start: boolean }) {
	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
	const [elapsedTime, setElapsedTime] = useState<number>(0)

	useEffect(() => {
		const interval = intervalRef.current

		if (!start) {
			if (interval) {
				clearInterval(interval)
				intervalRef.current = null
			}

			return
		}

		intervalRef.current = setInterval(() => {
			setElapsedTime(prevElapsedTime => prevElapsedTime + 1)
		}, 1000)

		return () => {
			if (interval) {
				clearInterval(interval)
				intervalRef.current = null
			}
		}
	}, [start])

	const minutes = Math.floor(elapsedTime / 60)
	const seconds = elapsedTime % 60

	return `${minutes}:${`${seconds}`.padStart(2, '0')}`
}

function EndSession() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])

	return (
		<TooltipProvider>
			<Tooltip delayDuration={0}>
				<TooltipTrigger
					className="inline-flex items-center justify-center"
					asChild
				>
					<Link
						className="flex items-center justify-center gap-1"
						to={routes.enable.roleplay.end({
							companyId: params.companyId,
							conversationId: params.conversationId,
						})}
					>
						<Icon
							size="sm"
							name="stop-filled-alt"
							className={cn('transition-all', 'text-status-danger-fg')}
						/>
					</Link>
				</TooltipTrigger>
				<TooltipContent side="bottom" align="center" className="max-w-[280px]">
					End
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

function VoiceChatStatus({ callState }: { callState: CallState }) {
	const text = () => {
		switch (callState) {
			case CallState.READY:
				return 'In session'
			case CallState.CONNECTING:
				return 'Connecting...'
			case CallState.IDLE:
			default:
				return 'Paused'
		}
	}
	const chipVariant = () => {
		switch (callState) {
			case CallState.READY:
				return 'green'
			case CallState.CONNECTING:
				return 'orange'
			case CallState.IDLE:
			default:
				return 'red'
		}
	}

	return (
		<Chip className="py-1 pl-1 pr-2" variant={chipVariant()}>
			<Icon
				name="circle-fill"
				size="sm"
				className={cn(
					'flex flex-nowrap items-center transition-colors',
					callState === CallState.READY ? 'text-green-70' : '',
					callState === CallState.CONNECTING ? 'text-orange-70' : '',
					callState === CallState.IDLE ? 'text-red-60' : '',
				)}
			/>
			<p>{text()}</p>
		</Chip>
	)
}

function VoiceChatButton({
	callState,
	toggleConversation,
}: {
	callState: CallState
	toggleConversation: () => void
}) {
	const [seen, setSeen] = useState<boolean>(false)
	const elapsedTime = useElapsedTime({ start: callState === CallState.READY })

	const icon = () => {
		switch (callState) {
			case CallState.READY:
				return 'pause-filled'
			case CallState.IDLE:
				return 'play-filled-alt'
			case CallState.CONNECTING:
				return 'loading-sm'
			default:
				throw new Error('Unhandled icon name')
		}
	}

	const tooltipText = () => {
		switch (callState) {
			case CallState.READY:
				return 'Pause'
			case CallState.IDLE:
				return 'Continue'
			case CallState.CONNECTING:
			default:
				return 'Connecting...'
		}
	}

	useEffect(() => {
		if (seen) return
		if (callState === CallState.CONNECTING) setSeen(true)
	}, [callState, seen])

	return (
		<div className="flex flex-col items-center justify-center gap-2">
			<p
				className={cn(
					'pointer-events-none text-label-md text-neutral-2-fg',
					seen ? 'invisible' : '',
				)}
			>
				Click “Play” to begin
			</p>
			<div
				className={cn(
					'flex items-center gap-2.5 rounded-xl px-6 py-4',
					'border border-neutral-2-bd bg-transparent',
				)}
			>
				<span className="min-w-12 p-1 text-left text-neutral-2-fg">
					{elapsedTime}
				</span>
				<TooltipProvider>
					<Tooltip delayDuration={0}>
						<TooltipTrigger
							className="inline-flex items-center justify-center"
							asChild
						>
							<button
								type="button"
								className="flex items-center justify-center"
								onClick={() => void toggleConversation()}
							>
								<Icon
									size="sm"
									name={icon()}
									className={cn(
										'text-neutral-1-fg',
										callState === CallState.CONNECTING ? 'animate-spin' : '',
									)}
								/>
							</button>
						</TooltipTrigger>
						<TooltipContent
							side="bottom"
							align="center"
							className="max-w-[280px]"
						>
							{tooltipText()}
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>

				<EndSession />
			</div>
		</div>
	)
}

function usePendingConversationRevalidator() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const revalidator = useRevalidator()
	const queryClient = useQueryClient()

	const { data: conversationStatus } = useQuery({
		...statusQuery(params.companyId, params.conversationId),
		refetchInterval: ({ state: { data } }) => {
			if (!data || data.status !== 'ready') {
				return 15 * 1000 // 15s
			} else return false
		},
		refetchIntervalInBackground: true,
	})

	useEffect(() => {
		const revalidate = async () => {
			await queryClient.invalidateQueries({
				queryKey: roleplayKeys.all,
			})

			revalidator.revalidate()
		}
		if (['ready', 'crashed'].includes(conversationStatus?.status ?? '')) {
			void revalidate()
		}
	}, [conversationStatus?.status, revalidator, queryClient])

	return conversationStatus?.status
}

function ChatPending() {
	usePendingConversationRevalidator()

	return (
		<ChatLoader
			animate={true}
			message="Initializing..."
			subMessage="It might take up to 3 minutes or more"
			className="h-full w-full gap-4 overflow-hidden"
		/>
	)
}

export function ChatLoader({
	animate,
	icon,
	message,
	messageClassName,
	subMessage,
	className,
}: {
	animate: boolean
	icon?: ReactNode
	message: string
	messageClassName?: string
	subMessage?: string
	className?: string
}) {
	return (
		<section
			className={cn(
				'flex flex-col items-center justify-center gap-6',
				className,
			)}
		>
			<ChatAvatar animate={animate} icon={icon} />
			<div className="relative z-0 flex max-w-screen-lg flex-col items-center justify-center gap-2 px-6">
				<Markdown
					className={cn(
						'text-center text-title-lg text-neutral-1-fg',
						messageClassName,
					)}
				>
					{message}
				</Markdown>
				{subMessage ? (
					<p className="z-0 text-label-sm text-neutral-3-fg">{subMessage}</p>
				) : null}
			</div>
		</section>
	)
}

function ChatAvatar({ animate, icon }: { animate: boolean; icon?: ReactNode }) {
	return (
		<div className="relative z-0 w-max">
			<div
				className={cn(
					'pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center opacity-0',
					animate && 'animate-pulsing-rings-1',
				)}
			>
				<div className="min-h-[670px] w-[42.5%] min-w-[670px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.06]" />
			</div>
			<div
				className={cn(
					'pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center opacity-0',
					animate && 'animate-pulsing-rings-2',
				)}
			>
				<div className="min-h-[500px] w-[32.5%] min-w-[500px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.09]" />
			</div>
			<div
				className={cn(
					'pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center opacity-0',
					animate && 'animate-pulsing-rings-3',
				)}
			>
				<div className="min-h-[330px] w-[22.5%] min-w-[330px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-[0.14]" />
			</div>
			<div
				className={cn(
					'pointer-events-none absolute inset-0 bottom-[-500%] flex select-none flex-col items-center justify-center',
				)}
			>
				<div className="min-h-[300px] w-[32.5%] min-w-[600px] rounded-[300px_300px_0_0] bg-[radial-gradient(circle,rgba(255,255,255,1)_0%,rgba(255,255,255,1)_30%,rgba(255,255,255,0)_75%)]" />
			</div>
			{icon ? icon : <Logo size="xl" type="symbol" />}
		</div>
	)
}

function ChatCrashed() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const {
		handle: { canRestartChat },
	} = useLoaderData() as RoleplaySessionLoaderResponse
	const fetcher = useFetcher({ key: 'roleplay-restart' })
	const action = routes.enable.roleplay.restart({
		companyId: params.companyId,
		conversationId: params.conversationId,
	})

	return (
		<div className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden">
			<Logo size="xl" type="symbol" />
			<h1 className="text-heading-sm text-neutral-1-fg">Ouch!</h1>
			<section className="flex flex-col items-center justify-center gap-1 text-center">
				<p className="text-title-sm text-neutral-1-fg">
					There was an issue with the roleplay.
				</p>
				<p className="text-body-sm font-normal text-neutral-1-fg">
					Our team is aware and working to fix the issue — apologies for any
					inconvenience.
				</p>
				{canRestartChat && (
					<fetcher.Form action={action} method="PUT">
						<Button
							className="mt-4 flex gap-1"
							type="submit"
							disabled={fetcher.state !== 'idle'}
						>
							<Icon name="reset" />
							Restart roleplay
						</Button>
					</fetcher.Form>
				)}
			</section>
		</div>
	)
}
