import * as DialogPrimitive from '@radix-ui/react-dialog'
import { type ReactNode } from 'react'
import { useFetcher } from 'react-router-dom'
import { Dialog, DialogTrigger } from '#src/components/ui/dialog'
import { Button } from './ui/button'
import { StatusButton } from './ui/status-button'

/**
 * IMPORTANT: This will implicitly close the modal after the action is done
 * because it is expected that this component is rendered within the context
 * of the deleted entity which will be removed from the UI after the action.
 */
export default function DeleteConfirm({
	trigger,
	action,
	title,
	description,
}: {
	trigger: ReactNode
	action: string
	title: ReactNode
	description: ReactNode
}) {
	return (
		<Dialog
			trigger={<DialogTrigger asChild>{trigger}</DialogTrigger>}
			content={
				<DeleteConfirmContent title={title} description={description}>
					<DeleteConfirmFooter action={action} />
				</DeleteConfirmContent>
			}
		/>
	)
}

export function DeleteConfirmContent({
	title,
	description,
	children,
}: {
	title: ReactNode
	description: ReactNode
	children: ReactNode
}) {
	return (
		<div className="flex w-full max-w-xl flex-col gap-4">
			<h1 className="text-title-lg text-neutral-1-fg">{title}</h1>
			<p className="text-body-lg text-neutral-2-fg">{description}</p>
			{children}
		</div>
	)
}

export function DeleteConfirmFooter({ action }: { action: string }) {
	const fetcher = useFetcher()

	return (
		<div className="flex w-full items-center justify-end gap-2">
			<DialogPrimitive.Close asChild>
				<Button type="button" variant="outline">
					Cancel
				</Button>
			</DialogPrimitive.Close>
			<fetcher.Form action={action} method="DELETE">
				<StatusButton
					status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
					type="submit"
					variant="danger"
					disabled={fetcher.state !== 'idle'}
				>
					Remove
				</StatusButton>
			</fetcher.Form>
		</div>
	)
}
