import { Icon, type IconName } from '#src/components/ui/icon'

export function TrainingScenario({
	title,
	icon,
}: {
	title: string
	icon: IconName
}) {
	return (
		<div className="flex items-center gap-2">
			<div className="flex h-8 w-8 items-center justify-center rounded border border-neutral-1-bd bg-neutral-3-bg text-brand-1-fg">
				<Icon name={icon} size="sm" />
			</div>
			<p className="text-body-md font-semibold">{title}</p>
		</div>
	)
}
