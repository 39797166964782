import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import PrioritizeLayout from '#src/routes/prioritize/_layout'
import { action as assignSignalAction } from '#src/routes/prioritize/assign'
import { action as signalUsageExampleAction } from '#src/routes/prioritize/example'
import { action as enrichFilterAction } from '#src/routes/prioritize/filters'
import { action as saveSignalAction } from '#src/routes/prioritize/save'
import Signals, { loader as enrichLoader } from '#src/routes/prioritize/signals'
import SignalsLayout from '#src/routes/prioritize/signals/_layout'
import SignalDetailsLayout from '#src/routes/prioritize/signals/_layout/details'
import SignalDetails, {
	type EnrichDetailLoaderResponse,
	loader as enrichDetailsLoader,
	SignalDetailsLoaderResponseSchema,
} from '#src/routes/prioritize/signals/details'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes.js'

export default (client: QueryClient) =>
	({
		element: <PrioritizeLayout />,
		children: [
			{
				element: <SignalsLayout />,
				children: [
					{
						path: '/prioritize/:companyId',
						element: <Signals />,
						errorElement: <RouteBoundary />,
						loader: enrichLoader(client),
						children: [
							{
								path: 'filters',
								action: enrichFilterAction(client),
							},
						],
					},
				],
			},
			{
				element: <SignalDetailsLayout />,
				children: [
					{
						path: '/prioritize/:companyId/details/:signalId',
						element: <SignalDetails />,
						errorElement: <RouteBoundary />,
						loader: enrichDetailsLoader(client),
						handle: {
							crumb: (data: EnrichDetailLoaderResponse) => {
								const parsedRouteHandleData =
									SignalDetailsLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData
								return [
									{
										to: `/prioritize/${handle.companyId}`,
										label: 'Signals',
										icon: 'upstream',
									},
									handle.signalTemplate?.id
										? {
												to: routes.prioritize.signal.index({
													companyId: handle.companyId,
													signalId: handle.signalTemplate.id.toString(),
												}),
												label: handle.signalTemplate.name,
											}
										: undefined,
								]
							},
						},
						children: [
							{
								path: 'save',
								action: saveSignalAction(client),
							},
							{
								path: 'assign',
								action: assignSignalAction(client),
							},
							{
								path: 'example/:personaId',
								action: signalUsageExampleAction(client),
							},
						],
					},
				],
			},
		],
	}) satisfies RouteObject
