import { z } from 'zod'

export enum MessageStatus {
	Sending = 'sending',
	Error = 'error',
	Generating = 'generating',
}

export enum PersonalityTrait {
	Analytical = 'analytical',
	Amiable = 'amiable',
	Expressive = 'expressive',
	Driver = 'driver',
}
export enum EmotionalState {
	Skeptical = 'skeptical',
	Supportive = 'supportive',
	Busy = 'busy',
	Indecisive = 'indecisive',
}
export enum CommunicationStyle {
	Direct = 'direct',
	Indirect = 'indirect',
	Formal = 'formal',
	Informal = 'informal',
}
export enum DecisionMakingProcess {
	Collaborative = 'collaborative',
	Autonomous = 'autonomous',
	Hierarchical = 'hierarchical',
}

export const ConversationConfigSchema = z.object({
	personalityTrait: z.enum([
		PersonalityTrait.Analytical,
		PersonalityTrait.Amiable,
		PersonalityTrait.Expressive,
		PersonalityTrait.Driver,
	]),
	emotionalState: z.enum([
		EmotionalState.Skeptical,
		EmotionalState.Supportive,
		EmotionalState.Busy,
		EmotionalState.Indecisive,
	]),
	communicationStyle: z.enum([
		CommunicationStyle.Direct,
		CommunicationStyle.Indirect,
		CommunicationStyle.Formal,
		CommunicationStyle.Informal,
	]),
	decisionMakingProcess: z.enum([
		DecisionMakingProcess.Collaborative,
		DecisionMakingProcess.Autonomous,
		DecisionMakingProcess.Hierarchical,
	]),
	voice: z.string(),
})

export const ConversationSchema = z.object({
	id: z.string(),
	name: z.string(),
	user: z.string(),
	createdAt: z.string(),
	canDelete: z.boolean().optional(),
	type: z.string(),
	status: z.enum([
		'waiting_for_contact',
		'closed',
		'ready',
		'initializing',
		'crashed',
	]),
	lastMessage: z.string().nullable(),
	evaluation: z
		.object({
			status: z.string(),
			value: z.string().nullable(),
		})
		.nullable(),
	config: ConversationConfigSchema.optional().nullable(),
	title: z.string().optional().nullable(),
	scenario: z.string().optional().nullable(),
})

export const ConversationDataSchema = z.object({
	conversation: ConversationSchema,
	participant: z.object({
		info: z.object({ title: z.string().optional().nullable() }),
		persona: z.object({ type: z.string().optional().nullable() }),
	}),
	writable: z.boolean(),
})

export const MessageSchema = z.object({
	message: z.string(),
})

export const ChatMessageSchema = z
	.object({
		id: z.number().or(z.string()),
		author: z.string().optional(),
		isAi: z.boolean(),
		createdAt: z.string().optional(),
		status: z
			.enum([
				MessageStatus.Sending,
				MessageStatus.Error,
				MessageStatus.Generating,
			])
			.optional(),
	})
	.merge(MessageSchema)

export const ChatSchema = ChatMessageSchema.array()

export const ChatsSchema = z
	.object({
		conversation: ConversationSchema,
		participant: z.object({
			info: z.object({ title: z.string().optional().nullable() }),
			persona: z.object({ type: z.string().optional().nullable() }),
		}),
	})
	.array()

export const ScenarioSchema = z.object({
	name: z.string(),
	scenario: z.string().optional(),
	evaluation: z.string().optional(),
})

export const ScenariosSchema = ScenarioSchema.array()

export const VoiceSchema = z.object({
	voiceId: z.string(),
	name: z.string(),
})

export const VoicesSchema = VoiceSchema.array()

export const UsersSchema = z
	.object({
		id: z.number(),
		username: z.string(),
	})
	.array()

export const StatusSchema = z.object({
	status: z.enum([
		'waiting_for_contact',
		'closed',
		'ready',
		'initializing',
		'crashed',
	]),
})

export const NewConversationAPISchema = z.object({
	conversationId: z.string(),
	company: z.string(),
})

export const NewConversationFormSchema = z.object({
	title: z.string().optional(),
	ecosystemId: z.number(),
	verticalId: z.number(),
	personaId: z.number(),
	linkedinUrl: z.string().optional().nullable(),
	config: ConversationConfigSchema,
	scenario: ScenarioSchema,
	count: z
		.string()
		.refine(value => !Number.isNaN(Number(value)), {
			message: 'Number of duplicates must be a number',
		})
		.refine(value => Number(value) > 0, {
			message: 'Number of duplicates must be greater than 0',
		})
		.refine(value => Number(value) <= 10, {
			message: 'Number of duplicates must be less than or equal to 10',
		})
		.optional(),
})

export const NewSoloConversationFormAPISchema = NewConversationFormSchema.pick({
	title: true,
	personaId: true,
	linkedinUrl: true,
	config: true,
}).merge(
	z.object({
		scenarioTitle: z.string().optional(),
		scenario: z.string().optional(),
		evaluation: z.string().optional(),
	}),
)
export const NewGroupConversationFormAPISchema = NewConversationFormSchema.pick(
	{
		title: true,
		personaId: true,
		linkedinUrl: true,
		config: true,
	},
).merge(
	z.object({
		scenarioTitle: z.string().optional(),
		scenario: z.string().optional(),
		evaluation: z.string().optional(),
		count: z.number(),
	}),
)
