import { useState, type ReactNode } from 'react'
import { cn } from '#src/utils/misc'
import { Icon } from './ui/icon'

type TableCell = {
	children: ReactNode
	static?: boolean
	className?: string
	onClick?: () => void
}

type TableRow = {
	focus?: boolean
	columns: TableCell[]
}

type TableHead = {
	options?: {
		sticky?: boolean
	}
	columns: TableCell[]
}

type TableCollapsible = {
	options?: {
		sticky?: boolean
		collapsible?: boolean
		defaultCollapsed?: boolean
	}
	columns: TableCell[]
}

type TableBody = {
	rows: TableRow[]
}

type TableData = {
	head?: TableHead
	collapsible?: TableCollapsible
	body?: TableBody
}

type TableDataGroup = {
	head?: TableHead
	bodyGroups: TableData[]
}

const Row = ({
	className,
	children,
}: {
	className?: string
	children: ReactNode
}) => <tr className={className}>{children}</tr>

const Cell = ({
	className,
	children,
	onClick,
}: {
	className?: string
	children?: ReactNode
	onClick?: () => void
}) => (
	<td
		className={cn(
			'h-full w-[var(--table-col-width)]',
			className,
			onClick ? 'cursor-pointer' : '',
		)}
		onClick={onClick}
	>
		{children}
	</td>
)

function TableHeaderGroup({ head }: { head: TableHead }) {
	const { options, columns } = head

	return (
		<tbody>
			<Row>
				{columns.map((cell, i) => {
					return (
						<Cell
							key={i}
							onClick={cell.onClick}
							className={cn(
								'bg-neutral-1-bg',
								'border-r border-neutral-1-bd last:border-r-transparent',
								'p-2',
								options?.sticky
									? 'sticky top-[var(--builder-header-height)] z-[1] h-[var(--table-header-height)]'
									: '',
								i === 0 ? 'sticky left-0' : '',
								i === 0 && options?.sticky ? 'z-[2]' : '',
								i === 0 ? 'w-[var(--table-sidebar-width)]' : '',
								cell.className,
							)}
						>
							{cell.children}
						</Cell>
					)
				})}
			</Row>
		</tbody>
	)
}

function TableCollapsibleGroup({
	collapsible,
	open,
	onToggle,
}: {
	collapsible: TableCollapsible
	open: boolean
	onToggle: (open: boolean) => void
}) {
	const { options, columns } = collapsible

	return (
		<tbody>
			<Row>
				{columns.map((cell, i) => {
					return (
						<Cell
							key={i}
							onClick={cell.onClick}
							className={cn(
								'bg-neutral-2-bg',
								'px-4 py-2',
								'border-b border-r border-t border-neutral-1-bd last:border-r-transparent',
								options?.sticky
									? 'sticky top-[calc(var(--table-header-height)+var(--builder-header-height))] z-[1]'
									: '',
								i === 0 ? 'sticky left-0' : '',
								i === 0 && options?.sticky ? 'z-[2]' : '',
								i === 0 ? 'w-[var(--table-sidebar-width)]' : '',
								// NOTE: aligning under chevron
								i === 0 && !options?.collapsible ? 'pl-10' : '',
								cell.className,
							)}
						>
							{i === 0 && options?.collapsible ? (
								<div className="flex h-full w-full items-center gap-2">
									<button
										type="button"
										className="flex items-center justify-center"
										onClick={() => onToggle(!open)}
									>
										<Icon
											name="chevron-down"
											size="sm"
											className={cn(open ? 'rotate-180' : '')}
										/>
									</button>
									{cell.children}
								</div>
							) : (
								cell.children
							)}
						</Cell>
					)
				})}
			</Row>
		</tbody>
	)
}

function TableBodyGroup({ body, open }: { body: TableBody; open: boolean }) {
	return (
		<tbody className={cn(open ? '' : 'hidden')}>
			{body.rows.map((row, i) => (
				<Row key={i} className="group">
					{row.columns.map((cell, j) => {
						const { focus } = row
						return (
							<Cell
								key={j}
								onClick={cell.onClick}
								className={cn(
									'transition-colors',
									'border-b border-r border-neutral-1-bd last:border-r-transparent group-last:border-b-transparent',
									'px-4 py-2',
									j === 0 ? 'sticky left-0' : '',
									// NOTE: aligning under chevron
									j === 0 ? 'w-[var(--table-sidebar-width)] pl-10' : '',
									cell.className,
									!cell.static
										? focus
											? // NOTE: bg colors with alpha channel don't work nicely with sticky elements
												'group-hover:bg-[#e2e2e2]'
											: 'group-hover:bg-[#f1f1f1]'
										: '',
									focus ? 'bg-[#e8e8e8]' : 'bg-[#ffffff]',
								)}
							>
								{cell.children}
							</Cell>
						)
					})}
				</Row>
			))}
		</tbody>
	)
}

function TableGroup({ group }: { group: Omit<TableData, 'head'> }) {
	const { collapsible, body } = group
	const [open, setOpen] = useState(
		!collapsible?.options?.defaultCollapsed ?? false,
	)

	return (
		<>
			{collapsible ? (
				<TableCollapsibleGroup
					collapsible={collapsible}
					open={open}
					onToggle={setOpen}
				/>
			) : null}
			{body ? <TableBodyGroup body={body} open={open} /> : null}
		</>
	)
}

/**
 * Example TableData:
 * 
 * ```
 * {
    head: {
      options: { sticky: true },
      columns: [
        { children: 'Group 3, Header, Column 1' },
        { children: 'Group 3, Header, Column 2' },
        { children: 'Group 3, Header, Column 3' },
        { children: 'Group 3, Header, Column 4' },
        { children: 'Group 3, Header, Column 5' },
        { children: 'Group 3, Header, Column 6' },
      ],
    },
    bodyGroups: [
			{
				collapsible: {
					options: { sticky: true, collapsible: true, defaultCollapsed: true },
					columns: [
						{ children: 'Group 3, Header, Column 1' },
						{ children: 'Group 3, Header, Column 2' },
						{ children: 'Group 3, Header, Column 3' },
						{ children: 'Group 3, Header, Column 4' },
						{ children: 'Group 3, Header, Column 5' },
						{ children: 'Group 3, Header, Column 6' },
					],
				},
				body: {
					rows: [
						{
							columns: [
								{ children: 'Group 3, Row 1, Column 1' },
								{ children: 'Group 3, Row 1, Column 2' },
								{ children: 'Group 3, Row 1, Column 3' },
								{ children: 'Group 3, Row 1, Column 4' },
								{ children: 'Group 3, Row 1, Column 5' },
								{ children: 'Group 3, Row 1, Column 6' },
							],
						},
					],
				},
			}
		]
  },
 * ```
 * 
 * 
 * @param param0 
 * @returns 
 */
export default function TableList({ data }: { data: TableDataGroup }) {
	const { head, bodyGroups } = data

	return (
		<table className="w-full table-fixed border-separate border-spacing-0 border-b border-neutral-1-bd">
			{head ? <TableHeaderGroup head={head} /> : null}
			{bodyGroups.map((group, index) => (
				<TableGroup key={index} group={group} />
			))}
		</table>
	)
}
