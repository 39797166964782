import {
	NavLink,
	matchPath,
	useLocation,
	useRouteLoaderData,
} from 'react-router-dom'
import ContactAvatar, {
	avatarVariantByPersonaType,
} from '#src/components/chat/avatar'
import { Icon } from '#src/components/ui/icon'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#src/components/ui/tooltip'
import { type AidtLoaderResponse } from '#src/routes/organize/aidt'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

type ChatNavPersona = {
	id: string
	to: string
	name: string
	lastMessage: string
	type: string
	status: string
	updatedAt: string
}

export default function ChatsPanel() {
	const params = useParsedRouteParams(['companyId'])
	const { chats } = useRouteLoaderData('aidt-loader') as AidtLoaderResponse

	const nav = chats.map(chat => ({
		id: chat.conversation.id,
		to: routes.organize.aidt.index({
			companyId: params.companyId,
			conversationId: chat.conversation.id,
		}),
		name: chat.conversation.name,
		lastMessage: chat.lastMessage ?? '',
		type: chat.conversation.type ?? '',
		status: chat.conversation.status ?? '',
		updatedAt: chat.conversation.lastMessage ?? chat.conversation.createdAt,
	})) satisfies ChatNavPersona[]

	return (
		<section className="flex h-full flex-col px-2">
			<div className="flex flex-col gap-1">
				{nav.map(chat => (
					<ChatHeadingWithRole key={chat.id} {...chat} />
				))}
			</div>
		</section>
	)
}

function ChatHeadingWithRole({
	to,
	name,
	type,
	lastMessage,
	status,
}: ChatNavPersona) {
	const location = useLocation()
	const isActive = !!matchPath(location.pathname, to)

	return (
		<div
			className={cn(
				'group flex items-center gap-2 rounded bg-transparent p-2 transition-colors hover:bg-neutral-1-bg-hover active:bg-neutral-2-bg-pressed',
				isActive
					? 'bg-neutral-2-bg-selected hover:bg-neutral-1-bg-selected-hover'
					: 'text-neutral-2-fg',
			)}
		>
			<NavLink to={to} className="flex grow items-center gap-2 truncate">
				<ContactAvatar
					className="inline-flex shrink-0 items-center justify-center rounded-full"
					variant={avatarVariantByPersonaType(type?.toLowerCase())}
					size="md"
					initial={name.slice(0, 1)}
					badge={
						(status === 'closed' && (
							<Icon
								name="checkmark-filled-bordered"
								className="absolute bottom-0 right-0 h-3 w-3 text-status-success-bg"
							/>
						)) ||
						(status === 'unread' && (
							<div className="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-brand-1-fg" />
						))
					}
				/>
				<div className="flex flex-col gap-0.5 overflow-hidden">
					<p
						className={cn(
							'truncate text-body-md font-semibold text-neutral-1-fg',
							status === 'closed' && 'text-neutral-3-fg',
						)}
					>
						{name}
					</p>
					<TooltipProvider>
						<Tooltip delayDuration={0}>
							<TooltipTrigger>
								<p
									className={cn(
										'text-left text-body-sm',
										status === 'closed' && 'text-neutral-3-fg',
										status === 'ready' && 'text-neutral-2-fg',
										isActive
											? status === 'closed'
												? 'text-neutral-3-fg'
												: 'text-neutral-2-fg'
											: '',
									)}
								>
									{lastMessage}
								</p>
							</TooltipTrigger>
							<TooltipContent
								side="bottom"
								align="start"
								sideOffset={0}
								className="max-w-60"
							>
								{lastMessage}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</NavLink>
		</div>
	)
}
