import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { createSalesCoach } from './mutations'
import { SalesCoachListAPISchema, SalesCoachListSchema } from './schema'

export const salesCoachKeys = {
	all: ['salescoach'] as const,
	list: (companyId: string, personaId: string) =>
		[...salesCoachKeys.all, 'list', companyId, personaId] as const,
}

export const fetchSalesCoach = async (companyId: string, personaId: string) => {
	const result = await safeFetch(
		SalesCoachListAPISchema,
		api.salescoach.list(companyId, personaId),
		{
			method: 'GET',
		},
	)

	if (!result?.length) return null

	const calibrated = result.find(sc => !sc.month)
	const timing = result.find(sc => sc.month && sc.selected)

	return SalesCoachListSchema.parse({
		calibrated,
		timing,
		// NOTE: this is used to apply adjustments both for calibrated and timing when updating
		adjustments: timing?.adjustments ?? calibrated?.adjustments ?? null,
		rest: result.filter(sc => sc.id !== calibrated?.id && sc.id !== timing?.id),
	})
}

export const salesCoachQuery = (companyId: string, personaId: string) => ({
	queryKey: salesCoachKeys.list(companyId, personaId),
	queryFn: async () => {
		const result = await fetchSalesCoach(companyId, personaId)

		if (result?.calibrated) return result

		// NOTE: this creates calibrated sales coach if none exists
		await createSalesCoach(companyId, personaId, {
			questionCount: 3,
			wordsMin: 10,
			wordsMax: 50,
		})

		return await fetchSalesCoach(companyId, personaId)
	},
})
