import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import OrganizeLayout from '#src/routes/organize/_layout'
import Aidt, {
	loader as aidtLoader,
	AidtLoaderResponseSchema,
	type AidtLoaderResponse,
} from '#src/routes/organize/aidt'
import AidtLayout from '#src/routes/organize/aidt/_layout'
import OrganizeDashboard, {
	loader as organizeDashboardLoader,
} from '#src/routes/organize/dashboard'
import OrganizeDashboardLayout from '#src/routes/organize/dashboard/_layout'
import RouteBoundary from '#src/routes/route-boundary'
import { routes } from '#src/utils/routes'

export default (client: QueryClient) =>
	({
		path: '/organize/:companyId',
		element: <OrganizeLayout />,
		children: [
			{
				element: <OrganizeDashboardLayout />,
				children: [
					{
						index: true,
						element: <OrganizeDashboard />,
						errorElement: <RouteBoundary />,
						loader: organizeDashboardLoader(client),
					},
				],
			},
			{
				element: <AidtLayout />,
				children: [
					{
						id: 'aidt-loader',
						path: 'aidt/:conversationId?',
						element: <Aidt />,
						errorElement: <RouteBoundary />,
						loader: aidtLoader(client),
						handle: {
							crumb: (data: AidtLoaderResponse) => {
								const parsedRouteHandleData =
									AidtLoaderResponseSchema.safeParse(data)
								if (!parsedRouteHandleData.success) return
								const {
									data: { handle },
								} = parsedRouteHandleData

								return [
									{
										to: routes.organize.index({ companyId: handle.companyId }),
										label: 'Organize',
										icon: 'data-unreal',
									},
									{
										to: routes.organize.aidt.index({
											companyId: handle.companyId,
											conversationId: null,
										}),
										label: 'Lead Research Agent questions',
										icon: 'machine-learning-model',
									},
									...(handle.conversationId && handle.conversationData
										? [
												{
													to: routes.organize.aidt.index({
														companyId: handle.companyId,
														conversationId: handle.conversationId,
													}),
													label: handle.conversationData.conversation.name,
													icon: 'chat',
												},
											]
										: []),
								]
							},
						},
					},
				],
			},
		],
	}) satisfies RouteObject
