import { type QueryClient } from '@tanstack/react-query'
import {
	Link,
	Outlet,
	useLoaderData,
	type LoaderFunctionArgs,
} from 'react-router-dom'
import { z } from 'zod'
import AiGenerated from '#src/components/ai-generated'
import NoTerms from '#src/components/no-terms'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { DeleteTermAsyncForm } from '#src/routes/enable/glossary/delete'
import { userQuery } from '#src/routes/init/user/me'
import {
	checkIsEnableOnlySession,
	checkIsReadOnlySession,
} from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { glossaryQuery } from './queries'
import { GlossaryTermListAPISchema } from './schema'

export type GlossaryLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const GlossaryLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		readOnlySession: z.boolean(),
		enableOnlySession: z.boolean(),
	}),
	glossary: GlossaryTermListAPISchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const glossary = await queryClient.fetchQuery(
			glossaryQuery(params.companyId),
		)

		return {
			handle: {
				companyId: params.companyId,
				readOnlySession: checkIsReadOnlySession(user.roles),
				enableOnlySession: checkIsEnableOnlySession(user.roles),
			},
			glossary,
		}
	}

export default function Glossary() {
	const params = useParsedRouteParams(['companyId'])
	const { glossary } = useLoaderData() as GlossaryLoaderResponse

	if (!glossary?.length) {
		return (
			<main className="flex w-full flex-grow flex-col items-center justify-center px-20">
				<NoTerms
					companyId={params.companyId}
					className="w-full max-w-screen-lg"
				>
					<h1 className="text-center text-body-lg text-neutral-2-fg">
						You don&apos;t have any Industry-jargons
					</h1>
				</NoTerms>

				<Outlet />
			</main>
		)
	}

	return (
		<main className="grid h-full w-full grid-cols-[1fr,max-content] gap-4 p-20">
			<div className="h-full max-w-screen-lg justify-self-center pb-20">
				<GlossaryList />
			</div>
			<Outlet />
		</main>
	)
}

function GlossaryList() {
	const params = useParsedRouteParams(['companyId'])
	const {
		glossary,
		handle: { readOnlySession },
	} = useLoaderData() as GlossaryLoaderResponse

	return (
		<ul className="space-y-4">
			{glossary.map(term => (
				<Surface as="li" key={term.id} className="flex flex-col gap-6">
					<section className="flex flex-col gap-2">
						<h2 className="flex items-center justify-between text-title-md text-neutral-1-fg">
							{term.term}
							{!readOnlySession ? (
								<div className="flex items-center gap-4 justify-self-end text-neutral-3-fg">
									<Link
										to={routes.enable.glossary.edit({
											companyId: params.companyId,
											termId: term.id.toString(),
										})}
										className="flex items-center justify-center"
									>
										<Icon name="edit" size="font" />
									</Link>
									<DeleteTermAsyncForm termId={term.id} />
								</div>
							) : null}
						</h2>
						<p className="flex items-start gap-2 text-body-sm text-neutral-2-fg">
							<AiGenerated />
							{term.definition}
						</p>
					</section>

					<section className="flex flex-col gap-2">
						<h3 className="text-label-md text-neutral-3-fg">Ecosystem</h3>
						<p className="text-body-sm">{term.ecosystem.name}</p>
					</section>

					{term.manualInput ? (
						<section className="flex flex-col gap-2">
							<h3 className="text-label-md text-neutral-3-fg">Manual input</h3>
							<p className="text-body-sm">{term.manualInput}</p>
						</section>
					) : (
						''
					)}
				</Surface>
			))}
		</ul>
	)
}
