import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { fetch, safeFetch } from '#src/utils/safeFetch'
import {
	ConversationDataSchema,
	NewConversationAPISchema,
	type NewSoloConversationFormAPISchema,
	type NewGroupConversationFormAPISchema,
} from './schema'

export const createChatForSolo = async (
	companyId: string,
	data: z.infer<typeof NewSoloConversationFormAPISchema>,
) => {
	return await safeFetch(
		NewConversationAPISchema,
		api.roleplay.new(companyId),
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
	)
}
export const createChatForGroup = async (
	companyId: string,
	data: z.infer<typeof NewGroupConversationFormAPISchema>,
) => {
	// NOTE: API doesn't care what to return here so we don't validate the response
	return await fetch(api.roleplay.mass(companyId), {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(data),
	})
}

export const endRoleplayChat = async (
	companyId: string,
	conversationId: string,
) => {
	return await fetch(api.roleplay.end(companyId, conversationId), {
		method: 'PUT',
	})
}

export const restartConversationChat = async (
	companyId: string,
	conversationId: string,
) => {
	return await safeFetch(
		ConversationDataSchema,
		api.roleplay.restart(companyId, conversationId),
		{
			method: 'PUT',
		},
	)
}

export const deleteConversation = async (
	companyId: string,
	conversationId: string,
) => {
	return await fetch(api.roleplay.delete(companyId, conversationId), {
		method: 'DELETE',
	})
}
