import { Link, useRouteLoaderData } from 'react-router-dom'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { Button } from './ui/button'
import { Icon } from './ui/icon'
import { Surface } from './ui/surface'

export default function NoVerticals({
	companyId,
	ecosystemId,
	className,
}: {
	companyId: string
	ecosystemId: string | number
	className?: string
}) {
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse

	return (
		<Surface
			className={cn(
				'flex min-h-[260px] w-full flex-col items-center justify-center gap-4 border-none bg-neutral-2-bg',
				className,
			)}
		>
			<Icon name="category" size="xl" className="text-neutral-3-fg" />
			<h1 className="text-body-lg text-neutral-2-fg">
				You don&apos;t have any verticals and personas for this ecosystem yet
			</h1>
			{readOnlySession ? null : (
				<div>
					<Button
						asChild
						variant="outline"
						className="flex flex-nowrap items-center gap-1"
					>
						<Link
							to={routes.calibrate.verticals.create({
								companyId: companyId,
								ecosystemId: ecosystemId.toString(),
							})}
						>
							<Icon name="add" /> Add Vertical
						</Link>
					</Button>
				</div>
			)}
		</Surface>
	)
}
