// NOTE: Business decision to use the following regions
// https://evergrowthio.slack.com/archives/C06UW9HEJHZ/p1714035448325429?thread_ts=1713955060.724979&cid=C06UW9HEJHZ
export const REGION_CLUSTERS = [
	{ name: 'ANZ', regions: ['Australia', 'New Zealand'] },
	{
		name: 'APAC',
		regions: [
			'American Samoa',
			'Australia',
			'Bangladesh',
			'Bhutan',
			'Brunei Darussalam',
			'Cambodia',
			'China',
			'Cook Islands',
			'French Polynesia',
			'Guam',
			'Hong Kong',
			'India',
			'Indonesia',
			'Malaysia',
			'Maldives',
			'Marshall Islands',
			'Micronesia',
			'Mongolia',
			'Myanmar',
			'Nauru',
			'Nepal',
			'New Caledonia',
			'New Zealand',
			'Niue',
			'North Korea',
			'Northern Mariana Islands',
			'Pakistan',
			'Palau',
			'Papua New Guinea',
			'Philippines',
			'Samoa',
			'Singapore',
			'Solomon Islands',
			'South Korea',
			'Sri Lanka',
			'Taiwan',
			'Thailand',
			'Timor-Leste',
			'Tonga',
			'Vanuatu',
			'Vietnam',
			'Wallis and Futuna',
		],
	},
	{
		name: 'APJ',
		regions: [
			'American Samoa',
			'Australia',
			'Bangladesh',
			'Bhutan',
			'Brunei Darussalam',
			'Cambodia',
			'China',
			'Cook Islands',
			'Fiji',
			'French Polynesia',
			'Guam',
			'Hong Kong',
			'India',
			'Indonesia',
			'Japan',
			'Laos',
			'Malaysia',
			'Maldives',
			'Marshall Islands',
			'Micronesia',
			'Mongolia',
			'Myanmar',
			'Nauru',
			'Nepal',
			'New Caledonia',
			'New Zealand',
			'Niue',
			'North Korea',
			'Northern Mariana Islands',
			'Pakistan',
			'Palau',
			'Papua New Guinea',
			'Philippines',
			'Samoa',
			'Singapore',
			'Solomon Islands',
			'South Korea',
			'Sri Lanka',
			'Taiwan',
			'Thailand',
			'Timor-Leste',
			'Tokelau',
			'Tonga',
			'Vanuatu',
			'Vietnam',
			'Wallis and Futuna ',
		],
	},
	{ name: 'Benelux', regions: ['Belgium', 'Luxembourg', 'Netherlands'] },
	{
		name: 'Central Africa',
		regions: [
			'Angola',
			'Cameroon',
			'Central African Republic',
			'Chad',
			'Congo (DRC)',
			'Republic of the Congo',
			'Equatorial Guinea',
			'Gabon',
			'São Tomé and Príncipe',
		],
	},
	{ name: 'Dach', regions: ['Austria', 'Germany', 'Switzerland'] },
	{
		name: 'East Asia',
		regions: [
			'China',
			'Mongolia',
			'North Korea',
			'South Korea',
			'Japan',
			'Hong Kong',
			'Taiwan',
			'Macau',
		],
	},
	{
		name: 'Eastern Africa',
		regions: [
			'Burundi',
			'Comoros',
			'Djibouti',
			'Eritrea',
			'Ethiopia',
			'Kenya',
			'Madagascar',
			'Malawi',
			'Mauritius',
			'Mayotte',
			'Mozambique',
			'Reunion',
			'Rwanda',
			'Seychelles',
			'Somalia',
			'South Sudan',
			'Tanzania',
			'Uganda',
			'Zambia',
			'Zimbabwe',
		],
	},
	{
		name: 'EMEA',
		regions: [
			'Albania',
			'Algeria',
			'Andorra',
			'Angola',
			'Austria',
			'Bahrain',
			'Belarus',
			'Belgium',
			'Benin',
			'Bosnia and Herzegovina',
			'Botswana',
			'Bulgaria',
			'Burkina Faso',
			'Burundi',
			'Cameroon',
			'Cape Verde',
			'Central African Republic',
			'Chad',
			'Comoros',
			"Cote D'Ivoire",
			'Croatia',
			'Cyprus',
			'Czechia',
			'Denmark',
			'Djibouti',
			'Egypt',
			'Equatorial Guinea',
			'Eritrea',
			'Estonia',
			'Ethiopia',
			'Faroe Islands',
			'Finland',
			'France',
			'Gabon',
			'Gambia',
			'Gaza Strip',
			'Germany',
			'Ghana',
			'Gibraltar',
			'Greece',
			'Guernsey',
			'Guinea',
			'Guinea-Bissau',
			'Holy See (Vatican City State)',
			'Hungary',
			'Iceland',
			'Islamic Republic of Iran',
			'Iraq',
			'Ireland',
			'Isle of Man',
			'Israel',
			'Italy',
			'Jan Mayen',
			'Jersey',
			'Jordan',
			'Kenya',
			'Kosovo',
			'Kuwait',
			'Latvia',
			'Lebanon',
			'Lesotho',
			'Liberia',
			'Libya',
			'Liechtenstein',
			'Lithuania',
			'Luxembourg',
			'Macedonia',
			'Madagascar',
			'Malawi',
			'Mali',
			'Malta',
			'Mauritania',
			'Moldova',
			'Montenegro',
			'Morocco',
			'Mozambique',
			'Namibia',
			'Netherlands',
			'Niger',
			'Nigeria',
			'Norway',
			'Oman',
			'Poland',
			'Portugal',
			'Qatar',
			'Republic of The Congo',
			'Romania',
			'Russia',
			'Rwanda',
			'Saint Helene',
			'San Marino',
			'Sao Tome and Principe',
			'Saudi Arabia',
			'Senegal',
			'Serbia',
			'Seychelles',
			'Sierra Leone',
			'Slovakia',
			'Slovenia',
			'Somalia',
			'South Africa',
			'South Sudan',
			'Spain',
			'Sudan',
			'Svalbard',
			'Swaziland',
			'Sweden',
			'Switzerland',
			'Syria',
			'Tanzania',
			'Togo',
			'Tunisia',
			'Turkey',
			'Uganda',
			'Ukraine',
			'United Arab Emirates',
			'United Kingdom',
			'West Bank',
			'Yemen',
			'Zambia',
			'Zimbabwe',
		],
	},
	{
		name: 'European Economic Area',
		regions: [
			'Austria',
			'Belgium',
			'Bulgaria',
			'Croatia',
			'Cyprus',
			'Czechia',
			'Denmark',
			'Estonia',
			'Finland',
			'France',
			'Germany',
			'Greece',
			'Hungary',
			'Iceland',
			'Ireland',
			'Italy',
			'Latvia',
			'Liechtenstein',
			'Lithuania',
			'Luxembourg',
			'Malta',
			'Netherlands',
			'Norway',
			'Poland',
			'Portugal',
			'Romania',
			'Slovakia',
			'Slovenia',
			'Spain',
			'Sweden',
		],
	},
	{
		name: 'European Union',
		regions: [
			'Austria',
			'Belgium',
			'Bulgaria',
			'Croatia',
			'Cyprus',
			'Czechia',
			'Denmark',
			'Estonia',
			'Finland',
			'France',
			'Germany',
			'Greece',
			'Hungary',
			'Ireland',
			'Italy',
			'Latvia',
			'Lithuania',
			'Luxembourg',
			'Malta',
			'Netherlands',
			'Poland',
			'Portugal',
			'Romania',
			'Slovakia',
			'Slovenia',
			'Spain',
			'Sweden',
		],
	},
	{
		name: 'Latin America',
		regions: [
			'Barbados',
			'Antigua and Barbuda',
			'Anguilla',
			'Netherlands Antilles',
			'Argentina',
			'Aruba',
			'Bermuda',
			'Bolivia',
			'Brazil',
			'Bahamas',
			'Belize',
			'Caribbean Nations',
			'Chile',
			'Colombia',
			'Costa Rica',
			'Cuba',
			'Dominica',
			'Dominican Republic',
			'Ecuador',
			'Falkland Islands (Malvinas)',
			'Grenada',
			'French Guiana',
			'Guadeloupe',
			'South Georgia and the South Sandwich Islands',
			'Guatemala',
			'Guyana',
			'Honduras',
			'Haiti',
			'Jamaica',
			'Saint Kitts and Nevis',
			'Cayman Islands',
			'Saint Lucia',
			'Martinique',
			'Montserrat',
			'Mexico',
			'Nicaragua',
			'Panama',
			'Peru',
			'Puerto Rico',
			'Paraguay',
			'Saint Helena',
			'Suriname',
			'El Salvador',
			'Turks and Caicos Islands',
			'Trinidad and Tobago',
			'Uruguay',
			'Saint Vincent and Grenadines',
			'Venezuela (Bolivarian Republic)',
			'British Virgin Islands',
			'Virgin Islands',
			'US',
		],
	},
	{
		name: 'Middle East',
		regions: [
			'Bahrain',
			'Cyprus',
			'Egypt',
			'Iran',
			'Iraq',
			'Israel',
			'Jordan',
			'Kuwait',
			'Lebanon',
			'Oman',
			'Palestinian Authority',
			'Qatar',
			'Saudi Arabia',
			'Turkey',
			'United Arab Emirates',
			'Yemen',
		],
	},
	{ name: 'NAMER', regions: ['United States', 'Canada', 'Mexico'] },
	{
		name: 'Nordics',
		regions: [
			'Denmark',
			'Faroe Islands',
			'Finland',
			'Greenland',
			'Iceland',
			'Jan Mayen',
			'Norway',
			'Svalbard',
			'Sweden',
		],
	},
	{
		name: 'Northern Africa',
		regions: [
			'Algeria',
			'Canary Islands',
			'Ceuta',
			'Egypt',
			'Libya',
			'Madeira',
			'Melilla',
			'Morocco',
			'Sudan',
			'Tunisia',
			'Western Sahara',
		],
	},
	{
		name: 'Oceania',
		regions: [
			'American Samoa',
			'Australia',
			'Baker Island',
			'Christmas Island',
			'Coco (Keeling) Islands',
			'Micronesia',
			'French Polynesia',
			'New Caledonia',
			'Cook Islands',
			'Fiji',
			'Guam',
			'Howland Island',
			'Jarvis Island ',
			'Johnston Atoll',
			'Kingman Reef',
			'Kiribati',
			'Marshall Islands',
			'Midway Islands',
			'Nauru',
			'New Zealand',
			'Niue',
			'Norfolk Island',
			'Northern Mariana Islands',
			'Palau',
			'Palmyra Atoll',
			'Papua New Guinea',
			'Pitcairn',
			'Samoa',
			'Solomon Islands',
			'Tokelau',
			'Tonga',
			'Tuvalu',
			'Vanuatu',
			'Wake Island',
			'Wallis and Futuna',
		],
	},
	{
		name: 'South Asia',
		regions: [
			'Sri Lanka',
			'Bangladesh',
			'India',
			'Afghanistan',
			'Pakistan',
			'Bhutan',
			'Nepal',
			'Maldives',
		],
	},
	{
		name: 'Southeast Asia',
		regions: [
			'Brunei Darussalam',
			'Cambodia',
			'Indonesia',
			'Laos',
			'Malaysia',
			'Myanmar',
			'Philippines',
			'Singapore',
			'Thailand',
			'Timor Leste',
			'Vietnam',
			'Christmas Island',
			'Coco (Keeling) Islands',
		],
	},
	{
		name: 'Southern Africa',
		regions: ['Botswana', 'Eswatini', 'Lesotho', 'Namibia', 'South Africa'],
	},
	{
		name: 'Sub-Saharan Africa',
		regions: [
			'Angola',
			'Burundi',
			'Congo (DRC)',
			'Cameroon',
			'Central African Republic',
			'Chad',
			'Republic of the Congo',
			'Equatorial Guinea',
			'Gabon',
			'Kenya',
			'Nigeria',
			'Rwanda',
			'São Tomé and Príncipe',
			'Tanzania',
			'Uganda',
			'Sudan',
			'South Sudan',
			'Djibouti',
			'Eritrea',
			'Ethiopia',
			'Somalia',
			'Botswana',
			'Comoros',
			'Lesotho',
			'Madagascar',
			'Malawi',
			'Mauritius',
			'Mozambique',
			'Namibia',
			'Seychelles',
			'South Africa',
			'Eswatini',
			'Zambia',
			'Zimbabwe',
			'Benin',
			'Mali',
			'Burkina Faso',
			'Cape Verde',
			"Côte d'Ivoire",
			'Gambia',
			'Ghana',
			'Guinea',
			'Guinea-Bissau',
			'Liberia',
			'Mauritania',
			'Niger',
			'Senegal',
			'Sierra Leone',
			'Togo',
		],
	},
	{
		name: 'Baltics',
		regions: ['Estonia', 'Latvia', 'Lithuania'],
	},
	{
		name: 'Western Africa',
		regions: [
			'Benin',
			'Burkina Faso',
			'Cape Verde',
			"Côte d'Ivoire",
			'Gambia',
			'Ghana',
			'Guinea',
			'Guinea-Bissau',
			'Liberia',
			'Mali',
			'Mauritania',
			'Niger',
			'Nigeria',
			'Saint Helena',
			'Ascension and Tristan da Cunha',
			'Senegal',
			'Sierra Leone',
			'Togo',
		],
	},
]

// NOTE: https://docs.google.com/document/d/11aPrX47M1gSKz7P4cTD-LuEzswlkIYJKk7vMAkpZvc4/edit
export const COUNTRIES = [
	{ code: 'us', name: 'United States' },
	{ code: 'af', name: 'Afghanistan' },
	{ code: 'ax', name: 'Aland Islands' },
	{ code: 'al', name: 'Albania' },
	{ code: 'dz', name: 'Algeria' },
	{ code: 'as', name: 'American Samoa' },
	{ code: 'ad', name: 'Andorra' },
	{ code: 'ao', name: 'Angola' },
	{ code: 'ai', name: 'Anguilla' },
	{ code: 'aq', name: 'Antarctica' },
	{ code: 'ag', name: 'Antigua and Barbuda' },
	{ code: 'ar', name: 'Argentina' },
	{ code: 'am', name: 'Armenia' },
	{ code: 'aw', name: 'Aruba' },
	{ code: 'au', name: 'Australia' },
	{ code: 'at', name: 'Austria' },
	{ code: 'az', name: 'Azerbaijan' },
	{ code: 'bs', name: 'Bahamas' },
	{ code: 'bh', name: 'Bahrain' },
	{ code: 'bd', name: 'Bangladesh' },
	{ code: 'bb', name: 'Barbados' },
	{ code: 'by', name: 'Belarus' },
	{ code: 'be', name: 'Belgium' },
	{ code: 'bz', name: 'Belize' },
	{ code: 'bj', name: 'Benin' },
	{ code: 'bm', name: 'Bermuda' },
	{ code: 'bt', name: 'Bhutan' },
	{ code: 'bo', name: 'Bolivia' },
	{ code: 'ba', name: 'Bosnia and Herzegovina' },
	{ code: 'bw', name: 'Botswana' },
	{ code: 'br', name: 'Brazil' },
	{ code: 'io', name: 'British Indian Ocean Territory' },
	{ code: 'bn', name: 'Brunei Darussalam' },
	{ code: 'bg', name: 'Bulgaria' },
	{ code: 'bf', name: 'Burkina Faso' },
	{ code: 'bi', name: 'Burundi' },
	{ code: 'kh', name: 'Cambodia' },
	{ code: 'cm', name: 'Cameroon' },
	{ code: 'ca', name: 'Canada' },
	{ code: 'cv', name: 'Cape Verde' },
	{ code: 'cb', name: 'Caribbean Nations' },
	{ code: 'ky', name: 'Cayman Islands' },
	{ code: 'cf', name: 'Central African Republic' },
	{ code: 'td', name: 'Chad' },
	{ code: 'cl', name: 'Chile' },
	{ code: 'cn', name: 'China' },
	{ code: 'cx', name: 'Christmas Island' },
	{ code: 'cc', name: 'Cocos (Keeling) Islands' },
	{ code: 'co', name: 'Colombia' },
	{ code: 'km', name: 'Comoros' },
	{ code: 'cg', name: 'Congo' },
	{ code: 'ck', name: 'Cook Islands' },
	{ code: 'cr', name: 'Costa Rica' },
	{ code: 'ci', name: 'Cote D’Ivoire (Ivory Coast)' },
	{ code: 'hr', name: 'Croatia' },
	{ code: 'cu', name: 'Cuba' },
	{ code: 'cy', name: 'Cyprus' },
	{ code: 'cz', name: 'Czech Republic' },
	{ code: 'cd', name: 'Democratic Republic of the Congo' },
	{ code: 'dk', name: 'Denmark' },
	{ code: 'dj', name: 'Djibouti' },
	{ code: 'dm', name: 'Dominica' },
	{ code: 'do', name: 'Dominican Republic' },
	{ code: 'tp', name: 'East Timor' },
	{ code: 'ec', name: 'Ecuador' },
	{ code: 'eg', name: 'Egypt' },
	{ code: 'sv', name: 'El Salvador' },
	{ code: 'gq', name: 'Equatorial Guinea' },
	{ code: 'er', name: 'Eritrea' },
	{ code: 'ee', name: 'Estonia' },
	{ code: 'et', name: 'Ethiopia' },
	{ code: 'fk', name: 'Falkland Islands (Malvinas)' },
	{ code: 'fo', name: 'Faroe Islands' },
	{ code: 'fm', name: 'Federated States of Micronesia' },
	{ code: 'fj', name: 'Fiji' },
	{ code: 'fi', name: 'Finland' },
	{ code: 'fr', name: 'France' },
	{ code: 'gf', name: 'French Guiana' },
	{ code: 'pf', name: 'French Polynesia' },
	{ code: 'tf', name: 'French Southern Territories' },
	{ code: 'ga', name: 'Gabon' },
	{ code: 'gm', name: 'Gambia' },
	{ code: 'ge', name: 'Georgia' },
	{ code: 'de', name: 'Germany' },
	{ code: 'gh', name: 'Ghana' },
	{ code: 'gi', name: 'Gibraltar' },
	{ code: 'gr', name: 'Greece' },
	{ code: 'gl', name: 'Greenland' },
	{ code: 'gd', name: 'Grenada' },
	{ code: 'gp', name: 'Guadeloupe' },
	{ code: 'gu', name: 'Guam' },
	{ code: 'gt', name: 'Guatemala' },
	{ code: 'gg', name: 'Guernsey' },
	{ code: 'gn', name: 'Guinea' },
	{ code: 'gw', name: 'Guinea-Bissau' },
	{ code: 'gy', name: 'Guyana' },
	{ code: 'ht', name: 'Haiti' },
	{ code: 'hn', name: 'Honduras' },
	{ code: 'hk', name: 'Hong Kong' },
	{ code: 'hu', name: 'Hungary' },
	{ code: 'is', name: 'Iceland' },
	{ code: 'in', name: 'India' },
	{ code: 'id', name: 'Indonesia' },
	{ code: 'ir', name: 'Iran' },
	{ code: 'iq', name: 'Iraq' },
	{ code: 'ie', name: 'Ireland' },
	{ code: 'im', name: 'Isle of Man' },
	{ code: 'il', name: 'Israel' },
	{ code: 'it', name: 'Italy' },
	{ code: 'jm', name: 'Jamaica' },
	{ code: 'jp', name: 'Japan' },
	{ code: 'je', name: 'Jersey' },
	{ code: 'jo', name: 'Jordan' },
	{ code: 'kz', name: 'Kazakhstan' },
	{ code: 'ke', name: 'Kenya' },
	{ code: 'ki', name: 'Kiribati' },
	{ code: 'kr', name: 'Korea' },
	{ code: 'kp', name: 'Korea (North)' },
	{ code: 'kw', name: 'Kuwait' },
	{ code: 'kg', name: 'Kyrgyzstan' },
	{ code: 'la', name: 'Laos' },
	{ code: 'lv', name: 'Latvia' },
	{ code: 'lb', name: 'Lebanon' },
	{ code: 'ls', name: 'Lesotho' },
	{ code: 'lr', name: 'Liberia' },
	{ code: 'ly', name: 'Libya' },
	{ code: 'li', name: 'Liechtenstein' },
	{ code: 'lt', name: 'Lithuania' },
	{ code: 'lu', name: 'Luxembourg' },
	{ code: 'mo', name: 'Macao' },
	{ code: 'mk', name: 'Macedonia' },
	{ code: 'mg', name: 'Madagascar' },
	{ code: 'mw', name: 'Malawi' },
	{ code: 'my', name: 'Malaysia' },
	{ code: 'mv', name: 'Maldives' },
	{ code: 'ml', name: 'Mali' },
	{ code: 'mt', name: 'Malta' },
	{ code: 'mh', name: 'Marshall Islands' },
	{ code: 'mq', name: 'Martinique' },
	{ code: 'mr', name: 'Mauritania' },
	{ code: 'mu', name: 'Mauritius' },
	{ code: 'yt', name: 'Mayotte' },
	{ code: 'mx', name: 'Mexico' },
	{ code: 'md', name: 'Moldova' },
	{ code: 'mc', name: 'Monaco' },
	{ code: 'mn', name: 'Mongolia' },
	{ code: 'me', name: 'Montenegro' },
	{ code: 'ms', name: 'Montserrat' },
	{ code: 'ma', name: 'Morocco' },
	{ code: 'mz', name: 'Mozambique' },
	{ code: 'mm', name: 'Myanmar' },
	{ code: 'na', name: 'Namibia' },
	{ code: 'nr', name: 'Nauru' },
	{ code: 'np', name: 'Nepal' },
	{ code: 'nl', name: 'Netherlands' },
	{ code: 'an', name: 'Netherlands Antilles' },
	{ code: 'nc', name: 'New Caledonia' },
	{ code: 'nz', name: 'New Zealand' },
	{ code: 'ni', name: 'Nicaragua' },
	{ code: 'ne', name: 'Niger' },
	{ code: 'ng', name: 'Nigeria' },
	{ code: 'nu', name: 'Niue' },
	{ code: 'nf', name: 'Norfolk Island' },
	{ code: 'mp', name: 'Northern Mariana Islands' },
	{ code: 'no', name: 'Norway' },
	{ code: 'om', name: 'Oman' },
	{ code: 'pk', name: 'Pakistan' },
	{ code: 'pw', name: 'Palau' },
	{ code: 'ps', name: 'Palestinian Territory' },
	{ code: 'pa', name: 'Panama' },
	{ code: 'pg', name: 'Papua New Guinea' },
	{ code: 'py', name: 'Paraguay' },
	{ code: 'pe', name: 'Peru' },
	{ code: 'ph', name: 'Philippines' },
	{ code: 'pn', name: 'Pitcairn' },
	{ code: 'pl', name: 'Poland' },
	{ code: 'pt', name: 'Portugal' },
	{ code: 'pr', name: 'Puerto Rico' },
	{ code: 'qa', name: 'Qatar' },
	{ code: 're', name: 'Reunion' },
	{ code: 'ro', name: 'Romania' },
	{ code: 'ru', name: 'Russian Federation' },
	{ code: 'rw', name: 'Rwanda' },
	{ code: 'sh', name: 'Saint Helena' },
	{ code: 'kn', name: 'Saint Kitts and Nevis' },
	{ code: 'lc', name: 'Saint Lucia' },
	{ code: 'pm', name: 'Saint Pierre and Miquelon' },
	{ code: 'vc', name: 'Saint Vincent and the Grenadines' },
	{ code: 'ws', name: 'Samoa' },
	{ code: 'sm', name: 'San Marino' },
	{ code: 'st', name: 'Sao Tome and Principe' },
	{ code: 'sa', name: 'Saudi Arabia' },
	{ code: 'sn', name: 'Senegal' },
	{ code: 'rs', name: 'Serbia' },
	{ code: 'sc', name: 'Seychelles' },
	{ code: 'sl', name: 'Sierra Leone' },
	{ code: 'sg', name: 'Singapore' },
	{ code: 'sk', name: 'Slovak Republic' },
	{ code: 'si', name: 'Slovenia' },
	{ code: 'sb', name: 'Solomon Islands' },
	{ code: 'so', name: 'Somalia' },
	{ code: 'za', name: 'South Africa' },
	{ code: 'es', name: 'Spain' },
	{ code: 'lk', name: 'Sri Lanka' },
	{ code: 'sd', name: 'Sudan' },
	{ code: 'sr', name: 'Suriname' },
	{ code: 'sj', name: 'Svalbard and Jan Mayen' },
	{ code: 'sz', name: 'Swaziland' },
	{ code: 'se', name: 'Sweden' },
	{ code: 'ch', name: 'Switzerland' },
	{ code: 'sy', name: 'Syria' },
	{ code: 'tw', name: 'Taiwan' },
	{ code: 'tj', name: 'Tajikistan' },
	{ code: 'tz', name: 'Tanzania' },
	{ code: 'th', name: 'Thailand' },
	{ code: 'tl', name: 'Timor-Leste' },
	{ code: 'tg', name: 'Togo' },
	{ code: 'tk', name: 'Tokelau' },
	{ code: 'to', name: 'Tonga' },
	{ code: 'tt', name: 'Trinidad and Tobago' },
	{ code: 'tn', name: 'Tunisia' },
	{ code: 'tr', name: 'Turkey' },
	{ code: 'tm', name: 'Turkmenistan' },
	{ code: 'tc', name: 'Turks and Caicos Islands' },
	{ code: 'tv', name: 'Tuvalu' },
	{ code: 'ug', name: 'Uganda' },
	{ code: 'ua', name: 'Ukraine' },
	{ code: 'ae', name: 'United Arab Emirates' },
	{ code: 'uk', name: 'United Kingdom' },
	{ code: 'uy', name: 'Uruguay' },
	{ code: 'uz', name: 'Uzbekistan' },
	{ code: 'vu', name: 'Vanuatu' },
	{ code: 'va', name: 'Vatican City State (Holy See)' },
	{ code: 've', name: 'Venezuela' },
	{ code: 'vn', name: 'Vietnam' },
	{ code: 'vg', name: 'Virgin Islands (British)' },
	{ code: 'vi', name: 'Virgin Islands (U.S.)' },
	{ code: 'wf', name: 'Wallis and Futuna' },
	{ code: 'eh', name: 'Western Sahara' },
	{ code: 'ye', name: 'Yemen' },
	{ code: 'zm', name: 'Zambia' },
	{ code: 'zw', name: 'Zimbabwe' },
]
