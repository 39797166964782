import { useCallback, useEffect } from 'react'
import { useBeforeUnload, useLocation, useNavigation } from 'react-router-dom'

// https://github.com/epicweb-dev/restore-scroll/blob/main/src/index.tsx
// NOTE: only without restoring scroll on page load
export function useElementScrollRestoration(
	elementId: string,
	getKey?: (location: ReturnType<typeof useLocation>) => string,
) {
	const navigation = useNavigation()
	const location = useLocation()
	const STORAGE_KEY = `position:${elementId}`
	const POSITION_KEY = getKey && location ? getKey(location) : location.pathname

	const updatePositions = useCallback(() => {
		const element = document.getElementById(elementId)
		if (!element) return
		let positions = {}
		try {
			const rawPositions = JSON.parse(
				sessionStorage.getItem(STORAGE_KEY) || '{}',
			)
			if (typeof rawPositions === 'object' && rawPositions !== null) {
				positions = rawPositions
			}
		} catch (error) {
			console.warn(`Error parsing scroll positions from sessionStorage:`, error)
		}

		const newPositions = {
			...positions,
			[POSITION_KEY]: {
				x: element.scrollLeft,
				y: element.scrollTop,
			},
		}
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newPositions))
	}, [STORAGE_KEY, POSITION_KEY, elementId])

	useEffect(() => {
		if (navigation.state === 'idle') {
			const element = document.getElementById(elementId)
			if (!element) return
			try {
				const positions = JSON.parse(
					sessionStorage.getItem(STORAGE_KEY) || '{}',
				) as { [key: string]: { x: number; y: number } }

				const storedY = positions[POSITION_KEY]?.y
				const storedX = positions[POSITION_KEY]?.x

				if (typeof storedY === 'number') {
					element.scrollTop = storedY
				} else {
					element.scrollTop = 0
				}
				if (typeof storedX === 'number') {
					element.scrollLeft = storedX
				} else {
					element.scrollLeft = 0
				}
			} catch (error: unknown) {
				console.error(error)
				sessionStorage.removeItem(STORAGE_KEY)
			}
		} else {
			updatePositions()
		}
	}, [
		STORAGE_KEY,
		POSITION_KEY,
		elementId,
		navigation.state,
		location.pathname,
		updatePositions,
	])

	useBeforeUnload(() => {
		updatePositions()
	})
}
