import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { userQuery } from '#src/routes/init/user/me'
import { checkIsAdminSession } from '#src/utils/misc'
import { restartConversationChat } from './mutations'
import { roleplayKeys, statusQuery } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())
		const adminSession = checkIsAdminSession(user.roles)

		if (!adminSession)
			throw new Response('Access Denied', {
				status: 403,
				statusText: 'Forbidden',
			})

		const response = await restartConversationChat(
			params.companyId,
			params.conversationId,
		)
		const checkStatus = async () => {
			let status = null

			while (status !== 'ready') {
				const statusCheck = await queryClient.fetchQuery({
					...statusQuery(user.companyId, response.conversation.id),
					staleTime: 0,
				})
				status = statusCheck.status

				await new Promise(resolve => setTimeout(resolve, 15 * 1000)) // 15 seconds
			}

			return status
		}

		await checkStatus()

		await queryClient.invalidateQueries({
			queryKey: roleplayKeys.chats(params.companyId),
		})
		await queryClient.invalidateQueries({
			queryKey: roleplayKeys.chat(params.companyId, params.conversationId),
		})
		await queryClient.invalidateQueries({
			queryKey: roleplayKeys.conversation(
				params.companyId,
				params.conversationId,
			),
		})

		return null
	}
