import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import {
	SignalTemplateAPISchema,
	type SignalConfigFormSchema,
	AssignedSignalsAPISchema,
	type AssignedSignalsUpdateSchema,
	SignalUsageExampleAPISchema,
} from './schema'

export const updateSignal = async (
	companyId: string,
	signalId: string,
	data: z.infer<typeof SignalConfigFormSchema>,
) =>
	await safeFetch(
		SignalTemplateAPISchema,
		api.signal.config(companyId, signalId),
		{
			method: 'PATCH',
			body: JSON.stringify(data),
		},
	)

export const updateAssignedSignals = async (
	companyId: string,
	signalId: string,
	data: z.infer<typeof AssignedSignalsUpdateSchema>,
) =>
	await safeFetch(
		AssignedSignalsAPISchema,
		api.signal.assign(companyId, signalId),
		{
			method: 'PATCH',
			body: JSON.stringify(data),
		},
	)

export const signalUsageExampleMutation = async (
	companyId: string,
	personaId: string,
	signalId: string,
) =>
	await safeFetch(
		SignalUsageExampleAPISchema,
		api.signal.example(companyId, signalId, personaId),
		{
			method: 'PUT',
		},
	)
