import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { CompaniesSchema, CompanySchema, StatsSchema } from './schema'

export const companyKeys = {
	all: ['company'] as const,
	list: () => [...companyKeys.all, 'list'] as const,
	details: () => [...companyKeys.all, 'details'] as const,
	detail: (id: string) => [...companyKeys.details(), id] as const,
	stats: (id: string) => [...companyKeys.all, 'stats', id] as const,
}

export const companyQuery = (id: string) => ({
	queryKey: companyKeys.detail(id),
	queryFn: async () =>
		await safeFetch(CompanySchema, api.company.details(id), {
			method: 'GET',
		}),
})
export const statsQuery = (companyId: string) => ({
	queryKey: companyKeys.stats(companyId),
	queryFn: async () =>
		await safeFetch(StatsSchema, api.company.stats(companyId), {
			method: 'GET',
		}),
})

export const companiesQuery = () => ({
	queryKey: companyKeys.all,
	queryFn: async () =>
		await safeFetch(CompaniesSchema, api.company.list, {
			method: 'GET',
		}),
})
