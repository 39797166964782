import * as DialogPrimitive from '@radix-ui/react-dialog'
import { type QueryClient } from '@tanstack/react-query'
import {
	type ActionFunctionArgs,
	redirect,
	useFetcher,
	useNavigate,
} from 'react-router-dom'
import { Button } from '#src/components/ui/button.js'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import { StatusButton } from '#src/components/ui/status-button.js'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { endRoleplayChat } from './mutations'
import { roleplayKeys } from './queries'

export type EndRoleplayActionResponse = Awaited<
	ReturnType<ReturnType<typeof action>>
>

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await endRoleplayChat(params.companyId, params.conversationId)

		await queryClient.invalidateQueries({
			queryKey: roleplayKeys.all,
		})

		return redirect(
			routes.enable.roleplay.review({
				companyId: params.companyId,
				conversationId: params.conversationId,
			}),
		)
	}

export default function EndRoleplayChat() {
	const fetcher = useFetcher()
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const navigate = useNavigate()
	const action = routes.enable.roleplay.end({
		companyId: params.companyId,
		conversationId: params.conversationId,
	})
	const isPending = useIsPending({
		formAction: action,
		formMethod: 'PUT',
	})

	return (
		<DialogRoot
			defaultOpen={true}
			onOpenChange={isOpen => {
				if (!isOpen && !isPending) {
					navigate(
						routes.enable.roleplay.session({
							companyId: params.companyId,
							conversationId: params.conversationId,
						}),
					)
				}
			}}
		>
			<DialogContent
				className="w-full max-w-lg"
				closeClassName={isPending ? 'opacity-50 cursor-default' : ''}
			>
				<div className="flex w-full max-w-xl flex-col gap-4">
					<h1 className="text-title-lg text-neutral-1-fg">End session</h1>
					<p className="text-body-lg text-neutral-2-fg">
						Are you sure you want to end this session?
					</p>
				</div>
				<div className="flex w-full items-center justify-end gap-2 pt-8">
					<DialogPrimitive.Close asChild>
						<Button type="button" variant="outline" className="min-w-24">
							Cancel
						</Button>
					</DialogPrimitive.Close>
					<fetcher.Form action={action} method="DELETE">
						<StatusButton
							status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
							type="submit"
							disabled={fetcher.state !== 'idle'}
							className="min-w-24"
						>
							Yes, end
						</StatusButton>
					</fetcher.Form>
				</div>
			</DialogContent>
		</DialogRoot>
	)
}
