import { type QueryClient } from '@tanstack/react-query'
import { Link, useLoaderData, type LoaderFunctionArgs } from 'react-router-dom'
import {
	DashboardCard,
	DashboardCardCount,
} from '#src/components/dashboard-card'
import ProductTip from '#src/components/product-tip'
import { Icon } from '#src/components/ui/icon'
import { companyQuery } from '#src/routes/calibrate/company/queries'
import { userQuery } from '#src/routes/init/user/me'
import { useUnreadChats } from '#src/routes/organize/aidt'
import { chatsQuery as aidtQuery } from '#src/routes/organize/aidt/queries'
import { checkIsAdminSession, checkIsDataTrainerSession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

type OrganizeDashboardLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))

		if (!company)
			throw new Response('Company Not Found', {
				status: 404,
				statusText: 'Not Found',
			})

		const aidt = await queryClient.fetchQuery(aidtQuery(params.companyId))
		const user = await queryClient.fetchQuery(userQuery())

		return { aidt, user, company }
	}

export default function EnableDashboard() {
	const params = useParsedRouteParams(['companyId'])
	const { aidt, user, company } =
		useLoaderData() as OrganizeDashboardLoaderResponse
	const { unreadChats } = useUnreadChats(
		checkIsDataTrainerSession(user.roles) || checkIsAdminSession(user.roles),
	)
	const { dashboard } = company
	const tamOrganizer = dashboard?.find(item => item.name === 'Organize')

	return (
		<main className="flex h-full w-full flex-col items-center">
			<section className="flex w-full max-w-screen-2xl flex-col items-start px-20 pb-4 pt-10">
				<h1 className="pb-2 text-heading-sm text-neutral-1-fg">Organize</h1>
			</section>

			<section className="grid w-full max-w-screen-2xl grid-cols-3 grid-rows-1 gap-6 px-20">
				<a
					{...(tamOrganizer?.url
						? {
								href: tamOrganizer?.url || undefined, // Ensure href is undefined if it doesn't exist
								target: '__blank',
							}
						: { ['data-disabled']: true })}
					aria-disabled={!tamOrganizer?.url}
					aria-description={tamOrganizer?.name}
				>
					<DashboardCard
						icon="chart-column"
						title={
							<div className="flex w-full items-center gap-1">
								Lead research agent jobs
								{!tamOrganizer?.url ? (
									<div className="ml-auto rounded-xl bg-neutral-2-bg-disabled px-2 py-1 text-label-sm text-neutral-2-fg">
										Connect your CRM
									</div>
								) : (
									<Icon
										name="launch"
										size="md"
										className="ml-auto text-brand-1-fg"
									/>
								)}
							</div>
						}
						isHoverable={!!tamOrganizer?.url}
					/>
				</a>
				{(checkIsDataTrainerSession(user.roles) ||
					checkIsAdminSession(user.roles)) && (
					<Link
						to={routes.organize.aidt.index({
							companyId: params.companyId,
							conversationId: null,
						})}
						className="block"
					>
						<DashboardCard
							icon="machine-learning-model"
							title={
								<div className="flex w-full items-center gap-1">
									Lead research agent Q&A
									<ProductTip
										className="text-neutral-3-fg"
										content="Lead Research Agent questions: Efficiently manage account data here. Answer generated questions in a chat format to fill in missing details. This process continues until the account qualifies as an ICP. After qualification, the Lead Research Agent provides IBP contact details and prompts you to answer any related questions. Use this tab to confirm any gaps in contact information."
									/>
									<div className="ml-auto rounded-xl bg-brand-3-bg px-2 py-1 text-label-sm text-brand-3-fg">
										{unreadChats?.length ? unreadChats.length : '0'}
									</div>
								</div>
							}
							isHoverable
						>
							<DashboardCardCount
								count={aidt.length ?? 0}
								countSuffix={aidt.length !== 1 ? 'Sessions' : 'Session'}
							/>
						</DashboardCard>
					</Link>
				)}
			</section>
		</main>
	)
}
