import {
	getFormProps,
	getInputProps,
	getSelectProps,
	getTextareaProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import {
	type LoaderFunctionArgs,
	useLoaderData,
	Link,
	Form,
} from 'react-router-dom'
import { z } from 'zod'
import {
	CheckboxField,
	ErrorList,
	Field,
	Select,
	SelectTextInput,
	TextareaField,
} from '#src/components/forms'
import Priority from '#src/components/priority'
import ProductTip from '#src/components/product-tip'
import Status from '#src/components/status'
import { Button } from '#src/components/ui/button'
import {
	Dropdown,
	DropdownItem,
	DropdownLabel,
	DropdownSub,
	DropdownSubContent,
	DropdownSubTrigger,
} from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import {
	Sidebar,
	SidebarFooter,
	SidebarHeader,
} from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import {
	PERSONA_TYPES,
	PERSONA_TYPE_OPTIONS,
	PRIORITY_OPTIONS,
	STATUS_OPTIONS,
} from '#src/utils/enumerations'
import { cn, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { personaQuery } from './queries'
import { PersonaEditFormSchema } from './schema'

export type PersonaEditLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

const DuplicatePersona = z.object({
	personaId: z.string().or(z.number()),
	ecosystemId: z.string().or(z.number()),
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const ecosystems = await queryClient.fetchQuery(
			ecosystemsQuery(params.companyId),
		)
		const ecosystem = ecosystems?.find(e => e.id === Number(params.ecosystemId))
		const verticals = ecosystem?.verticals
		if (!verticals?.length)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const vertical = verticals.find(v => v.id.toString() === params.verticalId)
		if (!vertical)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		const personas = vertical?.personas

		const url = new URL(request.url)
		const duplicateHash = url.searchParams.get('duplicate')

		if (!duplicateHash) {
			return {
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				verticalId: params.verticalId,
				personaId: params.personaId,
				ecosystems,
				vertical,
				personas,
				duplicateHash: undefined,
				duplicatePersonaId: undefined,
				duplicatePersonaEcosystemId: undefined,
				duplicatePersona: undefined,
			}
		}

		const duplicate = DuplicatePersona.parse(JSON.parse(atob(duplicateHash)))

		const { personaId, ecosystemId } = duplicate

		// NOTE: clever way to remove a keys from an object
		// prettier-ignore
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { id, vertical: personaVertical, reportsTo, reportsOverride, prompt, personaMapping, ...persona } = await queryClient.fetchQuery(
			personaQuery(params.companyId, personaId.toString()),
		)

		return {
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
			verticalId: params.verticalId,
			personaId: params.personaId,
			ecosystems,
			vertical,
			personas,
			duplicateHash,
			duplicatePersonaId: personaId.toString(),
			duplicatePersonaEcosystemId: ecosystemId.toString(),
			duplicatePersona: {
				...persona,
				verticals: [params.verticalId],
			},
		}
	}

export default function PersonaEdit() {
	const {
		companyId,
		ecosystemId,
		verticalId,
		ecosystems,
		vertical,
		personas,
		duplicateHash,
		duplicatePersona,
		duplicatePersonaId,
		duplicatePersonaEcosystemId,
		personaId,
	} = useLoaderData() as PersonaEditLoaderResponse

	const action = routes.calibrate.persona.save({
		companyId: companyId,
		ecosystemId: ecosystemId,
		verticalId: verticalId,
		personaId: personaId ?? null,
	})
	const method = 'POST'
	const formId = 'persona-form-' + (duplicateHash ?? personaId)

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	const cancelTo = routes.calibrate.persona.index({
		companyId: companyId,
		ecosystemId: ecosystemId,
		verticalId: verticalId,
		personaId: personaId ?? null,
	})

	return (
		<div className="w-[24rem]">
			<Sidebar
				header={
					<SidebarHeader>
						<div>
							<h1 className="text-title-sm">New Persona</h1>

							{duplicateHash ? (
								<Dropdown
									trigger={
										<button type="button" className="outline-none">
											<p className="flex flex-nowrap items-center gap-2 text-body-sm font-bold">
												{vertical.name} <Icon name="chevron-down" size="xs" />
											</p>
										</button>
									}
								>
									{ecosystems.map(ecosystem => (
										<DropdownSub key={ecosystem.id}>
											<DropdownSubTrigger
												className={cn(
													ecosystem.id!.toString() === ecosystemId
														? 'bg-neutral-2-bg'
														: '',
												)}
											>
												{ecosystem.name}
											</DropdownSubTrigger>
											<DropdownSubContent>
												{ecosystem.verticals.length ? (
													ecosystem.verticals.map(vertical => (
														<DropdownItem key={vertical.id} asChild>
															<Link
																className={cn(
																	vertical.id.toString() === verticalId
																		? 'bg-neutral-2-bg'
																		: '',
																)}
																replace
																preventScrollReset
																to={routes.calibrate.persona.duplicate({
																	companyId: companyId,
																	ecosystemId: ecosystem.id!.toString(),
																	verticalId: vertical.id.toString(),
																	personaId:
																		vertical.personas?.[0]?.id?.toString() ??
																		null,
																	duplicate: btoa(
																		JSON.stringify({
																			ecosystemId: duplicatePersonaEcosystemId,
																			personaId: duplicatePersonaId,
																		}),
																	),
																})}
																title={vertical.name}
															>
																{vertical.name}
															</Link>
														</DropdownItem>
													))
												) : (
													<DropdownLabel>No verticals</DropdownLabel>
												)}
											</DropdownSubContent>
										</DropdownSub>
									))}
								</Dropdown>
							) : null}
						</div>
						<Link className="inline-flex" to={cancelTo}>
							<Icon name="cross-1" />
						</Link>
					</SidebarHeader>
				}
				main={
					<PersonaForm
						formId={formId}
						method={method}
						action={action}
						persona={duplicatePersona}
						personas={personas.map(p => ({
							id: String(p.id ?? ''),
							name: String(p.jobTitles ?? p.type ?? ''),
						}))}
						verticalId={vertical.id.toString()}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link to={cancelTo}>Cancel</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							size="sm"
							type="submit"
							name="intent"
							value="persona"
							form={formId}
							disabled={isPending}
						>
							Create
						</StatusButton>
					</SidebarFooter>
				}
			/>
		</div>
	)
}

function PersonaForm({
	formId,
	action,
	method,
	persona,
	personas,
	verticalId,
}: {
	formId: string
	action: string
	method: 'POST'
	persona?: z.infer<typeof PersonaEditFormSchema>
	personas: {
		id: string
		name: string
	}[]
	verticalId: string
}) {
	const defaultValue = {
		verticals: [verticalId],
		reportsTo: null,
		reportsOverride: null,
		type: PERSONA_TYPES.decisionmaker,
		status: 'Ongoing',
		priority: 'High',
		expertise: '',
		jobTitles: '',
		jobSeniority: 'VP, Director or Head',
		jobExperience: '+7 years',
		typeOfTask: '',
		pain: '',
		painLength: 100,
		painOverride: null,
		aiFineTuning: {
			answerFormat:
				'Strictly answer with a list of 5 numbered bullet points with a sub-paragraph below the numbered bullet points. Strictly rank the numbered bullet points by importance. Strictly answer with 100 to 180 words for each numbered bullet point. Strictly do not write the number of words or word counts in your answer.  Strictly avoid any form of nested lists or sub-bullet points to maintain clarity. Each bullet point should stand independently and provide value independently without relying on additional context from other points. Strictly explain in detail and with illustrative examples how this applies specifically to your company business model in all your answers.',
			directReport: null,
			exampleCompanies: null,
			bizModel: true,
			employeeNumber: true,
			regions: true,
			regionsManual: null,
			subverticals: true,
			subverticalsManual: null,
			specificComment: null,
			comments: null,
		},
		...(persona ?? {}),
	}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(PersonaEditFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, {
				schema: PersonaEditFormSchema,
			})
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})
	const aiFineTuning = fields.aiFineTuning.getFieldset()

	return (
		<Form
			method={method}
			{...getFormProps(form)}
			action={action}
			className="-mx-4"
		>
			<input type="hidden" name="verticals" value={verticalId} />

			<section className="flex flex-col gap-4 px-4">
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Type
								<ProductTip content="Type: Specify the role of the persona (e.g., Decision Maker)." />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.type),
						defaultValue: undefined,
						value: String(fields.type.value) || undefined,
						onValueChange(value) {
							form.update({
								name: fields.jobSeniority.name,
								value:
									value === PERSONA_TYPES.decisionmaker
										? 'VP, Director or Head'
										: value === PERSONA_TYPES.influencer
											? 'Head, Manager'
											: value === PERSONA_TYPES.champion
												? 'Associate, coordinator, assistant'
												: '',
							})
							form.update({
								name: fields.jobExperience.name,
								value:
									value === PERSONA_TYPES.decisionmaker
										? '+7 years'
										: value === PERSONA_TYPES.influencer
											? '+5 years'
											: value === PERSONA_TYPES.champion
												? '+3 years'
												: '',
							})
						},
					}}
					options={PERSONA_TYPE_OPTIONS}
					errors={fields.status.errors}
				/>
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Status
								<ProductTip content="Status: Indicates the current state of the persona in your sales process." />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.status),
						defaultValue: fields.status.initialValue,
						value: fields.status.value,
					}}
					options={STATUS_OPTIONS.map(({ value, label }) => ({
						value,
						label: <Status status={label} />,
					}))}
					errors={fields.status.errors}
				/>
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Priority
								<ProductTip content="Priority: Set the priority level (High, Medium, Low) for resource allocation and focus. " />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.priority),
						defaultValue: fields.priority.initialValue,
						value: fields.priority.value,
					}}
					options={PRIORITY_OPTIONS.map(({ value, label }) => ({
						value,
						label: <Priority priority={label} />,
					}))}
					errors={fields.priority.errors}
				/>
			</section>

			<section className="mb-2 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					Setup info
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Expertise
								<ProductTip content="Expertise: Define the primary area of expertise relevant to the persona (e.g., Revenue)." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.expertise, { type: 'text' }),
						onBlur: e => {
							if (fields.jobTitles.value) return

							const jobSeniority =
								fields.jobSeniority.value ?? fields.jobSeniority.initialValue
							if (e.target.value && jobSeniority) {
								form.update({
									name: fields.jobTitles.name,
									value: `${jobSeniority} of ${e.target.value}`,
								})
							}
						},
					}}
					errors={fields.expertise.errors}
				/>
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Job Seniority
								<ProductTip content="Job Seniority: Specify the seniority level (e.g., Chief, President, SVP, VP, Director)." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.jobSeniority, { type: 'text' }),
						onBlur: e => {
							if (fields.jobTitles.value) return

							const expertise =
								fields.expertise.value ?? fields.expertise.initialValue
							if (e.target.value && expertise) {
								form.update({
									name: fields.jobTitles.name,
									value: `${e.target.value} of ${expertise}`,
								})
							}
						},
					}}
					errors={fields.jobSeniority.errors}
				/>
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Job Experience
								<ProductTip content="Job Experience: Indicate the required years of experience (e.g., +7 years)." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.jobExperience, { type: 'text' }),
					}}
					errors={fields.jobExperience.errors}
				/>

				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Job Title examples
								<ProductTip content="Job Title Examples: Provide examples of relevant job titles (e.g., CRO, Chief, President, SVP, VP, Director of Revenue or Sales)." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.jobTitles, { type: 'text' }),
					}}
					errors={fields.jobTitles.errors}
				/>

				<TextareaField
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Types of Tasks
								<ProductTip content="Types of Tasks: Describe the key responsibilities and tasks undertaken by the persona (e.g., implementing account-based methodologies). Be as specific and concrete as possible, since this information will influence the pain points and personas that the model generates." />
							</div>
						),
					}}
					textareaProps={{
						...getTextareaProps(fields.typeOfTask),
					}}
					errors={fields.typeOfTask.errors}
				/>
				<Select
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Reports To
								<ProductTip content="Reports To: Specify the reporting structure (e.g., CEO)." />
							</div>
						),
					}}
					inputProps={{
						...getSelectProps(fields.reportsTo),
						className: 'h-auto min-h-10',
						defaultValue: undefined,
						value: fields.reportsOverride.value
							? 'other'
							: fields.reportsTo.value
								? fields.reportsTo.value
								: '-',
						onValueChange: value => {
							if (value === 'other') {
								form.update({
									name: fields.reportsOverride.name,
									value: fields.reportsOverride.initialValue ?? '',
								})
							} else if (value == '-') {
								form.update({
									name: fields.reportsTo.name,
									value: '',
								})
								form.update({
									name: fields.reportsOverride.name,
									value: '',
								})
							} else {
								form.update({
									name: fields.reportsOverride.name,
									value: '',
								})
							}
						},
					}}
					options={[{ value: '-', label: '-' }]
						.concat(
							personas.map(p => ({
								value: String(p.id ?? ''),
								label: String(p.name ?? 'N/A'),
							})),
						)
						.concat([{ value: 'other', label: 'Other' }])}
					errors={fields.reportsTo.errors}
				/>
				<Field
					labelProps={{ children: 'Reports To (manual)' }}
					inputProps={{
						...getInputProps(fields.reportsOverride, { type: 'text' }),
						disabled:
							fields.reportsTo.value !== 'other' &&
							!fields.reportsOverride.value,
					}}
					errors={fields.reportsOverride.errors}
				/>
				<SelectTextInput
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Pain Length (# of words)
								<ProductTip content="Pain Length (# of words): Set the word count for describing pain points." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.painLength, { type: 'number' }),
						placeholder: 'Enter # of words',
					}}
					options={[
						{ value: '15', label: '15' },
						{ value: '30', label: '30' },
						{ value: '50', label: '50' },
						{ value: '100', label: '100' },
						{ value: '150', label: '150' },
					]}
					errors={fields.painLength.errors}
				/>
				<TextareaField
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								Fine tune pain
								<ProductTip content="Fine Tune Pain: Provide detailed pain points to guide AI prompts (e.g., Focus on new business revenue and account expansion, not retention)." />
							</div>
						),
					}}
					textareaProps={{
						...getTextareaProps(fields.painOverride),
					}}
					errors={fields.painOverride.errors}
				/>
			</section>

			<section className="mb-2 mt-6 bg-neutral-2-bg">
				<h3 className="flex items-center gap-1 px-4 py-2 text-label-sm font-semibold text-neutral-1-fg">
					AI Fine Tuning
					<ProductTip content="Here you can adjust the instructions given to the AI used to create the personas" />
				</h3>
			</section>

			<section className="flex flex-col gap-4 px-4">
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								AI Answer Format
								<ProductTip
									content="AI Answer Format: Describe the desired answer format in the input field at the top.
Choose which pieces of information should be included in the prompt by checking the corresponding boxes."
								/>
							</div>
						),
					}}
					inputProps={{
						...getInputProps(aiFineTuning.answerFormat, {
							type: 'text',
						}),
					}}
					errors={aiFineTuning.answerFormat.errors}
				/>
				<CheckboxField
					labelProps={{ children: 'Include Direct Report in the prompt?' }}
					buttonProps={{
						...getInputProps(aiFineTuning.directReport, {
							type: 'checkbox',
						}),
					}}
					errors={aiFineTuning.directReport.errors}
				/>
				<CheckboxField
					labelProps={{ children: 'Include Example Companies in the prompt?' }}
					buttonProps={{
						...getInputProps(aiFineTuning.exampleCompanies, {
							type: 'checkbox',
						}),
					}}
					errors={aiFineTuning.exampleCompanies.errors}
				/>
				<CheckboxField
					labelProps={{
						children:
							'Include Vertical business model description in the prompt?',
					}}
					buttonProps={{
						...getInputProps(aiFineTuning.bizModel, {
							type: 'checkbox',
						}),
					}}
					errors={aiFineTuning.bizModel.errors}
				/>
				<CheckboxField
					labelProps={{
						children:
							'Include the number of employees in the prompt in the prompt?',
					}}
					buttonProps={{
						...getInputProps(aiFineTuning.employeeNumber, {
							type: 'checkbox',
						}),
					}}
					errors={aiFineTuning.employeeNumber.errors}
				/>
				<CheckboxField
					labelProps={{
						children: 'Include locations from the ICPs?',
					}}
					buttonProps={{
						...getInputProps(aiFineTuning.regions, {
							type: 'checkbox',
						}),
						disabled: !!aiFineTuning?.regionsManual?.value?.length,
					}}
					errors={aiFineTuning.regions.errors}
				/>
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								or enter specific locations
								<ProductTip content="Provide Specific Details: If you checked the boxes for including locations or sub-verticals, enter the specific details in the text input fields provided." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(aiFineTuning.regionsManual, {
							type: 'text',
						}),
						onChange: e => {
							if (e.target.value?.length && aiFineTuning.regions.value) {
								form.update({
									name: aiFineTuning.regions.name,
									value: false,
								})
							}
						},
					}}
					errors={aiFineTuning.regionsManual.errors}
				/>
				<CheckboxField
					labelProps={{ children: 'Include Sub-Verticals from the ICPs?' }}
					buttonProps={{
						...getInputProps(aiFineTuning.subverticals, {
							type: 'checkbox',
						}),
						disabled: !!aiFineTuning?.subverticalsManual?.value?.length,
					}}
					errors={aiFineTuning.subverticals.errors}
				/>
				<Field
					labelProps={{
						children: (
							<div className="flex items-center gap-1">
								or enter specific Sub-Verticals
								<ProductTip content="Provide Specific Details: If you checked the boxes for including locations or sub-verticals, enter the specific details in the text input fields provided." />
							</div>
						),
					}}
					inputProps={{
						...getInputProps(aiFineTuning.subverticalsManual, {
							type: 'text',
						}),
						onChange: e => {
							if (e.target.value?.length && aiFineTuning.subverticals.value) {
								form.update({
									name: aiFineTuning.subverticals.name,
									value: false,
								})
							}
						},
					}}
					errors={aiFineTuning.subverticalsManual.errors}
				/>
				<Field
					labelProps={{
						children: (
							<>
								<span>
									Missing something? Add one more specific comment to the prompt
								</span>
								&nbsp;
								<span className="text-label-sm">
									*Complete this sentence: &quot;Before answering the question,
									remember that you are&quot;
								</span>
								<ProductTip
									className="align-middle"
									content="Add Additional Comments: If necessary, provide additional comments or instructions in the last text input field to guide the AI’s responses further."
								/>
							</>
						),
					}}
					inputProps={{
						...getInputProps(aiFineTuning.specificComment, {
							type: 'text',
						}),
					}}
					errors={aiFineTuning.specificComment.errors}
				/>

				<ErrorList errors={form.errors} id={form.errorId} />
			</section>
		</Form>
	)
}
