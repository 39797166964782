import { Link, Outlet, useRouteLoaderData } from 'react-router-dom'
import Header from '#src/components/header'
import Actions from '#src/components/header/actions'
import Crumbs from '#src/components/header/crumbs'
import PageLoader from '#src/components/loader'
import Panel, { PanelProvider, PanelToggle } from '#src/components/panel'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { type EnableLoaderResponse } from '#src/routes/enable'
import Sidebar from '#src/routes/enable/_layout/sidebar'
import { useCanSendMessageStatus } from '#src/routes/enable/chat/mutations'
import { routes } from '#src/utils/routes'
import ChatsPanel from './panel'

function Main() {
	const { chats, user, companyId, conversationId } = useRouteLoaderData(
		'enable-loader',
	) as EnableLoaderResponse

	const status = useCanSendMessageStatus()

	if (!chats?.length) {
		return (
			<>
				<Sidebar />

				<div
					id="scrollable"
					className="relative flex w-full flex-col overflow-auto"
				>
					<PageLoader
						status={status === 'pending' ? 'submitting' : undefined}
					/>
					<Outlet />
				</div>
			</>
		)
	}

	return (
		<>
			<Sidebar />

			<Panel
				header={
					<div className="flex w-full items-center justify-between gap-4">
						{user.company && user.roles.includes('enable-only') ? (
							<h1>{user.company}</h1>
						) : (
							<>
								<span className="w-full text-center text-body-sm font-semibold tracking-[.02em] text-neutral-3-fg">
									Co-pilot
								</span>
								<Button asChild size="sm" className="h-6 w-6 shrink-0 p-0">
									<Link
										to={routes.enable.copilot.new({
											companyId,
											conversationId: conversationId ?? null,
										})}
									>
										<Icon name="add" size="sm" />
									</Link>
								</Button>
							</>
						)}
					</div>
				}
			>
				<ChatsPanel />
			</Panel>

			<div
				id="scrollable"
				className="relative flex w-full flex-col overflow-auto"
			>
				<Header
					panelToggle={<PanelToggle />}
					crumbs={<Crumbs />}
					actions={<Actions />}
					className="border-b border-neutral-1-bd"
				/>
				<PageLoader status={status === 'pending' ? 'submitting' : undefined} />
				<Outlet />
			</div>
		</>
	)
}

export default function Layout() {
	return (
		<PanelProvider>
			<Main />
		</PanelProvider>
	)
}
