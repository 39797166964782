import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs } from 'react-router-dom'
import { userQuery } from '../init/user/me'
import { chatsQuery } from './chat/queries'

export type EnableLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const chats = await queryClient.fetchQuery(chatsQuery(params.companyId))
		const user = await queryClient.fetchQuery(userQuery())

		if (params.conversationId) {
			return {
				companyId: params.companyId,
				conversationId: params.conversationId,
				chats,
				user,
			}
		}

		return {
			companyId: params.companyId,
			chats,
			user,
		}
	}
