import { useState } from 'react'
import { cn } from '#src/utils/misc'
import { Icon } from '../ui/icon'
import { Input } from '../ui/input'

export function Search({ onSearch }: { onSearch: (query: string) => void }) {
	const [query, setQuery] = useState('')
	const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const target = evt.target

		setQuery(target.value)
		onSearch(target.value)
	}

	return (
		<div className="relative">
			<Icon
				name="search"
				size="sm"
				className="absolute left-2 top-1/2 -translate-y-1/2 text-neutral-3-fg"
			/>
			<Input
				value={query}
				onChange={handleSearch}
				className={cn(
					'h-8 bg-neutral-2-bg px-8 py-1.5 placeholder:text-neutral-4-fg',
					query ? 'px-8' : 'pr-2',
				)}
				placeholder="Search"
			/>
			{query && (
				<div
					onClick={() => {
						setQuery('')
						onSearch('')
					}}
					className="absolute right-0 top-1/2 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center text-neutral-3-fg"
				>
					<Icon name="close-filled" size="sm" />
				</div>
			)}
		</div>
	)
}

export function NoResults({ query }: { query: string }) {
	return (
		<div className="flex h-full flex-col justify-center gap-2">
			<Icon name="search-dialogue" className="h-16 w-16 text-brand-2-bd" />
			<div>
				<p className="text-center text-title-sm text-neutral-2-fg">
					No search results
				</p>
				<p className="break-words text-center text-body-md text-neutral-2-fg">
					No results for “{query}”
				</p>
			</div>
		</div>
	)
}
