import { useRef, useState } from 'react'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

export default function ChatInput({
	send,
	disabled,
}: {
	send: (message: string, intent: 'create' | 'resend') => void
	disabled: boolean
}) {
	const inputRef = useRef<HTMLTextAreaElement>(null)
	const [value, setValue] = useState('')

	const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(event.target.value)
	}

	const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter' && !event.shiftKey && !disabled) {
			event.preventDefault()
			void send(value, 'create')
			setValue('')
		}

		if (event.key === 'Enter' && event.shiftKey) {
			event.preventDefault()
			setValue(prev => prev + '\n')
		}
	}

	const handleSend = () => {
		if (disabled) return

		if (value.trim()) {
			void send(value.trim(), 'create')
			setValue('')

			if (inputRef.current) {
				inputRef.current.focus()
			}
		}
	}

	return (
		<section className="w-full bg-white pb-6">
			<label
				htmlFor="resize-textarea"
				className={cn(
					'font:text-body-md text-body-md text-neutral-1-fg placeholder:font-normal placeholder:text-neutral-2-fg',
					'cursor-text',
					'rounded border border-neutral-2-bd',
					'relative grid gap-x-2 px-3',
					'after:my-3 after:py-3 after:pr-32',
					'after:col-start-1 after:col-end-3',
					'after:row-start-1 after:row-end-3',
					'after:invisible after:whitespace-pre-wrap',
					`after:content-[attr(data-text)]`,
				)}
				data-text={value + ' '}
			>
				<textarea
					id="resize-textarea"
					ref={inputRef}
					className={cn(
						'resize-none overflow-hidden border-none bg-transparent outline-none',
						'my-3 py-3 pr-32',
						'col-start-1 col-end-3',
						'row-start-1 row-end-3',
						'self-stretch',
					)}
					rows={1}
					placeholder="Type a message..."
					value={value}
					onKeyDown={handleKeyPress}
					onChange={handleInputChange}
				/>
				<Button
					disabled={disabled}
					type="button"
					variant="default"
					className={cn('w-24 gap-1', 'absolute bottom-3.5 right-3')}
					onClick={handleSend}
				>
					<Icon name="send" />
					Send
				</Button>
			</label>
		</section>
	)
}
