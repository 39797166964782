import {
	useQuery,
	useQueryClient,
	type QueryClient,
} from '@tanstack/react-query'
import { format } from 'date-fns'
import { useEffect, useRef } from 'react'
import {
	type LoaderFunctionArgs,
	Outlet,
	useLoaderData,
	useNavigate,
	useRevalidator,
	useMatch,
} from 'react-router-dom'
import { z } from 'zod'
import ContactAvatar, {
	avatarVariantByPersonaType,
} from '#src/components/chat/avatar'
import ChatInput from '#src/components/chat/input'
import ChatLayout from '#src/components/chat/layout'
import Message from '#src/components/chat/message'
import Resolved from '#src/components/chat/resolved'
import { Icon } from '#src/components/ui/icon'
import { Logo } from '#src/components/ui/logo'
import { Surface } from '#src/components/ui/surface'
import { userQuery } from '#src/routes/init/user/me'
import { UserSchema } from '#src/routes/init/user/schema'
import {
	checkIsAdminSession,
	checkIsDataTrainerSession,
	cn,
} from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { useSendMessageMutation } from './mutations'
import {
	aidtKeys,
	chatQuery,
	chatsQuery,
	conversationQuery,
	unreadChatsQuery,
} from './queries'
import { ChatsSchema, ConversationDataSchema } from './schema'
import { streamMessageQuery } from './stream'

export type AidtLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const AidtLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		conversationId: z.string().nullable().optional(),
		conversationData: ConversationDataSchema.nullable(),
	}),
	conversationStatus: z
		.enum([
			'unread',
			'initializing',
			'ready',
			'closed',
			'waiting_for_contact',
			'crashed',
		])
		.nullable(),
	chats: ChatsSchema,
	user: UserSchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const user = await queryClient.fetchQuery(userQuery())

		if (
			!checkIsDataTrainerSession(user.roles) &&
			!checkIsAdminSession(user.roles)
		) {
			throw new Response('Page Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		const chats = await queryClient.fetchQuery(chatsQuery(params.companyId))

		if (!params.conversationId)
			return {
				handle: {
					companyId: params.companyId,
					conversationId: undefined,
					conversationData: null,
				},
				conversationStatus: null,
				chats,
				user,
			}

		const conversationData = await queryClient.fetchQuery(
			conversationQuery(params.companyId, params.conversationId),
		)

		return {
			handle: {
				companyId: params.companyId,
				conversationId: params.conversationId,
				conversationData,
			},
			conversationStatus: conversationData?.conversation.status,
			chats,
			user,
		}
	}

export default function Aidt() {
	const {
		chats,
		handle: { conversationId },
	} = useLoaderData() as AidtLoaderResponse

	if (!chats?.length) {
		return (
			<main className="flex w-full flex-grow flex-col items-center px-20 pb-20">
				<NoChats />

				<Outlet />
			</main>
		)
	}

	if (!conversationId) {
		return (
			<main className="flex w-full flex-grow flex-col items-center px-20">
				<Dashboard />

				<Outlet />
			</main>
		)
	}

	return (
		<main className="relative grid h-[calc(100%-var(--builder-header-height))] w-full grid-cols-[1fr,max-content]">
			<Conversation />

			<Outlet />
		</main>
	)
}

function Dashboard() {
	const params = useParsedRouteParams(['companyId'])
	const navigate = useNavigate()
	const { chats } = useLoaderData() as AidtLoaderResponse

	return (
		<>
			<section className="w-full max-w-[1126px] py-6">
				<h1 className="text-heading-sm text-neutral-1-fg">Lead Research Agent</h1>
			</section>
			<section className="w-full max-w-[1126px] pb-20">
				<table className="w-full">
					<thead className="text-left">
						<tr>
							<th className="py-3 pl-3 text-label-md text-neutral-2-fg">
								Lead Research Agent questions
							</th>
							<th className="py-3 text-label-md text-neutral-2-fg">Status</th>
							<th className="py-3 text-label-md text-neutral-2-fg">
								Last updated
							</th>
							<th></th>
						</tr>
					</thead>

					<tbody className="text-left">
						{chats.map(chat => {
							const status =
								chat.conversation.status === 'closed'
									? 'resolved'
									: 'unresolved'

							return (
								<tr
									key={chat.conversation.id}
									className="cursor-pointer border-t border-neutral-1-bd transition-colors first:border-none hover:bg-neutral-1-bg-hover has-[.action-button:hover]:bg-transparent"
									onClick={() =>
										navigate(
											routes.organize.aidt.index({
												companyId: params.companyId,
												conversationId: chat.conversation.id,
											}),
										)
									}
								>
									<td className="py-4 pl-3">
										<div className="grid grid-cols-[max-content,1fr] items-center gap-x-2">
											<ContactAvatar
												className="inline-flex shrink-0 items-center justify-center rounded-full"
												variant={avatarVariantByPersonaType(
													chat.conversation.type?.toLowerCase(),
												)}
												size="md"
												initial={chat.conversation.name
													.slice(0, 1)
													.toUpperCase()}
												badge={
													(chat.conversation.status === 'closed' && (
														<Icon
															name="checkmark-filled-bordered"
															className="absolute bottom-0 right-0 h-3 w-3 text-status-success-bg"
														/>
													)) ||
													(chat.conversation.status === 'unread' && (
														<div className="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-brand-1-fg" />
													))
												}
											/>
											<div>
												<p
													className={cn(
														'w-full truncate text-body-md font-semibold text-neutral-1-fg',
														status === 'resolved' && 'text-neutral-3-fg',
													)}
												>
													{chat.conversation.name}
												</p>
												<p
													className={cn(
														'w-full max-w-96 truncate text-body-sm font-medium text-neutral-1-fg',
														status === 'resolved' && 'text-neutral-3-fg',
														chat.conversation.status === 'ready' &&
															'text-neutral-2-fg',
													)}
												>
													{chat.lastMessage}
												</p>
											</div>
										</div>
									</td>
									<td className="py-4">
										<Status status={status} />
									</td>
									<td className="py-4">
										<p className="text-body-md text-neutral-1-fg">
											{format(new Date(chat.conversation.createdAt), 'PP')}
										</p>
									</td>
									<td className="py-4 pr-3 text-right">
										<Icon
											name="chevron-right"
											size="sm"
											className="text-neutral-2-fg"
										/>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</section>
		</>
	)
}

function Conversation() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const {
		user,
		handle: { conversationData },
	} = useLoaderData() as AidtLoaderResponse

	if (!conversationData) {
		throw new Error('Missing conversationData')
	}

	const { send, status } = useSendMessageMutation({
		companyId: params.companyId,
		conversationId: params.conversationId,
	})

	const { data: chat } = useQuery(
		chatQuery(params.companyId, params.conversationId),
	)

	if (['crashed'].includes(conversationData?.conversation.status)) {
		return <ChatCrashed />
	}

	if (
		!['ready', 'closed', 'unread'].includes(
			conversationData?.conversation.status,
		)
	) {
		return <ChatPending />
	}

	return (
		<ChatLayout
			key={`${params.conversationId}-${status === 'pending' ? 'pending' : 'default'}`}
			containerClassName={
				chat?.length ? 'flex flex-col gap-6' : 'h-full flex flex-col gap-8'
			}
			status={
				<>
					{conversationData?.conversation.status === 'closed' && <Resolved />}
				</>
			}
			content={
				<>
					{(chat || []).map(message => (
						<Message
							key={message.id}
							message={{
								id: message.id,
								message: message.message,
								author: {
									name: message.isAi
										? conversationData.conversation.name
										: message.author,
									avatarVariant: avatarVariantByPersonaType(
										conversationData.conversation.type,
									),
									showAuthorName:
										message.isAi ||
										(checkIsAdminSession(user.roles) &&
											!conversationData.writable),
								},
								status: message.status,
								isReply: message.isAi,
							}}
						/>
					))}

					<StreamedMessage />
				</>
			}
			footer={
				<>
					{conversationData.writable &&
						conversationData?.conversation?.status !== 'closed' && (
							<ChatInput send={send} disabled={status === 'pending'} />
						)}
				</>
			}
		/>
	)
}

function StreamedMessage() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const {
		handle: { conversationData },
	} = useLoaderData() as AidtLoaderResponse

	if (!conversationData) {
		throw new Error('Missing parameters')
	}

	const { data: streamedMessage } = useQuery(
		streamMessageQuery({
			companyId: params.companyId,
			conversationId: params.conversationId,
		}),
	)

	if (!streamedMessage) return null

	return (
		<Message
			message={{
				id: streamedMessage.id,
				message: streamedMessage.message,
				author: {
					name: conversationData.conversation.name,
					avatarVariant: avatarVariantByPersonaType(
						conversationData.conversation.type,
					),
					showAuthorName: true,
				},
				status: streamedMessage.status,
				isReply: streamedMessage.isAi,
			}}
		/>
	)
}

function Status({ status }: { status: 'resolved' | 'unresolved' }) {
	return (
		<div
			className={cn(
				'flex items-center gap-0.5 text-label-md',
				(status === 'resolved' && 'text-neutral-inverse-bd') ||
					(status === 'unresolved' && 'text-coolgray-60'),
			)}
		>
			{(status === 'resolved' && (
				<>
					<Icon
						size="sm"
						name="checkmark-filled"
						className="text-status-success-bg"
					/>
					Resolved
				</>
			)) ||
				(status === 'unresolved' && (
					<>
						<Icon
							size="sm"
							name="checkmark-outline"
							className="text-coolgray-60"
						/>
						Unresolved
					</>
				))}
		</div>
	)
}

function NoChats() {
	return (
		<>
			<section className="w-full max-w-[1126px] py-6">
				<h1 className="text-heading-sm text-neutral-1-fg">Lead Research Agent questions</h1>
			</section>
			<Surface className="flex min-h-[360px] w-full max-w-[1126px] flex-grow flex-col items-center justify-center gap-2 border-none bg-neutral-2-bg">
				<Icon name="conversation" className="h-16 w-16 text-brand-1-fg" />
				<div>
					<h1 className="text-center text-title-sm text-neutral-2-fg">
						You don’t have any Lead Research Agent questions.
					</h1>
					<p className="text-center text-body-md text-neutral-2-fg">
						Contact your manager for further assistance
					</p>
				</div>
			</Surface>
		</>
	)
}

function ChatPending() {
	return (
		<div className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden">
			<div className="relative flex flex-col items-center justify-center gap-4">
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-1 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[670px] w-[42.5%] min-w-[670px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.06]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-2 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[500px] w-[32.5%] min-w-[500px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.09]" />
				</div>
				<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-3 select-none flex-col items-center justify-center opacity-0">
					<div className="min-h-[330px] w-[22.5%] min-w-[330px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-[0.14]" />
				</div>
				<Logo size="xl" type="symbol" />
				<p className="text-title-md text-neutral-1-fg">Initializing...</p>
			</div>
		</div>
	)
}

function ChatCrashed() {
	return (
		<div className="relative flex h-full w-full flex-col items-center justify-center gap-4 overflow-hidden">
			<Logo size="xl" type="symbol" />
			<h1 className="text-heading-sm text-neutral-1-fg">Ouch!</h1>
			<section className="flex flex-col items-center justify-center gap-1 text-center">
				<p className="text-title-sm text-neutral-1-fg">
					There was an issue with the chat.
				</p>
				<p className="text-body-sm font-normal text-neutral-1-fg">
					Our team is aware and working to fix the issue — apologies for any
					inconvenience.
				</p>
			</section>
		</div>
	)
}

export function useUnreadChats(isEnabled: boolean) {
	const params = useParsedRouteParams(['companyId'])

	const queryClient = useQueryClient()
	const revalidator = useRevalidator()
	const previousUnreadChatsRef = useRef<z.infer<typeof ChatsSchema> | null>(
		null,
	)
	const aidtMatch = Boolean(useMatch('/enable/:companyId/aidt'))

	const { data: unreadChats } = useQuery({
		...unreadChatsQuery(params.companyId),
		refetchInterval: () => {
			return 60 * 1000 // 1min
		},
		refetchIntervalInBackground: true,
		enabled: isEnabled,
	})

	useEffect(() => {
		const revalidate = async () => {
			if (unreadChats && previousUnreadChatsRef.current) {
				const previousLength = previousUnreadChatsRef.current.length
				const currentLength = unreadChats.length

				if (currentLength > previousLength) {
					await queryClient.invalidateQueries({
						queryKey: aidtKeys.all,
					})

					if (aidtMatch) {
						revalidator.revalidate()
					}
				}
			}

			previousUnreadChatsRef.current = unreadChats ?? null
		}

		void revalidate()
	}, [unreadChats, queryClient, aidtMatch, revalidator])

	return { unreadChats }
}
