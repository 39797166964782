import {
	getFormProps,
	getInputProps,
	getTextareaProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import { type QueryClient } from '@tanstack/react-query'
import {
	type ActionFunctionArgs,
	Form,
	redirect,
	useActionData,
} from 'react-router-dom'
import { ErrorList, Field, TextareaField } from '#src/components/forms'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import { createCompany } from '#src/routes/admin/mutations'
import {
	companiesQuery,
	companyQuery,
} from '#src/routes/calibrate/company/queries'
import { useIsPending } from '#src/utils/misc'
import { NewCompanyFormSchema } from './schema'

type NewCompanyActionResponse = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ request }: ActionFunctionArgs) => {
		const formData = await request.formData()

		const submission = parseWithZod(formData, { schema: NewCompanyFormSchema })

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		try {
			const company = await createCompany(submission.value)

			await queryClient.invalidateQueries({
				queryKey: companyQuery(company.id).queryKey,
			})
			await queryClient.invalidateQueries({
				queryKey: companiesQuery().queryKey,
			})

			return redirect(`/company/${company.id}/details/ecosystem`)
		} catch (error) {
			captureException(error)

			let errorResponse = 'There was error submitting the form'

			if (error instanceof Error) {
				errorResponse = error.message
			} else if (error instanceof Response) {
				const response = (await error.json()) as { message: string }

				if ('message' in response) errorResponse = response.message
			}

			return {
				ok: false,
				result: submission.reply({
					formErrors: [errorResponse],
				}),
			}
		}
	}

export default function New() {
	const actionData = useActionData() as NewCompanyActionResponse
	const isPending = useIsPending()
	const [form, fields] = useForm({
		id: 'company-form',
		constraint: getZodConstraint(NewCompanyFormSchema),
		lastResult: actionData && 'result' in actionData ? actionData.result : null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: NewCompanyFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<main className="grid h-full w-full grid-cols-1 grid-rows-1 items-start justify-items-center">
			<Surface className="flex max-w-screen-md flex-col items-center gap-6 px-24 py-10">
				<h1 className="text-center text-title-md">
					Define product value proposition <br /> by describing your services
				</h1>

				<Form
					method="POST"
					{...getFormProps(form)}
					className="flex w-full flex-col gap-6"
				>
					<Field
						labelProps={{ children: 'Name' }}
						inputProps={{
							...getInputProps(fields.name, { type: 'text' }),
							autoFocus: true,
							placeholder: 'Enter company name',
						}}
						errors={fields.name.errors}
					/>

					<div className="flex flex-col gap-4">
						<TextareaField
							labelProps={{
								children: 'Value Proposition',
							}}
							textareaProps={{
								...getTextareaProps(fields.valueProposition),
								placeholder: 'Paste or type here',
								rows: 10,
							}}
							errors={fields.valueProposition.errors}
						/>
						<p className="w-full rounded-sm bg-brand-1-bg p-2 text-center text-body-sm">
							Use our free{' '}
							<a
								href="https://chatgpt.com/g/g-5eVvHz6wz-value-prop-expert-v16"
								target="__blank"
								title="Value Prop Sage GPT"
								className="text-link transition-colors hover:text-link-hover active:text-link-pressed"
							>
								<Icon name="magic-wand" asSuffix>
									Value Proposition Sage GPT
								</Icon>
							</a>{' '}
							AI helper. Paste your Website URL, upload files and answer few
							questions to get your company&apos;s value proposition fast!
						</p>
					</div>

					<ErrorList errors={form.errors} id={form.errorId} />

					<div className="flex w-full justify-center">
						<StatusButton
							status={isPending ? 'pending' : form.status ?? 'idle'}
							type="submit"
							disabled={isPending}
							size="md"
							className="min-w-40"
						>
							Continue
						</StatusButton>
					</div>
				</Form>
			</Surface>
		</main>
	)
}
