import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import {
	type ActionFunctionArgs,
	redirect,
	type LoaderFunctionArgs,
} from 'react-router-dom'
import { routes } from '#src/utils/routes'
import { ecosystemKeys } from '../ecosystem/queries'
import { createPersonas, updatePersona } from './mutations'
import { personaKeys, personaQuery } from './queries'
import { PersonaEditFormSchema, PersonaUpdateFormSchema } from './schema'

export type PersonaActionResponse = Awaited<
	ReturnType<ReturnType<typeof action>>
>

export type PersonaLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (params.companyId && params.ecosystemId && params.personaId) {
			return {
				persona: await queryClient.fetchQuery(
					personaQuery(params.companyId, params.personaId),
				),
			}
		}

		return {
			persona: null,
		}
	}

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()
		const intent = formData.get('intent')

		if (!intent || intent !== 'persona')
			throw redirect(
				routes.calibrate.persona.index({
					companyId: params.companyId,
					ecosystemId: params.ecosystemId,
					verticalId: params.verticalId,
					personaId: params.personaId || null,
				}),
			)

		let personaId = formData.get('id')?.toString()

		if (personaId) {
			const submission = parseWithZod(formData, {
				schema: PersonaUpdateFormSchema,
			})

			if (submission.status !== 'success') {
				throw Error('Failed to parse form data')
			}

			const data = submission.value

			// NOTE: clever way to remove a keys from an object
			// NOTE: do not send redundant data to API
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { reportsTo, reportsOverride, prompt, pain, personaMapping, vertical, id, ...persona } = await queryClient.ensureQueryData(
				personaQuery(params.companyId, personaId.toString()),
			)

			await updatePersona(params.companyId, personaId.toString(), {
				...(persona ?? {}),
				reportsTo: data.reportsTo || reportsTo?.id || null,
				reportsOverride: data.reportsOverride || reportsOverride || null,
				...data,
			})
		} else {
			const submission = parseWithZod(formData, {
				schema: PersonaEditFormSchema,
			})

			if (submission.status !== 'success') {
				throw Error('Failed to parse form data')
			}

			const data = submission.value

			const response = await createPersonas(params.companyId, data)

			if (!response?.length) throw Error('Failed to create persona')

			if (response[0].id) personaId = response[0].id.toString()
		}

		await queryClient.invalidateQueries({
			queryKey: personaKeys.all,
		})
		void queryClient.invalidateQueries({
			queryKey: ecosystemKeys.list(params.companyId),
		})

		return redirect(
			routes.calibrate.persona.index({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				verticalId: params.verticalId,
				personaId: personaId!,
			}),
		)
	}
