import * as SelectPrimitive from '@radix-ui/react-select'
import {
	forwardRef,
	type ComponentPropsWithoutRef,
	type ElementRef,
} from 'react'

import { cn } from '#src/utils/misc'
import { Icon } from './icon'

export type SelectProps = ComponentPropsWithoutRef<
	typeof SelectPrimitive.Root
> & {
	triggerProps?: ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
	contentProps?: ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
	id?: string
	className?: string
	placeholder?: string
}

const Select = forwardRef<ElementRef<typeof SelectPrimitive.Root>, SelectProps>(
	(
		{ children, className, triggerProps, contentProps, placeholder, ...props },
		ref,
	) => {
		return (
			<SelectRoot {...props}>
				<SelectTrigger
					className={cn(
						'transition-all',
						'grid h-10 w-full grid-cols-[1fr,max-content] grid-rows-1 items-center rounded px-3 py-2.5',
						'bg-transparent disabled:bg-neutral-2-bg',
						'border border-neutral-2-bd outline-none hover:border-neutral-2-bd-selected focus-visible:border-brand-2-bd aria-[invalid]:border-status-danger-bd',
						triggerProps?.autoFocus && 'focus-visible:border-brand-2-bd',
						'text-body-md text-neutral-1-fg placeholder:text-neutral-inverse-fg disabled:text-neutral-inverse-fg-disabled',
						'disabled:cursor-not-allowed disabled:opacity-50',
						'file:border-0 file:bg-transparent file:text-button-sm',
						'*:w-full *:overflow-hidden *:truncate *:text-left',
						className,
					)}
					{...triggerProps}
					ref={ref}
				>
					<SelectValue placeholder={placeholder} />
					<SelectIcon>
						<Icon name="chevron-down" />
					</SelectIcon>
				</SelectTrigger>
				<SelectContent {...contentProps}>{children}</SelectContent>
			</SelectRoot>
		)
	},
)
Select.displayName = SelectPrimitive.Root.displayName

const SelectRoot = SelectPrimitive.Root

const SelectValue = SelectPrimitive.Value

const SelectIcon = SelectPrimitive.Icon

const SelectTrigger = SelectPrimitive.Trigger

const SelectContent = forwardRef<
	ElementRef<typeof SelectPrimitive.Content>,
	ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ children, className, ...props }, ref) => {
	return (
		<SelectPrimitive.Content
			className={cn(
				'z-50 flex flex-col gap-2 overflow-hidden rounded border border-neutral-1-bd bg-neutral-1-bg py-2 text-neutral-2-fg shadow',
				className,
			)}
			ref={ref}
			{...props}
		>
			<SelectPrimitive.ScrollUpButton />
			<SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
			<SelectPrimitive.ScrollDownButton />
		</SelectPrimitive.Content>
	)
})
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectItem = forwardRef<
	ElementRef<typeof SelectPrimitive.Item>,
	ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ children, className, ...props }, ref) => {
	return (
		<SelectPrimitive.Item
			className={cn(
				'flex cursor-pointer select-none items-center justify-between gap-2 px-2 py-1.5 text-body-md outline-none hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover radix-disabled:cursor-not-allowed radix-disabled:opacity-50 radix-disabled:hover:bg-transparent',
				className,
			)}
			{...props}
			ref={ref}
		>
			<SelectItemText>{children}</SelectItemText>
			<SelectItemIndicator>
				<Icon name="check" />
			</SelectItemIndicator>
		</SelectPrimitive.Item>
	)
})
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectItemText = SelectPrimitive.ItemText
const SelectItemIndicator = SelectPrimitive.ItemIndicator

export {
	Select,
	SelectRoot,
	SelectValue,
	SelectIcon,
	SelectItem,
	SelectItemText,
	SelectItemIndicator,
	SelectTrigger,
	SelectContent,
}
