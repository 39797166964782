import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import MainLayout, { loader as mainLoader } from '#src/routes/_layout/main'
import dashboardRouter from '#src/routes/_router/dashboard'
import calibrateRouter from './calibrate'
import companyRouter from './company'
import enableRouter from './enable'
import organizeRouter from './organize'
import prioritizeRouter from './prioritize'

export default (client: QueryClient) =>
	({
		id: 'main-loader',
		element: <MainLayout />,
		path: '/',
		loader: mainLoader(client),
		children: [
			dashboardRouter(client),
			companyRouter(client),
			calibrateRouter(client),
			prioritizeRouter(client),
			organizeRouter(client),
			enableRouter(client),
		],
	}) satisfies RouteObject
