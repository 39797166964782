import { type QueryClient } from '@tanstack/react-query'
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { deleteVertical } from './mutations'
import { verticalKeys } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await deleteVertical(params.companyId, params.verticalId)

		await queryClient.invalidateQueries({
			queryKey: verticalKeys.all,
		})
		void queryClient.invalidateQueries({
			queryKey: ecosystemKeys.list(params.companyId),
		})

		return redirect(
			routes.calibrate.verticals.index({
				companyId: params.companyId,
				ecosystemId: params.ecosystemId!,
			}),
		)
	}

export interface DeleteVerticalAsyncFormProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: number
	verticalId: string
	children: ReactNode
}

export const DeleteVerticalAsyncForm = forwardRef<
	HTMLButtonElement,
	DeleteVerticalAsyncFormProps
>(({ ecosystemId, verticalId, children, className, ...props }, ref) => {
	const params = useParsedRouteParams(['companyId'])
	const action = routes.calibrate.verticals.delete({
		companyId: params.companyId,
		ecosystemId: ecosystemId.toString(),
		verticalId: verticalId,
	})

	return (
		<DeleteConfirm
			trigger={
				<button
					{...props}
					ref={ref}
					type="button"
					className={cn('flex items-center justify-center', className)}
				>
					{children}
				</button>
			}
			action={action}
			title="Remove vertical?"
			description="This action cannot be undone. This will permanently delete the vertical and remove any associated data such as personas."
		/>
	)
})
DeleteVerticalAsyncForm.displayName = 'DeleteVerticalAsyncForm'
