import { Link, useLoaderData } from 'react-router-dom'
import { checkIsReadOnlySession } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { type RoleplayLoaderResponse } from '../routes/enable/roleplay'
import { Button } from './ui/button'
import { Icon } from './ui/icon'
import { Surface } from './ui/surface'

export default function NoRoleplay() {
	const { user } = useLoaderData() as RoleplayLoaderResponse
	const params = useParsedRouteParams(['companyId'])

	const readOnlySession = checkIsReadOnlySession(user.roles)

	return (
		<Surface className="flex min-h-[360px] w-full max-w-[920px] flex-col items-center justify-center gap-4 border-none bg-neutral-2-bg">
			<Icon name="chat" size="xl" className="text-neutral-3-fg" />

			<h1 className="text-center text-body-lg text-neutral-2-fg">
				You don&apos;t have any roleplay chats yet.
			</h1>

			{!readOnlySession ? (
				<div className="flex items-center gap-2">
					<Button asChild className="flex items-center gap-1">
						<Link
							to={routes.enable.roleplay.new({
								companyId: params.companyId,
								newRoleplaySessionType: 'group',
							})}
						>
							<Icon name="user-multiple" /> New Group Session
						</Link>
					</Button>
					<Button asChild className="flex items-center gap-1">
						<Link
							to={routes.enable.roleplay.new({
								companyId: params.companyId,
								newRoleplaySessionType: 'solo',
							})}
						>
							<Icon name="user" /> New Solo Session
						</Link>
					</Button>
				</div>
			) : null}
		</Surface>
	)
}
