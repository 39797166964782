import { useMatches } from 'react-router-dom'
import { z } from 'zod'

export const CrumbSchema = z
	.object({
		to: z.string().optional(),
		id: z.string().optional(),
		action: z.string().optional(),
		method: z.enum(['PUT', 'POST', 'PATCH', 'DELETE']).optional(),
		label: z.string().optional(),
		icon: z.string().optional(),
		className: z.string().optional(),
		onClick: z.function().args().returns(z.any()).optional(),
	})
	.array()
	.optional()

const MatchesSchema = z
	.object({
		data: z.any(),
		handle: z
			.object({
				crumb: z.function().returns(CrumbSchema.optional()).optional(),
				action: z.function().returns(CrumbSchema.optional()).optional(),
			})
			.optional(),
	})
	.array()

export function useCrumbs(type: 'crumb' | 'action') {
	const matches = MatchesSchema.parse(useMatches())

	const crumbs = matches.reduce((acc: z.infer<typeof CrumbSchema>, match) => {
		const handleCrumb = match.handle?.[type]

		if (!handleCrumb) return acc

		const routeCrumbs = handleCrumb(match.data)

		return routeCrumbs?.length
			? [...(acc?.length ? acc : []), ...routeCrumbs]
			: acc
	}, [])

	if (!crumbs?.length) return []

	const parsedCrumbs = CrumbSchema.parse(crumbs)

	return parsedCrumbs?.length ? parsedCrumbs : []
}
