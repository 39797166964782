import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { useEffect, useState } from 'react'
import { Link, useParams, useRouteLoaderData } from 'react-router-dom'
import { PanelSectionItem, PanelSectionItemAction } from '#src/components/panel'
import { personaTypeColorVariants } from '#src/components/persona'
import { PriorityIcon } from '#src/components/priority'
import { StatusDot } from '#src/components/status'
import {
	Dropdown,
	DropdownItem,
	DropdownLabel,
	DropdownSub,
	DropdownSubContent,
	DropdownSubTrigger,
} from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { DeleteEcosystemAsyncForm } from '#src/routes/calibrate/ecosystem/delete'
import { DeletePersonaAsyncForm } from '#src/routes/calibrate/personas/delete'
import {
	RecalculatePersonaAsyncForm,
	RecalculatePersonasAsyncForm,
} from '#src/routes/calibrate/personas/recalculate'
import { autofocusVerticalMutation } from '#src/routes/calibrate/verticals/autofocus'
import { DeleteVerticalAsyncForm } from '#src/routes/calibrate/verticals/delete'
import { cn, formatPersonaTypeAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type CalibrateNavPersona = {
	id: number
	to: string
	title: string
	type: string
	status: string
	priority: string
}

type CalibrateNavVertical = {
	id: number
	ecosystemId: number | undefined
	to: string
	name: string
	duplicate: string
	addPersona: string
	personas: CalibrateNavPersona[]
}

type CalibrateNavEcosystem = {
	id: number | undefined
	to: string
	rename: string
	addCriteria: string
	addVertical: string
	name: string
	verticals: CalibrateNavVertical[]
}

type CalibrateNav = {
	title: string
	add: string
	ecosystems: CalibrateNavEcosystem[] | null
}

function PersonasPanelItem({
	vertical,
	isActive,
}: {
	vertical: CalibrateNavVertical
	isActive: boolean
}) {
	const { ecosystems, readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse
	const { personas } = vertical
	const params = useParams()

	return personas?.length ? (
		personas.map((persona, index) => (
			<PanelSectionItem
				key={index}
				isTrigger
				className="group gap-2 pl-8 text-body-md"
				isActive={persona.id === Number(params.personaId)}
			>
				<Link
					to={persona.to}
					className="-my-2 grid w-full grid-cols-[max-content,1fr] items-center gap-2 py-1 radix-disabled:opacity-50"
				>
					<span
						className={cn(
							personaTypeColorVariants({
								type: persona.type,
								className:
									'flex h-8 w-8 items-center justify-center rounded-full bg-neutral-1-bg text-label-sm',
							}),
						)}
					>
						{formatPersonaTypeAbbr(persona.type)}
					</span>
					<span
						className={cn(
							'truncate',
							isActive && persona.id === Number(params.personaId)
								? 'font-semibold'
								: '',
						)}
					>
						{persona.title}
					</span>
				</Link>

				{readOnlySession ? null : (
					<section
						className={cn(
							'panel-section-item-actions-meta',
							'grid-cols-[repeat(2,20px)] justify-items-end',
						)}
					>
						<Dropdown
							key={persona.id.toString()}
							trigger={
								<PanelSectionItemAction asChild isTrigger>
									<button type="button" data-type="contextmenu">
										<Icon name="overflow-menu-horizontal" size="sm" />
									</button>
								</PanelSectionItemAction>
							}
							contentProps={{
								side: 'bottom',
								align: 'start',
							}}
						>
							<DropdownItem asChild className="justify-start">
								<RecalculatePersonaAsyncForm
									ecosystemId={vertical.ecosystemId!}
									verticalId={vertical.id.toString()}
									personaId={persona.id.toString()}
									className="flex items-center gap-2"
									size="none"
								>
									<Icon name="rotate" size="sm" />
									Recalculate Persona
								</RecalculatePersonaAsyncForm>
							</DropdownItem>
							<DropdownItem
								asChild
								className="justify-start"
								onSelect={e => e.preventDefault()}
							>
								<DeletePersonaAsyncForm
									ecosystemId={vertical.ecosystemId!.toString()}
									verticalId={vertical.id.toString()}
									personaId={persona.id.toString()}
								>
									<div className="flex items-center gap-2">
										<Icon name="trash" size="sm" />
										Delete Persona
									</div>
								</DeletePersonaAsyncForm>
							</DropdownItem>
						</Dropdown>

						<Dropdown
							trigger={
								<PanelSectionItemAction asChild isTrigger>
									<button type="button" data-type="contextmenu">
										<Icon name="copy" size="sm" aria-hidden />
									</button>
								</PanelSectionItemAction>
							}
							contentProps={{
								side: 'bottom',
								align: 'start',
							}}
						>
							{ecosystems?.map(e => (
								<DropdownSub key={e.id}>
									<DropdownSubTrigger>{e.name}</DropdownSubTrigger>
									<DropdownSubContent>
										{e.verticals.length ? (
											e.verticals.map(v => (
												<DropdownItem
													key={v.id}
													asChild
													className="justify-start"
												>
													<Link
														replace
														preventScrollReset
														to={routes.calibrate.persona.duplicate({
															companyId: params.companyId!,
															ecosystemId: e.id!.toString(),
															verticalId: v.id.toString(),
															personaId: v.personas?.[0]?.id.toString() ?? null,
															duplicate: btoa(
																JSON.stringify({
																	ecosystemId: vertical.ecosystemId,
																	personaId: persona.id,
																}),
															),
														})}
														title={v.name}
													>
														{v.name}
													</Link>
												</DropdownItem>
											))
										) : (
											<DropdownLabel>No verticals</DropdownLabel>
										)}
									</DropdownSubContent>
								</DropdownSub>
							))}
						</Dropdown>
					</section>
				)}

				<section
					className={cn(
						readOnlySession ? 'grid' : 'panel-section-item-actions-primary',
						'grid-cols-[repeat(2,20px)] justify-items-end',
					)}
				>
					<StatusDot status={persona.status} />
					<PriorityIcon priority={persona.priority} />
				</section>
			</PanelSectionItem>
		))
	) : (
		<PanelSectionItem
			asChild
			isTrigger
			className="gap-2 pl-8 text-body-md text-link hover:bg-transparent hover:text-link-hover active:text-link-pressed"
		>
			<Link to={vertical.addPersona}>
				<Icon name="add" size="sm" aria-hidden />
				Add Persona
			</Link>
		</PanelSectionItem>
	)
}

function VerticalsPanelItem({
	verticals,
}: {
	verticals: CalibrateNavVertical[]
}) {
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse
	const [open, setOpen] = useState<string[]>([])
	const params = useParams()

	useEffect(() => {
		if (params.ecosystemId && params.personaId) {
			const openVertical = verticals.find(
				vertical =>
					!!vertical.personas.find(p => p.id.toString() === params.personaId),
			)

			const newOpen = openVertical
				? [...open, openVertical.id.toString()]
				: [...open]

			setOpen(newOpen)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.ecosystemId, params.personaId])

	return (
		<AccordionPrimitive.Root
			className="flex flex-col"
			type="multiple"
			value={open}
			onValueChange={setOpen}
		>
			{verticals.map(vertical => (
				<AccordionPrimitive.Item
					key={vertical.id}
					value={vertical.id.toString()}
				>
					<PanelSectionItem
						className="group justify-between"
						isTrigger
						isActive={
							open.includes(vertical.id.toString()) &&
							vertical.personas.some(p => p.id.toString() === params.personaId)
						}
					>
						<section className="-my-2 flex w-full flex-nowrap items-start gap-1 truncate py-2">
							<AccordionPrimitive.Trigger
								className="group"
								disabled={!vertical.personas?.length && readOnlySession}
							>
								<Icon
									name="chevron-down"
									size="sm"
									aria-hidden
									className="transform text-neutral-3-fg group-disabled:invisible group-radix-state-open:rotate-180"
								/>
							</AccordionPrimitive.Trigger>
							<Link
								to={`${vertical.to}`}
								className="-my-2 flex w-full flex-nowrap gap-2 truncate py-2"
								onClick={() =>
									autofocusVerticalMutation(vertical.id.toString())
								}
							>
								<Icon
									name="category"
									size="sm"
									aria-hidden
									className="shrink-0 text-neutral-3-fg"
								/>
								<span
									className={cn(
										'truncate',
										open.includes(vertical.id.toString())
											? 'font-semibold'
											: '',
									)}
								>
									{vertical.name}
								</span>
							</Link>
						</section>

						{readOnlySession ? null : (
							<section
								className={cn(
									'panel-section-item-actions-meta',
									'grid-cols-[repeat(2,20px)] justify-items-end',
								)}
							>
								<Dropdown
									key={vertical.id}
									trigger={
										<PanelSectionItemAction asChild isTrigger>
											<button type="button" data-type="contextmenu">
												<Icon name="overflow-menu-horizontal" size="sm" />
											</button>
										</PanelSectionItemAction>
									}
									contentProps={{
										side: 'bottom',
										align: 'start',
									}}
								>
									{vertical.personas?.length ? (
										<DropdownItem asChild className="justify-start">
											<RecalculatePersonasAsyncForm
												ecosystemId={vertical.ecosystemId!}
												verticalId={vertical.id.toString()}
												className="flex items-center gap-2"
												size="none"
											>
												<Icon name="rotate" size="sm" />
												Recalculate Personas
											</RecalculatePersonasAsyncForm>
										</DropdownItem>
									) : null}
									<DropdownItem asChild className="justify-start">
										<Link to={vertical.duplicate}>
											<Icon name="copy" size="sm" />
											Duplicate Vertical
										</Link>
									</DropdownItem>
									<DropdownItem
										asChild
										className="justify-start"
										onSelect={e => e.preventDefault()}
									>
										<DeleteVerticalAsyncForm
											ecosystemId={vertical.ecosystemId!}
											verticalId={vertical.id.toString()}
										>
											<div className="flex items-center gap-2">
												<Icon name="trash" size="sm" />
												Delete Vertical
											</div>
										</DeleteVerticalAsyncForm>
									</DropdownItem>
								</Dropdown>

								<PanelSectionItemAction asChild isTrigger>
									<Link to={vertical.addPersona}>
										<Icon name="user-follow" size="sm" aria-hidden />
									</Link>
								</PanelSectionItemAction>
							</section>
						)}

						<section
							className={cn(
								readOnlySession ? 'grid' : 'panel-section-item-actions-primary',
								'grid-cols-[repeat(1,20px)] justify-items-end',
							)}
						>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<span className="block h-5 w-5 pt-[0.15rem] text-center">
									{vertical.personas?.length ?? '0'}
								</span>
							</PanelSectionItemAction>
						</section>
					</PanelSectionItem>

					<AccordionPrimitive.Content
						className={cn(
							'overflow-hidden',
							'transition-opacity radix-state-closed:opacity-50 radix-state-open:opacity-100',
							'radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]',
						)}
					>
						<PersonasPanelItem
							vertical={vertical}
							isActive={open.includes(vertical.id.toString())}
						/>
					</AccordionPrimitive.Content>
				</AccordionPrimitive.Item>
			))}
		</AccordionPrimitive.Root>
	)
}

function EcosystemsPanelItem({
	ecosystems,
}: {
	ecosystems: CalibrateNavEcosystem[]
}) {
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse
	const [open, setOpen] = useState<string[]>([])
	const params = useParams()

	useEffect(() => {
		if (params.ecosystemId) {
			const newOpen = open?.length
				? [...open, params.ecosystemId]
				: [params.ecosystemId]
			setOpen(newOpen)
		} else {
			setOpen([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.ecosystemId])

	return (
		<AccordionPrimitive.Root
			type="multiple"
			value={open}
			onValueChange={setOpen}
		>
			{ecosystems?.map(ecosystem => (
				<AccordionPrimitive.Item
					key={ecosystem.id}
					value={ecosystem.id!.toString()}
				>
					<PanelSectionItem
						className="group items-start justify-between"
						isTrigger
						isActive={
							params.ecosystemId === ecosystem.id?.toString() &&
							!params.verticalId &&
							!params.personaId
						}
					>
						<section className="-my-2 flex w-full flex-nowrap items-start gap-1 truncate py-2">
							<AccordionPrimitive.Trigger
								className="group"
								disabled={!ecosystem.verticals?.length && readOnlySession}
							>
								<Icon
									name="chevron-down"
									size="sm"
									aria-hidden
									className="transform text-neutral-3-fg group-disabled:invisible group-radix-state-open:rotate-180"
								/>
							</AccordionPrimitive.Trigger>
							<Link
								to={ecosystem.to}
								className={cn(
									'-my-2 w-full truncate py-2',
									open.includes(ecosystem.id!.toString())
										? 'font-bold'
										: 'font-semibold',
								)}
							>
								{ecosystem.name}
							</Link>
						</section>

						{readOnlySession ? null : (
							<section
								className={cn(
									'panel-section-item-actions-meta',
									'grid-cols-[repeat(2,20px)] justify-items-end',
								)}
							>
								<Dropdown
									key={ecosystem.id}
									trigger={
										<PanelSectionItemAction asChild isTrigger>
											<button type="button" data-type="contextmenu">
												<Icon name="overflow-menu-horizontal" size="sm" />
											</button>
										</PanelSectionItemAction>
									}
									contentProps={{
										side: 'bottom',
										align: 'start',
									}}
								>
									<DropdownItem asChild className="justify-start">
										<Link to={ecosystem.rename}>
											<Icon name="request-quote" size="sm" />
											Rename Ecosystem
										</Link>
									</DropdownItem>
									<DropdownItem asChild className="justify-start">
										<Link to={ecosystem.addCriteria}>
											<Icon name="add-alt" size="sm" />
											Add Criteria
										</Link>
									</DropdownItem>
									<DropdownItem
										asChild
										className="justify-start"
										onSelect={e => e.preventDefault()}
									>
										<DeleteEcosystemAsyncForm ecosystemId={ecosystem.id!}>
											<div className="flex items-center gap-2">
												<Icon name="trash" size="sm" />
												Delete Ecosystem
											</div>
										</DeleteEcosystemAsyncForm>
									</DropdownItem>
								</Dropdown>

								<PanelSectionItemAction asChild isTrigger>
									<Link to={ecosystem.addVertical}>
										<Icon name="category-new" size="sm" />
									</Link>
								</PanelSectionItemAction>
							</section>
						)}

						<section
							className={cn(
								readOnlySession ? 'grid' : 'panel-section-item-actions-primary',
								'grid-cols-[repeat(4,20px)] justify-items-end',
							)}
						>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<Icon name="category" size="sm" />
							</PanelSectionItemAction>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<span className="block h-5 w-5 pt-[0.15rem] text-center">
									{ecosystem.verticals.length ?? '0'}
								</span>
							</PanelSectionItemAction>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<Icon name="user" size="sm" />
							</PanelSectionItemAction>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<span className="block h-5 w-5 pt-[0.15rem] text-center">
									{ecosystem.verticals.reduce(
										(totalPersonaCount, vertical) =>
											totalPersonaCount +
											(vertical.personas?.length
												? vertical.personas.length
												: 0),
										0,
									)}
								</span>
							</PanelSectionItemAction>
						</section>
					</PanelSectionItem>

					<AccordionPrimitive.Content
						className={cn(
							'overflow-hidden',
							'transition-opacity radix-state-closed:opacity-50 radix-state-open:opacity-100',
							'radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]',
						)}
					>
						{ecosystem.verticals?.length ? (
							<>
								<VerticalsPanelItem verticals={ecosystem.verticals} />
								<div className="w-full pb-8" />
							</>
						) : !readOnlySession ? (
							<PanelSectionItem
								asChild
								isTrigger
								className="gap-2 pl-8 text-body-md text-link hover:bg-transparent hover:text-link-hover active:text-link-pressed"
							>
								<Link to={ecosystem.addVertical}>
									<Icon name="category-new" size="sm" aria-hidden />
									Add Vertical
								</Link>
							</PanelSectionItem>
						) : null}
					</AccordionPrimitive.Content>
				</AccordionPrimitive.Item>
			))}
		</AccordionPrimitive.Root>
	)
}

export default function CalibratePanel() {
	const params = useParams()
	const { ecosystems, readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse

	const rootNav = {
		title: 'Ecosystems',
		add: routes.calibrate.ecosystem.create({
			companyId: params.companyId!,
		}),
	}

	const nav = {
		...rootNav,
		ecosystems: ecosystems?.length
			? ecosystems.map(e => ({
					id: e.id,
					to: routes.calibrate.verticals.index({
						companyId: params.companyId!,
						ecosystemId: e.id!.toString(),
					}),
					rename: routes.calibrate.ecosystem.edit({
						companyId: params.companyId!,
						ecosystemId: e.id!.toString(),
					}),
					addCriteria: routes.calibrate.criteria.create({
						companyId: params.companyId!,
						ecosystemId: e.id!.toString(),
					}),
					addVertical: routes.calibrate.verticals.create({
						companyId: params.companyId!,
						ecosystemId: e.id!.toString(),
					}),
					name: e.name,
					verticals: e.verticals.map(v => ({
						id: v.id,
						ecosystemId: e.id,
						to: routes.calibrate.verticals.index({
							companyId: params.companyId!,
							ecosystemId: e.id!.toString(),
						}),
						name: v.name,
						duplicate: routes.calibrate.verticals.duplicate({
							companyId: params.companyId!,
							ecosystemId: e.id!.toString(),
							verticalId: v.id.toString(),
							autoFocus: 'name',
						}),
						addPersona: routes.calibrate.persona.edit({
							companyId: params.companyId!,
							ecosystemId: e.id!.toString(),
							verticalId: v.id.toString(),
							personaId: v.personas?.[0]?.id?.toString() ?? null,
						}),
						personas:
							v.personas?.map(p => ({
								id: p.id,
								to: routes.calibrate.persona.index({
									companyId: params.companyId!,
									ecosystemId: e.id!.toString(),
									verticalId: v.id.toString(),
									personaId: p.id.toString(),
								}),
								type: p.type,
								title: p.expertise,
								status: p.status,
								priority: p.priority,
							})) ?? [],
					})),
				}))
			: null,
	} satisfies CalibrateNav

	return (
		<section className="flex flex-col px-2">
			<PanelSectionItem className="group justify-between">
				<h3 className="text-label-sm font-semibold tracking-widest text-neutral-3-fg">
					{nav.title}
				</h3>
				{readOnlySession ? null : (
					<Link
						to={nav.add}
						className="flex items-center opacity-0 transition-opacity group-hover:opacity-100"
					>
						<Icon name="add" size="sm" />
					</Link>
				)}
			</PanelSectionItem>

			{nav.ecosystems ? (
				<EcosystemsPanelItem ecosystems={nav.ecosystems} />
			) : !readOnlySession ? (
				<PanelSectionItem
					asChild
					isTrigger
					className="gap-2 text-body-md text-link hover:bg-transparent hover:text-link-hover active:text-link-pressed"
				>
					<Link to={nav.add}>
						<Icon name="add" size="sm" aria-hidden />
						Add Ecosystem
					</Link>
				</PanelSectionItem>
			) : null}
		</section>
	)
}
