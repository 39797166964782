import { getFormProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import * as SelectPrimitive from '@radix-ui/react-select'
import { type QueryClient } from '@tanstack/react-query'
import { type ReactNode } from 'react'
import { useFetcher, type ActionFunctionArgs } from 'react-router-dom'
import ProductTip from '#src/components/product-tip'
import { Icon } from '#src/components/ui/icon'
import { Label } from '#src/components/ui/label'
import {
	SelectRoot,
	SelectValue,
	SelectIcon,
	SelectItemText,
	SelectTrigger,
	SelectContent,
} from '#src/components/ui/select'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { updateSignal } from './mutations'
import { signalsKeys } from './queries'
import { SignalConfigFormSchema } from './schema'

export type SignalUpdateActionResponse = Awaited<
	ReturnType<ReturnType<typeof action>>
>

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.signalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()

		const submission = parseWithZod(formData, {
			schema: SignalConfigFormSchema,
		})

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		await updateSignal(params.companyId, params.signalId, {
			...submission.value,
		})

		void queryClient.invalidateQueries({
			queryKey: signalsKeys.all,
		})

		return { result: submission.reply() }
	}

const WEIGHT_OPTIONS = [
	{ value: '1', label: '1' },
	{ value: '2', label: '2' },
	{ value: '3', label: '3' },
	{ value: '4', label: '4' },
	{ value: '5', label: '5' },
]

function useSignalWeightForm({
	signalId,
	weight,
}: {
	signalId: number
	weight?: number | null
}) {
	const params = useParsedRouteParams(['companyId'])
	const fetcher = useFetcher<SignalUpdateActionResponse>()
	const response = fetcher.data

	const formId = 'weight-form-' + signalId
	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(SignalConfigFormSchema),
		lastResult: response?.result,
		defaultValue: {
			weight: weight?.toString() ?? '1',
		},
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: SignalConfigFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})
	const action = routes.prioritize.signal.save({
		companyId: params.companyId,
		signalId: signalId.toString(),
	})
	const submit = (value: string) => {
		form.update({
			name: 'weight',
			value,
		})
		fetcher.submit({ weight: Number(value) }, { action, method: 'PATCH' })
	}

	return {
		fetcher,
		form,
		fields,
		action,
		submit,
	}
}

export function SignalWeightDetailsAsyncForm({
	signalId,
	weight,
}: {
	signalId: number
	weight?: number | null
}) {
	const { fetcher, form, fields, action, submit } = useSignalWeightForm({
		signalId,
		weight,
	})

	return (
		<fetcher.Form
			method="PATCH"
			action={action}
			{...getFormProps(form)}
			className="flex items-end gap-3"
		>
			<div className="flex flex-col gap-1">
				<Label
					htmlFor={fields.weight.name}
					className="flex items-center gap-1 text-label-sm font-semibold text-neutral-3-fg"
				>
					Weight
					<ProductTip content="Weight: The importance or relevance of the intent signal, typically as a numerical value." />
				</Label>
				<WeightSelect
					id={fields.weight.name}
					name={fields.weight.name}
					value={fields.weight.value}
					onValueChange={v => {
						if (v && v !== fields.weight.value) {
							submit(v)
						}
					}}
				/>
			</div>

			<div
				className={cn(
					'flex items-center gap-2 text-label-sm',
					form.status || ['submitting', 'loading'].includes(fetcher.state)
						? 'pb-0.5'
						: '',
					form.status === 'success' ? 'text-green-60' : 'text-brand-1-fg',
				)}
			>
				{['submitting', 'loading'].includes(fetcher.state) ? (
					<Icon name="update" className="animate-spin" size="sm" />
				) : null}
				{['submitting', 'loading'].includes(fetcher.state)
					? 'Saving...'
					: form.status === 'success'
						? 'Success!'
						: null}
			</div>
		</fetcher.Form>
	)
}

export function SignalWeightAsyncForm({
	signalId,
	weight,
}: {
	signalId: number
	weight?: number | null
}) {
	const { fetcher, form, fields, action, submit } = useSignalWeightForm({
		signalId,
		weight,
	})

	return (
		<fetcher.Form
			method="PATCH"
			action={action}
			{...getFormProps(form)}
			className="flex items-end gap-4"
		>
			<WeightSelect
				name={fields.weight.name}
				value={fields.weight.value}
				onValueChange={v => {
					if (v && v !== fields.weight.value) {
						submit(v)
					}
				}}
			/>
		</fetcher.Form>
	)
}

const WeightSelect = ({
	id,
	name,
	value,
	onValueChange,
}: {
	id?: string
	name: string
	value: string | undefined
	onValueChange: (value: string) => void
}) => {
	return (
		<SelectRoot name={name} value={value} onValueChange={onValueChange}>
			<SelectTrigger className="group flex items-center outline-none" id={id}>
				<SelectValue>
					<WeightValue
						value={value}
						suffix={
							<SelectIcon>
								<Icon
									name="chevron-down"
									size="sm"
									className="group-data-[state='open']:rotate-180"
								/>
							</SelectIcon>
						}
					/>
				</SelectValue>
			</SelectTrigger>

			<SelectContent
				className="px-2"
				position="popper"
				side="bottom"
				align="start"
				sideOffset={4}
			>
				{WEIGHT_OPTIONS.map(({ value }) => (
					<SelectPrimitive.Item
						key={value}
						value={value}
						className={cn(
							'flex cursor-pointer select-none rounded-sm px-2 py-1.5 outline-none transition-colors hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
						)}
					>
						<SelectItemText>{<WeightValue value={value} />}</SelectItemText>
					</SelectPrimitive.Item>
				))}
			</SelectContent>
		</SelectRoot>
	)
}

export const WeightValue = ({
	value,
	suffix,
}: {
	value: string | undefined
	suffix?: ReactNode
}) => {
	return (
		<div
			className={cn(
				'inline-flex h-5 items-center justify-center gap-0.5 rounded-xl px-2 text-label-sm transition-colors',
				suffix ? 'w-max min-w-8 pr-1.5' : 'w-8',
				value === '1' ? 'bg-green-10 text-green-40' : '',
				value === '2' ? 'bg-green-20 text-green-70' : '',
				value === '3' ? 'bg-green-30 text-green-70' : '',
				value === '4' ? 'bg-green-70 text-green-10' : '',
				value === '5' ? 'bg-green-100 text-green-10' : '',
			)}
		>
			{value}
			{suffix}
		</div>
	)
}
