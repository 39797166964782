import { type QueryClient } from '@tanstack/react-query'
import { type VariantProps } from 'class-variance-authority'
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import {
	type ActionFunctionArgs,
	useParams,
	useFetcher,
} from 'react-router-dom'
import { Button, type buttonVariants } from '#src/components/ui/button'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { recalculatePersona, recalculatePersonas } from './mutations'
import { personaKeys } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ request, params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()
		const intent = formData.get('intent')

		if (intent === 'all') {
			await recalculatePersonas(params.companyId, params.verticalId)
		} else if (intent === 'single' && params.personaId) {
			await recalculatePersona(params.companyId, params.personaId)
		} else {
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})
		}

		await queryClient.invalidateQueries({
			queryKey: personaKeys.all,
		})

		return null
	}

export interface RecalculatePersonasAsyncFormProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: number
	verticalId: string
	children: ReactNode
	onSubmit?: () => void
}

export const RecalculatePersonasAsyncForm = forwardRef<
	HTMLButtonElement,
	RecalculatePersonasAsyncFormProps & VariantProps<typeof buttonVariants>
>(({ ecosystemId, verticalId, children, className, ...props }, ref) => {
	const params = useParams()
	const validatedParams = useParsedRouteParams(['companyId'])

	const fetcher = useFetcher()
	const action = routes.calibrate.persona.recalculate({
		companyId: validatedParams.companyId,
		ecosystemId: ecosystemId.toString(),
		verticalId: verticalId,
		personaId: params.personaId || null,
	})

	return (
		<Button
			variant="none"
			{...props}
			ref={ref}
			type="button"
			disabled={fetcher.state !== 'idle'}
			className={cn(
				'w-full',
				fetcher.state !== 'idle' ? 'animate-pulse' : '',
				className,
			)}
			onClick={() => {
				fetcher.submit({ intent: 'all' }, { method: 'PUT', action })
			}}
		>
			{children}
		</Button>
	)
})
RecalculatePersonasAsyncForm.displayName = 'RecalculatePersonasAsyncForm'

export interface RecalculatePersonaAsyncFormProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: number
	verticalId: string
	personaId: string
	children: ReactNode
	onSubmit?: () => void
}

export const RecalculatePersonaAsyncForm = forwardRef<
	HTMLButtonElement,
	RecalculatePersonaAsyncFormProps & VariantProps<typeof buttonVariants>
>(
	(
		{ ecosystemId, verticalId, personaId, children, className, ...props },
		ref,
	) => {
		const params = useParsedRouteParams(['companyId'])

		const fetcher = useFetcher()
		const action = routes.calibrate.persona.recalculate({
			companyId: params.companyId,
			ecosystemId: ecosystemId.toString(),
			verticalId: verticalId,
			personaId: personaId,
		})

		return (
			<Button
				variant="none"
				{...props}
				ref={ref}
				type="button"
				disabled={fetcher.state !== 'idle'}
				className={cn(
					'w-full',
					fetcher.state !== 'idle' ? 'animate-pulse' : '',
					className,
				)}
				onClick={() => {
					fetcher.submit({ intent: 'single' }, { method: 'PUT', action })
				}}
			>
				{children}
			</Button>
		)
	},
)
RecalculatePersonaAsyncForm.displayName = 'RecalculatePersonaAsyncForm'
