import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { captureException } from '@sentry/react'
import {
	type ActionFunctionArgs,
	Form,
	redirect,
	useActionData,
	useLoaderData,
} from 'react-router-dom'
import { ErrorList, Field } from '#src/components/forms'
import { StatusButton } from '#src/components/ui/status-button'
import { Surface } from '#src/components/ui/surface'
import {
	AUTH_REDIRECT_KEY,
	checkIsAuth0Callback,
	isAuth0Error,
	login,
	authorizeSession,
	refreshSession,
	checkSession,
} from '#src/routes/init/auth'
import { useIsPending } from '#src/utils/misc'
import { LoginFormSchema } from './schema'

type LoginLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async () => {
	try {
		if (checkIsAuth0Callback()) {
			await authorizeSession()
		} else {
			await refreshSession()
		}
	} catch (error) {
		if (isAuth0Error(error)) {
			captureException(error)

			return {
				ok: false,
				result: ['An error occurred while trying to authenticate'],
			}
		}

		throw error
	}

	if (checkSession()) {
		const redirectUrl = localStorage.getItem(AUTH_REDIRECT_KEY)
		if (redirectUrl) {
			localStorage.removeItem(AUTH_REDIRECT_KEY)

			throw redirect(redirectUrl)
		}

		throw redirect('/')
	} else {
		return null
	}
}

type LoginActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()

	const submission = parseWithZod(formData, { schema: LoginFormSchema })

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	const { username, password } = submission.value

	try {
		await login(username, password)

		return { ok: true, result: submission.reply() }
	} catch (error) {
		if (isAuth0Error(error)) {
			return {
				ok: false,
				result: submission.reply({
					formErrors: [
						error.error_description ??
							error.errorDescription ??
							'Authentication service error',
					],
				}),
			}
		}
		return {
			ok: false,
			result: submission.reply({
				formErrors: ['Something went wrong. Try again later.'],
			}),
		}
	}
}

export default function Login() {
	const loaderData = useLoaderData() as LoginLoaderResponse
	const actionData = useActionData() as LoginActionResponse

	const isPending = useIsPending()
	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		lastResult: actionData?.result ?? null,
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	return (
		<main className="grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-items-center">
			<Surface className="flex w-full max-w-sm flex-col items-center gap-8 py-8">
				<h1 className="text-center text-title-md">Login to Evergrowth</h1>
				<Form
					method="POST"
					{...getFormProps(form)}
					className="flex w-full flex-col items-center gap-8"
				>
					<div className="flex w-full flex-col items-center gap-6">
						<Field
							className="w-full"
							labelProps={{ children: 'Username' }}
							inputProps={{
								...getInputProps(fields.username, { type: 'text' }),
								placeholder: 'Enter your username',
								autoFocus: true,
							}}
							errors={fields.username.errors}
						/>
						<Field
							className="w-full"
							labelProps={{ children: 'Password' }}
							inputProps={{
								...getInputProps(fields.password, { type: 'password' }),
								placeholder: 'Enter password',
							}}
							errors={fields.password.errors}
						/>
					</div>

					<ErrorList
						errors={form.errors || loaderData?.result}
						id={form.errorId}
					/>

					<StatusButton
						className="w-full max-w-32"
						status={isPending ? 'pending' : form.status ?? 'idle'}
						type="submit"
						disabled={isPending}
					>
						Login
					</StatusButton>
				</Form>
			</Surface>
		</main>
	)
}
