import { useQuery, type QueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import {
	useLoaderData,
	type LoaderFunctionArgs,
	Link,
	Outlet,
	useNavigate,
} from 'react-router-dom'
import { z } from 'zod'
import AiGenerated from '#src/components/ai-generated'
import Markdown from '#src/components/markdown'
import NoVerticals from '#src/components/no-verticals'
import { PersonaData } from '#src/components/persona'
import Priority from '#src/components/priority'
import ProductTip from '#src/components/product-tip'
import Status from '#src/components/status'
import Table from '#src/components/table'
import { Icon } from '#src/components/ui/icon'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#src/components/ui/tooltip'
import {
	criteriaQuery,
	dataPointsQuery,
	ecosystemsQuery,
} from '#src/routes/calibrate/ecosystem/queries'
import {
	CriteriaAPISchema,
	DataPointsAPISchema,
	EcosystemAPISchema,
	EcosystemListAPISchema,
} from '#src/routes/calibrate/ecosystem/schema'
import { userQuery } from '#src/routes/init/user/me'
import {
	EMPLOYEE_MAX_RANGE_UPPER_LIMIT,
	TAM_STATUS_ENUM,
} from '#src/utils/enumerations'
import { checkIsReadOnlySession, cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import {
	autofocusFieldMutation,
	autofocusFieldQuery,
	autofocusVerticalMutation,
	autofocusVerticalQuery,
} from './autofocus'
import { DeleteVerticalAsyncForm } from './delete'
import { verticalsQuery } from './queries'
import { RequestCalculateTAMForm } from './tam/request'

export type VerticalLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const VerticalsLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		ecosystemId: z.string().nullable().optional(),
		ecosystemName: z.string(),
		readOnlySession: z.boolean(),
	}),
	ecosystem: EcosystemAPISchema,
	ecosystems: EcosystemListAPISchema,
	criteria: CriteriaAPISchema,
	datapoints: DataPointsAPISchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		void queryClient.prefetchQuery({
			...verticalsQuery(params.companyId, Number(params.ecosystemId)),
		})

		const user = await queryClient.fetchQuery(userQuery())
		const ecosystems = await queryClient.fetchQuery(
			ecosystemsQuery(params.companyId),
		)
		const ecosystem = ecosystems?.find(e => e.id === Number(params.ecosystemId))
		const criteria = await queryClient.fetchQuery(
			criteriaQuery(params.companyId, params.ecosystemId),
		)
		const datapoints = await queryClient.fetchQuery(
			dataPointsQuery(params.companyId, params.ecosystemId),
		)

		return {
			handle: {
				companyId: params.companyId,
				ecosystemId: params.ecosystemId,
				ecosystemName: ecosystem?.name,
				readOnlySession: checkIsReadOnlySession(user.roles),
			},
			ecosystem,
			ecosystems,
			criteria,
			datapoints,
		}
	}

export default function Vertical() {
	const {
		handle: { companyId, ecosystemId, readOnlySession },
		datapoints,
		criteria,
		ecosystem,
	} = useLoaderData() as VerticalLoaderResponse

	const navigate = useNavigate()
	const { data: autofocus } = useQuery(autofocusFieldQuery())
	const { data: autofocusVertical } = useQuery(autofocusVerticalQuery())

	// NOTE: this will scroll into view if the board
	// is very wide given many verticals
	useEffect(() => {
		if (autofocusVertical) {
			setTimeout(() => {
				const element = document.getElementById(
					`vertical-column-${autofocusVertical}`,
				)

				element?.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center',
				})
			}, 100)
		}
	}, [autofocusVertical])

	const { data: verticals } = useQuery({
		...verticalsQuery(companyId, Number(ecosystemId)),
		refetchInterval: ({ state: { data } }) => {
			if (
				data?.some(
					vertical =>
						vertical.prompt.status &&
						['Pending', 'In Progress'].includes(vertical.prompt.status),
				)
			)
				return 1000
			else return false
		},
		refetchIntervalInBackground: true,
		throwOnError: true,
	})

	const data = verticals?.length
		? {
				head: {
					options: { sticky: true },
					focus: autofocus?.includes('name'),
					columns: [
						{ children: '' },
						...verticals.map(vertical => ({
							children: (
								<section className="flex w-full flex-col gap-1">
									<div className="flex w-full items-center justify-between gap-2">
										{readOnlySession ? (
											<p className="truncate text-title-sm">{vertical.name}</p>
										) : (
											<Link
												replace
												preventScrollReset
												className="grid grid-cols-[1fr,max-content] grid-rows-1 items-center gap-1"
												id={`vertical-column-${vertical.id ?? ''}`}
												to={routes.calibrate.verticals.edit({
													companyId: companyId,
													ecosystemId: ecosystemId,
													verticalId: vertical.id!.toString(),
												})}
												onClick={() => autofocusFieldMutation('name')}
											>
												<p
													className="w-full truncate text-title-sm"
													title={vertical.name}
												>
													{vertical.name}
												</p>
												<Icon
													name="edit"
													className="inline-block text-title-sm"
												/>
											</Link>
										)}
										{readOnlySession ? null : (
											<div className="flex items-center justify-between gap-2">
												<Link
													replace
													preventScrollReset
													className="flex items-center gap-1 text-title-sm"
													to={routes.calibrate.verticals.duplicate({
														companyId: companyId,
														ecosystemId: ecosystemId,
														verticalId: vertical.id!.toString(),
													})}
													onClick={() => autofocusFieldMutation('name')}
												>
													<Icon name="copy" className="text-title-sm" />
												</Link>
												<DeleteVerticalAsyncForm
													ecosystemId={Number(ecosystemId)}
													verticalId={String(vertical.id!)}
												>
													<Icon name="trash" className="text-title-sm" />
												</DeleteVerticalAsyncForm>
											</div>
										)}
									</div>
									<div className="flex items-center gap-2">
										<Status status={vertical.status} />
										<Priority priority={vertical.priority} />
									</div>
								</section>
							),
						})),
					],
				},
				bodyGroups: [
					{
						collapsible: {
							options: {
								sticky: true,
								collapsible: true,
								defaultCollapsed: true,
							},
							columns: [
								{
									static: true,
									children: (
										<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
											TAM Estimate
											<ProductTip content="TAM Estimate: The Total Addressable Market estimate within the specified Ecosystem or ICP vertical." />
										</p>
									),
								},
								...verticals.map(vertical => ({
									static: true,
									children: (
										<section className="flex w-full items-center justify-between gap-2">
											<div className="flex w-full flex-wrap items-center gap-2">
												<p className="text-body-sm font-normal text-neutral-1-fg">
													Min:{' '}
													<b className="font-bold">
														{formatNumber(vertical.tams?.estimateMin)}
													</b>
												</p>
												<p className="text-body-sm font-normal text-neutral-1-fg">
													Max:{' '}
													<b className="font-bold">
														{formatNumber(vertical.tams?.estimateMax)}
													</b>
												</p>
												<p className="text-body-sm font-normal text-neutral-1-fg">
													GAP:{' '}
													<b className="font-bold">
														{formatNumber(vertical.tams?.gap)}
													</b>
												</p>
											</div>
											{readOnlySession ? null : (
												<VerticalTAMAction
													verticalId={vertical.id!}
													tamStatus={vertical?.tams?.status}
												/>
											)}
										</section>
									),
								})),
							],
						},
						body: {
							rows: [
								{
									columns: [
										{
											static: true,
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													In CRM
													<ProductTip content="In CRM: Accounts in the CRM that match the criteria of this vertical." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											static: true,
											children: (
												<VerticalTAMValue value={vertical.tams?.inCrm} />
											),
										})),
									],
								},
								{
									columns: [
										{
											static: true,
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Dormant - to qualify
													<ProductTip content="Dormant - to qualify: These are inbound companies that need to be qualified to determine their potential." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											static: true,
											children: (
												<VerticalTAMValue
													value={vertical.tams?.dormantEstimate?.toQualify}
												/>
											),
										})),
									],
								},
								{
									columns: [
										{
											static: true,
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Dormant - no activities
													<ProductTip content="Dormant - no activities: Inactive potential prospects with no recent (3 to 6 weeks) engagement or activities." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											static: true,
											children: (
												<VerticalTAMValue
													value={vertical.tams?.dormantEstimate?.noActivities}
												/>
											),
										})),
									],
								},
								{
									columns: [
										{
											static: true,
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Dormant - lost opportunity
													<ProductTip content="Dormant - lost opportunity: Inactive potential prospects where 1 or more sales opportunities were lost." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											static: true,
											children: (
												<VerticalTAMValue
													value={
														vertical.tams?.dormantEstimate?.lostOpportunity
													}
												/>
											),
										})),
									],
								},
								{
									columns: [
										{
											static: true,
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Dormant - churned
													<ProductTip content="Dormant - churned: Inactive past customers who have stopped using the services." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											static: true,
											children: (
												<VerticalTAMValue
													value={vertical.tams?.dormantEstimate?.churned}
												/>
											),
										})),
									],
								},
								{
									columns: [
										{
											static: true,
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Working
													<ProductTip content="Working: Active prospects currently being engaged by the sales team." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											static: true,
											children: (
												<VerticalTAMValue value={vertical.tams?.working} />
											),
										})),
									],
								},
								{
									columns: [
										{
											static: true,
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Opportunity
													<ProductTip content="Opportunity: Prospects that represent potential sales opportunities." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											static: true,
											children: (
												<VerticalTAMValue value={vertical.tams?.opportunity} />
											),
										})),
									],
								},
								{
									columns: [
										{
											static: true,
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Customer
													<ProductTip content="Customer: Current clients." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											static: true,
											children: (
												<VerticalTAMValue value={vertical.tams?.customer} />
											),
										})),
									],
								},
							],
						},
					},
					{
						collapsible: {
							options: {
								sticky: true,
								collapsible: true,
							},
							columns: [
								{
									children: (
										<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
											Personas
											<span className="font-normal text-neutral-3-fg">
												{ecosystem?.verticals.reduce(
													(totalPersonaCount, vertical) =>
														totalPersonaCount +
														(vertical.personas?.length
															? vertical.personas.length
															: 0),
													0,
												) ?? 0}
											</span>
											<ProductTip content="Personas: Personas are created using a chain of AI prompts that process data from market research and real customer information, including demographics, job roles, and industry needs. These personas refine the model, guiding pain point generation, persona mapping, and sales coach modeling. To add or edit a persona, click “+ Add” in the personas section, complete the “New Persona” form, then adjust pain point details and AI settings for accuracy." />
										</p>
									),
								},
								...verticals.map(vertical => ({
									children: (
										<section className="flex w-full flex-wrap items-center justify-between gap-2">
											<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
												<Icon name="user" size="sm" />
												<span className="font-normal text-neutral-3-fg">
													{ecosystem?.verticals.find(v => v.id === vertical.id)
														?.personas?.length ?? null}
												</span>
											</p>
											{readOnlySession ? null : (
												<Link
													to={routes.calibrate.persona.edit({
														companyId: companyId,
														ecosystemId: ecosystemId.toString(),
														verticalId: vertical.id!.toString(),
														personaId:
															ecosystem?.verticals
																?.find(v => v.id === vertical.id)
																?.personas?.[0]?.id?.toString() ?? null,
													})}
													className="flex flex-nowrap items-center gap-1 bg-transparent text-button-sm text-link hover:text-link-hover active:text-link-pressed"
												>
													<Icon name="add" /> Add
												</Link>
											)}
										</section>
									),
								})),
							],
						},
						body: {
							rows: [
								{
									columns: [
										{
											children: '',
										},
										...verticals.map(vertical => {
											const v = ecosystem?.verticals.find(
												v => v.id === vertical.id,
											)

											if (!v?.personas?.length || !ecosystem)
												return {
													className: 'align-middle py-4',
													children: (
														<section className="flex w-full flex-col items-center justify-center gap-2">
															<Icon
																name="user-avatar"
																size="lg"
																className="text-neutral-3-fg"
															/>
															<p className="text-center text-body-sm font-normal text-neutral-1-fg">
																You don&apos;t have any personas in <br />
																<span className="font-semibold">
																	{vertical.name}
																</span>
															</p>
															{readOnlySession ? null : (
																<Link
																	to={routes.calibrate.persona.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId.toString(),
																		verticalId: vertical.id!.toString(),
																		personaId: null,
																	})}
																	className="flex flex-nowrap items-center gap-1 bg-transparent py-2 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
																>
																	<Icon name="add" /> Add Persona
																</Link>
															)}
														</section>
													),
												}

											return {
												className: 'align-top',
												children: (
													<section className="flex w-full flex-col items-start justify-start gap-2">
														{v.personas.map(persona => (
															<PersonaData
																key={persona.id}
																id={persona.id}
																type={persona.type}
																status={persona.status}
																priority={persona.priority}
																expertise={persona.expertise}
																companyId={companyId}
																ecosystemId={ecosystem.id!}
																verticalId={v.id.toString()}
															/>
														))}
													</section>
												),
											}
										}),
									],
								},
							],
						},
					},
					{
						collapsible: {
							options: { sticky: true, collapsible: criteria?.length > 0 },
							columns: [
								{
									children: (
										<section className="flex w-full items-center justify-between">
											<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
												Qualification Criteria
												<span className="font-normal text-neutral-3-fg">
													{criteria?.length ?? 0}
												</span>
												<ProductTip content="Qualification Criteria: The specific characteristics and attributes used to define and segment your personas based on market research and real customer data." />
											</p>
											{readOnlySession ? null : (
												<Link
													to={routes.calibrate.criteria.edit({
														companyId: companyId,
														ecosystemId: ecosystemId,
													})}
													className="text-button-sm text-link hover:text-link-hover active:text-link-pressed"
												>
													Edit
												</Link>
											)}
										</section>
									),
								},
								...verticals.map(() => ({
									className: 'border-r-transparent',
									children: '',
								})),
							],
						},
						...(criteria?.length
							? {
									body: {
										id: 'group-4',
										rows: criteria.map((criterion, index) => ({
											focus: autofocus?.includes(`answers[${index}].value`),
											columns: [
												{
													className: 'align-top',
													children: (
														<>
															{criterion.dataSources ? (
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger className="h-full w-full text-left">
																			<div className="absolute right-0 top-0 h-0 w-0 border-b-0 border-l-[15px] border-t-[13px] border-l-transparent border-t-brand-2-bd" />
																			<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
																				{criterion.question}
																			</p>
																		</TooltipTrigger>
																		<TooltipContent
																			side="right"
																			sideOffset={16}
																			align="center"
																			className="max-w-60"
																		>
																			AI Agent instructions:&nbsp;
																			{criterion.dataSources}
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															) : (
																<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
																	{criterion.question}
																</p>
															)}
														</>
													),
												},
												...verticals.map(vertical => ({
													...(readOnlySession
														? {}
														: {
																onClick: () => {
																	autofocusFieldMutation(
																		`answers[${index}].value`,
																	)
																	navigate(
																		routes.calibrate.verticals.edit({
																			companyId: companyId,
																			ecosystemId: ecosystemId,
																			verticalId: vertical.id!.toString(),
																		}),
																		{ replace: true, preventScrollReset: true },
																	)
																},
															}),
													className: 'align-top',
													children: (
														<p className="text-body-md font-normal text-neutral-2-fg">
															{
																vertical.answers?.find(
																	answer => answer.criterionId === criterion.id,
																)?.value
															}
														</p>
													),
												})),
											],
										})),
									},
								}
							: {}),
					},
					{
						collapsible: {
							options: { sticky: true, collapsible: true },
							columns: [
								{
									children: (
										<section className="flex w-full items-center justify-between">
											<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
												Enrichment Criteria
												<span className="font-normal text-neutral-3-fg">
													{datapoints?.length ?? 0}
												</span>
												<ProductTip content="Additional data to be collected about accounts that pass qualification." />
											</p>
											{readOnlySession ? null : (
												<Link
													to={routes.calibrate.datapoints.edit({
														companyId: companyId,
														ecosystemId: ecosystemId,
													})}
													className="text-button-sm text-link hover:text-link-hover active:text-link-pressed"
												>
													Edit
												</Link>
											)}
										</section>
									),
								},
								...verticals.map(() => ({
									className: 'border-r-transparent',
									children: '',
								})),
							],
						},
						...(datapoints?.length
							? {
									body: {
										id: 'group-6',
										rows: datapoints.map((datapoint, index) => ({
											focus: autofocus?.includes(`dataPoints[${index}].value`),
											columns: [
												{
													className: 'align-top',
													children: (
														<>
															{datapoint.dataSources ? (
																<TooltipProvider>
																	<Tooltip delayDuration={0}>
																		<TooltipTrigger className="h-full w-full text-left">
																			<div className="absolute right-0 top-0 h-0 w-0 border-b-0 border-l-[15px] border-t-[13px] border-l-transparent border-t-brand-2-bd" />
																			<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
																				{datapoint.title}
																			</p>
																		</TooltipTrigger>
																		<TooltipContent
																			side="right"
																			sideOffset={16}
																			align="center"
																			className="max-w-60"
																		>
																			AI Agent instructions:&nbsp;
																			{datapoint.dataSources}
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															) : (
																<p className="whitespace-pre-line text-body-md font-medium text-neutral-1-fg">
																	{datapoint.title}
																</p>
															)}
														</>
													),
												},
												...verticals.map(vertical => ({
													...(readOnlySession
														? {}
														: {
																onClick: () => {
																	autofocusFieldMutation(
																		`dataPoints[${index}].value`,
																	)
																	navigate(
																		routes.calibrate.verticals.edit({
																			companyId: companyId,
																			ecosystemId: ecosystemId,
																			verticalId: vertical.id!.toString(),
																		}),
																		{ replace: true, preventScrollReset: true },
																	)
																},
															}),
													className: 'align-top',
													children: (
														<p className="text-body-md font-normal text-neutral-2-fg">
															{
																vertical.dataPoints?.find(
																	item => item.id === datapoint.id,
																)?.value
															}
														</p>
													),
												})),
											],
										})),
									},
								}
							: {}),
					},
					{
						collapsible: {
							options: {
								sticky: true,
								collapsible: true,
							},
							columns: [
								{
									children: (
										<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
											Business model description <AiGenerated />
											<ProductTip content="Business Model Description: The business model description is created using the vertical’s criteria values. This data helps the AI to generate detailed descriptions of the business model for each vertical. These descriptions are crucial for identifying pain points and mapping personas accurately. To improve or fine-tune these descriptions, you can adjust the vertical’s criteria values, which will enhance the precision and relevance of the generated business model descriptions." />
										</p>
									),
								},
								...verticals.map(vertical => ({
									children: (
										<p
											className={cn(
												'truncate text-body-sm font-normal',
												['Pending', 'In Progress'].includes(
													vertical.prompt.status ?? '',
												)
													? 'animate-pulse'
													: '',
												vertical.prompt.status === 'Crashed'
													? 'text-status-danger-fg'
													: 'text-neutral-2-fg',
											)}
										>
											{['Pending', 'In Progress'].includes(
												vertical.prompt.status ?? '',
											)
												? vertical.bizDescription
													? 'Regenerating...'
													: 'Generating...'
												: vertical.prompt.status === 'Crashed'
													? `Failed to ${vertical.bizDescription ? 'regenerate' : 'generate'}`
													: vertical.bizDescription}
										</p>
									),
								})),
							],
						},
						body: {
							rows: [
								{
									columns: [
										{
											className: 'align-top',
											static: true,
											children: (
												<p className="text-body-md font-medium text-neutral-1-fg">
													Description
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												className: 'align-top',
												static: true,
												children: (
													<Markdown className="text-neutral-2-fg">
														{vertical.bizDescription}
													</Markdown>
												),
											}
										}),
									],
								},
								{
									focus: autofocus?.includes('bizDescriptionOverride'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Business model description (manual)
													<ProductTip content="Business model description (manual): A manually inputted summary of the business model to be incorporated into the AI-generated description above. This information is used to calibrate and refine the model." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('bizDescriptionOverride')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id!.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<p className="whitespace-pre-line text-body-md font-normal text-neutral-2-fg">
														{vertical.bizDescriptionOverride}
													</p>
												),
											}
										}),
									],
								},
								{
									focus: autofocus?.includes('bizModelLen'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="text-body-md font-medium text-neutral-1-fg">
													Description length (# of words)
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('bizModelLen')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id!.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<p className="text-body-md font-normal text-neutral-2-fg">
														{Intl.NumberFormat('en').format(
															Number(vertical.bizModelLen),
														)}
													</p>
												),
											}
										}),
									],
								},
								{
									focus: autofocus?.includes('exampleCompanies'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Example Companies
													<ProductTip content="Example Companies: Companies that exemplify the described business model. Ensure these examples are representative and well-known enough for the LLM to access information about them. This information is used to calibrate and refine the model." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('exampleCompanies')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id!.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<p className="text-body-md font-normal text-neutral-2-fg">
														{vertical.exampleCompanies}
													</p>
												),
											}
										}),
									],
								},
								{
									focus: autofocus?.includes('regions'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Locations
													<ProductTip content="Locations: Geographic areas where the companies are based or operate. This information is used to calibrate and refine the model." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('regions')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id!.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<p className="text-body-md font-normal text-neutral-2-fg">
														{vertical.regions}
													</p>
												),
											}
										}),
									],
								},
								{
									focus: autofocus?.includes('regions'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Excluded locations
													<ProductTip content="Excluded locations: Geographic areas that are not included within the scope of the business model description. This information is used to calibrate and refine the model." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('regions')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id!.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<p className="text-body-md font-normal text-neutral-2-fg">
														{vertical.regionsExcluded}
													</p>
												),
											}
										}),
									],
								},
								{
									focus: autofocus?.includes('subVerticals'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													Sub-Verticals
													<ProductTip content="Sub-Verticals: Specific niches or categories within the broader market vertical, such as SaaS or non-SaaS. This information is used to calibrate and refine the model." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('subVerticals')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id!.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<p className="text-body-md font-normal text-neutral-2-fg">
														{vertical.subVerticals}
													</p>
												),
											}
										}),
									],
								},
								{
									focus:
										autofocus?.includes('employeesMin') ||
										autofocus?.includes('employeesMax'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-medium text-neutral-1-fg">
													# of employees (min - max)
													<ProductTip content="# of employees (min - max): The range of employee numbers within the companies, indicating the scale of the workforce. This information is used to calibrate and refine the model." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												className: 'align-top py-0',
												children: (
													<section className="grid h-full w-full grid-cols-2 grid-rows-1">
														<p
															className={cn(
																'border-r border-neutral-1-bd py-2 text-body-md font-normal text-neutral-2-fg',
																readOnlySession
																	? 'cursor-default'
																	: 'cursor-pointer',
															)}
															onClick={
																readOnlySession
																	? undefined
																	: () => {
																			autofocusFieldMutation('employeesMin')
																			navigate(
																				routes.calibrate.verticals.edit({
																					companyId: companyId,
																					ecosystemId: ecosystemId,
																					verticalId: vertical.id!.toString(),
																				}),
																				{
																					replace: true,
																					preventScrollReset: true,
																				},
																			)
																		}
															}
														>
															{Intl.NumberFormat('en').format(
																Number(vertical.employeesMin),
															)}
														</p>

														<p
															className={cn(
																'py-2 pl-4 text-body-md font-normal text-neutral-2-fg',
																readOnlySession
																	? 'cursor-default'
																	: 'cursor-pointer',
															)}
															onClick={
																readOnlySession
																	? undefined
																	: () => {
																			autofocusFieldMutation('employeesMax')
																			navigate(
																				routes.calibrate.verticals.edit({
																					companyId: companyId,
																					ecosystemId: ecosystemId,
																					verticalId: vertical.id!.toString(),
																				}),
																				{
																					replace: true,
																					preventScrollReset: true,
																				},
																			)
																		}
															}
														>
															{vertical.employeesMax ===
															EMPLOYEE_MAX_RANGE_UPPER_LIMIT
																? Intl.NumberFormat('en').format(
																		Number(10001),
																	) + '+'
																: Intl.NumberFormat('en').format(
																		Number(vertical.employeesMax),
																	)}
														</p>
													</section>
												),
											}
										}),
									],
								},
							],
						},
					},
					{
						collapsible: {
							options: { sticky: true, collapsible: true },
							columns: [
								{
									children: (
										<p className="text-body-md font-semibold text-neutral-1-fg">
											Notes
										</p>
									),
								},
								...verticals.map(() => ({
									className: 'border-r-transparent',
									children: '',
								})),
							],
						},
						body: {
							id: 'group-5',
							rows: [
								{
									focus: autofocus?.includes('listBuildingNotes'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-normal text-neutral-2-fg">
													List building notes
													<ProductTip content="List building notes: Provide guidelines, platforms, tools, and resources for effective list building and ICP mapping." />
												</p>
											),
										},
										...verticals.map(vertical => ({
											...(readOnlySession
												? {}
												: {
														onClick: () => {
															autofocusFieldMutation('listBuildingNotes')
															navigate(
																routes.calibrate.verticals.edit({
																	companyId: companyId,
																	ecosystemId: ecosystemId,
																	verticalId: vertical.id!.toString(),
																}),
																{ replace: true, preventScrollReset: true },
															)
														},
													}),
											className: 'align-top',
											children: (
												<p className="whitespace-pre-line text-body-md font-normal text-neutral-2-fg">
													{vertical.listBuildingNotes}
												</p>
											),
										})),
									],
								},
								{
									focus: autofocus?.includes('otherNotes'),
									columns: [
										{
											className: 'align-top',
											children: (
												<p className="flex items-center gap-1 text-body-md font-normal text-neutral-2-fg">
													Other Notes
													<ProductTip content="Other Notes: Additional relevant information or remarks not covered under the above specific sections." />
												</p>
											),
										},
										...verticals.map(vertical => {
											return {
												...(readOnlySession
													? {}
													: {
															onClick: () => {
																autofocusFieldMutation('otherNotes')
																navigate(
																	routes.calibrate.verticals.edit({
																		companyId: companyId,
																		ecosystemId: ecosystemId,
																		verticalId: vertical.id!.toString(),
																	}),
																	{ replace: true, preventScrollReset: true },
																)
															},
														}),
												className: 'align-top',
												children: (
													<ol className="flex h-full w-full flex-col gap-2 pl-4">
														{vertical.otherNotes?.map((note, index) => (
															<li key={index} className="list-disc">
																<p className="whitespace-pre-line text-body-md font-normal text-neutral-2-fg">
																	{note}
																</p>
															</li>
														))}
													</ol>
												),
											}
										}),
									],
								},
							],
						},
					},
				],
			}
		: null

	if (!data) {
		return (
			<main className="flex w-full flex-grow flex-col items-center justify-center px-20">
				<NoVerticals
					companyId={companyId}
					ecosystemId={ecosystemId}
					className="min-h-[80vh]"
				/>
				<Outlet />
			</main>
		)
	}

	return (
		<main className="w-full flex-grow">
			<section className="flex gap-8">
				<Table key={verticals?.length} data={data} />
				<Outlet />
			</section>
		</main>
	)
}

function formatNumber(value: number | null | undefined) {
	return value
		? Intl.NumberFormat('en', {
				notation: 'compact',
				maximumSignificantDigits: 3,
			}).format(value)
		: 'N/A'
}

function VerticalTAMValue({ value }: { value: number | null | undefined }) {
	return (
		<p className="text-body-md font-normal text-neutral-1-fg">
			{formatNumber(value)}
		</p>
	)
}

function VerticalTAMAction({
	verticalId,
	tamStatus,
}: {
	verticalId: number
	tamStatus: string | null | undefined
}) {
	const params = useParsedRouteParams(['companyId', 'ecosystemId'])
	const { data: user, status: userQueryStatus } = useQuery(userQuery())

	if (['error', 'pending'].includes(userQueryStatus)) return null

	if (user?.roles?.includes('admin')) {
		return (
			<Link
				to={`/admin/tam/${params.companyId}/${params.ecosystemId}`}
				className="flex w-auto items-center gap-1 whitespace-nowrap bg-transparent text-button-sm text-link hover:text-link-hover active:text-link-pressed"
				onClick={() => {
					autofocusVerticalMutation(verticalId.toString())
				}}
			>
				Update TAM
			</Link>
		)
	}

	if (tamStatus === TAM_STATUS_ENUM.COMPLETED) return null

	if (tamStatus === TAM_STATUS_ENUM.REQUESTED) {
		return (
			<p className="flex w-auto flex-nowrap items-center gap-1 bg-transparent text-button-sm italic text-neutral-2-fg">
				Requested
			</p>
		)
	}

	return (
		<RequestCalculateTAMForm
			verticalId={verticalId}
			className="flex w-auto flex-nowrap items-center gap-1 bg-transparent text-button-sm text-link hover:text-link-hover active:text-link-pressed"
		>
			Calculate
		</RequestCalculateTAMForm>
	)
}
