import * as PopoverPrimitive from '@radix-ui/react-popover'
import { Slot } from '@radix-ui/react-slot'
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
	type ButtonHTMLAttributes,
} from 'react'
import { cn } from '#src/utils/misc'

export const PopoverRoot = ({
	children,
	...props
}: ComponentPropsWithoutRef<typeof PopoverPrimitive.Root>) => {
	return <PopoverPrimitive.Root {...props}>{children}</PopoverPrimitive.Root>
}
PopoverRoot.displayName = PopoverPrimitive.Root.displayName

export const PopoverAnchor = forwardRef<
	ElementRef<typeof PopoverPrimitive.Anchor>,
	ComponentPropsWithoutRef<typeof PopoverPrimitive.Anchor>
>(({ children, ...props }, ref) => {
	return (
		<PopoverPrimitive.Anchor {...props} ref={ref}>
			{children}
		</PopoverPrimitive.Anchor>
	)
})
PopoverAnchor.displayName = PopoverPrimitive.Anchor.displayName

export const PopoverTrigger = forwardRef<
	ElementRef<typeof PopoverPrimitive.Trigger>,
	ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>(({ children, ...props }, ref) => {
	return (
		<PopoverPrimitive.Trigger {...props} ref={ref}>
			{children}
		</PopoverPrimitive.Trigger>
	)
})
PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName

export const PopoverItem = forwardRef<
	HTMLButtonElement,
	ButtonHTMLAttributes<HTMLButtonElement> & {
		asChild?: boolean
		as?: string
	}
>(({ className, asChild = false, as = 'button', ...props }, ref) => {
	const Comp = asChild ? Slot : as
	return (
		<Comp
			className={cn(
				'select-none rounded-sm bg-transparent px-2 py-1.5 outline-none transition-colors hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
				className,
			)}
			ref={ref}
			{...props}
		/>
	)
})
PopoverItem.displayName = 'PopoverItem'

export const PopoverContent = forwardRef<
	ElementRef<typeof PopoverPrimitive.Content>,
	ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ children, className, ...props }, ref) => {
	return (
		<PopoverPrimitive.Portal>
			<PopoverPrimitive.Content
				className={cn(
					'z-50 flex min-w-[var(--radix-popper-anchor-width)] flex-col gap-2 overflow-hidden rounded border border-neutral-1-bd bg-neutral-1-bg p-2 text-neutral-2-fg shadow',
					className,
				)}
				{...props}
				ref={ref}
			>
				{children}
			</PopoverPrimitive.Content>
		</PopoverPrimitive.Portal>
	)
})
PopoverContent.displayName = PopoverPrimitive.Content.displayName
