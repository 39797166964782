import { api } from '#src/utils/endpoints'
import { fetch } from '#src/utils/safeFetch'

export const requestCalculateTAM = async (
	companyId: string,
	verticalId: string,
) => {
	return await fetch(api.vertical.tam.request(companyId, verticalId), {
		method: 'PUT',
	})
}
