import { Outlet } from 'react-router-dom'
import PageLoader from '#src/components/loader'
import Sidebar from '#src/routes/_layout/sidebar'

export default function Layout() {
	return (
		<>
			<Sidebar />

			<div className="relative flex w-full flex-col overflow-auto">
				<PageLoader />
				<Outlet />
			</div>
		</>
	)
}
