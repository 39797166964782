import { Slot } from '@radix-ui/react-slot'
import {
	type ReactNode,
	forwardRef,
	createContext,
	useContext,
	useState,
	useEffect,
} from 'react'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

const PANEL_CONTEXT_DEFAULTS = {
	floating: localStorage.getItem('builder-sidebar-minimized') === 'true',
	open: false,
}

export const PanelContext = createContext<{
	floating: boolean
	open: boolean
	toggle: (v: typeof PANEL_CONTEXT_DEFAULTS) => void
}>({
	...PANEL_CONTEXT_DEFAULTS,
	toggle(v) {
		void v
	},
})
export const PanelProvider = ({ children }: { children: ReactNode }) => {
	const [value, set] = useState(PANEL_CONTEXT_DEFAULTS)
	useEffect(() => {
		if (!value.floating) set(state => ({ ...state, open: false }))

		localStorage.setItem('builder-sidebar-minimized', String(value.floating))
	}, [value.floating])
	return (
		<PanelContext.Provider value={{ ...value, toggle: set }}>
			{children}
		</PanelContext.Provider>
	)
}
export const usePanel = () => useContext(PanelContext)

export function PanelToggle() {
	const { floating, open, toggle } = usePanel()
	const handleToggle = () => {
		toggle({ floating: !floating, open: !open })
	}

	if (!floating) return null

	return (
		<button
			type="button"
			onClick={handleToggle}
			onMouseEnter={() => toggle({ floating, open: true })}
			onMouseLeave={() => toggle({ floating, open: false })}
			className="group flex items-center p-2 text-neutral-3-fg"
		>
			<Icon name="side-panel-open" className="hidden group-hover:block" />
			<Icon name="menu" className="block group-hover:hidden" />
		</button>
	)
}

export default function Panel({
	children,
	header,
}: {
	children: ReactNode
	header?: ReactNode
}) {
	const { floating, open, toggle } = usePanel()
	const handleToggle = () => {
		toggle({ floating: !floating, open: !open })
	}

	return (
		<nav
			className={cn(
				'pointer-events-none sticky left-0 top-0 z-30 shrink-0 grow-0 transition-all',
				floating ? 'w-0' : 'w-[var(--builder-sidebar-width)]',
			)}
		>
			<div className="pointer-events-none absolute bottom-0 left-0 top-0 z-10 flex w-0 flex-col">
				<div
					className={cn(
						'pointer-events-auto flex h-screen w-[var(--builder-sidebar-width)] flex-col overflow-y-auto pb-4 transition-all bg-neutral-1-bg',
						floating
							? 'border-r border-r-transparent'
							: 'border-r border-r-neutral-1-bd',
						floating
							? 'h-[calc(100vh-var(--builder-header-height))]'
							: 'h-screen',
						floating
							? '-translate-x-[100%] translate-y-[var(--builder-header-height)] hover:translate-x-0 hover:shadow'
							: 'translate-x-0',
						open ? 'translate-x-0 shadow' : '',
					)}
				>
					<div className="sticky top-0 z-40 flex flex-nowrap items-center bg-neutral-1-bg p-2 pr-3">
						{!floating ? (
							<button
								onClick={handleToggle}
								type="button"
								className="flex items-center p-2"
							>
								<Icon name="side-panel-close" />
							</button>
						) : null}
						{header}
					</div>

					{children}
				</div>
			</div>
		</nav>
	)
}

type PanelSectionItemProps = {
	className?: string
	asChild?: boolean
	isTrigger?: boolean
	isActive?: boolean
	children: ReactNode | ReactNode[]
}
export const PanelSectionItem = forwardRef<
	HTMLDivElement,
	PanelSectionItemProps
>(({ className, asChild, isTrigger, isActive, children, ...props }, ref) => {
	const Comp = asChild ? Slot : 'div'
	return (
		<Comp
			ref={ref}
			className={cn(
				'mt-1 flex flex-nowrap items-center rounded-sm py-2 pl-3 pr-2 text-body-md transition-all',
				isTrigger ? 'cursor-pointer hover:bg-neutral-1-bg-hover' : '',
				isActive
					? 'bg-neutral-1-bg-selected text-neutral-1-fg'
					: 'bg-transparent text-neutral-2-fg',
				className,
			)}
			{...props}
		>
			{children}
		</Comp>
	)
})
PanelSectionItem.displayName = 'PanelSectionItem'

type PanelSectionItemActionProps = {
	className?: string
	asChild?: boolean
	isTrigger?: boolean
	children: ReactNode
}

export const PanelSectionItemAction = forwardRef<
	HTMLDivElement,
	PanelSectionItemActionProps
>(({ className, asChild, isTrigger, children, ...props }, ref) => {
	const Comp = asChild ? Slot : 'div'
	return (
		<Comp
			{...props}
			ref={ref}
			className={cn(
				'flex h-5 w-5 items-center justify-center',
				'rounded-sm text-neutral-2-fg transition-colors',
				isTrigger ? 'hover:bg-neutral-3-bg-hover' : '',
				className,
			)}
		>
			{children}
		</Comp>
	)
})
PanelSectionItemAction.displayName = 'PanelSectionItemAction'
