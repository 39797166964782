import { type QueryClient } from '@tanstack/react-query'
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs } from 'react-router-dom'
import DeleteConfirm from '#src/components/delete-confirm'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { deletePersona } from './mutations'
import { personaKeys } from './queries'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.personaId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await deletePersona(params.companyId, params.personaId)

		await queryClient.invalidateQueries({
			queryKey: personaKeys.all,
		})
		void queryClient.invalidateQueries({
			queryKey: ecosystemKeys.list(params.companyId),
		})

		return null
	}

export interface DeletePersonaAsyncFormProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: string
	verticalId: string
	personaId: string
	children: ReactNode
}

export const DeletePersonaAsyncForm = forwardRef<
	HTMLButtonElement,
	DeletePersonaAsyncFormProps
>(
	(
		{ ecosystemId, verticalId, personaId, children, className, ...props },
		ref,
	) => {
		const params = useParsedRouteParams(['companyId'])
		const action = routes.calibrate.persona.delete({
			companyId: params.companyId,
			ecosystemId: ecosystemId,
			verticalId: verticalId,
			personaId: personaId,
		})

		return (
			<DeleteConfirm
				trigger={
					<button
						{...props}
						ref={ref}
						type="button"
						className={cn('flex items-center justify-center', className)}
					>
						{children}
					</button>
				}
				action={action}
				title="Remove persona?"
				description="This action cannot be undone. This will permanently delete the persona and it's associated data."
			/>
		)
	},
)
DeletePersonaAsyncForm.displayName = 'DeletePersonaAsyncForm'
