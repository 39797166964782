import {
	getFormProps,
	getInputProps,
	getTextareaProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import {
	Form,
	Link,
	type LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom'
import { type z } from 'zod'
import {
	CheckboxField,
	ErrorList,
	Field,
	TextareaField,
} from '#src/components/forms'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import {
	Sidebar,
	SidebarFooter,
	SidebarHeader,
} from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { personaQuery } from './queries'
import { type PersonaAPISchema, PersonaTuneFormSchema } from './schema'

export type PersonaSetupLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (
			!params.companyId ||
			!params.ecosystemId ||
			!params.verticalId ||
			!params.personaId
		)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const persona = await queryClient.fetchQuery(
			personaQuery(params.companyId, params.personaId),
		)

		if (!persona)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		return {
			companyId: params.companyId,
			ecosystemId: params.ecosystemId,
			verticalId: params.verticalId,
			personaId: params.personaId,
			persona,
		}
	}

export default function PersonaSetup() {
	const { companyId, ecosystemId, verticalId, personaId, persona } =
		useLoaderData() as PersonaSetupLoaderResponse

	const action = routes.calibrate.persona.save({
		companyId: companyId,
		ecosystemId: ecosystemId,
		verticalId: verticalId,
		personaId: persona.id!.toString(),
	})
	const method = 'PATCH'
	const formId = 'persona-setup-form-' + persona.id

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<div className="w-[26rem]">
			<Sidebar
				header={
					<SidebarHeader>
						<h1>Fine tune AI</h1>
						<Link
							className="inline-flex"
							to={routes.calibrate.persona.index({
								companyId: companyId,
								ecosystemId: ecosystemId,
								verticalId: verticalId,
								personaId: personaId,
							})}
						>
							<Icon name="cross-1" />
						</Link>
					</SidebarHeader>
				}
				main={
					<PersonaFineTuneForm
						formId={formId}
						action={action}
						persona={persona}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link
								to={routes.calibrate.persona.index({
									companyId: companyId,
									ecosystemId: ecosystemId,
									verticalId: verticalId,
									personaId: personaId,
								})}
							>
								Cancel
							</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							size="sm"
							type="submit"
							name="intent"
							value="persona"
							form={formId}
							disabled={isPending}
						>
							Save
						</StatusButton>
					</SidebarFooter>
				}
			/>
		</div>
	)
}

function PersonaFineTuneForm({
	formId,
	action,
	persona,
}: {
	formId: string
	action: string
	persona: z.infer<typeof PersonaAPISchema>
}) {
	const method = 'PATCH'

	const defaultValue = {
		id: persona.id,
		aiFineTuning: {
			answerFormat:
				'Strictly answer with a list of 5 numbered bullet points ranked by importance. Avoid any form of nested lists or sub-bullet points to maintain clarity. Each bullet point should stand on its own and provide value independently, without relying on additional context from other points. Strictly answer with 45 to 55 words for each numbered bullet point. Strictly do not write the number of words or words counts in your answer.',
			directReport: null,
			exampleCompanies: null,
			bizModel: null,
			employeeNumber: null,
			regions: null,
			regionsManual: null,
			subverticals: null,
			subverticalsManual: null,
			specificComment: null,
			comments: null,
			...(persona?.aiFineTuning ?? {}),
		},
	}

	const [form, fields] = useForm({
		id: formId,
		constraint: getZodConstraint(PersonaTuneFormSchema),
		defaultValue,
		onValidate({ formData }) {
			return parseWithZod(formData, {
				schema: PersonaTuneFormSchema,
			})
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})
	const aiFineTuning = fields.aiFineTuning.getFieldset()

	return (
		<Form
			method={method}
			{...getFormProps(form)}
			action={action}
			className="flex flex-col gap-4"
		>
			<input
				{...getInputProps(fields.id, { type: 'hidden' })}
				defaultValue={fields.id.value}
			/>

			<TextareaField
				labelProps={{
					children: 'AI Answer Format',
				}}
				textareaProps={{
					...getTextareaProps(aiFineTuning.answerFormat),
					rows: 8,
				}}
				errors={aiFineTuning.answerFormat.errors}
			/>
			<CheckboxField
				labelProps={{ children: 'Include Direct Report in the prompt?' }}
				buttonProps={{
					...getInputProps(aiFineTuning.directReport, {
						type: 'checkbox',
					}),
				}}
				errors={aiFineTuning.directReport.errors}
			/>
			<CheckboxField
				labelProps={{ children: 'Include Example Companies in the prompt?' }}
				buttonProps={{
					...getInputProps(aiFineTuning.exampleCompanies, {
						type: 'checkbox',
					}),
				}}
				errors={aiFineTuning.exampleCompanies.errors}
			/>
			<CheckboxField
				labelProps={{
					children:
						'Include Vertical business model description in the prompt?',
				}}
				buttonProps={{
					...getInputProps(aiFineTuning.bizModel, {
						type: 'checkbox',
					}),
				}}
				errors={aiFineTuning.bizModel.errors}
			/>
			<CheckboxField
				labelProps={{
					children:
						'Include the number of employees in the prompt in the prompt?',
				}}
				buttonProps={{
					...getInputProps(aiFineTuning.employeeNumber, {
						type: 'checkbox',
					}),
				}}
				errors={aiFineTuning.employeeNumber.errors}
			/>

			<CheckboxField
				labelProps={{ children: 'Include locations from the ICPs?' }}
				buttonProps={{
					...getInputProps(aiFineTuning.regions, {
						type: 'checkbox',
					}),
					onCheckedChange: checked => {
						if (checked) {
							form.update({
								name: aiFineTuning.regionsManual.name,
								value: '',
							})
						}
					},
				}}
				errors={aiFineTuning.regions.errors}
			/>
			<Field
				labelProps={{ children: 'or enter specific locations' }}
				inputProps={{
					...getInputProps(aiFineTuning.regionsManual, {
						type: 'text',
					}),
					disabled: aiFineTuning.regions.value === 'on',
					onChange: () => {
						form.update({
							name: aiFineTuning.regions.name,
							value: false,
						})
					},
				}}
				errors={aiFineTuning.regionsManual.errors}
			/>

			<CheckboxField
				labelProps={{ children: 'Include Sub-Verticals from the ICPs?' }}
				buttonProps={{
					...getInputProps(aiFineTuning.subverticals, {
						type: 'checkbox',
					}),
					onCheckedChange: checked => {
						if (checked) {
							form.update({
								name: aiFineTuning.subverticalsManual.name,
								value: '',
							})
						}
					},
				}}
				errors={aiFineTuning.subverticals.errors}
			/>
			<Field
				labelProps={{ children: 'or enter specific Sub-Verticals' }}
				inputProps={{
					...getInputProps(aiFineTuning.subverticalsManual, {
						type: 'text',
					}),
					disabled: aiFineTuning.subverticals.value === 'on',
					onChange: () => {
						form.update({
							name: aiFineTuning.subverticals.name,
							value: false,
						})
					},
				}}
				errors={aiFineTuning.subverticalsManual.errors}
			/>

			<TextareaField
				labelProps={{
					children:
						'Missing something? Add one more specific comment to the prompt',
				}}
				textareaProps={{
					...getTextareaProps(aiFineTuning.specificComment),
				}}
				errors={aiFineTuning.specificComment.errors}
			/>
			<TextareaField
				labelProps={{ children: 'Comments' }}
				textareaProps={{
					...getTextareaProps(aiFineTuning.comments),
				}}
				errors={aiFineTuning.comments.errors}
			/>

			<ErrorList errors={form.errors} id={form.errorId} />
		</Form>
	)
}
