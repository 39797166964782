import { type QueryClient } from '@tanstack/react-query'
import { Link, useLoaderData, type LoaderFunctionArgs } from 'react-router-dom'
import {
	DashboardCard,
	DashboardCardCount,
} from '#src/components/dashboard-card'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { companyQuery, statsQuery } from '#src/routes/calibrate/company/queries'
import { userQuery } from '#src/routes/init/user/me'
import { chatsQuery as aidtQuery } from '#src/routes/organize/aidt/queries'
import {
	checkIsAdminSession,
	checkIsDataTrainerSession,
	cn,
} from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type CompanyDashboardResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))

		if (!company)
			throw new Response('Company Not Found', {
				status: 404,
				statusText: 'Not Found',
			})

		const stats = await queryClient.fetchQuery(statsQuery(params.companyId))
		const user = await queryClient.fetchQuery(userQuery())
		const aidtChats = await queryClient.fetchQuery(aidtQuery(params.companyId))

		return { company, stats, user, aidtChats }
	}

export default function CompanyDetails() {
	const { company, stats, user, aidtChats } =
		useLoaderData() as CompanyDashboardResponse
	const { dashboard } = company
	const msp = dashboard?.find(item => item.name === 'MSP')

	return (
		<main className="flex h-auto w-full flex-col items-center gap-6 py-10">
			<section className="flex w-full max-w-screen-2xl flex-col gap-6 px-20">
				<h1 className="text-heading-sm font-semibold">Home</h1>
			</section>

			<section className="grid w-full max-w-screen-2xl grid-cols-2 grid-rows-2 gap-6 px-20 xl:grid-cols-4 xl:grid-rows-1">
				<Link
					to={routes.calibrate.index({
						companyId: company.id,
					})}
					className="block"
				>
					<ProductPillarCard
						preffixIcon="data-unstructured"
						title="Calibrate"
						description="Calibration creates a unique customer intelligence dataset that is the foundation for all other Evergrowth agents. This dataset contains your Value Proposition, ICPs, Verticals, Key Accounts and IBPs. You must run Calibration before using other agents or copilots."
						titleColorClass="text-blue-60"
						bgColorClass="bg-blue-10"
						stats={[
							{
								label: 'Ecosystems',
								value: stats?.calibrate?.ecosystem_count ?? 0,
							},
							{
								label: 'Verticals',
								value: stats?.calibrate?.vertical_count ?? 0,
							},
							{
								label: 'Personas',
								value: stats?.calibrate?.persona_count ?? 0,
							},
						]}
					/>
				</Link>

				<Link to={`/organize/${company.id}`} className="block">
					<ProductPillarCard
						preffixIcon="data-unreal"
						title="Organize"
						description="These criteria-based agents organize, categorize, and enrich lists of accounts and contacts, to validate their ICP based on your customer intelligence and waterfall enrichment vendor setup."
						titleColorClass="text-indigo-70"
						bgColorClass="bg-indigo-10"
						stats={
							checkIsDataTrainerSession(user.roles) ||
							checkIsAdminSession(user.roles)
								? [{ label: 'Lead research agent Q&A', value: aidtChats.length }]
								: null
						}
					/>
				</Link>

				<Link to={`/prioritize/${company.id}`} className="block">
					<ProductPillarCard
						preffixIcon="upstream"
						title="Prioritize"
						description="Prioritize uses intent-based agents to crawl the web, listening for buying signals and event triggers mapped to your prospects, and scores them accordingly. The agent also produces actionable insights for both prospecting and outreach."
						titleColorClass="text-purple-70"
						bgColorClass="bg-purple-10"
						stats={[
							{ label: 'Signals used', value: stats?.prioritize?.signals ?? 0 },
							{
								label: 'Personas mapped',
								value: stats?.prioritize?.personas ?? 0,
							},
						]}
					/>
				</Link>

				<Link to={`/enable/${company.id}`} className="block">
					<ProductPillarCard
						preffixIcon="data-enrichment"
						title="Enable"
						description="Use AI copilots to generate ultra-personalized interactions with your prospects and customers across all stages of the sales funnel. Roleplay with simulated buyers based on LinkedIn profiles, or chat with personas for on-time training in a variety of sales scenarios."
						titleColorClass="text-green-80"
						bgColorClass="bg-green-10"
						stats={[
							{ label: 'Co-pilot chats', value: stats?.enable?.chats ?? 0 },
							{ label: 'Industry-jargons', value: stats?.enable?.terms ?? 0 },
						]}
					/>
				</Link>
			</section>

			<section className="grid w-full max-w-screen-2xl grid-cols-2 grid-rows-1 gap-6 px-20">
				<DashboardCard
					icon="book"
					className="h-max self-start"
					title="Resources"
				>
					<section className="mt-4 space-y-2">
						<Resource
							title="How to create your customer-centric Value Proposition"
							url="https://docs.evergrowth.com/platform/calibrate-guides/ai-customer-centric-value-proposition-generator"
						/>
						<Resource
							title="How to set up your Ecosystems"
							url="https://docs.evergrowth.com/platform/calibrate-guides/setting-up-your-ecosystems"
						/>
						<Resource
							title="How to set up your Verticals"
							url="https://docs.evergrowth.com/platform/calibrate-guides/setting-up-your-verticals"
						/>
						<Resource
							title="How to set up your Personas"
							url="https://docs.evergrowth.com/platform/calibrate-guides/setting-up-your-personas"
						/>
					</section>
				</DashboardCard>

				<section className="flex flex-col gap-6">
					<DashboardCard
						icon="time-plot"
						className="h-max self-start"
						title={
							<div className="flex items-center gap-1">
								Time saved
								<ProductTip
									className="text-neutral-3-fg"
									content="Total hours saved with AI-enabled processes."
								/>
							</div>
						}
					>
						<DashboardCardCount
							count={
								(stats?.time?.personas ?? 0) +
								(stats?.time?.signals ?? 0) +
								(stats?.time?.terms ?? 0) +
								(stats?.time?.verticals ?? 0)
							}
							countSuffix="Hours"
						/>
					</DashboardCard>

					<DashboardCard
						icon="portfolio"
						className="h-max self-start"
						title={
							<div className="flex w-full items-center justify-between">
								Mutual success plan
								<Button
									disabled={!msp?.url}
									asChild
									size="sm"
									variant="ghost"
									className="cursor-pointer gap-1 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
								>
									<a
										{...(msp?.url
											? { href: msp?.url, target: '__blank' }
											: { ['data-disabled']: true })}
									>
										View <Icon name="launch" size="sm" />
									</a>
								</Button>
							</div>
						}
					/>
				</section>
			</section>
		</main>
	)
}

function ProductPillarCard({
	preffixIcon,
	title,
	description,
	disabled = false,
	external = false,
	titleColorClass,
	bgColorClass,
	stats,
}: {
	preffixIcon: IconName
	title: string
	description: string
	disabled?: boolean
	external?: boolean
	titleColorClass: string
	bgColorClass: string
	stats?: { label: string; value: number }[] | null
}) {
	return (
		<Surface
			className={cn(
				'flex h-full w-full flex-col gap-4 p-6',
				disabled
					? 'cursor-not-allowed'
					: 'cursor-pointer bg-neutral-1-bg transition-colors hover:bg-neutral-1-bg-hover',
			)}
		>
			<div
				className={cn(
					'grid grid-cols-[max-content,1fr] grid-rows-2 gap-2 text-title-md',
					disabled ? 'text-neutral-1-fg-disabled' : 'text-neutral-1-fg',
					titleColorClass,
				)}
			>
				<div
					className={cn(
						'flex h-8 w-8 items-center justify-center rounded',
						bgColorClass,
					)}
				>
					<Icon name={preffixIcon} size="font" className={cn('shrink-0')} />
				</div>
				{disabled ? (
					<p className="ml-auto flex flex-nowrap items-center gap-0.5 self-start whitespace-nowrap rounded-full bg-neutral-2-bg px-2 py-0.5 text-label-sm text-neutral-2-fg">
						Connect your CRM
					</p>
				) : external ? (
					<Icon
						name="launch"
						size="sm"
						className="self-center justify-self-end text-label-sm text-neutral-3-fg"
					/>
				) : null}
				<div className="col-span-2 row-span-2">{title}</div>
			</div>
			<p
				className={cn(
					'text-body-sm',
					disabled ? 'text-neutral-2-fg-disabled' : 'text-neutral-2-fg',
				)}
			>
				{description}
			</p>
			<ul className="mt-auto">
				{stats &&
					stats.map((stat, i) => (
						<li
							key={i}
							className={cn(
								'flex w-full items-center justify-between border-b border-b-neutral-1-bd py-2 text-body-sm font-semibold last:border-none',
								disabled ? 'text-neutral-1-fg-disabled' : 'text-neutral-1-fg',
							)}
						>
							{stat.label}
							{disabled ? null : (
								<div className={cn('font-normal', titleColorClass)}>
									{stat.value}
								</div>
							)}
						</li>
					))}
			</ul>
		</Surface>
	)
}

function Resource({ title, url }: { title: string; url: string }) {
	return (
		<div className="flex items-center justify-between gap-4 rounded bg-neutral-2-bg px-3 py-2 text-body-md font-medium">
			{title}
			<Button
				asChild
				size="sm"
				variant="ghost"
				className="cursor-pointer gap-1"
			>
				<a href={url} target="__blank">
					View <Icon name="launch" size="sm" />
				</a>
			</Button>
		</div>
	)
}
