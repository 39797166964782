import './tailwind.css'

import * as ToastPrimitive from '@radix-ui/react-toast'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import {
	createRoutesFromChildren,
	RouterProvider,
	useLocation,
	useNavigationType,
	matchRoutes,
} from 'react-router-dom'

import { createAppRouter } from '#src/router'
import { createClientStore } from './store'

if (import.meta.env.PROD) {
	const appEnv = import.meta.env.VITE_APP_ENVIRONMENT

	Sentry.init({
		...(appEnv ? { environment: appEnv } : {}),
		dsn: 'https://9ba42fa9551b748297c9e421c872f798@o4506613836677120.ingest.sentry.io/4506823228915712',
		integrations: [
			Sentry.metrics.metricsAggregatorIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracePropagationTargets: ['localhost', /^https:\/\/evergrowth\.com/],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
	})
}

if (import.meta.env.DEV) {
	const { worker } = await import('../mocks/browser')

	await worker.start()
}

export const client = createClientStore()
const router = createAppRouter(client)

createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<QueryClientProvider client={client}>
			<ToastPrimitive.Provider>
				<RouterProvider router={router} />
			</ToastPrimitive.Provider>
			<ReactQueryDevtools buttonPosition="bottom-right" />
		</QueryClientProvider>
	</React.StrictMode>,
)
