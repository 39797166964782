import { type QueryClient } from '@tanstack/react-query'
import { type LoaderFunctionArgs, Outlet, redirect } from 'react-router-dom'
import { companiesQuery } from '#src/routes/calibrate/company/queries'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { userQuery } from '#src/routes/init/user/me'
import { signalsTemplatesQuery } from '#src/routes/prioritize/queries'
import {
	checkIsDataTrainerSession,
	checkIsEnableOnlySession,
	checkIsReadOnlySession,
} from '#src/utils/misc'

export type MainLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params, request }: LoaderFunctionArgs) => {
		const user = await queryClient.fetchQuery(userQuery())
		const readOnlySession = checkIsReadOnlySession(user.roles)
		const enableOnlySession = checkIsEnableOnlySession(user.roles)
		const dataTrainerSession = checkIsDataTrainerSession(user.roles)

		if (enableOnlySession && !new URL(request.url).pathname.includes('/enable'))
			throw redirect(`/enable/${user.companyId}`)

		if (!params.companyId) {
			if (enableOnlySession) throw redirect(`/enable/${user.companyId}`)
			throw redirect(`/company/${user.companyId}`)
		}

		if (enableOnlySession) {
			return {
				ecosystems: null,
				company: null,
				signals: null,
				user,
				readOnlySession,
				enableOnlySession,
				dataTrainerSession,
			}
		}

		const companies = await queryClient.fetchQuery(companiesQuery())

		if (companies?.length === 0)
			throw new Response('No companies found', {
				status: 500,
				statusText: 'Server Error',
			})
		const company = companies.find(c => c.id === params.companyId)
		if (!company)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		if (enableOnlySession) {
			return {
				ecosystems: null,
				user,
				company,
				signals: null,
				readOnlySession,
				enableOnlySession,
				dataTrainerSession,
			}
		}

		const ecosystems = await queryClient.fetchQuery(
			ecosystemsQuery(params.companyId),
		)
		const signals = await queryClient.fetchQuery(
			signalsTemplatesQuery(params.companyId),
		)

		return {
			ecosystems,
			user,
			company,
			signals,
			readOnlySession,
			enableOnlySession,
			dataTrainerSession,
		}
	}

export default function Layout() {
	return (
		<div className="relative flex h-screen w-screen overflow-hidden bg-neutral-1-bg text-neutral-1-fg">
			<Outlet />
		</div>
	)
}
