import * as DialogPrimitive from '@radix-ui/react-dialog'
import * as Portal from '@radix-ui/react-portal'
import { type ReactNode } from 'react'
import { cn } from '#src/utils/misc'

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
	header?: ReactNode
	main: ReactNode
	footer?: ReactNode
	onEscapeKeyDown?: () => void
}

export function Sidebar({ header, main, footer }: SidebarProps) {
	return (
		<Portal.Root>
			<div className="fixed right-0 top-[var(--builder-header-height)] z-10 h-screen w-[28rem] animate-in fade-in slide-in-from-right-10">
				<div className="h-[calc(100vh-var(--builder-header-height))] overflow-y-auto border-l border-neutral-1-bd bg-neutral-1-bg">
					<aside className="flex min-h-full grow flex-col">
						{header}
						<main className="grow p-4">{main}</main>
						{footer}
					</aside>
				</div>
			</div>
		</Portal.Root>
	)
}

export function Drawer({
	header,
	main,
	footer,
	dialogProps,
}: SidebarProps & { dialogProps: DialogPrimitive.DialogProps }) {
	return (
		<DialogPrimitive.Root {...dialogProps}>
			<DialogPrimitive.Portal>
				<DialogPrimitive.Overlay className="fixed inset-0 z-50 bg-overlay animate-in fade-in" />
				<DialogPrimitive.Content className="fixed right-0 top-0 z-50 h-screen w-[28rem] animate-in fade-in slide-in-from-right-10">
					<div className="h-full overflow-y-auto border-l border-neutral-1-bd bg-neutral-1-bg">
						<aside className="flex min-h-full grow flex-col">
							{header}
							<main className="grow p-4">{main}</main>
							{footer}
						</aside>
					</div>
				</DialogPrimitive.Content>
			</DialogPrimitive.Portal>
		</DialogPrimitive.Root>
	)
}

export function SidebarHeader({
	children,
	className,
}: {
	children: ReactNode
	className?: string
}) {
	return (
		<header
			className={cn(
				'sticky top-0 z-10 flex h-[var(--builder-header-height)] w-full items-center justify-between border-b border-t-0 border-b-neutral-1-bd bg-neutral-1-bg px-4',
				className,
			)}
		>
			{children}
		</header>
	)
}

export function SidebarFooter({
	children,
	className,
}: {
	children: ReactNode
	className?: string
}) {
	return (
		<footer
			className={cn(
				'sticky bottom-0 mt-auto flex w-full justify-end gap-2 border-t border-t-neutral-1-bd bg-neutral-1-bg px-4 py-3',
				className,
			)}
		>
			{children}
		</footer>
	)
}
