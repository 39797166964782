import { parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { type ActionFunctionArgs, redirect } from 'react-router-dom'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem/queries'
import { routes } from '#src/utils/routes'
import { createVertical, updateVertical } from './mutations'
import { verticalKeys, verticalsQuery } from './queries'
import { VerticalFormSchema } from './schema'

export const action =
	(queryClient: QueryClient) =>
	async ({ params, request }: ActionFunctionArgs) => {
		if (!params.companyId || !params.ecosystemId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const formData = await request.formData()
		const intent = formData.get('intent')

		if (intent === 'vertical') {
			const submission = parseWithZod(formData, {
				schema: VerticalFormSchema,
			})

			if (submission.status !== 'success') {
				throw Error('Failed to parse form data')
			}

			const { id, answers, ...data } = submission.value
			const payload = {
				...data,
				answers: answers?.filter(({ value }) => Boolean(value)),
				// TODO: API TBD
				// dataPoints: data.dataPoints?.map(datapoint => ({
				// 	...datapoint,
				// 	value: datapoint.value ? datapoint.value : '-',
				// })),
			}
			if (id) {
				await updateVertical(params.companyId, id, payload)

				await Promise.all([
					queryClient.invalidateQueries({
						queryKey: ecosystemKeys.list(params.companyId),
					}),
					queryClient.invalidateQueries({
						queryKey: verticalKeys.all,
					}),
				])

				return redirect(
					routes.calibrate.verticals.index({
						companyId: params.companyId,
						ecosystemId: params.ecosystemId,
					}),
				)
			}

			const verticals = await queryClient.ensureQueryData(
				verticalsQuery(params.companyId, Number(params.ecosystemId)),
			)

			await createVertical(
				params.companyId,
				Number(params.ecosystemId),
				payload,
			)

			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: ecosystemKeys.list(params.companyId),
				}),
				queryClient.invalidateQueries({
					queryKey: verticalKeys.all,
				}),
			])

			if (verticals.length === 0) {
				return redirect(
					routes.calibrate.criteria.create({
						companyId: params.companyId,
						ecosystemId: params.ecosystemId,
					}),
				)
			}

			return redirect(
				routes.calibrate.verticals.index({
					companyId: params.companyId,
					ecosystemId: params.ecosystemId,
				}),
			)
		}
	}
