import { generatePath } from 'react-router-dom'

const generatePathWithQuery = ({
	path,
	queryArgs,
}: {
	path: string
	queryArgs: string | URLSearchParams | string[][] | Record<string, string>
}) => {
	const query = new URLSearchParams(queryArgs).toString()

	return `${path}?${query}`
}

export const routes = {
	enable: {
		index: (params: { companyId: string }) =>
			generatePath('/enable/:companyId', params),
		glossary: {
			index: (params: { companyId: string }) =>
				generatePath('/enable/:companyId/glossary', params),
			save: (params: { companyId: string; termId: string | null }) =>
				generatePath('/enable/:companyId/glossary/save/:termId?', params),
			edit: (params: { companyId: string; termId: string | null }) =>
				generatePath('/enable/:companyId/glossary/edit/:termId?', params),
			delete: (params: { companyId: string; termId: string }) =>
				generatePath('/enable/:companyId/glossary/delete/:termId', params),
		},
		copilot: {
			index: (params: { companyId: string; conversationId: string | null }) =>
				generatePath('/enable/:companyId/copilot/:conversationId?', params),
			init: (params: { contactId: string }) =>
				generatePath('/copilot/:contactId', params),
			new: (params: { companyId: string; conversationId: string | null }) =>
				generatePath('/enable/:companyId/copilot/:conversationId?/new', params),
			restart: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/copilot/:conversationId/restart', params), // prettier-ignore
			delete: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/copilot/:conversationId/delete', params), // prettier-ignore
			persona: (params: { companyId: string; conversationId: string; properties: 'pain-points' | 'mapping' | 'co-pilot-recos' }) => // prettier-ignore
				generatePath('/enable/:companyId/copilot/:conversationId/persona/:properties', params), // prettier-ignore
			contact: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/copilot/:conversationId/contact', params), // prettier-ignore
		},
		roleplay: {
			index: (params: { companyId: string; }) =>
				generatePath('/enable/:companyId/roleplay', params), // prettier-ignore
			new: (params: {
				companyId: string
				newRoleplaySessionType: 'solo' | 'group'
			}) =>
				generatePath(
					'/enable/:companyId/roleplay/new/:newRoleplaySessionType',
					params,
				), // prettier-ignore,
			session: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/roleplay/session/:conversationId', params), // prettier-ignore
			end: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/roleplay/session/:conversationId/end-session', params), // prettier-ignore
			restart: (params: { companyId: string; conversationId: string }) =>
					generatePath('/enable/:companyId/roleplay/session/:conversationId/restart', params), // prettier-ignore
			delete: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/roleplay/session/:conversationId/delete', params), // prettier-ignore
			review: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/roleplay/review/:conversationId', params), // prettier-ignore
			deleteReviewed: (params: { companyId: string; conversationId: string }) =>
				generatePath('/enable/:companyId/roleplay/review/:conversationId/delete', params), // prettier-ignore
		},
	},
	calibrate: {
		index: (params: { companyId: string }) =>
			generatePath('/company/:companyId/details', params),
		edit: (params: { companyId: string }) =>
			generatePath('/company/:companyId/details/edit', params),
		ecosystem: {
			create: (params: { companyId: string; }) =>
				generatePath('/company/:companyId/details/ecosystem', params), // prettier-ignore
			delete: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/company/:companyId/details/ecosystem/:ecosystemId/delete', params), // prettier-ignore
			edit: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/company/:companyId/details/ecosystem/:ecosystemId', params), // prettier-ignore
			save: (params: { companyId: string; ecosystemId: string | null }) =>
				generatePath('/company/:companyId/details/ecosystem/:ecosystemId?/save', params), // prettier-ignore
			sort: (params: { companyId: string }) =>
				generatePath('/company/:companyId/details/ecosystem/sort', params),
		},
		verticals: {
			index: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/verticals/:companyId/:ecosystemId', params),
			create: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/verticals/:companyId/:ecosystemId/edit', params),
			edit: (params: { companyId: string; ecosystemId: string; verticalId: string }) => // prettier-ignore
				generatePath('/verticals/:companyId/:ecosystemId/edit/:verticalId', params), // prettier-ignore
			// prettier-ignore
			duplicate: (params: { companyId: string; ecosystemId: string; verticalId: string; autoFocus?: string | null }) => { // prettier-ignore
				return generatePathWithQuery({
					path: generatePath('/verticals/:companyId/:ecosystemId/edit', params),
					queryArgs: {
						duplicate: params.verticalId,
						...(params.autoFocus && { autofocus: params.autoFocus }),
					},
				})
			},
			update: (params: { companyId: string; ecosystemId: string; verticalId: string }) => // prettier-ignore
				generatePath('/verticals/:companyId/:ecosystemId/save/:verticalId', params), // prettier-ignore
			save: (params: { companyId: string; ecosystemId: string }) => // prettier-ignore
				generatePath('/verticals/:companyId/:ecosystemId/save', params), // prettier-ignore
			delete: (params: { companyId: string; ecosystemId: string; verticalId: string }) => // prettier-ignore
				generatePath('/verticals/:companyId/:ecosystemId/delete/:verticalId', params), // prettier-ignore
			tam: (params: { companyId: string; ecosystemId: string; verticalId: string }) => // prettier-ignore
				generatePath('/verticals/:companyId/:ecosystemId/tam/:verticalId', params), // prettier-ignore
		},
		criteria: {
			create: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/verticals/:companyId/:ecosystemId/criteria', params),
			edit: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/verticals/:companyId/:ecosystemId/criteria', params),
		},
		datapoints: {
			create: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/verticals/:companyId/:ecosystemId/datapoints', params),
			edit: (params: { companyId: string; ecosystemId: string }) =>
				generatePath('/verticals/:companyId/:ecosystemId/datapoints', params),
		},
		persona: {
			index: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null; }) => // prettier-ignore 
					generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId?', params), // prettier-ignore
			save: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null; }) => // prettier-ignore
				generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId?/save', params), // prettier-ignore
			edit: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null; }) => // prettier-ignore 
				generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId?/edit', params), // prettier-ignore
			delete: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string; }) => // prettier-ignore
				generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId/delete', params), // prettier-ignore
			setup: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string; }) => // prettier-ignore
				generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId/setup', params), // prettier-ignore
			tune: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string; }) => // prettier-ignore
				generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId/tune', params), // prettier-ignore
			recalculate: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null; }) => // prettier-ignore
				generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId?/recalculate', params), // prettier-ignore
			// prettier-ignore
			duplicate: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string | null; duplicate: string }) =>
				generatePathWithQuery({
					path: generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId?/edit', params),
					queryArgs: {
						duplicate: params.duplicate
					},
				}),
			coach: {
				// prettier-ignore
				index: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string; }) => // prettier-ignore
						generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId/coach', params), // prettier-ignore,
				// prettier-ignore
				recalculate: (params: { companyId: string; ecosystemId: string; verticalId: string; personaId: string; salesCoachId: string; }) => // prettier-ignore
						generatePath('/personas/:companyId/:ecosystemId/:verticalId/:personaId/coach/recalculate/:salesCoachId', params), // prettier-ignore,
			},
		},
	},
	prioritize: {
		filters: (params: { companyId: string }) =>
			generatePath('/prioritize/:companyId/filters', params),
		signal: {
			index: (params: { companyId: string; signalId: string }) =>
				generatePath('/prioritize/:companyId/details/:signalId', params),
			assign: (params: { companyId: string; signalId: string }) =>
				generatePath('/prioritize/:companyId/details/:signalId/assign', params),
			save: (params: { companyId: string; signalId: string }) =>
				generatePath('/prioritize/:companyId/details/:signalId/save', params),
			example: (params: { companyId: string; signalId: string; personaId: string }) => // prettier-ignore
				generatePath('/prioritize/:companyId/details/:signalId/example/:personaId', params), // prettier-ignore
		},
	},
	organize: {
		index: (params: { companyId: string; }) =>
			generatePath('/organize/:companyId', params), // prettier-ignore
		aidt: {
			index: (params: { companyId: string; conversationId: string | null }) =>
				generatePath('/organize/:companyId/aidt/:conversationId?', params), // prettier-ignore
		},
	},
}
