import { z } from 'zod'
import { IntentSource, IntentType } from './filters/schema'

export const SignalConfig = z.object({
	cost: z.number().default(0),
	cadence: z.string().default('Weekly'),
	weight: z.number().default(1),
})

export const SignalTemplateAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	type: z.enum([
		IntentType.COLD_OUTBOUND_SIGNAL,
		IntentType.STATIC_HOOK,
		IntentType.INBOUND_SIGNAL,
		IntentType.WARM_OUTBOUND_SIGNAL,
	]),
	source: z.enum([
		IntentSource.ACCOUNT,
		IntentSource.ACCOUNT_PUBLIC_COMPANY,
		IntentSource.CONTACT,
		IntentSource.EXTERNAL,
	]),
	hack: z.boolean(),
	manualSteps: z.string(),
	context: z.string(),
	config: SignalConfig,
	manualRequired: z.boolean(),
	available: z.boolean(),
})

export const SignalsTemplatesAPISchema = SignalTemplateAPISchema.array()

export const AssignedSignalAPISchema = z.object({
	personaId: z.number(),
	signalId: z.number(),
	manualInput: z.string().nullable().optional(),
})

export const AssignedSignalsAPISchema = AssignedSignalAPISchema.array()

export const SignalConfigFormSchema = SignalConfig.omit({
	cost: true,
	cadence: true,
})

export const AssignedSignalsFormSchema = z.object({
	personas: z
		.object({
			selected: z.string().optional(),
			id: z.number(),
			// NOTE: a necessary attribute for performance optimisations
			verticalId: z.number().optional().nullable(),
			manualInput: z.string().nullable().optional(),
		})
		.array(),
})

export const AssignedSignalsUpdateSchema = z.object({
	personas: z
		.object({
			id: z.number(),
			manualInput: z.string().nullable().optional(),
		})
		.array(),
})

export const SignalUsageExampleAPISchema = z.object({
	signal: z.number(),
	persona: z.number(),
	preview: z.string(),
})
