import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { type QueryClient } from '@tanstack/react-query'
import { Fragment, useEffect, useState } from 'react'
import {
	useFetcher,
	useLoaderData,
	useRouteLoaderData,
	type LoaderFunctionArgs,
} from 'react-router-dom'
import { z } from 'zod'
import EcosystemsAccordion, {
	transformEcosystemsToAccordionData,
} from '#src/components/ecosystems-accordion'
import Markdown from '#src/components/markdown'
import NoPersonas from '#src/components/no-personas'
import NoVerticals from '#src/components/no-verticals'
import { PersonaData } from '#src/components/persona'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { Dialog, DialogTrigger } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { Label } from '#src/components/ui/label'
import { Surface } from '#src/components/ui/surface'
import { Textarea } from '#src/components/ui/textarea'
import {
	Toast,
	ToastDescription,
	ToastViewport,
} from '#src/components/ui/toast'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem/queries'
import { EcosystemListAPISchema } from '#src/routes/calibrate/ecosystem/schema'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useDoubleCheck } from '#src/utils/use-doublecheck'
import { type PersonaAssignActionResponse } from '../assign'
import { type SignalUsageExampleActionResponse } from '../example'
import { assignedSignalsQuery, signalTemplateQuery } from '../queries'
import { SignalWeightDetailsAsyncForm, WeightValue } from '../save'
import {
	AssignedSignalsAPISchema,
	AssignedSignalsFormSchema,
	SignalTemplateAPISchema,
} from '../schema'
import { SignalType, Table, TableAction } from '.'

export type EnrichDetailLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const SignalDetailsLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		signalTemplate: SignalTemplateAPISchema,
	}),
	assignedSignals: AssignedSignalsAPISchema,
	ecosystems: EcosystemListAPISchema,
	signalId: z.string(),
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.signalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		return {
			handle: {
				companyId: params.companyId,
				signalTemplate: await queryClient.fetchQuery(
					signalTemplateQuery(params.companyId, params.signalId),
				),
			},
			assignedSignals: (
				await queryClient.fetchQuery(assignedSignalsQuery(params.companyId))
			).filter(s => s.signalId === Number(params.signalId)),
			ecosystems: await queryClient.fetchQuery(
				ecosystemsQuery(params.companyId),
			),
			signalId: params.signalId,
		}
	}

export default function SignalDetails() {
	return (
		<>
			<Signal />
			<AssignPersonas />
		</>
	)
}

function Signal() {
	const {
		handle: { signalTemplate },
	} = useLoaderData() as EnrichDetailLoaderResponse
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse

	return (
		<section className="px-20 pb-8 pt-10">
			<Surface className="flex flex-col gap-6 overflow-hidden p-10">
				{!signalTemplate.available ? (
					<div className="-mx-10 -mt-10 bg-orange-10 px-10 py-2 text-center text-body-sm text-orange-70">
						Currently, this signal is still under development, but you can map
						personas so we can prioritize them as soon as the signal becomes
						available
					</div>
				) : null}

				<div className="flex flex-col gap-2">
					<SignalType type={signalTemplate.type} />
					<h1 className="flex items-center gap-1 text-title-lg text-neutral-1-fg">
						{signalTemplate.name}
						{signalTemplate.available ? (
							<div className="flex items-center text-label-lg text-green-70">
								<Icon
									name="circle-fill"
									size="sm"
									className="flex flex-nowrap items-center text-green-70 transition-colors"
								/>
								Available
							</div>
						) : null}
					</h1>
				</div>
				{readOnlySession ? (
					signalTemplate.config.weight ? (
						<div className="flex flex-col gap-1">
							<Label className="flex items-center gap-1 text-label-sm font-semibold text-neutral-3-fg">
								Weight
								<ProductTip content="Weight: The importance or relevance of the intent signal, typically as a numerical value." />
							</Label>
							<WeightValue value={signalTemplate.config.weight.toString()} />
						</div>
					) : null
				) : (
					<SignalWeightDetailsAsyncForm
						signalId={signalTemplate.id}
						weight={signalTemplate.config?.weight}
					/>
				)}
				<div className="flex flex-col gap-2">
					<h2 className="flex items-center gap-1 text-label-sm text-neutral-3-fg">
						Hack compatible
						<ProductTip content="Hack Compatible: Suitable for use in the intent-based hack workflow within the TAM organizer." />
					</h2>
					<p className="text-body-md text-neutral-1-fg">
						{signalTemplate.hack ? 'Yes' : 'No'}
					</p>
				</div>
				<div className="flex flex-col gap-2">
					<h2 className="flex items-center gap-1 text-label-sm text-neutral-3-fg">
						Example of manual steps
						<ProductTip content="Example of manual steps: Steps to manually identify and approach this intent signal & prospect." />
					</h2>
					<p className="text-body-md text-neutral-1-fg">
						{signalTemplate.manualSteps}
					</p>
				</div>
				<div className="flex flex-col gap-2">
					<h2 className="flex items-center gap-1 text-label-sm text-neutral-3-fg">
						Context & Example
						<ProductTip content="Context & Example: Illustrates how the intent signal can be valuable for pitching specific solutions." />
					</h2>
					<p className="text-body-md text-neutral-1-fg">
						{signalTemplate.context}
					</p>
				</div>
			</Surface>
		</section>
	)
}

function AssignPersonas() {
	const {
		handle: { signalTemplate, companyId },
		assignedSignals,
		ecosystems,
		signalId,
	} = useLoaderData() as EnrichDetailLoaderResponse
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse
	const dc = useDoubleCheck()

	const fetcher = useFetcher<PersonaAssignActionResponse>()
	const action = routes.prioritize.signal.assign({
		companyId: companyId,
		signalId: signalId,
	})

	const personasFields = ecosystems
		.map(
			e =>
				e.verticals.map(
					v => v.personas?.map(p => ({ ...p, verticalId: v.id })) ?? [],
				) ?? [],
		)
		.flat(3)
		.map(p => ({
			id: p.id,
			// NOTE: see NOTE on optimisation below
			verticalId: p.verticalId,
			manualInput:
				assignedSignals?.find(
					s => s.personaId === p.id && s.signalId === Number(signalId),
				)?.manualInput ?? null,
		}))
	// NOTE: we always want to reset the form whenever the assignedSignals change after Map/Unmap actions
	const formId = 'assign-personas-form-' + btoa(JSON.stringify(assignedSignals))
	const [form, fields] = useForm({
		id: formId,
		defaultValue: {
			personas: personasFields ?? [],
		},
		constraint: getZodConstraint(AssignedSignalsFormSchema),
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: AssignedSignalsFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})
	const personasFieldList = fields.personas.getFieldList()
	const selectedPersonas = personasFieldList.filter(
		sp => sp.getFieldset().selected.value === 'on',
	)

	return (
		<>
			<fetcher.Form
				method="PATCH"
				action={action}
				{...getFormProps(form)}
				className="flex flex-col gap-4 px-20 pb-20"
			>
				{personasFieldList.map(personaField => {
					const input = personaField.getFieldset()

					return (
						<Fragment key={personaField.id}>
							<input
								{...getInputProps(input.id, { type: 'hidden' })}
								key={`id-${input.id.value}`}
							/>
							{/* NOTE: see NOTE on optimisation below */}
							<input
								{...getInputProps(input.verticalId, { type: 'hidden' })}
								key={`verticalId-${input.verticalId.value}`}
							/>
							<input
								{...getInputProps(input.manualInput, { type: 'hidden' })}
								key={`manualinput-${input.id.value}`}
							/>
							<input
								{...getInputProps(input.selected, { type: 'hidden' })}
								key={`selected-${input.id.value}`}
							/>
						</Fragment>
					)
				})}
				<section className="flex items-center justify-between">
					<h2 className="text-label-lg text-neutral-3-fg">Persona mapping</h2>
					{readOnlySession ? null : (
						<Button
							key={formId}
							size="sm"
							variant="danger-outline"
							className="min-w-24 items-center gap-1"
							{...dc.getButtonProps({
								type: 'submit',
								name: 'intent',
								value: 'unmap-all',
								form: formId,
								disabled:
									fetcher.state !== 'idle' &&
									fetcher.formData?.get('intent') === 'unmap-all',
							})}
						>
							<Icon name="subtract-alt" size="sm" />
							{fetcher.state === 'idle' ||
							fetcher.formData?.get('intent') !== 'unmap-all'
								? dc.doubleCheck
									? 'Confirm?'
									: 'Unmap all'
								: 'Unmapping...'}
						</Button>
					)}
				</section>

				<EcosystemsAccordion
					ecosystems={transformEcosystemsToAccordionData(
						ecosystems.map(es => ({
							...es,
							content: es.verticals?.length ? (
								<div className="flex flex-col gap-8">
									{es.verticals.map(v => {
										const personaCell = { children: 'Persona', className: '' }
										const mapStatusCell = { children: 'Status', className: '' }
										const theadCells = readOnlySession
											? [personaCell, mapStatusCell]
											: [
													{
														children: (
															<div className="flex h-full w-full items-center justify-center">
																<TableAction
																	checked={
																		selectedPersonas?.length ===
																		v.personas.length
																			? true
																			: selectedPersonas?.length
																				? 'indeterminate'
																				: false
																	}
																	onCheckedChange={() => {
																		form.update({
																			name: fields.personas.name,
																			value:
																				Array.isArray(form.value?.personas) &&
																				form.value?.personas?.length
																					? form.value?.personas?.map(p => {
																							if (
																								!v.personas
																									.map(vp => vp.id)
																									.includes(Number(p?.id))
																							) {
																								return p
																							}

																							return {
																								...p,
																								selected:
																									selectedPersonas?.length ===
																										v.personas.length ||
																									selectedPersonas?.length
																										? 'off'
																										: 'on',
																							}
																						})
																					: [],
																		})
																	}}
																/>
															</div>
														),
														className: 'w-8',
													},
													personaCell,
													mapStatusCell,
													{
														children: (
															<span>
																Manual Input
																{signalTemplate.manualRequired ? (
																	<span className="text-status-danger-fg">
																		*
																	</span>
																) : null}
															</span>
														),
														className: '',
													},
													{ children: 'Usage example', className: '' },
												]
										const personaDict = v.personas.reduce(
											(dict, p) => {
												return {
													...dict,
													[p.id]: p,
												}
											},
											{} as Record<string, (typeof v.personas)[0]>,
										)
										/**
										 * NOTE: A necessary optimisation because .getFieldSet() is very expensive
										 * and doing this for an N**2 loop is very underperformant
										 * so we filter down to vertical personas
										 * and then map over them using personaDict which is way faster
										 */
										const verticalPersonas = personasFieldList
											.filter(p => p.value?.verticalId === v.id.toString())
											.map(field => ({
												field,
												persona: personaDict[field.value!.id!],
												isAssigned: assignedSignals.some(
													s => s.personaId.toString() === field.value?.id,
												),
											}))

										return (
											<section key={v.id} className="flex flex-col gap-4">
												<h3 className="flex items-center gap-1 text-label-lg text-neutral-2-fg">
													<Icon name="category" size="sm" /> {v.name}
												</h3>
												{v.personas?.length ? (
													<Table
														theadCells={theadCells}
														bodyRows={verticalPersonas.map(
															({ field, persona: p, isAssigned }) => {
																const input = field.getFieldset()

																const personaCell = {
																	children: (
																		<PersonaData
																			key={p.id}
																			id={p.id}
																			type={p.type}
																			status={p.status}
																			priority={p.priority}
																			expertise={p.expertise}
																			companyId={companyId}
																			ecosystemId={es.id!}
																			verticalId={v.id.toString()}
																			className={cn(
																				input?.selected.value === 'on'
																					? 'bg-neutral-2-bg'
																					: '',
																			)}
																			avatarClassName={cn(
																				input?.selected.value === 'on'
																					? 'bg-neutral-1-bg'
																					: '',
																			)}
																		/>
																	),
																}
																const mapStatusCell = {
																	children: isAssigned ? (
																		<span className="inline-flex items-center gap-1 text-body-md text-neutral-1-fg">
																			<Icon
																				name="checkmark-filled"
																				size="sm"
																				className="text-green-70"
																			/>
																			Mapped
																		</span>
																	) : (
																		<span className="inline-flex items-center gap-1 text-body-md text-neutral-3-fg">
																			<Icon
																				name="checkmark-outline"
																				size="sm"
																			/>
																			Unmapped
																		</span>
																	),
																	className: 'last:text-right',
																}

																const bodyCells = readOnlySession
																	? [personaCell, mapStatusCell]
																	: [
																			{
																				children: (
																					<div className="flex h-full w-full items-center justify-center">
																						<TableAction
																							checked={
																								input?.selected.value === 'on'
																							}
																							onCheckedChange={checked => {
																								form.update({
																									name: input?.selected.name,
																									value: checked ? 'on' : 'off',
																								})
																							}}
																						/>
																					</div>
																				),
																			},
																			personaCell,
																			mapStatusCell,
																			{
																				className: '',
																				children: (
																					<AssignedPersonaManualInput
																						formId={form.id}
																						value={input?.manualInput.value}
																						initalValue={
																							input?.manualInput.initialValue
																						}
																						onValueChange={value => {
																							form.update({
																								name: input?.manualInput.name,
																								value,
																							})
																						}}
																					/>
																				),
																			},
																			{
																				className: '',
																				children: input?.id.value ? (
																					<SignalUsageExample
																						companyId={companyId}
																						signalId={signalId}
																						personaId={p.id.toString()}
																						personaJobTitle={
																							p.jobTitles ?? p.expertise
																						}
																					/>
																				) : null,
																			},
																		]

																return {
																	active: input?.selected.value === 'on',
																	cells: bodyCells,
																}
															},
														)}
													/>
												) : (
													<NoPersonas
														companyId={companyId}
														ecosystemId={es.id!}
														verticalId={v.id.toString()}
													>
														<h1 className="text-center text-body-lg text-neutral-2-fg">
															You don&apos;t have any personas in <br />
															<span className="font-semibold">
																{v.name ?? 'this vertical'}
															</span>
														</h1>
													</NoPersonas>
												)}
											</section>
										)
									})}
								</div>
							) : (
								<NoVerticals companyId={companyId} ecosystemId={es.id!} />
							),
						})),
					)}
				/>
			</fetcher.Form>

			<Toast
				shouldOpen={fetcher.state === 'loading' && !fetcher.data?.ok}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3 text-body-md">
					<Icon
						name="error-filled"
						size="md"
						className="text-status-danger-fg"
					/>
					{fetcher.data?.action === 'unmap-all'
						? 'Error while unmapping all personas. Try again.'
						: fetcher.data?.action === 'map'
							? 'Error while mapping all personas. Try again.'
							: 'Error while unmapping personas. Try again.'}
				</ToastDescription>
			</Toast>

			<Toast
				shouldOpen={
					fetcher.state === 'loading' &&
					!!fetcher.data?.ok &&
					['map'].includes(fetcher.data?.action)
				}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3 text-body-md">
					<Icon
						name="checkmark-filled"
						size="md"
						className="text-status-success-fg"
					/>
					Personas successfully mapped
				</ToastDescription>
			</Toast>
			<Toast
				shouldOpen={
					fetcher.state === 'loading' &&
					!!fetcher.data?.ok &&
					['unmap-all'].includes(fetcher.data?.action)
				}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3 text-body-md">
					<Icon
						name="checkmark-filled"
						size="md"
						className="text-status-success-fg"
					/>
					All personas successfully unmapped
				</ToastDescription>
			</Toast>
			<Toast
				shouldOpen={
					fetcher.state === 'loading' &&
					!!fetcher.data?.ok &&
					['unmap'].includes(fetcher.data?.action)
				}
				duration={3000}
			>
				<ToastDescription className="flex items-center gap-3 text-body-md">
					<Icon
						name="checkmark-filled"
						size="md"
						className="text-status-success-fg"
					/>
					Selected personas successfully unmapped
				</ToastDescription>
			</Toast>
			<ToastViewport
				className={cn(selectedPersonas?.length ? 'bottom-14' : 'bottom-0')}
			/>

			{selectedPersonas?.length ? (
				<div className="sticky bottom-0 grid h-14 grid-cols-[max-content,1fr] grid-rows-1 items-center bg-brand-inverse-bg px-20 py-3 text-neutral-inverse-fg">
					<div className="text-body-md text-neutral-inverse-fg">{`${selectedPersonas.length} personas selected`}</div>
					<div className="flex items-center gap-2 justify-self-end">
						<div className="text-body-md text-brand-inverse-fg">Actions:</div>

						<Button
							type="submit"
							name="intent"
							value="map"
							form={form.id}
							disabled={
								(signalTemplate.manualRequired &&
									!selectedPersonas?.every(
										sp => !!sp.getFieldset().manualInput.value,
									)) ||
								(fetcher.state !== 'idle' &&
									['map', 'unmap'].includes(
										fetcher.formData?.get('intent') as string,
									))
							}
							variant="secondary"
							size="sm"
							className="min-w-24 items-center gap-1"
						>
							<Icon name="add-filled" size="sm" />
							{fetcher.state === 'idle' ||
							fetcher.formData?.get('intent') !== 'map'
								? 'Map'
								: 'Mapping...'}
						</Button>

						<Button
							type="submit"
							name="intent"
							value="unmap"
							form={form.id}
							disabled={
								fetcher.state !== 'idle' &&
								['map', 'unmap'].includes(
									fetcher.formData?.get('intent') as string,
								)
							}
							variant="secondary"
							size="sm"
							className="min-w-24 items-center gap-1"
						>
							<Icon name="subtract-alt" size="sm" />
							{fetcher.state === 'idle' ||
							fetcher.formData?.get('intent') !== 'unmap'
								? 'Unmap'
								: 'Unmapping...'}
						</Button>
						<Button
							type="submit"
							variant="outline"
							size="sm"
							className="min-w-24 items-center gap-1 bg-transparent text-white"
							{...form.reset.getButtonProps()}
						>
							Cancel
						</Button>
					</div>
				</div>
			) : null}
		</>
	)
}

function AssignedPersonaManualInput({
	formId,
	value,
	initalValue,
	onValueChange,
}: {
	formId: string | undefined
	value?: string | null
	initalValue?: string | null
	onValueChange: (v: string) => void
}) {
	const [open, setOpen] = useState(!!value)

	useEffect(() => {
		setOpen(!!initalValue)
	}, [formId, initalValue])

	if (!open)
		return (
			<button
				onClick={() => setOpen(true)}
				type="button"
				className="inline-flex items-center gap-1 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
			>
				<Icon name="add" size="sm" />
				Add
			</button>
		)

	return (
		<Textarea
			value={value ?? ''}
			className="text-body-sm"
			autoFocus={!value}
			onChange={e => onValueChange(e.currentTarget.value)}
			onBlur={e => {
				if (!e.currentTarget.value) {
					setOpen(false)
					onValueChange('')
				}
			}}
			rows={2}
		/>
	)
}

function SignalUsageExample({
	companyId,
	signalId,
	personaId,
	personaJobTitle,
}: {
	companyId: string
	signalId: string
	personaId: string
	personaJobTitle: string
}) {
	return (
		<Dialog
			trigger={
				<DialogTrigger asChild>
					<button
						type="button"
						className="inline-flex items-center gap-1 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
					>
						<Icon name="magic-wand" size="sm" />
						Generate
					</button>
				</DialogTrigger>
			}
			dialogTitle="Signal Usage Example"
			dialogDescription={personaJobTitle}
			content={
				<SignalUsageExampleContent
					companyId={companyId}
					signalId={signalId}
					personaId={personaId}
				/>
			}
			contentProps={{
				className: 'min-w-full md:min-w-[42rem] lg:min-w-[48rem] max-w-[48rem]',
			}}
		/>
	)
}

function SignalUsageExampleContent({
	companyId,
	signalId,
	personaId,
}: {
	companyId: string
	signalId: string
	personaId: string
}) {
	const fetcher = useFetcher<SignalUsageExampleActionResponse>()

	useEffect(() => {
		fetcher.submit(null, {
			method: 'PUT',
			action: routes.prioritize.signal.example({
				companyId: companyId,
				signalId: signalId,
				personaId: personaId,
			}),
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (fetcher.data?.preview && fetcher.state === 'idle')
		return (
			<>
				<section className="flex min-h-[260px] w-full flex-col items-center justify-center gap-6 pb-10">
					<Markdown className="text-body-lg text-neutral-1-fg">
						{fetcher.data.preview}
					</Markdown>
				</section>
				<section className="-mx-10 -mb-10 flex items-center justify-center border-t border-t-neutral-1-bd px-10 py-4">
					<Button
						type="button"
						variant="outline"
						className="min-w-32 gap-1"
						onClick={() => {
							fetcher.submit(null, {
								method: 'PUT',
								action: routes.prioritize.signal.example({
									companyId: companyId,
									signalId: signalId,
									personaId: personaId,
								}),
							})
						}}
					>
						<Icon name="restart" size="sm" />
						Regenerate
					</Button>
				</section>
			</>
		)

	if (fetcher.state === 'submitting')
		return (
			<section className="flex min-h-[260px] w-full flex-col items-center justify-center gap-6">
				<div className="flex flex-col items-center justify-center gap-3">
					<span className="text-[5.5rem] leading-none">
						<Icon
							name="loading-lg"
							size="font"
							className="animate-spin text-neutral-1-fg"
						/>
					</span>
					<h1 className="text-body-lg font-medium text-neutral-1-fg">
						Generating...
					</h1>
				</div>
				<div>
					<DialogPrimitive.Close asChild>
						<Button type="button" variant="outline" className="min-w-32">
							Cancel
						</Button>
					</DialogPrimitive.Close>
				</div>
			</section>
		)

	return (
		<section className="flex min-h-[260px] w-full flex-col items-center justify-center gap-6">
			<div className="flex flex-col items-center justify-center gap-3">
				<Icon name="warning-alt" size="xl" className="text-neutral-1-fg" />
				<h1 className="text-title-md text-neutral-1-fg">
					Ouch! Something went wrong.
				</h1>
			</div>
			<div>
				<Button
					type="button"
					className="min-w-32"
					onClick={() => {
						fetcher.submit(null, {
							method: 'PUT',
							action: routes.prioritize.signal.example({
								companyId: companyId,
								signalId: signalId,
								personaId: personaId,
							}),
						})
					}}
				>
					Try again
				</Button>
			</div>
		</section>
	)
}
