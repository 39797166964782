import { type VariantProps, cva } from 'class-variance-authority'
import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { PERSONA_TYPES } from '#src/utils/enumerations'
import { cn, formatPersonaTypeAbbr } from '#src/utils/misc'
import Priority from './priority'
import Status from './status'

type PersonaDataProps = {
	id: number
	type: string
	status: string
	priority: string
	expertise: string
	companyId: string
	ecosystemId: number
	verticalId: string
	className?: string
	avatarClassName?: string
}

export const PersonaData = forwardRef<HTMLAnchorElement, PersonaDataProps>(
	(
		{
			id,
			type,
			status,
			priority,
			expertise,
			companyId,
			ecosystemId,
			verticalId,
			className,
			avatarClassName,
		},
		ref,
	) => {
		return (
			<NavLink
				ref={ref}
				className={({ isActive }) =>
					cn(
						'group grid w-full grid-cols-[2.5rem,1fr] gap-1.5 rounded-sm bg-transparent p-2 transition-colors hover:bg-neutral-1-bg-hover',
						isActive ? 'bg-neutral-1-bg-selected' : '',
						className,
					)
				}
				to={`/personas/${companyId}/${ecosystemId}/${verticalId}/${id}`}
			>
				<PersonaAvatar
					type={type}
					className={cn('group-hover:bg-neutral-1-bg-hover', avatarClassName)}
				/>

				<div className="flex flex-col gap-0.5 overflow-hidden">
					<p className="truncate text-label-sm text-neutral-2-fg">
						{expertise}
					</p>
					<div className="flex items-center gap-2">
						<Status status={status} />
						<Priority priority={priority} />
					</div>
				</div>
			</NavLink>
		)
	},
)

PersonaData.displayName = 'PersonaData'

const personaAvatarVariants = cva(
	'flex items-center justify-center rounded-full bg-neutral-2-bg transition-all',
	{
		variants: {
			size: {
				sm: 'h-8 w-8 text-body-sm font-medium',
				default: 'h-10 w-10 text-body-sm font-medium',
				lg: 'h-20 w-20 text-title-lg font-semibold',
			},
		},
		defaultVariants: {
			size: 'default',
		},
	},
)

export const personaTypeColorVariants = cva('', {
	variants: {
		type: {
			[PERSONA_TYPES.decisionmaker]: 'text-blue-60',
			[PERSONA_TYPES.champion]: 'text-red-60',
			[PERSONA_TYPES.influencer]: 'text-orange-70',
		},
	},
	defaultVariants: {
		type: PERSONA_TYPES.decisionmaker,
	},
})

export function PersonaAvatar({
	type,
	size,
	className,
}: { type: string; className?: string } & VariantProps<
	typeof personaAvatarVariants
>) {
	return (
		<div className={cn(personaAvatarVariants({ size, className }))}>
			<span className={cn(personaTypeColorVariants({ type }))}>
				{formatPersonaTypeAbbr(type)}
			</span>
		</div>
	)
}
