import { useState } from 'react'
import {
	NavLink,
	matchPath,
	useLocation,
	useRouteLoaderData,
} from 'react-router-dom'
import ContactAvatar, {
	avatarVariantByPersonaType,
} from '#src/components/chat/avatar'
import { Search, NoResults } from '#src/components/chat/search'
import { Icon } from '#src/components/ui/icon'
import { Label } from '#src/components/ui/label'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#src/components/ui/tooltip'
import { type ChatLoaderResponse } from '#src/routes/enable/chat'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

type ChatNavPersona = {
	id: string
	to: string
	name: string
	type: string
	description: string
	updatedAt: string
	conversationId: string
	canDelete: boolean
}

function dateLabel(date: string) {
	const today = new Date()
	const targetDate = new Date(date)
	const timeDiff = Math.abs(today.getTime() - targetDate.getTime())
	const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

	if (diffDays <= 1) {
		return 'Today'
	} else if (diffDays <= 7) {
		return 'Last 7 days'
	} else if (diffDays <= 365) {
		return targetDate.toLocaleDateString(undefined, {
			month: 'short',
		})
	} else {
		return targetDate.toLocaleDateString(undefined, {
			year: 'numeric',
			month: 'short',
		})
	}
}

export default function ChatsPanel() {
	const params = useParsedRouteParams(['companyId'])
	const { chats } = useRouteLoaderData('enable-loader') as ChatLoaderResponse
	const [searchQuery, setSearchQuery] = useState('')

	const handleSearch = (query: string) => {
		setSearchQuery(query.toLowerCase())
	}

	const nav = chats.map(chat => ({
		id: chat.conversation.id,
		to: routes.enable.copilot.index({
			companyId: params.companyId,
			conversationId: chat.conversation.id,
		}),
		name: chat.conversation.name,
		description: chat.participant.info.title ?? '',
		type: chat.participant.persona.type ?? '',
		updatedAt: chat.conversation.lastMessage ?? chat.conversation.createdAt,
		conversationId: chat.conversation.id,
		canDelete:
			(chat.conversation.canDelete && chat.conversation.status === 'ready') ??
			false,
	})) satisfies ChatNavPersona[]

	const filteredNav = nav.filter(chat =>
		chat.name.toLowerCase().includes(searchQuery),
	)

	const groupedNav = filteredNav.reduce(
		(acc: Record<string, ChatNavPersona[]>, chat) => {
			const key = dateLabel(chat.updatedAt)
			acc[key] = [...(acc[key] ?? []), chat]
			return acc
		},
		{} as Record<string, ChatNavPersona[]>,
	)

	return (
		<section className="flex h-full flex-col px-2">
			<Search onSearch={handleSearch} />
			{Object.keys(groupedNav).length > 0 ? (
				Object.entries(groupedNav).map(([date, chats]) => (
					<div key={date}>
						<Label className="block w-full px-2 py-[0.438rem] text-label-md font-semibold text-gray-60">
							{date}
						</Label>
						<div className="flex flex-col gap-1">
							{chats.map(chat => (
								<ChatHeadingWithRole key={chat.id} {...chat} />
							))}
						</div>
					</div>
				))
			) : (
				<NoResults query={searchQuery} />
			)}
		</section>
	)
}

function ChatHeadingWithRole({
	to,
	name,
	type,
	description,
	conversationId,
	canDelete,
}: ChatNavPersona) {
	const params = useParsedRouteParams(['companyId'])
	const location = useLocation()
	const isActive = !!matchPath(location.pathname, to)

	return (
		<div
			className={cn(
				'group flex items-center gap-2 rounded bg-transparent p-2 transition-colors hover:bg-neutral-1-bg-hover active:bg-neutral-2-bg-pressed',
				isActive
					? 'bg-neutral-2-bg-selected hover:bg-neutral-1-bg-selected-hover'
					: 'text-neutral-2-fg',
			)}
		>
			<NavLink to={to} className="flex grow items-center gap-2 truncate">
				<ContactAvatar
					className="inline-flex shrink-0 items-center justify-center rounded-full"
					variant={avatarVariantByPersonaType(type?.toLowerCase())}
					size="md"
					initial={name.slice(0, 1)}
				/>
				<div className="flex flex-col gap-0.5 overflow-hidden">
					<p className="truncate text-body-md font-semibold">{name}</p>
					<TooltipProvider>
						<Tooltip delayDuration={0}>
							<TooltipTrigger>
								<p className="text-left text-body-sm">{description}</p>
							</TooltipTrigger>
							<TooltipContent side="right" align="center">
								{description}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</NavLink>
			{canDelete && (
				<div className="ml-auto hidden group-hover:flex group-hover:animate-in group-hover:fade-in">
					<NavLink
						className={({ isPending }) =>
							cn(
								'flex h-10 w-10 shrink-0 items-center justify-center rounded bg-neutral-1-bg-selected text-status-danger-fg outline-none ring-neutral-2-bd ring-offset-0 transition-colors focus-within:ring-0 hover:bg-neutral-1-bg-hover focus-visible:ring-0',
								isPending ? 'pointer-events-none' : '',
							)
						}
						to={routes.enable.copilot.delete({
							companyId: params.companyId,
							conversationId: conversationId,
						})}
					>
						<Icon name="trash" size="sm" />
					</NavLink>
				</div>
			)}
		</div>
	)
}
