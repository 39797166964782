import { type ReactNode } from 'react'
import { Link, useRouteLoaderData } from 'react-router-dom'
import { type MainLoaderResponse } from '#src/routes/_layout/main'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { Button } from './ui/button'
import { Icon } from './ui/icon'
import { Surface } from './ui/surface'

export default function NoTerms({
	companyId,
	children,
	className,
}: {
	companyId: string
	children?: ReactNode
	className?: string
}) {
	const { readOnlySession } = useRouteLoaderData(
		'main-loader',
	) as MainLoaderResponse

	return (
		<Surface
			className={cn(
				'flex min-h-[360px] w-full flex-col items-center justify-center gap-4 border-none bg-neutral-2-bg',
				className,
			)}
		>
			<Icon name="notebook-reference" size="xl" className="text-neutral-3-fg" />
			{children}
			{readOnlySession ? null : (
				<div>
					<Button asChild className="flex flex-nowrap items-center gap-1">
						<Link to={routes.enable.glossary.edit({ companyId, termId: null })}>
							<Icon name="add" /> Add new term
						</Link>
					</Button>
				</div>
			)}
		</Surface>
	)
}
