import {
	useQuery,
	useQueryClient,
	type QueryClient,
} from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import {
	type LoaderFunctionArgs,
	Outlet,
	useLoaderData,
	useRevalidator,
} from 'react-router-dom'
import { z } from 'zod'
import Markdown from '#src/components/markdown.js'
import NoTranscript from '#src/components/no-transcript'
import {
	conversationQuery,
	chatQuery,
	roleplayKeys,
} from '#src/routes/enable/roleplay/queries'
import { cn } from '#src/utils/misc'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { ChatSchema, ConversationDataSchema } from './schema'
import { ChatLoader } from './session'

export type RoleplayReviewLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const RoleplayReviewLoaderResponseSchema = z.object({
	handle: z.object({
		companyId: z.string(),
		conversationId: z.string(),
		canDeleteChat: z.boolean().optional(),
	}),
	conversationData: ConversationDataSchema,
	chat: ChatSchema,
})

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId || !params.conversationId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const conversationData = await queryClient.fetchQuery(
			conversationQuery(params.companyId, params.conversationId),
		)
		const chat = await queryClient.fetchQuery(
			chatQuery(params.companyId, params.conversationId),
		)

		return {
			handle: {
				companyId: params.companyId,
				conversationId: params.conversationId,
				canDeleteChat: Boolean(conversationData?.conversation.canDelete),
			},
			conversationId: params.conversationId,
			conversationData,
			chat,
		}
	}

export default function RoleplayReview() {
	const [tab, setTab] = useState<'evaluation' | 'transcript'>('evaluation')
	const { conversationData, chat } =
		useLoaderData() as RoleplayReviewLoaderResponse

	if (
		['Pending', 'Evaluating'].includes(
			conversationData?.conversation.evaluation?.status ?? '',
		)
	)
		return (
			<>
				<RoleplayEvaluating />
				<Outlet />
			</>
		)

	if (chat.length === 0)
		return (
			<main className="grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-items-center overflow-hidden px-8 py-10">
				<NoTranscript />

				<Outlet />
			</main>
		)

	return (
		<main className="grid h-full w-full grid-cols-1 grid-rows-[max-content,1fr] items-start justify-items-center px-8 py-10">
			<ul className="flex w-full flex-nowrap items-center border-b-2 border-neutral-1-bd">
				<li className="flex items-center justify-center">
					<button
						type="button"
						className={cn(
							'group relative w-full min-w-[100px] bg-transparent px-4 py-3 text-center text-body-md',
							tab === 'evaluation'
								? 'active text-neutral-2-fg-selected'
								: 'text-neutral-2-fg',
						)}
						onClick={() => setTab('evaluation')}
					>
						Evaluation
						<div className="absolute bottom-[-2px] left-0 h-2 w-full border-b-2 border-transparent transition-colors hover:border-brand-1-bd-hover group-hover:border-brand-1-bd-hover group-[.active]:border-brand-1-bd-selected" />
					</button>
				</li>
				<li className="flex items-center justify-center">
					<button
						type="button"
						className={cn(
							'group relative w-full min-w-[100px] bg-transparent px-4 py-3 text-center text-body-md',
							tab === 'transcript'
								? 'active text-neutral-2-fg-selected'
								: 'text-neutral-2-fg',
						)}
						onClick={() => setTab('transcript')}
					>
						Transcript
						<div className="absolute bottom-[-2px] left-0 h-2 w-full border-b-2 border-transparent transition-colors hover:border-brand-1-bd-hover group-hover:border-brand-1-bd-hover group-[.active]:border-brand-1-bd-selected" />
					</button>
				</li>
			</ul>

			<section className="mx-auto w-full max-w-screen-md py-6">
				{tab === 'evaluation' ? <RoleplayEvaluation /> : null}
				{tab === 'transcript' ? <RoleplayTranscript /> : null}
			</section>

			<Outlet />
		</main>
	)
}

export function RoleplayEvaluation() {
	const { conversationData } = useLoaderData() as RoleplayReviewLoaderResponse

	switch (conversationData?.conversation.evaluation?.status) {
		case 'Crashed':
			return (
				<Markdown className="text-body-md text-status-danger-fg">
					Something went wrong with evaluation.
				</Markdown>
			)
		default:
			return (
				<Markdown className="text-body-md">
					{conversationData?.conversation.evaluation?.value}
				</Markdown>
			)
	}
}

export function RoleplayTranscript() {
	const { chat } = useLoaderData() as RoleplayReviewLoaderResponse

	let lastEnumeration = 1
	const enumeratedMessages = chat.map(message => ({
		...message,
		enumeration: message.isAi ? null : lastEnumeration++,
	}))

	return (
		<div className="flex flex-col items-start gap-6">
			{enumeratedMessages.map(({ id, message, isAi, enumeration }) => (
				<div
					key={id}
					className={cn(
						'w-full text-body-lg',
						isAi
							? 'rounded-lg bg-brand-1-bg p-4 text-brand-1-fg'
							: 'text-neutral-2-fg',
					)}
				>
					{!isAi ? `${enumeration}. ${message}` : message}
				</div>
			))}
		</div>
	)
}

function useRoleplayEvaluationRevalidator() {
	const params = useParsedRouteParams(['companyId', 'conversationId'])
	const revalidator = useRevalidator()
	const queryClient = useQueryClient()

	const { data: conversationData } = useQuery({
		...conversationQuery(params.companyId, params.conversationId),
		refetchInterval: ({ state: { data } }) => {
			if (
				['Pending', 'Evaluating'].includes(
					data?.conversation.evaluation?.status ?? '',
				)
			) {
				return 15 * 1000 // 15s
			} else return false
		},
		refetchIntervalInBackground: true,
	})

	useEffect(() => {
		const revalidate = async () => {
			await queryClient.invalidateQueries({
				queryKey: roleplayKeys.all,
			})

			revalidator.revalidate()
		}
		if (conversationData?.conversation.evaluation?.status === 'Completed') {
			void revalidate()
		}
	}, [conversationData, revalidator, queryClient])

	return {
		status: conversationData?.conversation.evaluation?.status,
		value: conversationData?.conversation.evaluation?.value,
	}
}

function RoleplayEvaluating() {
	useRoleplayEvaluationRevalidator()

	return (
		<ChatLoader
			animate={true}
			message="Generating results..."
			subMessage="It might take up to 3 minutes or more"
			className="h-full w-full gap-4 overflow-hidden"
		/>
	)
}
