import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch, fetch } from '#src/utils/safeFetch'
import { VerticalAPISchema, type VerticalFormSchema } from './schema'

export const createVertical = async (
	companyId: string,
	ecosystemId: number,
	data: z.infer<typeof VerticalFormSchema>,
) => {
	return await safeFetch(VerticalAPISchema, api.vertical.create(companyId), {
		method: 'POST',
		body: JSON.stringify({ ...data, ecosystemId }),
	})
}

export const updateVertical = async (
	companyId: string,
	verticalId: number,
	data: z.infer<typeof VerticalFormSchema>,
) => {
	return await safeFetch(
		VerticalAPISchema,
		api.vertical.details(companyId, verticalId),
		{
			method: 'PATCH',
			body: JSON.stringify(data),
		},
	)
}

export const deleteVertical = async (companyId: string, verticalId: string) => {
	return await fetch(api.vertical.details(companyId, Number(verticalId)), {
		method: 'DELETE',
	})
}
