import { type QueryClient } from '@tanstack/react-query'
import { Link, useLoaderData } from 'react-router-dom'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { companiesQuery } from '../calibrate/company/queries'
import { DeleteCompanyForm } from './delete'

export type CompaniesLoaderResponse = Awaited<
	ReturnType<ReturnType<typeof loader>>
>

export const loader = (queryClient: QueryClient) => async () => {
	const companies = await queryClient.fetchQuery(companiesQuery())

	return {
		companies,
	}
}

export default function Companies() {
	const { companies } = useLoaderData() as CompaniesLoaderResponse

	return (
		<main className="grid h-full w-full grid-cols-1 grid-rows-1 justify-items-center">
			<section className="flex w-full max-w-screen-md flex-col gap-4">
				<h1 className="text-title-md">Select existing company or create new</h1>

				{companies?.length ? (
					<ul className="space-y-2">
						{companies.map(company => (
							<Surface
								key={company.id}
								as="li"
								className="flex w-full items-center gap-2 p-2 transition-all hover:translate-x-1 hover:shadow-lg"
							>
								<Link
									to={`/company/${company.id}`}
									className="h-full w-full py-4 pl-4"
								>
									{company.name}
								</Link>
								<div className="inline-flex h-full items-center gap-2 py-4 pr-4">
									<Link
										to={`/admin/tam/${company.id}/`}
										className="text-link hover:text-link-hover active:text-link-pressed"
									>
										TAM
									</Link>
									<Link
										to={`/admin/company/${company.id}/edit`}
										className="inline-flex h-full items-center gap-1 whitespace-nowrap"
									>
										<Icon name="edit" size="sm" />
									</Link>
									<DeleteCompanyForm companyId={company.id} />
								</div>
							</Surface>
						))}
					</ul>
				) : null}

				<section className="w-full">
					<Button asChild>
						<Link to="/admin/company/new">Create new Company</Link>
					</Button>
				</section>
			</section>
		</main>
	)
}
