import { type ReactNode } from 'react'
import { cn } from '#src/utils/misc'

export default function Header({
	className,
	panelToggle,
	crumbs,
	actions,
}: {
	className?: string
	panelToggle?: ReactNode
	crumbs?: ReactNode
	actions?: ReactNode
}) {
	return (
		<header
			className={cn(
				'sticky left-0 top-0 z-40',
				'flex items-center justify-between gap-4',
				'max-h-[var(--builder-header-height)] min-h-[var(--builder-header-height)] px-2 py-2',
				'border-b border-neutral-1-bd bg-neutral-1-bg',
				className,
			)}
		>
			<div className="flex items-center gap-2">
				{panelToggle}

				{crumbs}
			</div>

			{actions}
		</header>
	)
}
