import { z } from 'zod'

export const Auth0AuthAPISchema = z
	.object({
		access_token: z.string(),
		refresh_token: z.string(),
	})
	.transform(value => ({
		accessToken: value.access_token,
		refreshToken: value.refresh_token,
	}))
