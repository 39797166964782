import { clsx, type ClassValue } from 'clsx'
import { useFormAction, useNavigation } from 'react-router-dom'
import { extendTailwindMerge } from 'tailwind-merge'

import { type z } from 'zod'
import { PERSONA_TYPES } from './enumerations.ts'
import { extendedTheme } from './extended-theme.ts'
import { theme } from './theme.ts'

function formatColors() {
	const colors = []

	for (const [key, color] of Object.entries(extendedTheme.colors)) {
		if (typeof color === 'string') {
			colors.push(key)
		} else {
			const colorGroup = Object.keys(color).map(subKey =>
				subKey === 'DEFAULT' ? '' : subKey,
			)
			colors.push({ [key]: colorGroup })
		}
	}

	return colors
}

const customTwMerge = extendTailwindMerge<string, string>({
	override: {
		classGroups: {
			'font-size': [{ text: Object.keys(theme.fontSize) }],
		},
	},
	extend: {
		theme: {
			colors: formatColors(),
		},
	},
})

/**
 * A common use-case for this is to whenever `className`
 * is a pass through property to an already styled element
 * This ensures that there're no clashing styles
 *
 * @param inputs ClassValue[]
 * @returns
 */
export function cn(...inputs: ClassValue[]) {
	return customTwMerge(clsx(inputs))
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = 'POST',
	state = 'non-idle',
}: {
	formAction?: string
	formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
	state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
	const contextualFormAction = useFormAction()
	const navigation = useNavigation()
	const isPendingState =
		state === 'non-idle'
			? navigation.state !== 'idle'
			: navigation.state === state
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod?.toUpperCase() === formMethod
	)
}

export type ErrorResponse = {
	status: number
	statusText: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any
}
/**
 * Check if the given error is an error Response generated from a 4xx/5xx
 * Response thrown from an action/loader
 */
export function isRouteErrorResponse(error: unknown): error is ErrorResponse {
	return (
		(error != null &&
			typeof error === 'object' &&
			'status' in error &&
			typeof error.status === 'number' &&
			'data' in error &&
			typeof error.data !== 'undefined') ||
		error instanceof Response
	)
}

export const checkType = <
	Output = never,
	Def extends z.ZodTypeDef = z.ZodTypeDef,
	Input = Output,
>(
	schema: z.ZodType<Output, Def, Input>,
	data: unknown,
): data is Input => schema.safeParse(data).success

export const checkIsReadOnlySession = (roles: string[]) =>
	roles.includes('read-only')
export const checkIsEnableOnlySession = (roles: string[]) =>
	roles.includes('enable-only')
export const checkIsDataTrainerSession = (roles: string[]) =>
	roles.includes('data-trainer')
export const checkIsAdminSession = (roles: string[]) => roles.includes('admin')

export function formatPersonaTypeAbbr(type: string) {
	if (type === PERSONA_TYPES.decisionmaker) return 'DM'
	if (type === PERSONA_TYPES.champion) return 'CH'
	if (type === PERSONA_TYPES.influencer) return 'INF'
	return 'P'
}

// 	.replaceAll('\n', ' ')
// 	.replaceAll(new RegExp(/[.,/#!$%^&*;:{}=\-_`~()"?“”]/g, 'g'), ' ')
// 	.split(' ')
// 	.filter(w => Boolean(w)).length ?? 0
export const getWordCount = (value: string): number =>
	value?.replaceAll('\n', ' ').split(' ').length ?? 0
