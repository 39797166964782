import * as AccordionPrimitive from '@radix-ui/react-accordion'
import {
	forwardRef,
	type ComponentPropsWithoutRef,
	type ElementRef,
	type ReactNode,
} from 'react'
import { cn } from '#src/utils/misc'
import { Icon } from './icon'
import { Surface } from './surface'

export const AccordionRoot = AccordionPrimitive.Root

export const AccordionItem = forwardRef<
	ElementRef<typeof AccordionPrimitive.Item>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> & {
		trigger: ReactNode
		children: ReactNode
		contentProps?: ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
	}
>(({ trigger, children, contentProps, ...props }, forwardedRef) => (
	<AccordionPrimitive.Item {...props} asChild ref={forwardedRef}>
		<Surface className="flex flex-col gap-8">
			{trigger}
			<AccordionContent {...contentProps}>{children}</AccordionContent>
		</Surface>
	</AccordionPrimitive.Item>
))
AccordionItem.displayName = 'AccordionItem'

export const AccordionTrigger = forwardRef<
	ElementRef<typeof AccordionPrimitive.Trigger>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
	<AccordionPrimitive.Header>
		<AccordionPrimitive.Trigger
			className={cn(
				'group flex w-full items-center gap-2 text-title-md transition-opacity radix-disabled:opacity-50',
				className,
			)}
			{...props}
			ref={forwardedRef}
		>
			<Icon
				name="chevron-down"
				className="transform transition-opacity group-radix-disabled:opacity-50 group-radix-state-open:rotate-180"
				aria-hidden
			/>
			{children}
		</AccordionPrimitive.Trigger>
	</AccordionPrimitive.Header>
))
AccordionTrigger.displayName = 'AccordionTrigger'

export const AccordionContent = forwardRef<
	ElementRef<typeof AccordionPrimitive.Content>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ children, ...props }, forwardedRef) => (
	<AccordionPrimitive.Content
		className="overflow-hidden radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]"
		{...props}
		ref={forwardedRef}
	>
		{children}
	</AccordionPrimitive.Content>
))
AccordionContent.displayName = 'AccordionContent'

export const Accordion = forwardRef<
	ElementRef<typeof AccordionPrimitive.Root>,
	ComponentPropsWithoutRef<typeof AccordionPrimitive.Root> & {
		data: { trigger: ReactNode; content: ReactNode }[]
	}
>(({ data, ...props }, ref) => (
	<AccordionPrimitive.Root className="flex flex-col gap-4" {...props} ref={ref}>
		{data.map(({ trigger, content }, index) => (
			<AccordionItem
				key={index}
				value={`item-${index}`}
				trigger={<AccordionTrigger>{trigger}</AccordionTrigger>}
			>
				{content}
			</AccordionItem>
		))}
	</AccordionPrimitive.Root>
))
Accordion.displayName = 'Accordion'
