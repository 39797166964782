import { type ReactNode, useEffect, useRef, type WheelEvent } from 'react'
import { cn } from '#src/utils/misc'

interface ChatLayoutProps {
	status?: ReactNode
	content: ReactNode
	footer: ReactNode
	containerClassName?: string
}

export default function ChatLayout({
	containerClassName,
	status,
	content,
	footer,
}: ChatLayoutProps) {
	const chatScrollLockedRef = useRef<boolean>(true)
	const chatRef = useRef<HTMLDivElement>(null) // NOTE: Scrollable container
	const chatInnerRef = useRef<HTMLDivElement>(null) // NOTE: Inner container to observe for height changes
	const chatResizeObserver = useRef<ResizeObserver>(
		new ResizeObserver(entries => {
			if (!entries.length || entries.length > 1)
				throw Error("There's more than one entry")

			if (!chatRef.current) return

			if (!chatScrollLockedRef.current) return

			chatRef.current.scrollTop = chatRef.current.scrollHeight
		}),
	)

	/**
	 * Observe inner container for height changes
	 * regardless of component lifecycle or prop/state changes
	 */
	useEffect(() => {
		if (chatInnerRef.current) {
			chatResizeObserver.current.observe(chatInnerRef.current)
		}
	}, [])

	function onWheel(e: WheelEvent<HTMLElement>) {
		if (!chatRef.current) {
			return
		}

		if (e.deltaY < 0) {
			chatScrollLockedRef.current = false
		} else if (e.deltaY > 1) {
			chatScrollLockedRef.current =
				chatRef.current.scrollHeight -
					(chatRef.current.scrollTop + chatRef.current.clientHeight) <
				50
		}
	}

	return (
		<section className="flex h-full w-full flex-col justify-self-center overflow-y-auto overflow-x-hidden">
			{status && <div className="sticky top-0 w-full">{status}</div>}
			<section
				ref={chatRef}
				onWheel={onWheel}
				className="flex h-full w-full flex-col items-center overflow-y-auto overflow-x-hidden px-20"
			>
				<div
					ref={chatInnerRef}
					className={cn('w-full max-w-[920px]', containerClassName)}
				>
					{content}
				</div>
				<div className="sticky bottom-0 mt-auto w-full max-w-[920px] pt-6">
					{footer}
				</div>
			</section>
		</section>
	)
}
