import { Command as CommandPrimitive } from 'cmdk'
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
} from 'react'
import { cn } from '#src/utils/misc'

export const Root = forwardRef<
	ElementRef<typeof CommandPrimitive>,
	ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
	<CommandPrimitive ref={ref} className={cn(className)} {...props} />
))
Root.displayName = CommandPrimitive.displayName

export const Input = forwardRef<
	ElementRef<typeof CommandPrimitive.Input>,
	ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
	<CommandPrimitive.Input
		ref={ref}
		className={cn(
			'transition-all',
			'flex h-10 w-full px-3 py-2.5',
			'bg-transparent disabled:bg-neutral-2-bg',
			'border-b border-neutral-2-bd outline-none',
			'text-body-md text-neutral-1-fg placeholder:text-neutral-inverse-fg disabled:text-neutral-inverse-fg-disabled',
			className,
		)}
		{...props}
	/>
))
Input.displayName = CommandPrimitive.Input.displayName

export const List = forwardRef<
	ElementRef<typeof CommandPrimitive.List>,
	ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
	<CommandPrimitive.List
		ref={ref}
		className={cn(
			'max-h-[12rem] overflow-y-auto overflow-x-hidden py-2',
			className,
		)}
		{...props}
	/>
))
List.displayName = CommandPrimitive.List.displayName

export const Empty = forwardRef<
	ElementRef<typeof CommandPrimitive.Empty>,
	ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
	<CommandPrimitive.Empty
		ref={ref}
		className="pb-3 pt-6 text-center text-body-md"
		{...props}
	/>
))
Empty.displayName = CommandPrimitive.Empty.displayName

export const Item = forwardRef<
	ElementRef<typeof CommandPrimitive.Item>,
	ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
	<CommandPrimitive.Item
		ref={ref}
		className={cn(
			'relative flex cursor-pointer items-center justify-between gap-2 px-3 py-1.5 text-body-md outline-none hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50',
			className,
		)}
		{...props}
	/>
))
Item.displayName = CommandPrimitive.Item.displayName
