import { PRIORITY } from '#src/utils/enumerations'
import { cn } from '#src/utils/misc'
import { Icon } from './ui/icon'

export default function Priority({ priority }: { priority: string }) {
	return (
		<div
			className={cn(
				'inline-flex flex-nowrap items-center gap-0.5 rounded-full border border-neutral-1-bd bg-transparent py-0.5 pl-0.5 pr-2 transition-colors',
				priority === PRIORITY.high ? 'text-green-70' : '',
				priority === PRIORITY.medium ? 'text-orange-70' : '',
				priority === PRIORITY.low ? 'text-red-60' : '',
			)}
		>
			<PriorityIcon priority={priority} />
			<p className="text-label-sm">{priority}</p>
		</div>
	)
}

export function PriorityIcon({ priority }: { priority: string }) {
	const icon =
		priority === PRIORITY.high
			? 'arrow-up'
			: priority === PRIORITY.medium
				? 'arrow-up-right'
				: 'arrow-down'

	return (
		<Icon
			name={icon}
			size="sm"
			className={cn(
				'flex flex-nowrap items-center transition-colors',
				priority === PRIORITY.high ? 'text-green-70' : '',
				priority === PRIORITY.medium ? 'text-orange-70' : '',
				priority === PRIORITY.low ? 'text-red-60' : '',
			)}
		/>
	)
}
