import { Icon } from '../ui/icon'

export default function Resolved() {
	return (
		<section className="w-full bg-[#E6F3E9] p-2">
			<p className="flex items-center justify-center gap-0.5 text-center text-body-sm font-semibold text-green-80">
				<Icon name="checkmark-filled" />
				Resolved
			</p>
		</section>
	)
}
