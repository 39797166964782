import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Icon, type IconName } from '#src/components/ui/icon'
import { useCrumbs } from '#src/utils/use-crumbs'

export default function Crumbs() {
	const crumbs = useCrumbs('crumb')

	if (!crumbs?.length) return null

	return (
		<ol className="flex items-center gap-2">
			{crumbs.map(
				(crumb, index) =>
					crumb.to && (
						<Fragment key={index}>
							<li className="text-body-md text-neutral-1-fg">
								<Link
									to={crumb.to}
									className="flex items-center gap-1 rounded-sm bg-transparent px-1 py-0.5 transition-colors hover:bg-neutral-2-bg"
									onClick={crumb.onClick}
								>
									{crumb.icon ? (
										<Icon name={crumb.icon as IconName} size="sm" />
									) : null}
									{crumb.label}
								</Link>
							</li>
							{index < crumbs.length - 1 ? (
								<li className="text-body-md text-neutral-1-fg">/</li>
							) : null}
						</Fragment>
					),
			)}
		</ol>
	)
}
