import { z } from 'zod'

export const EcosystemAPIPersonaSchema = z.object({
	id: z.number(),
	type: z.string(),
	jobTitles: z.string().optional(),
	expertise: z.string(),
	status: z.string(),
	priority: z.string(),
})

export const EcosystemAPIVerticalSchema = z.object({
	id: z.number(),
	name: z.string(),
	personas: EcosystemAPIPersonaSchema.array(),
	status: z.string(),
	priority: z.string(),
})

export const EcosystemAPISchema = z.object({
	id: z.number().optional(),
	name: z.string(),
	verticals: EcosystemAPIVerticalSchema.array(),
})

export const EcosystemListAPISchema = z.array(EcosystemAPISchema)

export const EcosystemTemplate = z.object({
	id: z.number(),
	name: z.string(),
})

export const EcosystemTemplateListAPISchema = z.array(EcosystemTemplate)

export const EcosystemTemplateFormSchema = z.object({
	ecosystemId: z.string(),
})

export const CriterionAPISchema = z.object({
	id: z.number().optional(),
	question: z.string(),
	dataSources: z.string().nullable().optional(),
})

export const CriteriaAPISchema = z.array(CriterionAPISchema)

export const DataPointAPISchema = z.object({
	id: z.number().optional(),
	title: z.string(),
	inBizmodel: z.boolean().optional(),
	dataSources: z.string().nullable().optional(),
})

export const DataPointsAPISchema = z.array(DataPointAPISchema)

export const EcosystemFormSchema = z.object({
	id: z.string().optional(),
	name: z.string().optional(),
	template: z.string().nullable().optional(),
})

export const EcosystemsOrderFormSchema = z.object({
	ecosystems: z.array(z.number()),
})
