import { type QueryClient } from '@tanstack/react-query'
import { type RouteObject } from 'react-router-dom'
import CompanyLayout from '#src/routes/calibrate/company/_layout'
import CompanyDetails, {
	loader as companyDetailsLoader,
} from '#src/routes/calibrate/company/details'
import EditCompany, {
	loader as editCompanyLoader,
	action as editCompanyAction,
} from '#src/routes/calibrate/company/edit'
import Ecosystem, {
	loader as ecosystemLoader,
} from '#src/routes/calibrate/ecosystem'
import { action as deleteEcosystemAction } from '#src/routes/calibrate/ecosystem/delete'
import { action as saveEcosystemAction } from '#src/routes/calibrate/ecosystem/save'
import { action as reorderEcosystemsAction } from '#src/routes/calibrate/ecosystem/sort'
import RouteBoundary from '#src/routes/route-boundary'

export default (client: QueryClient) =>
	({
		path: '/company/:companyId',
		element: <CompanyLayout />,
		children: [
			{
				path: 'details',
				element: <CompanyDetails />,
				errorElement: <RouteBoundary />,
				loader: companyDetailsLoader(client),
				children: [
					{
						path: 'edit',
						id: 'companyEdit',
						element: <EditCompany />,
						loader: editCompanyLoader(client),
						action: editCompanyAction(client),
					},
					{
						path: 'ecosystem/:ecosystemId?',
						id: 'ecosystem',
						element: <Ecosystem />,
						loader: ecosystemLoader(client),
						children: [
							{
								path: 'save',
								action: saveEcosystemAction(client),
							},
							{
								path: 'delete',
								action: deleteEcosystemAction(client),
							},
							{
								path: 'sort',
								action: reorderEcosystemsAction(client),
							},
						],
					},
				],
			},
		],
	}) satisfies RouteObject
