import { z } from 'zod'
import { CompanySchema, DashboardSchema } from '../calibrate/company/schema'

export const NewCompanyFormSchema = CompanySchema.omit({
	dashboard: true,
}).partial({
	id: true,
})
export const CompanyDashboardFormSchema = z.object({
	dashboard: DashboardSchema.omit({ name: true })
		.transform(v => ({ ...v, url: v.url ? v.url : null }))
		.array(),
})
