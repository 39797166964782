import { type QueryClient } from '@tanstack/react-query'
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type ActionFunctionArgs, useFetcher } from 'react-router-dom'
import { verticalKeys } from '#src/routes/calibrate/verticals/queries'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { requestCalculateTAM } from './mutations'

export const action =
	(queryClient: QueryClient) =>
	async ({ params }: ActionFunctionArgs) => {
		if (!params.companyId || !params.verticalId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		await requestCalculateTAM(params.companyId, params.verticalId)

		await queryClient.invalidateQueries({
			queryKey: verticalKeys.all,
		})

		return null
	}

export interface RequestCalculateTAMFormProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	verticalId: number
	children: ReactNode
	onSubmit?: () => void
}

export const RequestCalculateTAMForm = forwardRef<
	HTMLButtonElement,
	RequestCalculateTAMFormProps
>(({ verticalId, children, className, ...props }, ref) => {
	const params = useParsedRouteParams(['companyId', 'ecosystemId'])
	const fetcher = useFetcher()

	const action = routes.calibrate.verticals.tam({
		companyId: params.companyId,
		ecosystemId: params.ecosystemId,
		verticalId: verticalId.toString(),
	})

	return (
		<button
			{...props}
			ref={ref}
			type="button"
			disabled={fetcher.state !== 'idle'}
			className={cn(
				'w-full',
				fetcher.state !== 'idle' ? 'animate-pulse' : '',
				className,
			)}
			onClick={() => {
				fetcher.submit(null, { method: 'PUT', action })
			}}
		>
			{children}
		</button>
	)
})
RequestCalculateTAMForm.displayName = 'RequestCalculateTAMForm'
