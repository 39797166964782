import { type ReactNode } from 'react'
import { Icon, type IconName } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'
import { Surface } from './ui/surface'

export function DashboardCardCount({
	count,
	countSuffix,
}: {
	count: number
	countSuffix?: string
}) {
	return (
		<div className="mt-6 text-heading-md leading-none text-neutral-1-fg">
			{count ?? 0}
			{countSuffix && (
				<span className="text-body-md font-normal">
					&nbsp;{countSuffix ?? 0}
				</span>
			)}
		</div>
	)
}

export function DashboardCard({
	icon,
	title,
	isHoverable = false,
	className,
	children,
}: {
	icon: IconName
	title: ReactNode
	isHoverable?: boolean
	className?: string
	children?: ReactNode
}) {
	return (
		<Surface
			className={cn(
				'flex h-full w-full flex-col p-6',
				isHoverable ? 'transition-colors hover:bg-neutral-1-bg-hover' : '',
				className,
			)}
		>
			<div className="flex flex-nowrap items-center gap-2 text-title-md text-neutral-1-fg">
				<div className="flex h-8 w-8 flex-none items-center justify-center rounded border border-neutral-1-bd bg-neutral-2-bg">
					<Icon name={icon} size="font" className="text-brand-1-fg" />
				</div>
				{title}
			</div>
			{children ? <>{children}</> : null}
		</Surface>
	)
}
