import { queryOptions } from '@tanstack/react-query'
import { client as queryClient } from '#src/main'
import { api } from '#src/utils/endpoints'
import {
	PERSONA_TYPE_RANK,
	PRIORITY_RANK,
	STATUS_RANK,
} from '#src/utils/enumerations'
import { safeFetch } from '#src/utils/safeFetch'
import {
	CriteriaAPISchema,
	DataPointsAPISchema,
	EcosystemAPISchema,
	EcosystemListAPISchema,
	EcosystemTemplateListAPISchema,
} from './schema'

export const ecosystemKeys = {
	all: ['ecosystem'] as const,
	list: (companyId: string) =>
		[...ecosystemKeys.all, 'list', companyId] as const,
	details: () => [...ecosystemKeys.all, 'details'] as const,
	detail: (companyId: string, ecosystemId: string) =>
		[...ecosystemKeys.details(), companyId, ecosystemId] as const,
	criteria: (companyId: string, ecosystemId: string) =>
		[...ecosystemKeys.details(), 'criteria', companyId, ecosystemId] as const,
	datapoint: (companyId: string, ecosystemId: string) =>
		[...ecosystemKeys.details(), 'datapoint', companyId, ecosystemId] as const,
	templates: (companyId: string) =>
		[...ecosystemKeys.all, 'templates', companyId] as const,
}

export const ecosystemsQuery = (companyId: string) => ({
	queryKey: ecosystemKeys.list(companyId),
	queryFn: async () => {
		const ecosystems = await safeFetch(
			EcosystemListAPISchema,
			api.ecosystem.list(companyId),
			{
				method: 'GET',
			},
		)

		const sortedEcosystems = ecosystems.map(ecosystem => {
			const sortedVerticals = [...ecosystem.verticals].sort((a, b) => {
				const statusA = STATUS_RANK[a.status]
				const statusB = STATUS_RANK[b.status]
				const statusComparison = statusA - statusB
				if (statusComparison !== 0) {
					return statusComparison
				}

				const priorityComparison =
					PRIORITY_RANK[a.priority] - PRIORITY_RANK[b.priority]
				return priorityComparison
			})

			const verticals = sortedVerticals.map(vertical => {
				return {
					...vertical,
					personas: [...vertical.personas].sort((a, b) => {
						const typeA = PERSONA_TYPE_RANK[a.type]
						const typeB = PERSONA_TYPE_RANK[b.type]
						const typeComparison = typeA - typeB
						if (typeComparison !== 0) {
							return typeComparison
						}

						const statusA = STATUS_RANK[a.status]
						const statusB = STATUS_RANK[b.status]
						const statusComparison = statusA - statusB
						if (statusComparison !== 0) {
							return statusComparison
						}

						const priorityComparison =
							PRIORITY_RANK[a.priority] - PRIORITY_RANK[b.priority]
						return priorityComparison
					}),
				}
			})

			return {
				...ecosystem,
				verticals,
			}
		})

		return sortedEcosystems
	},
})
export const ecosystemQuery = (companyId: string, ecosystemId: string) => ({
	queryKey: ecosystemKeys.detail(companyId, ecosystemId),
	queryFn: async () => {
		return await safeFetch(
			EcosystemAPISchema,
			api.ecosystem.details(companyId, ecosystemId),
			{
				method: 'GET',
			},
		)
	},
	initialData: () => {
		const ecosystems = queryClient.getQueryData(
			ecosystemKeys.list(companyId),
		) as Awaited<
			ReturnType<ReturnType<typeof ecosystemsQuery>['queryFn']>
		> | null

		if (ecosystems?.length) {
			const ecosystem = ecosystems.find(v => v.id === Number(ecosystemId))

			if (ecosystem && EcosystemAPISchema.safeParse(ecosystem).success) {
				return ecosystem
			}

			return undefined
		}

		return undefined
	},
})

export const ecosystemsTemplatesQuery = (companyId: string) =>
	queryOptions({
		queryKey: ecosystemKeys.templates(companyId),
		queryFn: async () => {
			return await safeFetch(
				EcosystemTemplateListAPISchema,
				api.ecosystem.templates(companyId),
				{
					method: 'GET',
				},
			)
		},
	})

export const criteriaQuery = (companyId: string, ecosystemId: string) => ({
	queryKey: ecosystemKeys.criteria(companyId, ecosystemId),
	queryFn: async () => {
		return await safeFetch(
			CriteriaAPISchema,
			api.ecosystem.criteria(companyId, ecosystemId),
			{
				method: 'GET',
			},
		)
	},
})

export const dataPointsQuery = (companyId: string, ecosystemId: string) => ({
	queryKey: ecosystemKeys.datapoint(companyId, ecosystemId),
	queryFn: async () => {
		return await safeFetch(
			DataPointsAPISchema,
			api.ecosystem.datapoints(companyId, ecosystemId),
			{
				method: 'GET',
			},
		)
	},
})
