import {
	getFormProps,
	getInputProps,
	getTextareaProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type QueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import {
	type ActionFunctionArgs,
	useFetcher,
	type LoaderFunctionArgs,
	useRouteLoaderData,
	useNavigate,
} from 'react-router-dom'
import { type z } from 'zod'
import { ErrorList, Field, TextareaField } from '#src/components/forms'
import { DialogContent, DialogRoot } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { ecosystemKeys } from '../ecosystem/queries'
import { personaKeys } from '../personas/queries'
import { verticalKeys } from '../verticals/queries'
import { updateCompany } from './mutations'
import { companyKeys, companyQuery } from './queries'
import { type CompanySchema, EditCompanyFormSchema } from './schema'

type EditLoaderResponse = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		if (!params.companyId)
			throw new Response('Missing parameters', {
				status: 400,
				statusText: 'Bad Request',
			})

		const company = await queryClient.fetchQuery(companyQuery(params.companyId))

		if (!company) {
			throw new Response('Company Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		return {
			company,
		}
	}

type EditActionResponse = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(queryClient: QueryClient) =>
	async ({ request }: ActionFunctionArgs) => {
		const formData = await request.formData()

		const submission = parseWithZod(formData, { schema: EditCompanyFormSchema })

		if (submission.status !== 'success') {
			throw Error('Failed to parse form data')
		}

		try {
			const { id, ...companyForm } = submission.value
			const company = await updateCompany(id, companyForm)

			if (!company) {
				throw Error('Could not udpate company')
			}

			await queryClient.refetchQueries({
				queryKey: companyKeys.detail(company.id),
			})

			void queryClient.invalidateQueries({
				queryKey: ecosystemKeys.all,
			})
			void queryClient.invalidateQueries({
				queryKey: verticalKeys.all,
			})
			void queryClient.invalidateQueries({
				queryKey: personaKeys.all,
			})
			void queryClient.invalidateQueries({
				queryKey: personaKeys.all,
			})

			return {
				ok: true,
			}
		} catch (error) {
			let errorMessage =
				'Something went wrong with updating company details. Try again later.'

			if (error instanceof Response) {
				const errorBody = (await error.json()) as { message?: string }

				if (errorBody?.message) {
					errorMessage = errorBody.message
				}
			}

			return {
				ok: false,
				result: submission.reply({
					formErrors: [errorMessage],
				}),
			}
		}
	}

export default function EditCompany() {
	const data = useRouteLoaderData('companyEdit') as EditLoaderResponse
	const navigate = useNavigate()

	return (
		<DialogRoot
			defaultOpen={true}
			onOpenChange={isOpen =>
				!isOpen &&
				navigate(routes.calibrate.index({ companyId: data.company.id }))
			}
		>
			<DialogContent
				dialogTitle="Company Details"
				className="w-full max-w-prose"
			>
				<EditCompanyForm
					company={data.company}
					onSuccess={() =>
						navigate(routes.calibrate.index({ companyId: data.company.id }))
					}
					onCancel={() =>
						navigate(routes.calibrate.index({ companyId: data.company.id }))
					}
				/>
			</DialogContent>
		</DialogRoot>
	)
}

function EditCompanyForm({
	company,
	onSuccess,
	onCancel,
}: {
	company: z.infer<typeof CompanySchema>
	onSuccess?: () => void
	onCancel?: () => void
}) {
	const params = useParsedRouteParams(['companyId'])
	const fetcher = useFetcher()
	const fetcherData = fetcher.data as EditActionResponse
	const action = routes.calibrate.edit({
		companyId: params.companyId,
	})

	const [form, fields] = useForm({
		id: 'company-edit-form-' + company.id,
		constraint: getZodConstraint(EditCompanyFormSchema),
		defaultValue: company,
		lastResult: fetcherData?.result ?? null,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: EditCompanyFormSchema })
		},
		shouldValidate: 'onBlur',
		shouldRevalidate: 'onInput',
	})

	useEffect(() => {
		if (fetcherData?.ok && onSuccess) {
			onSuccess()
		}
	}, [fetcherData?.ok, onSuccess])

	return (
		<fetcher.Form
			method="PATCH"
			{...getFormProps(form)}
			action={action}
			className="flex w-full flex-col gap-6"
		>
			<input
				{...getInputProps(fields.id, { type: 'hidden' })}
				defaultValue={company.id}
			/>
			<Field
				labelProps={{ children: 'Name' }}
				inputProps={{
					...getInputProps(fields.name, { type: 'text' }),
					autoFocus: true,
					placeholder: 'Enter company name',
				}}
				errors={fields.name.errors}
			/>

			<div className="flex flex-col gap-4">
				<TextareaField
					labelProps={{
						children: 'Value Proposition',
					}}
					textareaProps={{
						...getTextareaProps(fields.valueProposition),
						placeholder: 'Paste or type here',
						rows: 10,
					}}
					errors={fields.valueProposition.errors}
				/>
				<p className="w-full rounded-sm bg-brand-1-bg p-2 text-center text-body-sm">
					Use our free{' '}
					<a
						href="https://chatgpt.com/g/g-5eVvHz6wz-value-prop-expert-v16"
						target="__blank"
						title="Value Prop Sage GPT"
						className="text-link transition-colors hover:text-link-hover active:text-link-pressed"
					>
						<Icon name="magic-wand" asSuffix>
							Value Proposition Sage GPT
						</Icon>
					</a>{' '}
					AI helper. Paste your Website URL, upload files and answer few
					questions to get your company&apos;s value proposition fast!
				</p>
			</div>

			<ErrorList errors={form.errors} id={form.errorId} />

			<div className="flex w-full justify-end gap-2">
				<StatusButton
					status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
					type="button"
					disabled={fetcher.state !== 'idle'}
					size="sm"
					className="min-w-32"
					variant="outline"
					onClick={() => onCancel?.()}
				>
					Cancel
				</StatusButton>
				<StatusButton
					status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
					type="submit"
					disabled={fetcher.state !== 'idle'}
					size="sm"
					className="min-w-32"
				>
					Save
				</StatusButton>
			</div>
		</fetcher.Form>
	)
}
