import { type z } from 'zod'
import { api } from '#src/utils/endpoints'
import { safeFetch, fetch } from '#src/utils/safeFetch'
import { type EditTermFormSchema, GlossaryTermAPISchema } from './schema'

export const createGlossaryTerm = async (
	companyId: string,
	data: z.infer<typeof EditTermFormSchema>,
) => {
	return await safeFetch(
		GlossaryTermAPISchema,
		api.glossary.create(companyId),
		{
			method: 'POST',
			body: JSON.stringify(data),
		},
	)
}

export const updateGlossaryTerm = async (
	companyId: string,
	termId: string,
	data: z.infer<typeof EditTermFormSchema>,
) => {
	return await safeFetch(
		GlossaryTermAPISchema,
		api.glossary.details(companyId, termId),
		{
			method: 'PATCH',
			body: JSON.stringify(data),
		},
	)
}
export const deleteGlossaryTerm = async (companyId: string, termId: string) => {
	return await fetch(api.glossary.details(companyId, termId), {
		method: 'DELETE',
	})
}
