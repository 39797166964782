import { type z } from 'zod'
import {
	EcosystemListAPISchema,
	type EcosystemsOrderFormSchema,
} from '#src/routes/calibrate/ecosystem/schema'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { CompanySchema, type EditCompanyFormSchema } from './schema'

export const updateCompany = async (
	companyId: string,
	data: Omit<z.infer<typeof EditCompanyFormSchema>, 'id'>,
) =>
	await safeFetch(CompanySchema, api.company.details(companyId), {
		method: 'PATCH',
		body: JSON.stringify(data),
	})

export const reorderEcosystems = async (
	companyId: string,
	data: z.infer<typeof EcosystemsOrderFormSchema>,
) => {
	await safeFetch(EcosystemListAPISchema, api.company.sort(companyId), {
		method: 'POST',
		body: JSON.stringify(data),
	})
}
