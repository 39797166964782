import { z } from 'zod'
import { TAM_STATUS_ENUM } from '#src/utils/enumerations'

export const TAMSchema = z.object({
	estimateMin: z.number().nullable().optional(),
	estimateMax: z.number().nullable().optional(),
	gap: z.number().nullable().optional(),
	inCrm: z.number().nullable().optional(),
	dormantEstimate: z
		.object({
			toQualify: z.number().nullable().optional(),
			noActivities: z.number().nullable().optional(),
			lostOpportunity: z.number().nullable().optional(),
			churned: z.number().nullable().optional(),
		})
		.nullable()
		.optional(),
	working: z.number().nullable().optional(),
	opportunity: z.number().nullable().optional(),
	customer: z.number().nullable().optional(),
	status: z
		.enum([
			TAM_STATUS_ENUM.REQUESTED,
			TAM_STATUS_ENUM.IN_PROGRESS,
			TAM_STATUS_ENUM.COMPLETED,
		])
		.nullable()
		.optional(),
})
